import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    makeStyles,
    Slide,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
  } from "@material-ui/core";
  import React, { useEffect, useMemo, useState } from "react";
  import _ from "lodash";
  import GridContainer from "../../components/Grid/GridContainer";
  import GridItem from "../../components/Grid/GridItem";
  import { Autocomplete } from "@material-ui/lab";
  import AddIcon from "@material-ui/icons/Add";
  import axios from "axios";
  import {
    CreateBillAPI,
    GetAllAccountsAPI,
    GetAllBanksAPI,
    GetAllBillsAPI,
    GetAllChallansAPI,
    UpdateChallanAPI,
    GetAccountAPI,
    GetEntityAPI,
    GetAllCreditsAPI,
    GetAllCreditBillsAPI,
    UpdateCreditAPI,
    CreateCreditBillAPI,
    UpdateCreditBillAPI,
  } from "../../config/api";
  import { Receipt } from "@material-ui/icons";
  import VisibilityIcon from "@material-ui/icons/Visibility";
  import BillView from "./BillView";
  import Moment from "moment";
  
  import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
  import DateUtils from "@date-io/moment";
  import { invoiceTemplate } from "../../assets/html/invoiceTemplate";
  import { dataURItoBlob, htmlTemplateToDataString } from "../../utils";
  import { extendMoment } from "moment-range";
  import Card from "../../components/Card/Card.js";
  import CardHeader from "../../components/Card/CardHeader.js";
  import { useSelector } from "react-redux";
  import { toast } from "react-toastify";
  
  const moment = extendMoment(Moment);
  const useStyles = makeStyles((theme) => ({
    fab: {
      position: "absolute",
      bottom: theme.spacing(-24),
      right: theme.spacing(2),
    },
    formControl: {
      paddingBottom: "10px",
      margin: "27px 0 0 0",
      position: "relative",
      verticalAlign: "unset",
    },
    cardTitleWhite: {
      color: "#FFFFFF",
      marginTop: "0px",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1",
      },
    },
  }));
  
  const columns = [
    { id: "challanCodes", label: "Challan Codes", minWidth: 100 },
    { id: "code", label: "Code", minWidth: 100 },
    { id: "party", label: "Party", minWidth: 100 },
    // { id: "paymentStatus", label: "Payment Status", minWidth: 100 },
    {
      id: "createdAt",
      label: "Created At",
      format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
    },
    { id: "action", label: "Action" },
  ];
  
  const groupedChallansColumns = [
    {
      id: "party",
      label: "Party",
    },
    { id: "code", label: "Code" },
    { id: "amount", label: "Amount" },
  
    {
      id: "createdAt",
      label: "Created At",
      format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
    },
  ];
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
  export const BillListCredit = (props) => {
    const { searchTerm, filter, accounts } = props;
    const classes = useStyles();
    const authToken = useSelector((state) => state.userReducer.authToken);
  
    const [bills, setBills] = useState([]);
    const [openBillAddDialog, setOpenBillAddDialog] = useState(false);
  
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openViewDialog, setOpenViewDialog] = useState(null);
    const [openBulkBillDialog, setOpenBulkBillDialog] = useState(false);
    const [openAccountViewDialog, setOpenAccountViewDialog] = useState(null);
    const [challans, setChallans] = useState([]);
    let userInfo = useSelector((state) => state.userReducer.userInfo);
  
    const [entity, setEntity] = useState("");
  
    useEffect(() => {
      _getBills();
      _getChallans();
    }, [openBulkBillDialog]);
  
    useEffect(() => {
      _getEntity();
    }, []);
  
    async function _getEntity() {
      try {
        let res = await axios.get(GetEntityAPI(userInfo.entity), {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setEntity(res.data);
      } catch (err) {
        setEntity("");
        // toast.error(('Cannot fetch data'), {
        //   position: "top-center",
        //   autoClose: 1500,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
      }
    }
  
    // async function _getChallans() {
    //   try {
    //     let res = await axios.get(GetAllCreditsAPI(userInfo.entity), {
    //       headers: {
    //         Authorization: `Bearer ${authToken}`,
    //       },
    //     });
    //     setChallans(res.data.challans);
    //   } catch (err) {
    //     // console.log(err);
    //     // toast.error(('Cannot fetch data'), {
    //     //   position: "top-center",
    //     //   autoClose: 1500,
    //     //   hideProgressBar: false,
    //     //   closeOnClick: true,
    //     //   pauseOnHover: true,
    //     //   draggable: true,
    //     //   progress: undefined,
    //     //   theme: "light",
    //     // });
    //   }
    // }

    // const _getChallans = useMemo(
    //     () => async () => {
    
    //       try {
    //         let query = `&isBilled=${false}&paymentStatus=${"Pending"}&status=${"Active"}`;
    //         // let query = `&isBilled=${false}`;
    //         let res = await axios.get(GetAllCreditsAPI(userInfo.entity) + query, {
    //           headers: {
    //             Authorization: `Bearer ${authToken}`,
    //           },
    //         });
    //         console.log(res, "credit listbill")    
    //         setChallans(res.data.credits);
    //         // setTota(res.data.total);
    //       } catch (err) {
    //         // Handle error
    //         toast.error("Error occured while fetching Transactions.");
    //       }
    //     },
    //     []
    //   );

    const _getChallans = async () => {
        try {
          let query = `&isBilled=${false}&paymentStatus=${"Pending"}&status=${"Active"}`;
          // let query = `&isBilled=${false}`;
          let res = await axios.get(GetAllCreditsAPI(userInfo.entity) + query, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });
          console.log(res, "credit listbill");
          setChallans(res.data.credits);
          // setTota(res.data.total);
        } catch (err) {
          // Handle error
          toast.error("Error occurred while fetching Transactions.");
        }
      };
      
  
    async function _getBills() {
      try {
        // let query = `&search=${searchTerm}`;
        let res = await axios.get(GetAllCreditBillsAPI(userInfo.entity), {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setBills(res.data.bills);
        console.log(res.data, "my res")
      } catch (err) {
        // console.log(err);
        // toast.error(('Cannot fetch data'), {
        //   position: "top-center",
        //   autoClose: 1500,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
      }
    }
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  
    function closeViewDialog() {
      setOpenViewDialog(null);
    }
  
    async function _onPreview(selectedItem) {
      console.log(selectedItem)
      if (_.isEmpty(selectedItem)) {
        return;
      }
      let htmlData = {},
        htmlTemplate = {};
      let { party } = selectedItem;
      let res = await axios.get(GetAllBanksAPI(userInfo.entity));
  
      let accountRes = await axios.get(GetAccountAPI(party.accountId), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      let partyGstNumber = accountRes.data.gstNumber;
      let partyAddress = accountRes.data.city + "," + accountRes.data.state;
      console.log(selectedItem)
      htmlData = {
        clientAddress: partyAddress,
        isEligibleForIgst: party?.isEligibleForIgst,
        clientName: party.accountName,
        clientGSTNo: partyGstNumber,
        challanDate: moment().format("DD-MM-YYYY"),
        items: selectedItem.items,
        bank: res.data.banks[0],
        billCode: selectedItem.code,
        entityDetails: entity,
        packagingCosts: selectedItem.packagingCosts,
        packagingTaxPercent: selectedItem.packagingTaxPercents
      };
      let options = {
        orientation: "p",
        unit: "pt",
        format: [850, 1066],
        // format:'letter',
        putOnlyUsedFonts: true,
      };
      htmlTemplate = invoiceTemplate(htmlData);
      await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
        const preBlob = dataURItoBlob(dataString);
        window.open(URL.createObjectURL(preBlob));
      });
    }
  
    const RenderGroupChallansRow = (props) => {
      let { row } = props;
      if (!row) {
        return null;
      }
      let { orderId, orderNo, challanId, orderDate, party, items } = row;
      return (
        <>
          <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
            {groupedChallansColumns.map((column) => {
              let value = row[column.id];
              if (column.id === "party") {
                value = value ? value.accountName : "";
              }
  
              return (
                <TableCell
                  key={column.id}
                  align={column.align}
                  onClick={async () => {
                    if (column.id === "party") {
                      try {
                        let res = await axios.get(GetAccountAPI(row._id), {
                          headers: {
                            Authorization: `Bearer ${authToken}`,
                          },
                        });
                        setOpenAccountViewDialog({
                          open: true,
                          item: res.data,
                        });
                      } catch (e) {
                        // console.log("err:", e);
                        // toast.error(("Something went wrong, Please Contact Admin") , {
                        //   position: "top-center",
                        //   autoClose: 1500,
                        //   hideProgressBar: false,
                        //   closeOnClick: true,
                        //   pauseOnHover: true,
                        //   draggable: true,
                        //   progress: undefined,
                        //   theme: "light",
                        //   }); 
                      }
                    }
                  }}
                >
                  {column.format && typeof value === "number"
                    ? column.format(value)
                    : value}
                </TableCell>
              );
            })}
          </TableRow>
        </>
      );
    };
  
    const BillAddDialog = () => {
        const [users, setUsers] = useState([]);
        const [challans, setChallans] = useState([]);
        const [selectedChallans, setSelectedChallans] = useState([]);
        const [buttonType, setButtonType] = useState(true);
        const [party, setParty] = useState(null);
        const [banks, setBanks] = useState([]);
        const [bank, setBank] = useState(null);
      
        let userInfo = useSelector((state) => state.userReducer.userInfo);
        let authToken = useSelector((state) => state.userReducer.authToken);
      
        // Fetch Banks on component mount
        useEffect(() => {
          _getBanks();
        }, []);
      
        // Fetch Challans when party changes
        useEffect(() => {
          if (party) {
            _getChallans();
          }
        }, [party]);
      
        // Fetch bank data
        const _getBanks = async () => {
          try {
            const res = await axios.get(GetAllBanksAPI(userInfo.entity), {
              headers: { Authorization: `Bearer ${authToken}` },
            });
            setBanks(res.data.banks);
          } catch (e) {
            toast.error('Error fetching banks');
          }
        };
      
        // Fetch Challans based on party
        const _getChallans = async () => {
          try {
            let query = `&isBilled=${false}&paymentStatus=${"Pending"}&status=${"Active"}`;
            const res = await axios.get(GetAllCreditsAPI(userInfo.entity) + query, {
              headers: { Authorization: `Bearer ${authToken}` },
            });
            setChallans(res.data.credits);
          } catch (err) {
            toast.error("Error occurred while fetching Transactions.");
          }
        };
      
        // Generate Bill
        const _onGenerate = async () => {
          if (!bank || selectedChallans.length === 0) return;
      
          try {
            let packagingCosts = [];
            let packagingTaxPercents = [];
            let creditIds = [];
            let creditCodes = [];
            let totalAmount = 0;
            let items = [];
      
            for (const i of selectedChallans) {
              creditIds.push(i._id);
              creditCodes.push(i.code);
              packagingCosts.push(Number(i.packagingCost));
              packagingTaxPercents.push(Number(i.packagingTaxPercent));
              items = _.concat(items, i.items);
      
              if (i.amount) {
                totalAmount += Number(i.amount);
              }
      
              // Mark challan as billed
              await axios.patch(UpdateCreditBillAPI(i._id), { isBilled: true }, {
                headers: { Authorization: `Bearer ${authToken}` },
              });
            }
      
            const bill = {
              entity: userInfo.entity,
              creditIds,
              creditCodes,
              packagingCosts,
              packagingTaxPercents,
              items,
              bankDetail: bank,
              party: {
                accountId: party._id,
                accountCode: party.accountCode,
                accountName: party.name,
                accountAddress: party.address,
                accountGst: party.gst,
                isEligibleForIgst: party.isEligibleForIgst,
              },
              createdAt: Date.now(),
              entityDetails: entity,
            };
      
            // Create Bill
            await axios.post(CreateCreditBillAPI(), bill, {
              headers: { Authorization: `Bearer ${authToken}` },
            });
      
            setOpenBillAddDialog(false);
            toast.success("Bill Generated Successfully", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
      
            _getBills(); // Refresh bills after successful generation
          } catch (e) {
            toast.error("Something went wrong, Please Contact Admin", {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        };
      
        return (
          <Dialog
            TransitionComponent={Transition}
            maxWidth={"md"}
            fullWidth
            open={openBillAddDialog}
            onClose={() => setOpenBillAddDialog(false)}
          >
            <DialogContent>
              <GridContainer spacing={4}>
                <GridItem xs={12} sm={12} md={12}>
                  <Autocomplete
                    className={classes.formControl}
                    options={accounts}
                    fullWidth
                    value={party}
                    onChange={(event, value) => setParty(value)}
                    getOptionLabel={(option) => option.name}
                    id="party"
                    renderInput={(params) => <TextField {...params} label="Party" />}
                  />
                </GridItem>
      
                <GridItem xs={12} sm={12} md={12}>
                  <Autocomplete
                    multiple
                    className={classes.formControl}
                    options={challans}
                    fullWidth
                    value={selectedChallans}
                    onChange={(event, value) => setSelectedChallans(value)}
                    getOptionLabel={(option) => option.code}
                    id="orders"
                    renderInput={(params) => <TextField {...params} label="Challans" />}
                  />
                </GridItem>
      
                <GridItem xs={12} sm={12} md={12}>
                  <Autocomplete
                    className={classes.formControl}
                    options={banks}
                    fullWidth
                    value={bank}
                    onChange={(event, value) => setBank(value)}
                    getOptionLabel={(option) => option.bankName}
                    id="banks"
                    renderInput={(params) => <TextField {...params} label="Banks" />}
                  />
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => _onGenerate()}
                color="primary"
                disabled={selectedChallans.length > 0 && bank !== null ? false : true}
              >
                Generate
              </Button>
            </DialogActions>
          </Dialog>
        );
      };
      
  
    // const BulkBillDialog = () => {
    //   const [startDate, setStartDate] = useState(null);
    //   const [endDate, setEndDate] = useState(null);
    //   const [party, setParty] = useState([]);
    //   const [users, setUsers] = useState([]);
    //   const [banks, setBanks] = useState([]);
    //   const [bank, setBank] = useState(null);
    //   const [groupedChallans, setGroupedChallans] = useState([]);
    //   let userInfo = useSelector((state) => state.userReducer.userInfo);
  
    //   useEffect(() => {
    //     // _getUsers();
    //     _getBanks();
    //   }, []);
  
    // //   async function _getUsers() {
    // //     try {
    // //       let res = await axios.get(GetAllAccountsAPI(userInfo.entity), {
    // //         headers: {
    // //           Authorization: `Bearer ${authToken}`,
    // //         },
    // //       });
    // //       setUsers(res.data.accounts);
    // //     } catch (e) {
    // //       // toast.error(('Cannot fetch data'), {
    // //       //   position: "top-center",
    // //       //   autoClose: 1500,
    // //       //   hideProgressBar: false,
    // //       //   closeOnClick: true,
    // //       //   pauseOnHover: true,
    // //       //   draggable: true,
    // //       //   progress: undefined,
    // //       //   theme: "light",
    // //       // });
    // //     }
    // //   }
  
    //   async function _getBanks() {
    //     try {
    //       let res = await axios.get(GetAllBanksAPI(userInfo.entity));
    //       setBanks(res.data.banks);
    //     } catch (e) {
    //       // console.log("er:", e);
    //       // toast.error(('Cannot fetch data'), {
    //       //   position: "top-center",
    //       //   autoClose: 1500,
    //       //   hideProgressBar: false,
    //       //   closeOnClick: true,
    //       //   pauseOnHover: true,
    //       //   draggable: true,
    //       //   progress: undefined,
    //       //   theme: "light",
    //       // });
    //     }
    //   }
  
    //   async function _groupChallans() {
    //     let party_id = party.map((item) => item._id);
  
    //     // select challans which fall in range, pending payment status, is not billed prior, and is of selected party
    //     let selected_challans = _.filter(challans, (item) => {
    //       let updatedAt = moment(item.updatedAt).toDate();
    //       let startDt = moment(startDate, "DD-MM-YY");
    //       let endDt = moment(endDate, "DD-MM-YY");
    //       let range = moment().range(startDt, endDt);
  
    //       if (
    //         _.includes(party_id, item.party.accountId) &&
    //         item.paymentStatus === "Pending" &&
    //         item.isBilled === false &&
    //         range.contains(updatedAt)
    //       ) {
    //         return true;
    //       }
    //     });
    //     setGroupedChallans(selected_challans);
    //   }
  
    //   async function _createBill() {
    //     // create dict by grouping companies by their challans.
    //     let challanObj = {};
  
    //     _.forEach(groupedChallans, (item) => {
    //       if (_.includes(Object.keys(challanObj), item.party.accountId)) {
    //         challanObj[item.party.accountId].push(item);
    //       } else {
    //         challanObj[item.party.accountId] = [item];
    //       }
    //     });
  
    //     // iterate over each company and create bill for all grouped challans.
    //     _.forEach(Object.keys(challanObj), async (item) => {
    //       let party = _.filter(users, { _id: item })[0];
    //       let companyChallans = challanObj[item];
    //       let challanIds = [],
    //         challanCodes = [],
    //         totalAmount = 0,
    //         items = [];
    //       _.map(companyChallans, (i) => {
    //         challanIds.push(i._id);
    //         challanCodes.push(i.code);
    //         items = _.concat(items, i.items);
    //         if (i.amount) {
    //           totalAmount = Number(totalAmount) + Number(i.amount) + Number(i.packagingCost);
    //           totalAmount = Number(totalAmount) + Number(i.taxPercent * totalAmount)
    //         }
    //       });
    //       let bill = {
    //         entity: userInfo.entity,
    //         challanIds,
    //         challanCodes,
    //         items,
    //         amount: totalAmount,
    //         bankDetail: bank,
    //         party: {
    //           accountId: party._id,
    //           accountCode: party.accountCode,
    //           accountName: party.name,
    //           accountAddress: party.address,
    //           accountGst: party.gst,
    //         },
    //         createdAt: Date.now(),
    //       };
    //       try {
    //         let res = await axios.post(CreateBillAPI(), bill, {
    //           headers: {
    //             Authorization: `Bearer ${authToken}`,
    //           },
    //         });
    //       } catch (e) {
    //         // console.log(e);
    //         // toast.error(("Something went wrong, Please Contact Admin") , {
    //         //   position: "top-center",
    //         //   autoClose: 1500,
    //         //   hideProgressBar: false,
    //         //   closeOnClick: true,
    //         //   pauseOnHover: true,
    //         //   draggable: true,
    //         //   progress: undefined,
    //         //   theme: "light",
    //         //   }); 
    //       }
    //     });
  
    //     // set isBilled true for grouped challans;
    //     _.forEach(groupedChallans, async (item) => {
    //       item["isBilled"] = true;
    //       try {
    //         let challan_res = await axios.patch(
    //           UpdateChallanAPI(item._id),
    //           item,
    //           {
    //             headers: {
    //               Authorization: `Bearer ${authToken}`,
    //             },
    //           }
    //         );
    //       } catch (e) {
    //         // console.log(e);
    //         // toast.error(("Something went wrong, Please Contact Admin") , {
    //         //   position: "top-center",
    //         //   autoClose: 1500,
    //         //   hideProgressBar: false,
    //         //   closeOnClick: true,
    //         //   pauseOnHover: true,
    //         //   draggable: true,
    //         //   progress: undefined,
    //         //   theme: "light",
    //         //   }); 
    //       }
    //     });
    //     setOpenBulkBillDialog(false);
    //   }
  
    //   return (
    //     <Dialog
    //       onClose={() => setOpenBulkBillDialog(false)}
    //       aria-labelledby="simple-dialog-title"
    //       open={openBulkBillDialog}
    //       fullScreen
    //     >
    //       <DialogContent>
    //         <CardHeader color="primary" style={{ marginTop: 16 }}>
    //           <Box
    //             display={"flex"}
    //             alignItems={"center"}
    //             justifyContent={"space-between"}
    //           >
    //             <h4 className={classes.cardTitleWhite}>Create Bulk Bill</h4>
    //             <Button
    //               className={classes.cardTitleWhite}
    //               color={"white"}
    //               onClick={() => setOpenBulkBillDialog(false)}
    //             >
    //               Close
    //             </Button>
    //           </Box>
    //         </CardHeader>
    //         <GridContainer>
    //           <MuiPickersUtilsProvider utils={DateUtils}>
    //             <GridItem xs={12} sm={12} md={4} style={{ marginTop: 20 }}>
    //               <DatePicker
    //                 label="Start Date"
    //                 fullWidth
    //                 value={startDate}
    //                 onChange={(date) => setStartDate(date)}
    //                 animateYearScrolling
    //               />
    //             </GridItem>
    //             <GridItem xs={12} sm={12} md={4} style={{ marginTop: 20 }}>
    //               <DatePicker
    //                 label="End Date"
    //                 fullWidth
    //                 value={endDate}
    //                 onChange={(date) => setEndDate(date)}
    //                 animateYearScrolling
    //               />
    //             </GridItem>
    //             <GridItem xs={12} sm={12} md={4}>
    //               <Autocomplete
    //                 className={classes.formControl}
    //                 options={banks}
    //                 fullWidth
    //                 value={bank}
    //                 onChange={(event, value) => setBank(value)}
    //                 getOptionLabel={(option) => option.bankName}
    //                 id="banks"
    //                 renderInput={(params) => (
    //                   <TextField {...params} label="Banks" />
    //                 )}
    //               />
    //             </GridItem>
    //             <GridItem xs={12} sm={12} md={12}>
    //               <Autocomplete
    //                 multiple
    //                 className={classes.formControl}
    //                 // options={_.filter(users, { cashPreference: true })}
    //                 options={_.filter(users, { cashPreference: false })}
    //                 fullWidth
    //                 value={party}
    //                 onChange={(event, value) => setParty(value)}
    //                 getOptionLabel={(option) => option.name}
    //                 id="party"
    //                 style={{ marginTop: 26 }}
    //                 renderInput={(params) => (
    //                   <TextField {...params} label="Party" />
    //                 )}
    //               />
    //             </GridItem>
  
    //             <GridItem
    //               xs={12}
    //               sm={12}
    //               md={12}
    //               style={{
    //                 display: "flex",
    //                 justifyContent: "center",
    //                 alignItems: "center",
    //               }}
    //             >
    //               <Button
    //                 type="button"
    //                 variant="contained"
    //                 color="primary"
    //                 onClick={_groupChallans}
    //               >
    //                 Generate Grouped Challans
    //               </Button>
    //             </GridItem>
    //           </MuiPickersUtilsProvider>
    //         </GridContainer>
    //         {!_.isEmpty(groupedChallans) && (
    //           <Table stickyHeader aria-label="sticky table">
    //             <TableHead>
    //               <TableRow>
    //                 {groupedChallansColumns.map((column) => (
    //                   <TableCell
    //                     key={column.id}
    //                     align={column.align}
    //                     style={{ minWidth: column.minWidth }}
    //                   >
    //                     {column.label}
    //                   </TableCell>
    //                 ))}
    //               </TableRow>
    //             </TableHead>
    //             <TableBody>
    //               {groupedChallans
    //                 .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    //                 .map((row) => {
    //                   return <RenderGroupChallansRow {...props} row={row} />;
    //                 })}
    //             </TableBody>
    //           </Table>
    //         )}
    //         {!_.isEmpty(groupedChallans) && (
    //           <TablePagination
    //             rowsPerPageOptions={[10, 25, 100]}
    //             component="div"
    //             count={data.length}
    //             rowsPerPage={rowsPerPage}
    //             page={page}
    //             onPageChange={handleChangePage}
    //             onRowsPerPageChange={handleChangeRowsPerPage}
    //           />
    //         )}
    //       </DialogContent>
    //       <DialogActions>
    //         {!_.isEmpty(groupedChallans) && (
    //           <Button
    //             variant="contained"
    //             type="button"
    //             color="primary"
    //             onClick={_createBill}
    //           >
    //             Generate Bills
    //           </Button>
    //         )}
    //       </DialogActions>
    //     </Dialog>
    //   );
    // };
  
  
    async function searchBills(searchTerm) {
      console.log("search>>>>", searchTerm)
      try {
        let query = `&search=${searchTerm}`;
        let res = await axios.get(GetAllBillsAPI(userInfo.entity) + query, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
  
        console.log('search bills', res.data);
        setBills(res.data.bills);
        // Process the result of the API call
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    }
  
    let data = bills;
    useEffect(() => {
      if (searchTerm !== "") {
        searchBills(searchTerm);
      } else {
        _getBills();
      }
    }, [searchTerm]);
    // if (searchTerm !== "") {
    //   data = _.filter(data, (i) => {
    //     return (
    //       (i.challanCodes && i.challanCodes.includes(searchTerm)) ||
    //       (i.code && i.code.toLowerCase().includes(searchTerm.toLowerCase()))
    //     );
    //   });
    // }
  
    if (filter) {
      let { startDate, endDate, party, status } = filter;
      if (startDate && endDate) {
        data = _.filter(data, (i) => {
          return (
            moment(startDate).isSame(moment.utc(i.createdAt), "day") ||
            moment(i.createdAt).isBetween(
              moment.utc(startDate),
              moment.utc(endDate),
              "day"
            ) ||
            moment(endDate).isSame(moment.utc(i.createdAt), "day")
          );
        });
      }
      if (party) {
        data = _.filter(data, (i) => {
          return i.party.accountName === party;
        });
      }
      if (status) {
        data = _.filter(data, (i) => {
          return i.paymentStatus === status;
        });
      }
    }
  
    return (
      <>
        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          sx={{ m: 2 }}
        >
          {/* <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenBulkBillDialog(true)}
          >
            Bulk Bills
          </Button> */}
          <IconButton aria-label="add" onClick={() => setOpenBillAddDialog(true)}>
            <AddIcon />
          </IconButton>
        </Box>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                console.log(row, "singlerow")
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      let value = row[column.id];
  
                      if (column.id === "challanCodes") {
                        let challanString = "";
                        row.creditCodes.forEach((i, index) => {
                          if (index + 1 === row.creditCodes.length) {
                            challanString += i;
                          } else {
                            challanString = challanString + i + ", ";
                          }
                        });
  
                        return <TableCell>{challanString}</TableCell>;
                      }
  
                      if (column.id === "createdAt") {
                        return (
                          <TableCell>
                            {moment(value).format("DD-MM-YYYY")}
                          </TableCell>
                        );
                      }
  
                      if (column.id === "party") {
                        value = value.accountName;
                      }
                      if (column.id === "action") {
                        return (
                          <TableCell align={"center"}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <IconButton
                                onClick={() => _onPreview(row)}
                                aria-label="view"
                              >
                                <Receipt />
                              </IconButton>
                              <IconButton
                                aria-label="view"
                                onClick={() =>
                                  setOpenViewDialog({
                                    open: true,
                                    item: row,
                                  })
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/*<Fab color="primary" aria-label="add" className={classes.fab}*/}
        {/*     onClick={() => setOpenBillAddDialog(true)}>*/}
        {/*    <AddIcon/>*/}
        {/*</Fab>*/}
        <BillAddDialog />
        {/* <BulkBillDialog /> */}
        {openViewDialog ? (
          <BillView
            open={openViewDialog.open}
            close={closeViewDialog}
            item={openViewDialog.item}
          />
        ) : null}
      </>
    );
  };
  