// import axios from "axios";
import React, { useState } from "react";
import { Grid, Dialog, DialogTitle, Button } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import { Chip } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

const statusList = [{ name: "Paid" }, { name: "Pending" }];

export const FilterDialog = (props) => {
  const {
    filter,
    setFilter,
    showFilterDialog,
    handleCloseFilterDialog,
    party,
  } = props;

  const [selectedStartDate, setSelectedStartDate] = useState(
    filter?.startDate ? filter.startDate : null
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    filter?.endDate ? filter.endDate : null
  );

  const [selectedParty, setSelectedParty] = useState(
    filter?.party ? filter.party : null
  );

  const [status, setStatus] = useState(filter?.status ? filter.status : null);

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      onClose={handleCloseFilterDialog}
      aria-labelledby="filter"
      open={showFilterDialog}
    >
      <DialogTitle id="filter">Filter</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item xs={6}>
              <KeyboardDatePicker
                id="selectedStartDate"
                label="Selected Start Date"
                format="dd/MM/yyyy"
                fullWidth
                size={"small"}
                inputVariant={"outlined"}
                value={selectedStartDate}
                onChange={(date) => setSelectedStartDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <KeyboardDatePicker
                id="selectedEndDate"
                label="Selected End Date"
                format="dd/MM/yyyy"
                fullWidth
                size={"small"}
                inputVariant={"outlined"}
                value={selectedEndDate}
                onChange={(date) => setSelectedEndDate(date)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </Grid>
          </MuiPickersUtilsProvider>

          <Grid item xs={12}>
            <Autocomplete
              id="tags-filled"
              options={party.map((option) => option)}
              value={selectedParty}
              onChange={(event, value) => setSelectedParty(value)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Party"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="tags-filled"
              options={statusList.map((option) => option.name)}
              value={status}
              onChange={(event, value) => setStatus(value)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Status"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setFilter(null);
            handleCloseFilterDialog();
          }}
          color="primary"
        >
          Clear
        </Button>
        <Button
          onClick={() => {
            if (selectedStartDate) {
              if (!selectedEndDate) {
                return alert("Please select end date");
              }
            }
            if (selectedEndDate) {
              if (!selectedStartDate) {
                return alert("Please select start date");
              }
            }
            let filter = {
              startDate: selectedStartDate,
              endDate: selectedEndDate,
              party: selectedParty,
              status: status,
            };
            setFilter(filter);
            handleCloseFilterDialog();
          }}
          color="primary"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
