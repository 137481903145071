import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import { DeletePaymentAPI, GetAllPaymentsAPI } from "../../config/api";
import DeleteIcon from "@material-ui/icons/Delete";
import { ConfirmationDialog } from "../../components/common";
import PaymentAdd from "./PaymentAdd";
import PaymentEdit from "./PaymentEdit";
import EditIcon from "@material-ui/icons/Edit";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInput";
import People from "@material-ui/icons/People";
import _ from "lodash";
import moment from "moment";
import PrintIcon from '@mui/icons-material/Print';

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { dataURItoBlob, htmlTemplateToDataString } from "../../utils";
import { paymentSlipTemplate } from "../../assets/html/readyOrderTemplate";
import { Search } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const columns = [
  { id: "paymentFor", label: "Payment For" },
  { id: "code", label: "Code" },
  { id: "party", label: "Party" },
  {
    id: "paymentDate",
    label: "Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : "NA"),
  },
  {
    id: "amount", label: "Amount",
    format: (value) =>
      Number(value).toLocaleString("en-IN", {
        maximumFractionDigits: 2,
        style: "currency",
        currency: "INR",
      }),
  },

  { id: "action", label: "Action" },
];

export default function PaymentList() {
  const classes = useStyles();
  const userInfo = useSelector((state) => state.userReducer.userInfo);

  const authToken = useSelector((state) => state.userReducer.authToken);
  const [payments, setPayments] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [tota, setTota] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  useEffect(() => {
    _getPayments(page, rowsPerPage);
  }, [page, rowsPerPage]);

  // Memoize the result of the function
  // const memoizedResult = useMemo(() => _getPayments(), []);
  // console.log("here>>>>", memoizedResult)

  async function _getPayments(page, rowsPerPage) {
    // let skipm = page + 2
    try {
      let query = `&limit=${rowsPerPage}&skip=${page * rowsPerPage}&sortBy=createdAt:desc`;
      let res = await axios.get(GetAllPaymentsAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("aaya>>>", res.data)
      setPayments(res.data.payments);
      setTota(res.data.total);
    } catch (err) {
      // console.log(err);
    }
  }

  async function _deleteItem(item) {
    try {
      let res = await axios.delete(DeletePaymentAPI(item._id), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      _getPayments()
      window.location.reload();
      toast.success("Deleted Successfully")
    } catch (e) {
      console.log("err:", e);
    }
  }


  const handlePrint = async (row) => {
    let options = {
      orientation: "p",
      unit: "pt",
      // format: [400, 800],
      format: 'a4',
      putOnlyUsedFonts: true,
    };
    let htmlTemplate = paymentSlipTemplate(row);
    await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
      const preBlob = dataURItoBlob(dataString);
      window.open(URL.createObjectURL(preBlob, "_blank"));
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function closeAddDialog() {
    setOpenAddDialog(false);
  }

  function closeEditDialog() {
    setOpenEditDialog(null);
  }

  const handleOpenConfirmationModal = (object) => {
    setOpenConfirmationModal(object);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(null);
  };

  async function searchChallan(searchTerm) {
    try {
      console.log("searchin", searchTerm)
      let query = `&search=${searchTerm}&sortBy=createdAt: desc`;
      let res = await axios.get(GetAllPaymentsAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setPayments(res.data.payments);
      setTota(res.data.total);
      // Process the result of the API call
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  }

  let data = payments;
  // if (searchTerm !== "") {
  //   data = _.filter(data, (i) => {
  //     return i.code && i.code.toLowerCase().includes(searchTerm.toLowerCase());
  //   });
  // }

  useEffect(() => {
    if (searchTerm !== "") {
      console.log("search>>>", searchTerm)
      searchChallan(searchTerm);
    } else {
      _getPayments(page, rowsPerPage)
    }
  }, [searchTerm]);

  function handleSearch() {
    setSearchTerm(search);
  }

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedPayments = React.useMemo(() => {
    let sortablePayments = [...payments];
    if (sortConfig.key) {
      sortablePayments.sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortablePayments;
  }, [payments, sortConfig]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <Box
              style={{
                display: "flex",
                flex: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4 className={classes.cardTitleWhite}>Payments</h4>
              <Box
                style={{
                  display: "flex",
                  flex: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomInput
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    style: {
                      color: "white",
                      marginTop: 0,
                    },
                    placeholder: "Search",
                    value: search,
                    // onChange: (e) => setSearch(e.target.value),
                    onChange: (e) => {
                      setSearch(e.target.value);
                      if (e.target.value === "" || e.target.value === null) {
                        _getPayments(page, rowsPerPage)
                      }

                    },
                    // onChange: (e) => handleInputChange(e),
                    onKeyDown: (e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        handleSearch();
                      }
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <People />
                      </InputAdornment>
                    ),
                  }}
                />
                <IconButton color={"secondary"} onClick={(e) => {
                  // setSearchTerm(e.target.value),
                  // handleKeyPress(e)
                  // handleKeyPress()
                  handleSearch()


                }}>
                  <Search />
                </IconButton>
                <IconButton
                  aria-label="add"
                  style={{ color: "white" }}
                  onClick={() => setOpenAddDialog(true)}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          </CardHeader>
          <CardBody>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => 
                  {return (
                    <Tooltip key={column.id} title={`Sort By ${column.id}`}>
                    <TableCell
                      key={column.id}
                      align={"center"}
                      onClick={() => requestSort(column.id)}
                      style={{ minWidth: column.minWidth, cursor: 'pointer' }}
                    >
                      {column.label}
                    </TableCell>
                    </Tooltip>
                  )})}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedPayments
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          let value = row[column.id];
                          if (column.id === "party") {
                            value = row.party.name;
                          }
                          if (column.id === "action") {
                            return (
                              <TableCell align={"center"}>
                                <Box display={"flex"} justifyContent={"center"}>
                                  {/* <IconButton
                                    aria-label="delete"
                                    onClick={() =>
                                      setOpenEditDialog({
                                        open: true,
                                        item: row,
                                      })
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton> */}
                                  <IconButton
                                    aria-label="view"
                                    onClick={() =>
                                      handlePrint(row)
                                    }
                                  >
                                    <PrintIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() =>
                                      handleOpenConfirmationModal({
                                        onPress: () => _deleteItem(row),
                                      })
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={column.id} align={"center"}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={tota}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      </GridItem>

      {/*<Fab color="primary" aria-label="add" className={classes.fab}*/}
      {/*     onClick={() => setOpenAddDialog(true)}>*/}
      {/*    <AddIcon/>*/}
      {/*</Fab>*/}
      <PaymentAdd
        fetchData={_getPayments}
        open={openAddDialog}
        close={closeAddDialog}
      />
      {openEditDialog ? (
        <PaymentEdit
          fetchData={_getPayments}
          open={openEditDialog.open}
          item={openEditDialog.item}
          close={closeEditDialog}
        />
      ) : null}
      {openConfirmationModal ? (
        <ConfirmationDialog
          open={openConfirmationModal}
          onClose={handleCloseConfirmationModal}
          onConfirm={openConfirmationModal.onPress}
          title={"Delete Record"}
          msg={"Do you want to delete this record?"}
        />
      ) : null}
    </GridContainer>
  );
}
