import React from "react";
// @material-ui/core components
import {
  Box,
  Button,
  Dialog,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import _ from "lodash";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  { id: "productCode", label: "Product Code" },
  { id: "productName", label: "Product Name" },
  { id: "lab", label: "Lab" },
  { id: "brand", label: "Brand" },
  { id: "price", label: "Price" },
];
export default function BillView(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { open, close, item } = props;

  const { party, paymentStatus, challanCodes, items, code } = item;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Bill Details</h4>
                <Button style={{ color: "white" }} onClick={close}>
                  Close
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="Party Name"
                    id="partyName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: party?.accountName ? party.accountName : "",
                      disabled: true,
                    }}
                  />
                </GridItem>

                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="Payment Status"
                    id="paymentStatus"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: paymentStatus,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Challan Codes"
                    id="challanCodes"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: challanCodes ? challanCodes.toString() : "",
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Code"
                    id="Code"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: code ? code.toString() : "",
                      disabled: true,
                    }}
                  />
                </GridItem>

              
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
