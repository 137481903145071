import React, { useState } from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  IconButton,
} from "@material-ui/core";
import OrderView from "../Order/OrderView";
import VisibilityIcon from "@material-ui/icons/Visibility";

const columns = [
  { id: "party", label: "Party" },
  { id: "code", label: "Order Code" },
  { id: "orderType", label: "Order Type" },
  { id: "product", label: "Product" },
  { id: "orderAmount", label: "Order Amount" },
  { id: "powerR", label: "Right Power" },
  { id: "powerL", label: "Left Power" },
  { id: "index", label: "Index" },
  { id: "coating", label: "Coating" },

  {
    id: "createdAt",
    label: "Order Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
  { id: "action", label: "Action" },
];

export function ChallanOrdersDialog(props) {
  const { open, onClose, data, OnR, OnL } = props;

  const [page, setPage] = useState(0);

  const handleButtonClickR = (row) => {
    OnR(row); // Send data to parent
    onClose()
  };
  const handleButtonClickL = (row) => {
    OnL(row); // Send data to parent
    onClose()
  };
  
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openViewDialog, setOpenViewDialog] = useState(null);
  function closeViewDialog() {
    setOpenViewDialog(null);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Dialog
      maxWidth={"lg"}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Orders</DialogTitle>
      <DialogContent>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      // if (column.id === "action") {
                      //   return (
                      //     <TableCell align={"center"}>
                      //       <Box display={"flex"} justifyContent={"center"}>
                      //         <IconButton
                      //           aria-label="view"
                      //           onClick={() =>
                      //             setOpenViewDialog({
                      //               open: true,
                      //               item: row,
                      //             })
                      //           }
                      //         >
                      //           <VisibilityIcon />
                      //         </IconButton>
                      //       </Box>
                      //     </TableCell>
                      //   );
                      // }

                      if (column.id === "powerR") {
                        value =
                          row.orderDetail.rSph +
                          "/" +
                          row.orderDetail.rCyl +
                          "/" +
                          row.orderDetail.rAxis +
                          "/" +
                          row.orderDetail.rAddition;
                      }

                      if (column.id === "powerL") {
                        value =
                          row.orderDetail.lSph +
                          "/" +
                          row.orderDetail.lCyl +
                          "/" +
                          row.orderDetail.lAxis +
                          "/" +
                          row.orderDetail.lAddition;
                      }

                      if (column.id === "index") {
                        value = row.orderDetail.product.index
                      }
                      if (column.id === "coating") {
                        value = row.orderDetail.coat
                      }

                      if (column.id === "product") {
                        if (row.orderType === "Fitting") {
                          value =
                            row.orderDetail && row.orderDetail.productName
                              ? row.orderDetail.productName
                              : "";
                        } else if (row.orderType === "Ready") {
                          value =
                            row.orderDetail && row.orderDetail.product
                              ? row.orderDetail.product.name
                              : "";
                        } else {
                          value =
                            row.orderDetail && row.orderDetail.product
                              ? row.orderDetail.product.productName
                              : "";
                        }
                      }
                      if (column.id === "party") {
                        return (
                          <TableCell key={column.id} align={"center"}>
                            {value.accountName}
                          </TableCell>
                        );
                      }

                      if (column.id === "action") {
                        return (
                          <TableCell key={column.id} align={"center"}>
                            <Button color="primary" onClick={()=>handleButtonClickR(row)}>Set as R</Button>
                            <Button color="primary" onClick={()=>handleButtonClickL(row)}>Set as L</Button>
                          </TableCell>
                        );
                      }
                      
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      {openViewDialog ? (
        <OrderView
          open={openViewDialog.open}
          close={closeViewDialog}
          item={openViewDialog.item}
        />
      ) : null}
    </Dialog>
  );
}
