import _ from "lodash";
import { inWords, inWords2 } from "../../utils";

export const invTemp = (data) => {

    let hsnn = "";
    let cgstt = "";
    let cgstP;
    let sgstP;
    let igstP;
    let sgstt = "";
    let igstt = "";
    let {
        clientAddress,
        clientName,
        clientGSTNo,
        challanDate,
        items,
        bank,
        billCode,
        entityDetails,
        packagingCosts,
        packagingTaxPercents,
        isEligibleForIgst
    } = data;

    const lastThreeParts = billCode.split('-').slice(-3).join('-');
    console.log(lastThreeParts);




    console.log(data, "items in template")

    let TotalPackagingCost = 0

    var igstPercent = ""
    var sgstPercent = ""
    var cgstPercent = ""
    var cgstAmount = ""
    var sgstAmount = ""
    var igstAmount = ""

    for (let i = 0; Number(i) < packagingCosts.length; Number(i++)) {
        TotalPackagingCost += packagingCosts[Number(i)]
    }
    console.log('summmmmm', TotalPackagingCost)

    let bankName = "SYNDICATE BANK",
        bankAccNo = "5015 1400 000 418",
        bankIFSC = "SYNB 000 5015",
        bankBranch = "Zaveri Bazar Kalbadevi Mumbai - 2";
    if (bank) {
        bankName = bank.bankName;
        bankAccNo = bank.acNo;
        bankIFSC = bank.ifscCode;
        bankBranch = bank.branch;
    }
    let totalAmountInWords = "",
        totalQuantity = 0,
        totalAmount = 0,
        totalAmountAfterTax = 0,
        roundOff = 0,
        cgstPer = items[0].taxPercent / 2,
        sgstPer = items[0].taxPercent / 2,
        igstPer = 0;

    let rows = "";
    items.map((o) => {
        let {
            amount,
            rate,
            nameL,
            nameR,
            quantity,
            fittingAmount,
            tint,
            discountAmount,
            discountPercent,
            hsnCode,
            taxPercent
        } = o;
        totalAmount = totalAmount + Number(amount);
        let name = nameL + " " + nameR;
        let hsn = "";

        if (isEligibleForIgst) {
            igstPercent = taxPercent
            sgstPercent = 0
            cgstPercent = 0
            cgstAmount = 0
            sgstAmount = 0
            igstAmount = (Number(taxPercent) / 100) * amount
        } else {
            igstPercent = 0
            cgstPercent = Number(taxPercent) / 2

            sgstPercent = Number(taxPercent) / 2
            cgstAmount = (Number(taxPercent) / 200) * amount
            sgstAmount = (Number(taxPercent) / 200) * amount
            igstAmount = 0
        }
        let item = {
            name,
            isEligibleForIgst: isEligibleForIgst,
            hsn: hsnCode,
            rate,
            amount: amount,
            taxPercent: taxPercent,
            quantity,
            cgstPercent: cgstPercent,
            cgst: cgstAmount,
            sgst: sgstAmount,
            sgstPercent: sgstPercent,
            igst: igstAmount,
            igstPercent: igstPercent,
            ec: Number(fittingAmount) + Number(tint),
            discount: discountAmount
                ? Number(discountAmount)
                : discountPercent
                    ? `${discountPercent}%`
                    : 0,
        };
        totalQuantity += Number(item.quantity);
        rows = rows + renderTableRow(item) + "\n";
        hsnn = hsnCode;
        cgstP = cgstPercent
        sgstP = sgstPercent
        igstP = igstPercent
        cgstt = cgstAmount;
        sgstt = sgstAmount;
        igstt = igstAmount;
    });
    let cgst = (cgstPer / 100) * totalAmount,
        sgst = (sgstPer / 100) * totalAmount,
        igst = "";
    let tax = (Number(cgstPer + sgstPer) / 100) * (totalAmount + TotalPackagingCost);

    totalAmountAfterTax = totalAmount + TotalPackagingCost + tax
    totalAmountInWords = inWords2(_.round(totalAmountAfterTax, 2).toFixed(0));
    return `<!DOCTYPE html>
<html lang="en">
<head>
    <title>GST Template 2</title>
    <meta charset="UTF-8">
    <style>
      
        #testResult {
            white-space: pre-wrap;
        }

        .border {
            border-style: solid;
            border-width: 1px;
            border-color: #000;
        } 
        .noBorder {
            border-style: none;
            border-width: 0;
        }

        .logo {
            align-self: center;
            height: 40px;
            width: 40px;
        }

        .center {
            text-align: center;
            align-items: center;
            justify-content: center;
        }

        .row {
            display: flex;
            flex-direction: row;
            flex: 1;
        }

        .space-between {
            align-items: center;
            justify-content: space-between;
        }

        .column {
            display: flex;
            flex-direction: column;
            flex: 1;
        }

        h2 {
            margin: 4px;
             color:#000;
               }

        h4 {
            margin: 4px;
            color:#000;
        }

        h3 {
            margin: 10px;
            color:#000;
        }

        h5 {
            margin: 1px;
            color:#000;
        }

        p {
            font-size: 14px;
            margin: 1px;
            color:#000;
        }
        div {
            color:#000;
        }
        table, th, td {
            font-weight: normal;
            font-size: 12px;
            border-collapse: collapse;
            border: 1px solid #000;
            color:#000;
        }
    </style>
</head>
<body>
<div class="border" style="display:flex;flex:1;flex-direction:column;margin: 20px;height: 1000px; width: 824px;">
    <div class="center">
        <h2>${entityDetails.name}</h2>
        <p>${entityDetails.address}</p>
    </div>
    <div class="row border space-between" style="flex:0;padding: 4px">
        <div>
            <p>GSTIN: ${entityDetails.gst}</p>
            <p>Contact No: ${entityDetails.phone}</p>
        </div>
    </div>
    <div class="center border">
        <h3>${billCode}</h3>
    </div>
    <div class="row " style="flex: 0">
        <div class="border" style="flex:1">
            <div class="border">
                <h4 class="center">CLIENT DETAILS</h4>
            </div>
            <div style="padding: 4px;width:100%">
                <h4>${clientName}</h4>
                <div class="row">
                    <p>Address</p>
                    <p style="width: 90%">: ${clientAddress}</p>
                </div>
                <div class="row">
                    <p>GSTIN</p>
                    <p>: ${clientGSTNo}</p>
                </div>
            </div>
        </div>
        <div class="border" style="flex:1;width:50%">
            <div class="border">
                <h4 class="center">INVOICE DETAILS</h4>
            </div>
            <div style="padding: 4px">
                <div class="row">
                    <p>Bill Date</p>
                    <p>: ${lastThreeParts}</p>
                </div>
            </div>
        </div>

    </div>
    <div style="display: flex;flex: 1;flex-direction: column;margin-top: 2px">
        <table style="width:100%;">
            <tr>
                <th style="width: 20%;text-align: left;padding-left: 10px">Description</th>
                <th style="width: 8%">HSN Code</th>
                <th style="width: 5%">Qty</th>
                <th style="width: 8%">Rate</th>
                <th style="width: 8%">Discount</th>
                <th style="width: 8%">Extra Cost</th>
                <th style="width: 8%">Amount</th>
                ${!isEligibleForIgst
            ?
            `
                    <th style="width: 5%">CGST %</th>
                    <th style="width: 5%">CGST Amt</th>
                    <th style="width: 5%">SGST %</th>
                    <th style="width: 5%">SGST Amt</th>
                    `
            :
            `
                    <th style="width: 5%">IGST %</th>
                    <th style="width: 5%">IGST Amt</th>
                    `

        }
             
            </tr>
            ${rows}
        </table>
    </div>
    <div >
        <table style="width:100%;flex:0">
            <tr>
                <th style="width: 31%;text-align: left;padding-left: 10px">
                <div class="space-between row">
            <p>Amount in Words</p>
            <p>E. & O.E.</p>
        </div>
                    <h4><p style="font-weight: normal">${totalAmountInWords} Rupees Only</p></h4>
                </th>
                <th style="width: 4.5%">${totalQuantity}</th>
                <th style="width: 23%"></th>
                <th style="width: 9.5%">${_.round(totalAmount, 2).toFixed(
            2
        )}</th>
                <th style="width: 4.5%"></th>
                <th style="width: 4.5%">${_.round(cgst, 2).toFixed(2)}</th>
                <th style="width: 4.5%"></th>
                <th style="width: 4.5%">${_.round(sgst, 2).toFixed(2)}</th>
            </tr>
        </table>
    </div>
    <div class="border" style="margin-top: 4px;margin-bottom: 4px">
        <div class="row">
            <div class="border" style="padding: 4px;width:15%">
                SAC/HSN
            </div>
            <div class="border" style="padding: 4px;width:15%">
                Taxable
                Value
            </div>
            ${!isEligibleForIgst ? 
                `<div class="border" style="width: 54%;display: flex;flex-direction: row">
                CGST ${cgstP}%
                </div>
                <div class="border" style="width: 54%;display: flex;flex-direction: row">
                SGST ${sgstP}%
                </div>
                `: `<div class="border" style="width: 54%;display: flex;flex-direction: row">
                IGST ${igstP}%
                </div>`
            }
            <div class="border" style="padding: 4px;width:20%">
                Total Tax Amount
            </div>
        </div>
        <div>
            <div class="row">
                <div class="border" style="padding: 4px;width:15%">
                    ${hsnn}
                </div>
                <div class="border" style="padding: 4px;width:15%">
                ${_.round(totalAmount, 2).toFixed(
                    2
                )}
                </div>
                ${!isEligibleForIgst ? 
                `<div class="border" style="width: 54%;display: flex;flex-direction: row">
                ${_.round(cgstt, 2).toFixed(
                    2
                )}
                </div>
                <div class="border" style="width: 54%;display: flex;flex-direction: row">
                ${_.round(sgstt, 2).toFixed(
                    2
                )}
                </div>` : `<div class="border" style="width: 54%;display: flex;flex-direction: row">
                ${_.round(igstt, 2).toFixed(
                    2
                )}
                </div>`}
                <div class="border" style="padding: 4px;width:20%">
                     ${totalAmountAfterTax}
                </div>
            </div>
        </div>
    </div>
    <div class="row" style="display: flex;flex:0">
        <div class="border" style="flex:1">
            <div class="border">
                <h4 class="center">BANK DETAILS</h4>
            </div>
            <div style="padding: 4px">
                <div class="row">
                    <p>Account Number</p>
                    <p>: ${bankAccNo ? bankAccNo : "-"}</p>
                </div>
                <div class="row">
                    <p>Branch</p>
                    <p>: ${bankBranch ? bankBranch : "-"}</p>
                </div>
                <div class="row">
                    <p>Bank Name</p>
                    <p>: ${bankName ? bankName : "-"}</p>
                </div>
                <div class="row">
                    <p>IFSC Code</p>
                    <p>: ${bankIFSC ? bankIFSC : "-"}</p>
                </div>
            </div>
        </div>
    <div class="border  space-between" style="display:flex;flex-direction:column;flex: 1 ">
            <div class="border center" style="width: 100%">
                <h4>FOR: ${entityDetails.name}</h4>
            </div>
            <div class="center" >
                <p>Signature</p>
                <p>This is a computer generated invoice.</p>
            </div>
        </div>
    </div>

</div>
</body>
</html>
`;
};

let renderTableRow = (item) => {
    let {
        name,
        hsn,
        rate,
        amount,
        quantity,
        cgstPercent,
        cgst,
        sgst,
        sgstPercent,
        igst,
        igstPercent,
        ec,
        discount,
        isEligibleForIgst
    } = item;
    return `<tr>
                <th style="width: 25%;text-align: left;padding-left: 10px">${name}</th>
                <th style="width: 5%">${hsn}</th>
                <th style="width: 5%">${quantity}</th>
                <th style="width: 8%">${rate}</th>
                <th style="width: 6%">${discount}</th>
                <th style="width: 6%">${ec}</th>
                <th style="width: 10%">${_.round(amount, 2).toFixed(2)}</th>
                ${!isEligibleForIgst ?
            ` <th style="width: 5%">${cgstPercent}</th>
                    <th style="width: 5%">${_.round(cgst, 2).toFixed(2)}</th>
                    <th style="width: 5%">${sgstPercent}</th>
                    <th style="width: 5%">${_.round(sgst, 2).toFixed(2)}</th>
                    ` : `
                    <th style="width: 5%">${igstPercent}</th>
                    <th style="width: 5%">${_.round(igst, 2).toFixed(2)}</th>
                    
                    `}
            </tr>`;
};
