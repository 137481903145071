import React, { useEffect, useState } from "react";
// @material-ui/core components
import {
  ListItem,
  ListItemText,
  Dialog,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme,
  Switch,
  Box,
  Typography,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import axios from "axios";
import {
  CreateBulkReadyMasterAPI,
  UpdateBulkReadyMasterRangeAPI,
  GetAllBrandsAPI,
  GetAllCategorysAPI,
  UpdateReadyMasterAPI,
  EditImageUploadAPI,
  GetAllReadyMastersAPI,
} from "../../config/api";
import { Autocomplete } from "@material-ui/lab";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";

import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { array } from "prop-types";
import { FilePicker } from "../../components/common/FilePicker.js";
import _ from "lodash";
import { toast } from "react-toastify";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 500,
});

const styles = {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function ReadyMasterRangeEdit(props) {
  let { item } = props;

  console.log(item, "My selected range haha")

  const classes = useStyles();
  const theme = useTheme();
  const userInfo = useSelector((state) => state.userReducer.userInfo);
  const authToken = useSelector((state) => state.userReducer.authToken);

  const { open, close, fetchData } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [fittings, setFittings] = useState([]);
  const [brand, setBrand] = useState([]);
  const [category, setCategory] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(
    item?.brand ? item.brand : null
  );
  const [selectedCategory, setSelectedCategory] = useState(
    item?.category ? item.category : null
  );

  const [productName, setProductName] = useState(item?.productName);
  const [productCode, setProductCode] = useState("00/0/0");
  const [index, setIndex] = useState(item?.index);
  const [side, setSide] = useState(item?.side);
  const [type, setType] = useState(item?.type);
  const [color, setColor] = useState(item?.color);
  const [rate, setRate] = useState(item?.rate);
  const [startSph, setStartSph] = useState(item?.startSph);
  const [endSph, setEndSph] = useState(item?.endSph);

  const [startCyl, setStartCyl] = useState(item?.startCyl);
  const [endCyl, setEndCyl] = useState(item?.endCyl);

  const [axis, setAxis] = useState(item?.axis);
  const [startAddition, setStartAddition] = useState(item?.startAddition);
  const [endAddition, setEndAddition] = useState(item?.endAddition);
  const [status, setStatus] = useState(item?.status)
  const [readyMastersProducts, setReadyMastersProducts] = useState([])
  // const readyMasters = useSelector((state) => state.userReducer.readyMasters);

  const [createdProductsOne, setCreatedProductsOne] = useState([]);
  const [createdProductsTwo, setCreatedProductsTwo] = useState([]);
  const [createdProductsThree, setCreatedProductsThree] = useState([]);

  const [readyMasterImage, setReadyMasterImage] = useState(
    item?.readyMasterImages ? item.readyMasterImages : []
  );

  // const readyMastersProducts = useSelector(
  //   (state) => state.userReducer.readyMasters
  // );

  const [disable, setDisabled] = useState(false);

  useEffect(() => {
    _getFittings();
    _getBrands();
    _getCategory();
  }, []);

  useEffect(() => {
    if (endSph >= 0 && endCyl >= 0) {
      rangeSame(startSph, endSph, startCyl, endCyl, startAddition, endAddition);
    } else if (endSph <= 0 && endCyl <= 0) {
      rangeSame(startSph, endSph, startCyl, endCyl, startAddition, endAddition);
    } else if ((endSph >= 0 && endCyl <= 0) || (endSph <= 0 && endCyl >= 0)) {
      rangeDiff(startSph, endSph, startCyl, endCyl, startAddition, endAddition);
    }
  }, [startSph, endSph, startCyl, endCyl, startAddition, endAddition]);

  async function _getBrands() {
    try {
      let res = await axios.get(GetAllBrandsAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setBrand(res.data.brandMasters);
    } catch (err) {
      // console.log(err);

    }
  }

  async function _getCategory() {
    try {
      let res = await axios.get(GetAllCategorysAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setCategory(res.data.categoryMasters);
    } catch (err) {
      // console.log(err);

    }
  }

  async function _getFittings() {
    try {
      let res = await axios.get(
        GetAllReadyMastersAPI(userInfo.entity),
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFittings(res.data.readyMasters);
      setReadyMastersProducts(res.data.readyMasters);
    } catch (err) {
      // console.log(err);

    }
  }

  console.log(readyMasterImage, "Mt imnagasdew")

  async function _onSave() {
    try {
      setDisabled(true);
      let images = [];
      let readyData = {
        productName,
        index,
        side,
        type,
        color,
        rate,
        startSph,
        endSph,
        startCyl,
        endCyl,
        axis,
        startAddition,
        endAddition,
        status,
        brand: selectedBrand?._id,
        category: selectedCategory?._id,
        entity: userInfo?.entity,
      }
      if (readyMasterImage[0].name) {
        console.log("first")
        for (let i = 0; i < readyMasterImage.length; i++) {
          images.push("/uploads/" + readyMasterImage[i].name);
        }
        readyData.readyMasterImages = images
      } else {
        images = readyMasterImage
      }
      console.log(images, "images ")


      let readyMasterRangeRes = await axios.patch(
        UpdateBulkReadyMasterRangeAPI() + item._id,
        readyData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      let data = new FormData()
      if (readyMasterImage) {
        data.append("readyMasterRangeImage", readyMasterImage[0]?.path)
        data.append("Name", "Umang")
        console.log("appending")
      }

      console.log(readyMasterImage[0].path, "My images")
      console.log(data, "My formdata")
      // return
      if (readyMasterImage[0].path) {
        let res = await axios.post("http://94.23.161.165:3000/readyMasterRange/imageUpload", data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        })
      }
      // console.log(res)
      // return

      await Promise.all(
        createdProductsThree.map(async (i) => {
          axis.map(async (ax) => {
            let pr = {
              productName,
              sph: i.x,
              cyl: i.y,
              addition: i.z,
              axis: ax,
              color,
              side,
              type,
              index,
              rate,
              status,
              brand: selectedBrand._id,
              category: selectedCategory._id,
              entity: userInfo.entity,
            };

            let rmp = _.filter(readyMastersProducts, (r) => {
              return (
                r.productCode == color + i.x + i.y + "/" + axis[0] + "/" + i.z
              );
            });

            if (rmp.length > 0) {
              let res = await axios.patch(
                UpdateReadyMasterAPI(rmp[0]._id),
                pr,
                {
                  headers: {
                    Authorization: `Bearer ${authToken}`,
                  },
                }
              );
            }
          });
        })
      );
      setDisabled(false);
      close();
      fetchData();
    } catch (e) {
      setDisabled(false);
      console.log("err:", e);
    }
  }

  // async function _onSave() {
  //   try {
  //     console.log("PRessed")
  //     // let images = [];
  //     // for (let i = 0; i < readyMasterImage.length; i++) {
  //     //   images.push("/uploads/" + readyMasterImage[i].name);
  //     // }

  //   } catch (e) {
  //     console.log(e, "This is my eorrro")
  //   }
  // }

  function rangeSame(
    startSph,
    endSph,
    startCyl,
    endCyl,
    startAddition,
    endAddition
  ) {
    let x = endSph;
    let y = endCyl;
    let addStart = startAddition;
    let addMax = endAddition;
    let array1 = [];
    let array2 = [];

    let xMax = null,
      xStart = null,
      yMax = null,
      yStart = null;
    if (x < 0) {
      xMax = -1 * x;
      xStart = -1 * startSph;
    } else {
      xMax = x;
      xStart = startSph;
    }

    if (y < 0) {
      yMax = -1 * y;
      yStart = -1 * startCyl;
    } else {
      yMax = y;
      yStart = startCyl;
    }

    for (
      let indexY = parseInt(yStart);
      indexY <= parseInt(yMax);
      indexY += 25
    ) {
      for (let index = parseInt(xStart); index <= parseInt(xMax); index += 25) {
        for (
          let addIndex = parseInt(addStart);
          addIndex <= parseInt(addMax);
          addIndex += 25
        ) {
          if (index === 0 && indexY === 0) {
            array1.push({ z: addIndex, x: "000", y: "000" });
          } else if (index === 0 && indexY < 100) {
            if (y < 0) {
              array1.push({ z: addIndex, x: "000", y: "-0" + indexY });
            } else {
              array1.push({ z: addIndex, x: "000", y: "0" + indexY });
            }
          } else if (index === 0 && indexY >= 100) {
            if (y < 0) {
              array1.push({ z: addIndex, x: "000", y: "-" + indexY });
            } else {
              array1.push({ z: addIndex, x: "000", y: indexY });
            }
          } else if (index > 0 && index < 100 && indexY == 0) {
            if (x < 0) {
              array1.push({ z: addIndex, x: "-0" + index, y: "000" });
            } else {
              array1.push({ z: addIndex, x: "0" + index, y: "000" });
            }
          } else if (index > 0 && index < 100 && indexY < 100) {
            if (x < 0 && y < 0) {
              array1.push({ z: addIndex, x: "-0" + index, y: "-0" + indexY });
            } else {
              array1.push({ z: addIndex, x: "0" + index, y: "0" + indexY });
            }
          } else if (index > 0 && index < 100 && indexY >= 100) {
            if (x < 0 && y < 0) {
              array1.push({ z: addIndex, x: "-0" + index, y: "-" + indexY });
            } else {
              array1.push({ z: addIndex, x: "0" + index, y: indexY });
            }
          } else if (index >= 100 && indexY == 0) {
            if (x < 0) {
              array1.push({ z: addIndex, x: "-" + index.toString(), y: "000" });
            } else {
              array1.push({ z: addIndex, x: index.toString(), y: "000" });
            }
          } else if (index >= 100 && indexY < 100) {
            if (x < 0 && y < 0) {
              array1.push({
                z: addIndex,
                x: "-" + index.toString(),
                y: "-0" + indexY,
              });
            } else {
              array1.push({
                z: addIndex,
                x: index.toString(),
                y: "0" + indexY,
              });
            }
          } else if (index >= 100 && indexY >= 100) {
            if (x < 0 && y < 0) {
              array1.push({
                z: addIndex,
                x: "-" + index.toString(),
                y: "-" + indexY.toString(),
              });
            } else {
              array1.push({
                z: addIndex,
                x: index.toString(),
                y: indexY.toString(),
              });
            }
          }
        }
      }
    }

    for (let index = parseInt(xStart); index <= parseInt(xMax); index += 25) {
      for (
        let indexY = parseInt(yStart);
        indexY <= parseInt(yMax);
        indexY += 25
      ) {
        for (
          let addIndex = parseInt(addStart);
          addIndex <= parseInt(addMax);
          addIndex += 25
        ) {
          if (index === 0 && indexY === 0) {
            array2.push({ z: addIndex, x: "000", y: "000" });
          } else if (index === 0 && indexY < 100) {
            if (y < 0) {
              array2.push({ z: addIndex, x: "000", y: "-0" + indexY });
            } else {
              array2.push({ z: addIndex, x: "000", y: "0" + indexY });
            }
          } else if (index === 0 && indexY >= 100) {
            if (y < 0) {
              array2.push({ z: addIndex, x: "000", y: "-" + indexY });
            } else {
              array2.push({ z: addIndex, x: "000", y: indexY });
            }
          } else if (index > 0 && index < 100 && indexY == 0) {
            if (x < 0) {
              array2.push({ z: addIndex, x: "-0" + index, y: "000" });
            } else {
              array2.push({ z: addIndex, x: "0" + index, y: "000" });
            }
          } else if (index > 0 && index < 100 && indexY < 100) {
            if (x < 0 && y < 0) {
              array2.push({ z: addIndex, x: "-0" + index, y: "-0" + indexY });
            } else {
              array2.push({ z: addIndex, x: "0" + index, y: "0" + indexY });
            }
          } else if (index > 0 && index < 100 && indexY >= 100) {
            if (x < 0 && y < 0) {
              array2.push({ z: addIndex, x: "-0" + index, y: "-" + indexY });
            } else {
              array2.push({ z: addIndex, x: "0" + index, y: indexY });
            }
          } else if (index >= 100 && indexY == 0) {
            if (x < 0) {
              array2.push({ z: addIndex, x: "-" + index.toString(), y: "000" });
            } else {
              array2.push({ z: addIndex, x: index.toString(), y: "000" });
            }
          } else if (index >= 100 && indexY < 100) {
            if (x < 0 && y < 0) {
              array2.push({
                z: addIndex,
                x: "-" + index.toString(),
                y: "-0" + indexY,
              });
            } else {
              array2.push({
                z: addIndex,
                x: index.toString(),
                y: "0" + indexY,
              });
            }
          } else if (index >= 100 && indexY >= 100) {
            if (x < 0 && y < 0) {
              array2.push({
                z: addIndex,
                x: "-" + index.toString(),
                y: "-" + indexY.toString(),
              });
            } else {
              array2.push({
                z: addIndex,
                x: index.toString(),
                y: indexY.toString(),
              });
            }
          }
        }
      }
    }

    setCreatedProductsOne(array1);
    setCreatedProductsTwo(array2);

    var array3 = array1.concat(array2);

    const uniqueIds = [];

    const unique = array3.filter((element) => {
      const isDuplicate = uniqueIds.includes(
        element.x.toString() + element.y.toString() + element.z.toString()
      );

      if (!isDuplicate) {
        uniqueIds.push(
          element.x.toString() + element.y.toString() + element.z.toString()
        );

        return true;
      }

      return false;
    });
    setCreatedProductsThree(unique);
  }

  function rangeDiff(
    startSph,
    endSph,
    startCyl,
    endCyl,
    startAddition,
    endAddition
  ) {
    let x = endSph;
    let y = endCyl;
    let array1 = [],
      array2 = [];
    let addStart = startAddition;
    let addMax = endAddition;
    let xMax = null,
      xStart = null,
      yMax = null,
      yStart = null;
    if (x < 0) {
      xMax = -1 * x;
      xStart = -1 * startSph;
    } else {
      xMax = x;
      xStart = startSph;
    }

    if (y < 0) {
      yMax = -1 * y;
      yStart = -1 * startCyl;
    } else {
      yMax = y;
      yStart = startCyl;
    }

    for (
      let indexY = parseInt(yStart);
      indexY <= parseInt(yMax);
      indexY += 25
    ) {
      for (let index = parseInt(xStart); index <= parseInt(xMax); index += 25) {
        for (
          let addIndex = parseInt(addStart);
          addIndex <= parseInt(addMax);
          addIndex += 25
        ) {
          if (index < indexY) {
            if (index === 0 && indexY === 0) {
              array1.push({ z: addIndex, x: "000", y: "000" });
            } else if (index === 0 && indexY < 100) {
              if (y < 0) {
                array1.push({ z: addIndex, x: "000", y: "-0" + indexY });
              } else {
                array1.push({ z: addIndex, x: "000", y: "0" + indexY });
              }
            } else if (index === 0 && indexY >= 100) {
              if (y < 0) {
                array1.push({ z: addIndex, x: "000", y: "-" + indexY });
              } else {
                array1.push({ z: addIndex, x: "000", y: indexY });
              }
            } else if (index < 100 && indexY == 0) {
              if (x < 0) {
                array1.push({ z: addIndex, x: "-0" + index, y: "000" });
              } else {
                array1.push({ z: addIndex, x: "0" + index, y: "000" });
              }
            } else if (index < 100 && indexY < 100) {
              if (x < 0 && y > 0) {
                array1.push({ z: addIndex, x: "-0" + index, y: "0" + indexY });
              } else {
                array1.push({ z: addIndex, x: "0" + index, y: "-0" + indexY });
              }
            } else if (index < 100 && indexY >= 100) {
              if (x < 0 && y > 0) {
                array1.push({ z: addIndex, x: "-0" + index, y: indexY });
              } else {
                array1.push({ z: addIndex, x: "0" + index, y: "-" + indexY });
              }
            } else if (index >= 100 && indexY == 0) {
              if (x < 0) {
                array1.push({
                  z: addIndex,
                  x: "-" + index.toString(),
                  y: "000",
                });
              } else {
                array1.push({ z: addIndex, x: index.toString(), y: "000" });
              }
            } else if (index >= 100 && indexY < 100) {
              if (x < 0 && y > 0) {
                array1.push({
                  z: addIndex,
                  x: "-" + index.toString(),
                  y: "0" + indexY,
                });
              } else {
                array1.push({
                  z: addIndex,
                  x: index.toString(),
                  y: "-0" + indexY,
                });
              }
            } else if (index >= 100 && indexY >= 100) {
              if (x < 0 && y > 0) {
                array1.push({
                  z: addIndex,
                  x: "-" + index.toString(),
                  y: indexY.toString(),
                });
              } else {
                array1.push({
                  z: addIndex,
                  x: index.toString(),
                  y: "-" + indexY.toString(),
                });
              }
            }
          }
        }
      }
    }

    for (let index = parseInt(xStart); index <= parseInt(xMax); index += 25) {
      for (
        let indexY = parseInt(yStart);
        indexY <= parseInt(yMax);
        indexY += 25
      ) {
        for (
          let addIndex = parseInt(addStart);
          addIndex <= parseInt(addMax);
          addIndex += 25
        ) {
          if (index < indexY) {
            if (index === 0 && indexY === 0) {
              array2.push({ z: addIndex, x: "000", y: "000" });
            } else if (index === 0 && indexY < 100) {
              if (y < 0) {
                array2.push({ z: addIndex, x: "000", y: "-0" + indexY });
              } else {
                array2.push({ z: addIndex, x: "000", y: "0" + indexY });
              }
            } else if (index === 0 && indexY >= 100) {
              if (y < 0) {
                array2.push({ z: addIndex, x: "000", y: "-" + indexY });
              } else {
                array2.push({ z: addIndex, x: "000", y: indexY });
              }
            } else if (index < 100 && indexY == 0) {
              if (x < 0) {
                array2.push({ z: addIndex, x: "-0" + index, y: "000" });
              } else {
                array2.push({ z: addIndex, x: "0" + index, y: "000" });
              }
            } else if (index < 100 && indexY < 100) {
              if (x < 0 && y > 0) {
                array2.push({ z: addIndex, x: "-0" + index, y: "0" + indexY });
              } else {
                array2.push({ z: addIndex, x: "0" + index, y: "-0" + indexY });
              }
            } else if (index < 100 && indexY >= 100) {
              if (x < 0 && y > 0) {
                array2.push({ z: addIndex, x: "-0" + index, y: indexY });
              } else {
                array2.push({ z: addIndex, x: "0" + index, y: "-" + indexY });
              }
            } else if (index >= 100 && indexY == 0) {
              if (x < 0) {
                array2.push({
                  z: addIndex,
                  x: "-" + index.toString(),
                  y: "000",
                });
              } else {
                array2.push({ z: addIndex, x: index.toString(), y: "000" });
              }
            } else if (index >= 100 && indexY >= 100) {
              if (x < 0 && y > 0) {
                array2.push({
                  z: addIndex,
                  x: "-" + index.toString(),
                  y: indexY,
                });
              } else {
                array2.push({
                  z: addIndex,
                  x: index.toString(),
                  y: "-" + indexY,
                });
              }
            } else if (index >= 100 && indexY <= 100) {
              if (x < 0 && y > 0) {
                array1.push({
                  z: addIndex,
                  x: "-" + index.toString(),
                  y: indexY.toString(),
                });
              } else {
                array1.push({
                  z: addIndex,
                  x: index.toString(),
                  y: "-" + indexY.toString(),
                });
              }
            }
          }
        }
      }
    }

    setCreatedProductsOne(array1);
    setCreatedProductsTwo(array2);

    const uniqueIds = [];
    var array3 = array1.concat(array2);

    const unique = array3.filter((element) => {
      const isDuplicate = uniqueIds.includes(
        element.x.toString() + element.y.toString() + element.z.toString()
      );

      if (!isDuplicate) {
        uniqueIds.push(
          element.x.toString() + element.y.toString() + element.z.toString()
        );

        return true;
      }

      return false;
    });
    setCreatedProductsThree(unique);
  }

  function _setReadyMasterImage(files) {
    setReadyMasterImage(files);
  }

  return (
    <Dialog
      maxWidth="md"
      fullScreen={fullScreen}
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Update Ready Master</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {/* <GridItem xs={12} sm={12} md={12}>
                  <Autocomplete
                    // filterOptions={filterOptions}
                    loading={fittings.length === 0 ? true : false}
                    className={classes.formControl}
                    options={fittings}
                    value={selectedProduct}
                    onChange={(event, newValue) => {
                      setSelectedProduct(newValue);
                    }}
                    getOptionLabel={(option) =>
                      option.productName
                        ? option.productName + " " + option.productCode
                        : ""
                    }
                    id="selectedProduct"
                    renderInput={(params) => (
                      <TextField {...params} label="Select Product" />
                    )}
                  />
                </GridItem> */}
                <GridItem xs={12}>
                  <FilePicker
                    files={readyMasterImage}
                    buttonLabel={"Upload Ready Master Image"}
                    id={"Ready Master Photo"}
                    maxFiles={10}
                    setNewFiles={_setReadyMasterImage}
                    filetype={".png,.jpg,.jpeg"}
                  />
                </GridItem>
                <GridItem xs={8} sm={8} md={8}>
                  <CustomInput
                    labelText="Product Name"
                    id="productName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: productName,
                      onChange: (event) => setProductName(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <Autocomplete
                    className={classes.formControl}
                    options={["Active", "Inactive"]}
                    value={status}
                    onChange={(event, newValue) => {
                      setStatus(newValue);
                    }}
                    getOptionLabel={(option) => option}
                    id="status"
                    renderInput={(params) => (
                      <TextField {...params} label="Status" />
                    )}
                  />
                </GridItem>
                {/* <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="Product Code"
                    id="productCode"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: productCode,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={12} sm={12}>
                  <Barcode value={productCode} />
                </GridItem> */}
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Index"
                    id="index"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: index,
                      onChange: (event) => setIndex(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  {/* <CustomInput
                    labelText="Side"
                    id="side"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: side,
                      onChange: (event) => setSide(event.target.value),
                    }}
                  /> */}
                  <Autocomplete
                    className={classes.formControl}
                    options={["B", "R", "L"]}
                    value={side}
                    onChange={(event, newValue) => {
                      setSide(newValue);
                    }}
                    getOptionLabel={(option) => option}
                    id="selectedBrand"
                    renderInput={(params) => (
                      <TextField {...params} label="Select Side" />
                    )}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Type"
                    id="type"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: type,
                      onChange: (event) => setType(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Color/ABR"
                    id="color"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: color,
                      onChange: (event) => {
                        setColor(event.target.value);
                      },
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Start Sph"
                    id="startsph"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: startSph,
                      disabled: true,
                      // onChange: (event) => {
                      //   let sph = event.target.value;

                      //   setStartSph(sph);
                      // },
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="End Sph"
                    id="endSph"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: endSph,
                      disabled: true,

                      // onChange: (event) => {
                      //   let sph = event.target.value;
                      //   setEndSph(sph);
                      // },
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Start Cyl"
                    id="startCyl"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: startCyl,
                      disabled: true,
                      // onChange: (event) => setStartCyl(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="End Cyl"
                    id="endCyl"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: endCyl,
                      disabled: true,
                      // onChange: (event) => setEndCyl(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Autocomplete
                    disabled
                    multiple
                    className={classes.formControl}
                    options={["0", "45", "90", "135", "180"]}
                    value={axis}
                    // onChange={(event, newValue) => {
                    //   setAxis(newValue);

                    // }}
                    getOptionLabel={(option) => option}
                    id="axis"
                    renderInput={(params) => (
                      <TextField {...params} label="Select Axis" />
                    )}
                  />
                  {/* <CustomInput
                    labelText="Axis"
                    id="axis"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: axis,
                      onChange: (event) => setAxis(event.target.value),
                    }}
                  /> */}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Start Addition"
                    id="startaddition"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: startAddition,
                      disabled: true,
                      // onChange: (event) => setStartAddition(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="End Addition"
                    id="endaddition"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: endAddition,
                      disabled: true,
                      // onChange: (event) => setEndAddition(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="rate"
                    id="rate"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rate,
                      onChange: (event) => setRate(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Autocomplete
                    filterOptions={filterOptions}
                    loading={brand.length === 0 ? true : false}
                    className={classes.formControl}
                    options={brand}
                    value={selectedBrand}
                    onChange={(event, newValue) => {
                      setSelectedBrand(newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    id="selectedBrand"
                    renderInput={(params) => (
                      <TextField {...params} label="Select Brand" />
                    )}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Autocomplete
                    filterOptions={filterOptions}
                    loading={category.length === 0 ? true : false}
                    className={classes.formControl}
                    options={category}
                    value={selectedCategory}
                    onChange={(event, newValue) => {
                      setSelectedCategory(newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    id="selectedCategory"
                    renderInput={(params) => (
                      <TextField {...params} label="Select Category" />
                    )}
                  />
                </GridItem>
                <GridItem xs={6}>
                  {createdProductsOne.map((i, index) => {
                    return axis.map((ax) => {
                      return (
                        <ListItem key={index}>
                          <ListItemText
                            primary={
                              color +
                              " " +
                              i.x +
                              " / " +
                              i.y +
                              " " +
                              ax +
                              " / " +
                              i.z
                            }
                          />
                        </ListItem>
                      );
                    });
                  })}
                </GridItem>
                <GridItem xs={6}>
                  {createdProductsTwo.map((i, index) => {
                    return axis.map((ax) => {
                      return (
                        <ListItem key={index}>
                          <ListItemText
                            primary={
                              color +
                              " " +
                              i.x +
                              " / " +
                              i.y +
                              " " +
                              ax +
                              " / " +
                              i.z
                            }
                          />
                        </ListItem>
                      );
                    });
                  })}
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ justifyContent: "center" }}>
              <Button
                color="primary"
                onClick={() => _onSave()}
                disabled={disable}
              >
                Update Ready Master
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
