import { inWords } from "../../utils";
import _ from "lodash";
import moment from "moment";

export const pointToPointBillTemplate = (data) => {
  let { bills, startDate, endDate } = data;

  let rows = "";
  let clientName = "";
  bills.map((item) => {
    if (clientName == "") {
      clientName = item.party.accountName;
      return;
    }
  });
  bills.map((o, index) => {
    // let createdAt = o["createdAt"];
    o.items.map((i, id) => {
      let { nameR, nameL, quantity, rate, amount , hsnCode , taxPercent} = i;
      let item = {
        nameR,
        nameL,
        quantity,
        index,
        rate,
        amount,
        hsnCode,
        taxPercent
      };

      if (id > 0) {
        rows = rows + renderTableRowIndex(item) + "\n";
      } else {
        rows = rows + renderTableRow(item) + "\n";
      }

      if (id + 1 == o.items.length) {
        rows = rows + renderTotalRow(o.code, o.createdAt) + "\n";
      }
    });
  });

  return `<!DOCTYPE html>
<html lang="en">
<head>
    <title>Challan 2</title>
    <meta charset="UTF-8">
    <style>
        .border {
            border-style: solid;
            border-width: 1px;
        }

        .center {
            text-align: center;
            align-items: center;
            justify-content: center;
        }

        .row {
            display: flex;
            flex-direction: row;
            flex: 1;
        }

        .space-between {
            align-items: center;
            justify-content: space-between;
        }

        h2 {
            margin: 4px;
        }

        h4 {
            margin: 4px;
        }

        h3 {
            margin: 10px;
        }

        h5 {
            margin: 1px;
        }

        p {
            font-size: 14px;
            margin: 1px;
        }

        table, th, td {
            font-weight: bold;
            font-size: 10px;
            border-collapse: collapse;
            border-bottom: 1px solid black;
        }
    </style>
</head>
<body >

<div style="height: 1000px; width: 824px;">
<div style="display:flex;flex:1;flex-direction:column;margin: 10px">
<div style="display: flex;flex-direction: row;justify-content: space-between">
<h4>Bill</h4>
${
  startDate != null && endDate != null
    ? `<p>${moment(startDate).format("DD-MM-YYYY")} to ${moment(endDate).format(
        "DD-MM-YYYY"
      )}</p>`
    : ``
}       
</div>
   
    <div style="display: flex;flex-direction: row;justify-content: space-between">
    <h4>M/S: ${clientName}</h4>
  
    </div>
   
  
    <div style="margin-top: 2px">
        <table>
            <tr>
                <th style="width: 5%;">Sr No.</th>
                <th style="width: 15%;">Description </th>
                <th style="width: 15%;">HSN Code</th>
                
                <th style="width: 10%">Qty</th>
                <th style="width: 10%;">Rate</th>
                <th style="width: 10%;">Amount</th>
                <th style="width: 10%;">CGST %</th>
                <th style="width: 10%;">CGST Amount</th>
                <th style="width: 10%;">SGST %</th>
                <th style="width: 10%;">SGST Amount</th>
                <th style="width: 10%;">IGST %</th>
                <th style="width: 10%;">IGST Amount</th>
                
            </tr>
            ${rows}
        </table>
    </div>
  
  
</div>
</body>
</html>

`;
};

let renderTableRow = (item) => {
  let { nameR, nameL, quantity, index, rate, amount , hsnCode , taxPercent} = item;

  return `<tr>
                <th style="width: 5%;padding: 5px">${index + 1}</th>
                <th style="width: 25%;padding: 5px">${nameR} ${nameL}</th>
                <th style="width: 10%padding: 5px">${hsnCode}</th>
                <th style="width: 10%padding: 5px">${quantity}</th>
                  <th style="width: 10%;padding: 5px">${rate}</th>
                  <th style="width: 15%;padding: 5px">${amount}</th>
                  <th style="width: 5%;padding: 5px">${Number(taxPercent) / 2 }</th>
                <th style="width: 15%;padding: 5px">${(amount * Number(taxPercent)) / 200}</th>
                <th style="width: 15%;padding: 5px">${Number(taxPercent)/2}</th>
                <th style="width: 15%;padding: 5px">${(amount * Number(taxPercent)) / 200} </th>
                <th style="width: 5%;padding: 5px">0</th>
                <th style="width: 15%;padding: 5px">0</th>
                <th style="width: 5%;padding: 5px">0.00</th>

              
            </tr>`;
};

let renderTableRowIndex = (item) => {
  let { nameR, nameL, quantity, rate, amount, hsnCode , taxPercent } = item;

  return `<tr>
                <th style="width: 5%; padding: 5px"></th>
                <th style="width: 15%; padding: 5px">${nameR} ${nameL}</th>
                <th style="width: 10%padding: 5px">${hsnCode}</th>
                <th style="width: 10% padding: 5px">${quantity}</th>

                  <th style="width: 10%; padding: 5px">${rate}</th>
                  <th style="width: 15%; padding: 5px">${amount}</th>
                  <th style="width: 15%; padding: 5px">${Number(taxPercent) / 2}</th>
                  <th style="width: 15%;padding: 5px">${(amount * Number(taxPercent)) / 200}</th>
                <th style="width: 15%; padding: 5px">${Number(taxPercent) / 2}</th>
                <th style="width: 15%;padding: 5px">${(amount * Number(taxPercent)) / 200}</th>
                <th style="width: 15%; padding: 5px">6</th>
                <th style="width: 15%; padding: 5px">0.00</th>

              
            </tr>`;
};

let renderTotalRow = (code, createdAt) => {
  //   let { code, createdAt } = item;

  return ` <tr>
  <th style="width: 15%">Dt: ${moment(createdAt).format("DD-MM-YYYY")}</th>
  <th style="width: 15%">${code}</th>
  <th style="width: 15%;"></th>

    <th style="width: 15%"></th>
    <th style="width: 7.8%"></th>
    <th style="width: 7.8%"></th>
    <th style="width: 7.8%"></th>
    <th style="width: 7.8%"></th>
    <th style="width: 7.8%"></th>
    <th style="width: 7.8%"></th>
    <th style="width: 7.8%"></th>
    <th style="width: 7.8%"></th>
    <th style="width: 7.8%"></th>


</tr>`;
};
