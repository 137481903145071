import React, { useEffect, useState } from "react";
// react plugin for creating charts
// @material-ui/core
import {
  Box,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import FileCopy from "@material-ui/icons/FileCopy";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import _ from "lodash";
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import moment from "moment";
import axios from "axios";
import {
  GetAllAccountsAPI,
  GetAllBillsAPI,
  GetAllChallansAPI,
  GetAllOrdersAPI,
  GetAllProductMastersAPI,
  GetAllReadyMastersAPI,
  GetEntityAPI,
} from "../../config/api";
import ChallanView from "../Challan/ChallanView";
import VisibilityIcon from "@material-ui/icons/Visibility";
import OrderView from "../Order/OrderView";
import { Receipt } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import BillView from "../Bill/BillView";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 500,
});

const useStyles = makeStyles(styles);

const orderColumns = [
  { id: "code", label: "Order Code" },
  { id: "orderType", label: "Order Type" },
  { id: "party", label: "Party" },
  { id: "product", label: "Product" },
  {
    id: "createdAt",
    label: "Order Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
  { id: "action", label: "Action" },
];

const challanColumns = [
  { id: "code", label: "Challan Id" },
  { id: "party", label: "Party" },
  { id: "orderRef", label: "Order Id" },
  { id: "amount", label: "Challan Amount" },
  // { id: "paymentStatus", label: "Payment Status" },
  {
    id: "createdAt",
    label: "Order Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
  { id: "action", label: "Action" },
];

const billColumns = [
  { id: "challanCodes", label: "Challan Codes", minWidth: 100 },
  { id: "code", label: "Code", minWidth: 100 },
  { id: "party", label: "Party", minWidth: 100 },
  // { id: "paymentStatus", label: "Payment Status", minWidth: 100 },
  { id: "action", label: "Action" },
];

const accountColumns = [
  { id: "name", label: "Name" },
  { id: "mobile", label: "Mobile Number" },
  { id: "city", label: "City" },
  { id: "orderCount", label: "Order Count" },
  { id: "status", label: "Status" },
];

const productColumns = [
  { id: "productName", label: "Name" },
  { id: "threshold", label: "Threshold Number" },
  { id: "quantity", label: "Balance" },
];

export default function Dashboard() {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const [bills, setBills] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [challans, setChallans] = useState([]);
  const [entityName, setEntityName] = useState("");
  const authToken = useSelector((state) => state.userReducer.authToken);
  const userInfo = useSelector((state) => state.userReducer.userInfo);
  useEffect(() => {
    _getOrders();
    _getChallans();
    _getAccounts();
    _getEntity();
  }, []);

  async function _getAccounts() {
    try {
      let query = `&limit=10&skip=0`;
      let res = await axios.get(GetAllAccountsAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      let accounts = res.data.accounts;
      setAccounts(accounts);
    } catch (err) {
      setAccounts([]);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  async function _getChallans() {
    try {
      let query = `&paymentStatus=Pending`;
      let res = await axios.get(GetAllChallansAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setChallans(res.data.challans);
    } catch (err) {
      setChallans([]);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  async function _getOrders() {
    // try {
    //   let query = `&limit=10&skip=0`;
    //   let res = await axios.get(GetAllOrdersAPI(userInfo.entity) + query, {
    //     headers: {
    //       Authorization: `Bearer ${authToken}`,
    //     },
    //   });
    //   setOrders(res.data.orders);
    // } catch (err) {
    //   setOrders([]);
    //   // toast.error(("Something went wrong, Please Contact Admin") , {
    //   //   position: "top-center",
    //   //   autoClose: 1500,
    //   //   hideProgressBar: false,
    //   //   closeOnClick: true,
    //   //   pauseOnHover: true,
    //   //   draggable: true,
    //   //   progress: undefined,
    //   //   theme: "light",
    //   //   });
    // }
  }

  async function _getEntity() {
    try {
      let res = await axios.get(GetEntityAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setEntityName(res.data.name);
    } catch (err) {
      setOrders([]);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  function _renderPrescriptionOrders() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [orders, setOrders] = React.useState([]);
    const [openViewDialog, setOpenViewDialog] = useState(null);

    useEffect(() => {
      _getOrders();
    }, []);

    async function _getOrders() {
      try {
        let query = `&limit=10&skip=0&orderStatus=Pending&orderType=Prescription`;
        let res = await axios.get(GetAllOrdersAPI(userInfo.entity) + query, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setOrders(res.data.orders);
      } catch (err) {
        setOrders([]);
        // toast.error(("Something went wrong, Please Contact Admin") , {
        //   position: "top-center",
        //   autoClose: 1500,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
      }
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    function closeViewDialog() {
      setOpenViewDialog(null);
    }

    return (
      <>
        <Table
          stickyHeader
          id={"order"}
          key={"order"}
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              {orderColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {orderColumns.map((column) => {
                      let value = row[column.id];
                      if (column.id === "product") {
                        if (row.orderType === "Fitting") {
                          value = row.orderDetail?.product?.name
                            ? row.orderDetail.product.name
                            : "";
                        } else if (row.orderType === "Ready") {
                          value = row.orderDetail?.product?.name
                            ? row.orderDetail.product.name
                            : "";
                        } else {
                          value = row.orderDetail?.product?.productName
                            ? row.orderDetail.product.productName
                            : "";
                        }
                      }
                      if (column.id === "party") {
                        value = value.accountName;
                      }
                      if (column.id === "action") {
                        return (
                          <TableCell align={"center"}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <IconButton
                                aria-label="view"
                                onClick={() =>
                                  setOpenViewDialog({
                                    open: true,
                                    item: row,
                                  })
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {openViewDialog ? (
          <OrderView
            open={openViewDialog.open}
            close={closeViewDialog}
            item={openViewDialog.item}
          />
        ) : null}
      </>
    );
  }

  function _renderFittingOrders() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [orders, setOrders] = React.useState([]);
    const [openViewDialog, setOpenViewDialog] = useState(null);

    useEffect(() => {
      _getOrders();
    }, []);

    async function _getOrders() {
      try {
        let query = `&limit=10&skip=0&orderStatus=Pending&orderType=Fitting`;
        let res = await axios.get(GetAllOrdersAPI(userInfo.entity) + query, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setOrders(res.data.orders);
      } catch (err) {
        setOrders([]);
        // toast.error(("Something went wrong, Please Contact Admin") , {
        //   position: "top-center",
        //   autoClose: 1500,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
      }
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    function closeViewDialog() {
      setOpenViewDialog(null);
    }

    return (
      <>
        <Table
          stickyHeader
          id={"order"}
          key={"order"}
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              {orderColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {orderColumns.map((column) => {
                      let value = row[column.id];
                      if (column.id === "product") {
                        if (row.orderType === "Fitting") {
                          value = row.orderDetail?.productName
                            ? row.orderDetail.productName
                            : "";
                        } else if (row.orderType === "Ready") {
                          value = row.orderDetail?.product?.name
                            ? row.orderDetail.product.name
                            : "";
                        } else {
                          value = row.orderDetail?.product?.productName
                            ? row.orderDetail.product.productName
                            : "";
                        }
                      }
                      if (column.id === "party") {
                        value = value.accountName;
                      }
                      if (column.id === "action") {
                        return (
                          <TableCell align={"center"}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <IconButton
                                aria-label="view"
                                onClick={() =>
                                  setOpenViewDialog({
                                    open: true,
                                    item: row,
                                  })
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {openViewDialog ? (
          <OrderView
            open={openViewDialog.open}
            close={closeViewDialog}
            item={openViewDialog.item}
          />
        ) : null}
      </>
    );
  }

  function _renderChallans() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openViewDialog, setOpenViewDialog] = useState(null);

    useEffect(() => {
      _getChallans();
    }, []);

    async function _getChallans() {
      try {
        let query = `&limit=10&skip=0`;
        let res = await axios.get(GetAllChallansAPI(userInfo.entity), {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setChallans(res.data.challans);
      } catch (err) {
        setChallans([]);
        // toast.error(("Something went wrong, Please Contact Admin") , {
        //   position: "top-center",
        //   autoClose: 1500,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
      }
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    function closeViewDialog() {
      setOpenViewDialog(null);
    }

    return (
      <>
        <Table
          stickyHeader
          id={"challan"}
          key={"challan"}
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              {challanColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {challans
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {challanColumns.map((column) => {
                      let value = row[column.id];
                      if (column.id === "party") {
                        value = value.accountName;
                      }
                      if (column.id === "product") {
                        value = row.items.length;
                      }
                      if (column.id === "action") {
                        return (
                          <TableCell align={"center"}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <IconButton
                                aria-label="view"
                                onClick={() =>
                                  setOpenViewDialog({
                                    open: true,
                                    item: row,
                                  })
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        );
                      }
                      if (column.id === "orderRef") {
                        let orderString = "";
                        let orderRefs = row.items.map((i, index) => {
                          if (index + 1 === row.items.length) {
                            orderString += i.orderRef;
                          } else {
                            orderString = orderString + i.orderRef + ", ";
                          }
                        });
                        value = orderString;
                      }
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={challans.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {openViewDialog ? (
          <ChallanView
            open={openViewDialog.open}
            close={closeViewDialog}
            item={openViewDialog.item}
          />
        ) : null}
      </>
    );
  }

  function _renderBills() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openViewDialog, setOpenViewDialog] = useState(null);

    useEffect(() => {
      _getBills();
    }, []);

    async function _getBills() {
      try {
        let query = `&limit=10&skip=0`;
        let res = await axios.get(GetAllBillsAPI(userInfo.entity) + query, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setBills(res.data.bills);
      } catch (err) {
        setChallans([]);
        // toast.error(("Something went wrong, Please Contact Admin") , {
        //   position: "top-center",
        //   autoClose: 1500,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
      }
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    function closeViewDialog() {
      setOpenViewDialog(null);
    }

    return (
      <>
        <Table stickyHeader id={"bill"} key={"bill"} aria-label="sticky table">
          <TableHead>
            <TableRow>
              {billColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {bills
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {billColumns.map((column) => {
                      let value = row[column.id];
                      if (column.id === "party") {
                        value = value.accountName;
                      }
                      if (column.id === "action") {
                        return (
                          <TableCell align={"center"}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <IconButton
                                aria-label="view"
                                onClick={() =>
                                  setOpenViewDialog({
                                    open: true,
                                    item: row,
                                  })
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={bills.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {openViewDialog ? (
          <BillView
            open={openViewDialog.open}
            close={closeViewDialog}
            item={openViewDialog.item}
          />
        ) : null}
      </>
    );
  }

  function _renderAccounts() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => { }, []);

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    return (
      <>
        <Table
          stickyHeader
          id={"account"}
          key={"account"}
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              {accountColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {accountColumns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={accounts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  }

  function _renderProducts() {
    const [products, setProducts] = useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    useEffect(() => {
      _getReady();
    }, []);

    async function _getReady() {
      try {
        let res = await axios.get(
          GetAllReadyMastersAPI(userInfo.entity) +
          `&limit=${rowsPerPage}&skip=${page * rowsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        let data = res.data.readyMasters;
        data = _.filter(data, (i) => {
          if (i.quantity < i.threshold) {
            return true;
          }
        });
        setProducts(data);
      } catch (err) {
        // console.log(err);
        // toast.error(("Something went wrong, Please Contact Admin") , {
        //   position: "top-center",
        //   autoClose: 1500,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
      }
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
    return (
      <>
        <Table
          stickyHeader
          id={"account"}
          key={"account"}
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              {productColumns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {products
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {productColumns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={products.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  }

  return (
    <div style={{ padding: 20 }}>
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <FileCopy />
              </CardIcon>
              <p className={classes.cardCategory}>Unpaid Challans</p>
              <h3 className={classes.cardTitle}>{challans.length}</h3>
            </CardHeader>
            <CardFooter stats>
              {/*<div className={classes.stats}>*/}
              {/*    <Danger>*/}
              {/*        <Warning/>*/}
              {/*    </Danger>*/}
              {/*    <a href="#pablo" onClick={e => e.preventDefault()}>*/}
              {/*        Get more space*/}
              {/*    </a>*/}
              {/*</div>*/}
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Orders</p>
              <h3 className={classes.cardTitle}>{orders.length}</h3>
            </CardHeader>
            <CardFooter stats>
              {/*<div className={classes.stats}>*/}
              {/*    <DateRange/>*/}
              {/*    Last 24 Hours*/}
              {/*</div>*/}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <ErrorOutline />
              </CardIcon>
              <p className={classes.cardCategory}>Pending Orders</p>
              <h3 className={classes.cardTitle}>{orders.length}</h3>
            </CardHeader>
            <CardFooter stats>
              {/*<div className={classes.stats}>*/}
              {/*    <LocalOffer/>*/}
              {/*    Tracked from Github*/}
              {/*</div>*/}
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Clients</p>
              <h3 className={classes.cardTitle}>{accounts.length}</h3>
            </CardHeader>
            <CardFooter stats>
              {/*<div className={classes.stats}>*/}
              {/*    <Update/>*/}
              {/*    Just Updated*/}
              {/*</div>*/}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="warning">
            <h4 className={classes.cardTitleWhite}>Stock Threshold</h4>
          </CardHeader>
          <CardBody> {_renderProducts()} </CardBody>
        </Card>
      </GridItem>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title=""
            headerColor="primary"
            tabs={[
              {
                tabName: "Pending Prescription Orders",
                tabIcon: BugReport,
                tabContent: _renderPrescriptionOrders(),
              },
              {
                tabName: "Pending Fitting Orders",
                tabIcon: BugReport,
                tabContent: _renderFittingOrders(),
              },
              {
                tabName: "New Challans",
                tabIcon: Code,
                tabContent: _renderChallans(),
              },
              {
                tabName: "New Bills",
                tabIcon: Cloud,
                tabContent: _renderBills(),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
