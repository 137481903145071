import React, { useEffect, useState } from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  IconButton,
  TextField,
} from "@material-ui/core";
import OrderView from "../Order/OrderView";
import VisibilityIcon from "@material-ui/icons/Visibility";
import _ from "lodash";

const columns = [
  { id: "productName", label: "Product" },
  { id: "color", label: "Color" },
];

export function ColorsDialog(props) {
  const { open, onClose, data } = props;
  console.log("rmr", data)
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);

  const groupedProducts = _.groupBy(data, 'productName');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openViewDialog, setOpenViewDialog] = useState(null);
  function closeViewDialog() {
    setOpenViewDialog(null);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    const filtered = data.filter((item) =>
      item.productName.toLowerCase().includes(query.toLowerCase()) || item.color.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
    setPage(0);
  };

  useEffect(() => {
    setFilteredData(data)
  }, [data]);

  const handleFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ width: "100%" }}
      fullWidth={true}
      maxWidth='md'
    >
      <DialogTitle id="alert-dialog-title">Codes</DialogTitle>
      <DialogContent>
        <TextField
          label="Search"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
          fullWidth
          style={{ marginBottom: 10 }}
        />
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id === "action") {
                        return (
                          <TableCell align={"center"}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <IconButton
                                aria-label="view"
                                onClick={() =>
                                  setOpenViewDialog({
                                    open: true,
                                    item: row,
                                  })
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleFullScreen} color="primary">
          Toggle Full Screen
        </Button>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      {openViewDialog ? (
        <OrderView
          open={openViewDialog.open}
          close={closeViewDialog}
          item={openViewDialog.item}
        />
      ) : null}
    </Dialog>
  );
}
