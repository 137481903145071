import _ from "lodash";
import { inWords, inWords2 } from "../../utils";

export const deliveryChallanTemplate = (data) => {
    console.log("deli", data)
    let {
        clientAddress,
        clientName,
        clientGSTNo,
        challanDate,
        items,
        challanCode,
        entityDetails,
        percent
    } = data;

    let totalAmountInWords = "",
        totalQuantity = 0,
        totalAmount = 0,
        totalAmountAfterTax = 0,
        roundOff = 0,
        cgstPer = 6,
        sgstPer = 6,
        igstPer = 6;

    let rows = "";
    items.map((o) => {
        let {
            amount,
            rate,
            rateL,
            rateR,
            nameL,
            nameR,
            quantity,
            fittingAmount,
            tint,
            discountAmount,
            discountPercent,
        } = o;
        totalAmount = totalAmount + Number(quantity * amount);
        let name = nameL + " " + nameR;
        let hsn = "";
        let item = {
            name,
            rate,
            amount: amount,
            totalAmount,
            quantity,
            cgstPercent: cgstPer,
            cgst: (cgstPer / 100) * amount,
        };
        totalQuantity += Number(item.quantity);
        rows = rows + renderTableRow(item, percent) + "\n";
    });
    let cgst = (cgstPer / 100) * totalAmount;
    totalAmountAfterTax = totalAmount + cgst;

    totalAmountInWords = inWords2(_.round(totalAmountAfterTax, 2).toFixed(0));

    return `<!DOCTYPE html>
<html lang="en">
<head>
    <title>Delivery Challan</title>
    <meta charset="UTF-8">
    <style>
        .border {
            border-style: solid;
            border-width: 1px;
        }

        .center {
            text-align: center;
            align-items: center;
            justify-content: center;
        }

        .row {
            display: flex;
            flex-direction: row;
            flex: 1;
        }

        .space-between {
            align-items: center;
            justify-content: space-between;
        }

        h2 {
            margin: 4px;
        }

        h4 {
            margin: 4px;
        }

        h3 {
            margin: 10px;
        }

        h5 {
            margin: 1px;
        }

        p {
            font-size: 14px;
            margin: 1px;
        }

        table, th, td {
            font-weight: bold;
            font-size: 12px;
            border-collapse: collapse;
            border: 1px solid black;
        }

        .page-break {
    page-break-before: avoid;
  }
@page {
  margin: 20px 30px 20px 50px;
    }
.pagebreak {
  display: block;
  clear: both;
  page-break-after: always;
}

        @media print{
            @page{
                size: 8.5in 11in; 
                margin: 0mm;
                padding: 0mm;
            }
        }
    </style>
</head>
<body >
<div style="height: 1000px; width: 824px;">
<div style="display:flex;flex:1;flex-direction:column;margin: 10px">
    <div class="center border">
        <h2 >${entityDetails.name}</h2>
        <p >${entityDetails.address}</p>
        <p >Tel: ${entityDetails.phone} GST No : ${entityDetails.gst}</p>
    </div>
    <div class="row border space-between" style="flex:0;padding: 4px">
        <div>
            <h5>M/S ${clientName}</h5>
            <p>${clientAddress}</p>
            <p>Buyer's GST No : ${clientGSTNo ? clientGSTNo : ""}</p>
        </div>
        <div>
        <p>Dt: ${challanDate}</p>
            <p>No: ${challanCode}</p>
        </div>
    </div>
    <div style="display: flex;flex: 1;flex-direction: column;margin-top: 2px;">
        <table style="width:100%;">
            <tr>
                <th style="width: 55%;text-align: left;padding-left: 10px">Description</th>
                <th style="width: 15%">Pairs</th>
                <th style="width: 15%">Rate</th>
                <th style="width: 15%">Amount</th>
               
            </tr>
            ${rows}
        </table>
    </div>
    <div >
        <table style="width:100%;flex:0">
            <tr>
                <th style="width: 55%;text-align: left;padding-left: 10px">
                    <h4>Total</h4>
                </th>
                <th style="width: 15%">${totalQuantity}</th>
                <th style="width: 15%"></th>
                <th style="width: 15%">${_.round(totalAmount, 2).toFixed(
        2
    )}</th>
                
            </tr>
        </table>
        <div style="display: flex;flex-direction: row;flex:0;width: 100%">
            
            <div  style="flex:1;align-self:center;justify-content:flex-start;display:flex;flex-direction: row;">
                <div style="flex-direction: row; margin-right: 4px">
                    <p>In Words: ${totalAmountInWords} Rupees Only </p>
                </div>
            </div>

            <div  style="flex:1;padding: 4px">
                <div style="display:flex;flex-direction:row;justify-content: flex-end">
                    <p>${_.round(totalAmount, 2).toFixed(2)}</p>
                </div>
                <div style="display:flex;flex-direction:row;justify-content: flex-end">
                    <p>${_.round(cgst, 2).toFixed(2)}</p>
                </div>
               
                <div style="display:flex;flex-direction:row;justify-content: flex-end">
                    <p>${_.round(totalAmountAfterTax, 2).toFixed(2)}</p>
                </div>
            </div>
        </div>
        <div style="display:flex;flex-direction:row;justify-content: space-between">
            <div style="display: flex; flex-direction: column">
                <h4>WORKING HOURS: 10:30 A.M. to 7:00 P.M.</h4>
                <p style="font-size: 12px">I/we hereby certify that my/our registration certificate under the <br /> Maharashtra Value Added
                Tax Act 2003  is in force on the date on which <br /> the sale of goods specified in this tax invoice
                n made by me/us and that <br /> tax invoice has been effected by me / us and it shall be accounted
                for in <br /> the turn over of sales while filing of the returns and the tax due, if any. <br />
                paybale on the sale has been paid or shall be paid. <br /> Subject To Mumbai Jurisdiction.</p>
            </div>
            <h4 style="display:flex;flex: 1; align-items:center;align-self: center; justify-content: center">Authorised Signatory</h4>
        </div>
    </div>
</div>
</div>
</body>
</html>


`;
};

let renderTableRow = (item, percent) => {
    let { name, rate, amount, totalAmount, quantity } = item;
    let tota = (amount * percent) / 100
    let tota2 = (totalAmount * percent) / 100
    return `<tr>
                <th style="width: 55%;text-align: left;padding-left: 10px">${name}</th>
                <th style="width: 15%">${_.round(quantity).toFixed(1)}</th>
                <th style="width: 15%">${_.round(tota).toFixed(2)}</th>
                <th style="width: 15%">${_.round(quantity * tota2).toFixed(
        2
    )}</th>
            </tr>`;
};
