import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
// @material-ui/core components
import {
  Box,
  Dialog,
  Fab,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import {
  CreateOrderAPI,
  GetAllAccountsAPI,
  GetAllReadyMastersAPI,
} from "../../config/api";

import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { toast } from "react-toastify"

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 500,
});

const styles = {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

const coatOptions = ["C", "D", "E", "F"];
const designOptions = ["C", "D", "E", "F"];
const typeOptions = ["C", "D", "E", "F"];
const brandOptions = ["C", "D", "E", "F"];
let invoiceTemplates = [
  {
    label: "Invoice",
    value: "invoice",
  },
  {
    label: "Challan 1",
    value: "challan1",
  },
  {
    label: "Challan 2",
    value: "challan2",
  },
  {
    label: "Challan 3",
    value: "challan3",
  },
];

const sides = ["B", "L", "R"];

const columns = [
  { id: "productCode", label: "Product Code" },
  { id: "name", label: "Product Name" },
  { id: "index", label: "Index" },
  { id: "side", label: "Side" },
  { id: "type", label: "Type" },
  { id: "color", label: "Color/ABR" },
  { id: "rate", label: "Rate" },
  { id: "range", label: "Range" },
  { id: "action", label: "Action" },
];
export default function ReadyOrderAdd(props) {
  const classes = useStyles();
  const theme = useTheme();

  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);

  const readyMastersProducts = useSelector(
    (state) => state.userReducer.readyMasters
  );
  const { open, close, fetchData } = props;
  const [selectedItem, setSelectedItem] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [productCode, setProductCode] = useState("");
  const [productName, setProductName] = useState("");
  const [index, setIndex] = useState("");
  const [side, setSide] = useState("");
  const [type, setType] = useState("");
  const [color, setColor] = useState("");
  const [rate, setRate] = useState("");
  const [range, setRange] = useState("");

  const [users, setUsers] = useState([]);
  const [readyProducts, setReadyProducts] = useState([]);
  const [selectedReadyProduct, setSelectedReadyProduct] = useState(null);
  const [party, setParty] = useState(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    console.log("Ready Order line 138");
    _getUsers();
    _getReadyProducts();
  }, []);

  useEffect(() => {
    if (selectedReadyProduct) {
      setProductCode(selectedReadyProduct.productCode);
      setProductName(selectedReadyProduct.productName);
      setIndex(selectedReadyProduct.index);
      setSide(selectedReadyProduct.side);
      setType(selectedReadyProduct.type);
      setColor(selectedReadyProduct.color);
      setRate(selectedReadyProduct.price);
      setRange(selectedReadyProduct.range);
    } else {
      setProductCode("");
      setProductName("");
      setIndex("");
      setSide("");
      setType("");
      setColor("");
      setRate("");
      setRange("");
    }
  }, [selectedReadyProduct]);

  async function _getUsers() {
    try {
      let res = await axios.get(GetAllAccountsAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setUsers(res.data.accounts);
    } catch (e) {
      // console.log("er:", e);

    }
  }

  async function _getReadyProducts() {
    try {
      // let res = await axios.get(GetAllReadyMastersAPI());

      setReadyProducts(readyMastersProducts);
    } catch (e) {
      // console.log("er:", e);

    }
  }

  async function _onSave() {
    try {
      await Promise.all(
        _.map(items, async (i, index) => {
          let { party } = i;
          let order = {
            orderType: "Ready",
            party: {
              accountCode: party.accountCode,
              accountId: party._id,
              accountName: party.name,
              accountAddress: party.address,
              accountGst: party.gst,
            },
            orderDetail: i,
            createdBy: userInfo._id,
            createdByName: userInfo.name,
            entity: userInfo.entity,
          };

          let res = await axios.post(CreateOrderAPI(), order, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          });
        })
      );
      toast.success('Successfully Added!', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      close();
      fetchData();
    } catch (e) {
      // console.log("err:", e);

    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Add Ready Order</h4>
                <Button color={"transparent"} onClick={() => _onSave()}>
                  Save
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <Autocomplete
                    id="party"
                    className={classes.formControl}
                    options={users}
                    value={party}
                    onChange={(event, value) => {
                      setParty(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Party" />
                    )}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <Autocomplete
                    id="selectedReadyProduct"
                    filterOptions={filterOptions}
                    className={classes.formControl}
                    options={readyProducts}
                    loading={readyProducts?.length === 0 ? true : false}
                    value={selectedReadyProduct}
                    onChange={(event, value) => {
                      setSelectedReadyProduct(value);
                    }}
                    getOptionLabel={(option) =>
                      `${option.productCode} (${option.productName})`
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Select Ready Product" />
                    )}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="Product Code"
                    id="productCode"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: productCode,
                      onChange: (event) => setProductCode(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="Product Name"
                    id="productName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: productName,
                      onChange: (event) => setProductName(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    labelText="Index"
                    id="index"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: index,
                      onChange: (event) => setIndex(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <Autocomplete
                    id="side"
                    className={classes.formControl}
                    options={sides}
                    value={side}
                    onChange={(event, value) => {
                      setSide(value);
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Side" />
                    )}
                  />
                </GridItem>

                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    labelText="Type"
                    id="type"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: type,
                      onChange: (event) => setType(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    labelText="Color/ABR"
                    id="color"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: color,
                      onChange: (event) => setColor(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    labelText="Rate"
                    id="rate"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rate,
                      onChange: (event) => setRate(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    labelText="Range"
                    id="range"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: range,
                      onChange: (event) => setRange(event.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={1}>
                  <Box justifyContent={"center"} marginTop={4}>
                    <Fab
                      size={"small"}
                      color="primary"
                      aria-label="add"
                      onClick={() => {
                        let object = {
                          productCode,
                          productName,
                          index,
                          side,
                          type,
                          color,
                          rate,
                          range,
                          party,
                        };

                        let copy = _.clone(items);
                        copy.push(object);
                        setItems(copy);

                        setProductCode("");
                        setProductName("");
                        setIndex("");
                        setSide("");
                        setType("");
                        setColor("");
                        setRate("");
                        setRange("");

                        setSelectedReadyProduct(null);
                        setParty(null);
                      }}
                    >
                      <AddIcon />
                    </Fab>
                  </Box>
                </GridItem>
                {_.isEmpty(items) ? null : (
                  <GridItem xs={12}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={"center"}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                if (column.id === "action") {
                                  return (
                                    <TableCell align={"center"}>
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                      >
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() => {
                                            let copy = _.clone(items);
                                            copy.splice(index, 1);
                                            setItems(copy);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </TableCell>
                                  );
                                }
                                return (
                                  <TableCell key={column.id} align={"center"}>
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
