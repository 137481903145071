import {
  FCM_TOKEN_REFRESH,
  LOGIN_SUCCESS,
  LOGOUT,
  UPDATE_AUTH_PIN,
  UPDATE_USER_INFO,
  READY_MASTER,
} from "../actions/types";

const DEFAULT_STATE = {
  userInfo: null,
  fcmToken: null,
  readyMasters: null,
  authToken: null,
  authPin: "",
};

export default (state = DEFAULT_STATE, { type, payload } = {}) => {
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        userInfo: payload.userInfo,
        authToken: payload.authToken,
      };
    case READY_MASTER:
      return {
        ...state,
        readyMasters: payload.readyMasters,
      };
    case FCM_TOKEN_REFRESH:
      return { ...state, fcmToken: payload };
    case UPDATE_USER_INFO:
      return { ...state, userInfo: payload };
    case UPDATE_AUTH_PIN:
      return { ...state, authPin: payload };
    case LOGOUT:
      return { ...state, userInfo: null, authToken: null, readyMasters: null };
    default:
      return state;
  }
};
