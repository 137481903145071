import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Provider, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { createBrowserHistory } from "history";
import _ from "lodash";
import createHistory from "history/createBrowserHistory";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import "./App.css";
import Login from "./screens/Login/Login";
import ForgotPassword from "./screens/Login/ForgotPassword";
import ResetPassword from "./screens/Login/ResetPassword";

import { store, persistor } from "./store";
import { DrawerLayout } from "./layouts/DrawerLayout";
import Dashboard from "./screens/Dashboard/Dashboard";
import UserProfile from "./screens/UserProfile/UserProfile";
import PurchaseList from "./screens/Purchase/PurchaseList";
import AccountList from "./screens/Account/AccountList";
import DiscountList from "./screens/Discount/DiscountList";
import OrderList from "./screens/Order/OrderList";
import TransactionHistory from "./screens/Transaction/TransactionHistory";
import Master from "./screens/Master/Master";
import OrderListStatus from "./screens/Order/OrderListStatus";
import AccountingList from "./screens/Accounting/AccountingList";
import PaymentList from "./screens/Payment/PaymentList";
import PurchaseBarcodes from "./screens/Purchase/PurchaseBarcodes";
import ProductBarcodes from "./screens/Master/ProductBarcodes";
import OrderPrintPage from "./screens/Order/OrderPrintPage";
import PrintAdList from "./screens/PrintAd/PrintAdList";
import BrandMastersList from "./screens/Brands/BrandMastersList";
import OrderListApp from "./screens/OrderApp/OrderList";
import OrderReturn from "./screens/OrderApp/OrderReturn";
import PageNotFound from "./screens/PageNotFound/PageNotFound";
import AccountVerificationList from "./screens/AccountVerification/AccountVerificationList";
import CategoryMastersList from "./screens/Category/CategoryMastersList";
import OfferMastersList from "./screens/Offers/OfferMastersList";
import BrochureMastersList from "./screens/Brochure/BrochureMastersList";
import TrendingMastersList from "./screens/Trending/TrendingMastersList";
import PricingListMastersList from "./screens/PricingList/PricingListMastersList";
import EmployeeList from "./screens/Employee/EmployeeList";
import Register from "./screens/Login/Register";
import HomePageMastersList from "./screens/HomePage/HomePageMastersList";
import AccountUserList from "./screens/AccountUser/AccountUserList";
import AccountAddress from "./screens/Account/accountAddress";
import ReadyMasterRangeList from "./screens/ReadyMasterRange/ReadyMasterRangeList";
import LedgerRequestList from "./screens/LedgerRequest/LedgerRequestList";
import ResetPasswordApp from "./screens/Login/ReetPasswordApp";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrescriptionList from "./screens/PrescriptionList/PrescriptionList";
import ReadyOrderList from "./screens/ReadyOrders/ReadyOrderList";
import BankList from "./screens/Bank/BankList";
import LabMasterList from "./screens/labmaster/LabMasterList";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CreditNote from "./screens/Payment/CreditNote";
import DebitNote from "./screens/Payment/DebitNote";
import Test from "./screens/Test/Test";
import ProdLogList from "./screens/ProdLog/ProdLogList";



const theme = createMuiTheme({
  palette: {
    background: {
      paper: "#fff",
      default: "#fafafa",
    },
    primary: {
      main: "#512da8",
    },
    secondary: {
      main: "#fff",
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export default function App(props) {
  // const history = useHistory();
  const history = createBrowserHistory();

  const PrivateRoute = ({ component: Component, ...rest }) => {
    const userInfo = useSelector((state) => state.userReducer.userInfo);
    let permissionObject = Object.keys(userInfo);
    let viewRoute = false;
    let routeObjects = Object.keys(rest);
    let item = _.filter(
      routeObjects,
      (routeObject) => routeObject.indexOf("v") === 0
    );
    // if (_.includes(permissionObject, item[0])) {
    //   viewRoute = true;
    // }
    if (userInfo[item[0]] == rest[item[0]]) {
      viewRoute = true;
    }



    return (
      <Route
        {...rest}
        render={(props) =>
          userInfo && userInfo?.isSystemUser && viewRoute ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );
  };

  return (
    <MuiThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>

        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => {
                    return <Redirect to="/login" />;
                  }}
                />
                <Route path="/login" component={Login} />
                <Route path="/register" component={Register} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/reset-password-app" component={ResetPasswordApp} />

                <DrawerLayout {...props}>
                  {/* <Redirect from="/" to="/admin/dashboard" /> */}
                  <PrivateRoute
                    exact
                    path="/admin/user"
                    component={UserProfile}
                    viewUserProfile={true}
                  />
                  <PrivateRoute
                    path="/admin/dashboard"
                    component={Dashboard}
                    viewDashboard={true}
                  />
                  <PrivateRoute
                    path="/admin/home-page"
                    component={HomePageMastersList}
                    viewDashboard={true}
                  />
                  <PrivateRoute
                    path="/admin/system-user"
                    component={EmployeeList}
                    viewSystemUsers={true}
                  />
                  <PrivateRoute
                    path="/admin/account-user"
                    component={AccountUserList}
                    viewAccountUsers={true}
                  />
                  <PrivateRoute
                    path="/admin/purchase-list"
                    component={PurchaseList}
                    viewPurchase={true}
                  />
                  <PrivateRoute
                    path="/admin/account-list"
                    component={AccountList}
                    viewAccounts={true}
                  />
                  <PrivateRoute
                    path="/admin/account-verfication"
                    component={AccountVerificationList}
                    viewAccountsVerification={true}
                  />
                  <PrivateRoute
                    path="/admin/discount-list"
                    component={DiscountList}
                    viewDiscounts={true}
                  />
                  <PrivateRoute
                    path="/admin/order-list"
                    component={OrderList}
                    viewOrders={true}
                  />
                  <PrivateRoute
                    path="/admin/prescription-list"
                    component={PrescriptionList}
                  // viewPrescriptionList={true}
                  />
                  <PrivateRoute
                    path="/admin/readyorder-list"
                    component={ReadyOrderList}
                  // viewPrescriptionList={true}
                  />
                  <PrivateRoute
                    path="/admin/order-app-list"
                    component={OrderListApp}
                    viewOrdersApp={true}
                  />
                  <PrivateRoute
                    path="/admin/order-return-list"
                    component={OrderReturn}
                    viewOrderReturn={true}
                  />
                  <PrivateRoute
                    path="/admin/transaction-history"
                    component={TransactionHistory}
                    viewTransaction={true}
                  />
                  <PrivateRoute
                    path="/admin/prod-log-list"
                    component={ProdLogList}
                    viewTransaction={true}
                  />
                  <PrivateRoute
                    path="/admin/master"
                    component={Master}
                    viewMaster={true}
                  />
                  <PrivateRoute
                    path="/admin/readyMasterRange"
                    component={ReadyMasterRangeList}
                    viewMaster={true}
                  />
                  <PrivateRoute
                    path="/admin/order-status"
                    component={OrderListStatus}
                    viewOrderStatus={true}
                  />
                  <PrivateRoute
                    path="/admin/accounting"
                    component={AccountingList}
                    viewAccounting={true}
                  />
                  <PrivateRoute
                    path="/admin/ledgerRequest"
                    component={LedgerRequestList}
                    viewAccounting={true}
                  />
                  <PrivateRoute
                    path="/admin/bank-list"
                    component={BankList}
                    viewBankList={true}
                  />
                  <PrivateRoute
                    path="/admin/lab-list"
                    component={LabMasterList}
                  // viewlablist={true}
                  />
                  <PrivateRoute
                    path="/admin/payment-list"
                    component={PaymentList}
                    viewPaymentList={true}
                  />
                  <PrivateRoute
                    path="/admin/creditNote"
                    component={CreditNote}
                    viewPaymentList={true}
                  />
                  <PrivateRoute
                    path="/admin/test"
                    component={Test}
                    viewPaymentList={true}
                  />
                  <PrivateRoute
                    path="/admin/debitNote"
                    component={DebitNote}
                    viewPaymentList={true}
                  />
                  <PrivateRoute
                    path="/admin/purchase-barcodes"
                    component={PurchaseBarcodes}
                    viewPurchaseBarcode={true}
                  />
                  <PrivateRoute
                    path="/admin/product-barcodes"
                    component={ProductBarcodes}
                    viewProductBarcode={true}
                  />
                  <PrivateRoute
                    path="/admin/account-address"
                    component={AccountAddress}
                    viewAccountAddress={true}
                  />
                  <PrivateRoute
                    path="/admin/order-print"
                    component={OrderPrintPage}
                    viewOrderPrintPage={true}
                  />
                  <PrivateRoute
                    path="/admin/print-ad-list"
                    component={PrintAdList}
                    viewPrintAd={true}
                  />
                  <PrivateRoute
                    path="/admin/brand-list"
                    component={BrandMastersList}
                    viewBrandsList={true}
                  />
                  <PrivateRoute
                    path="/admin/category-list"
                    component={CategoryMastersList}
                    viewCategoryList={true}
                  />
                  <PrivateRoute
                    path="/admin/offer-list"
                    component={OfferMastersList}
                    viewOfferList={true}
                  />
                  <PrivateRoute
                    path="/admin/trending-list"
                    component={TrendingMastersList}
                    viewTrendingList={true}
                  />
                  <PrivateRoute
                    path="/admin/brochure-list"
                    component={BrochureMastersList}
                    viewBrochureList={true}
                  />
                  <PrivateRoute
                    path="/admin/pricing-list"
                    component={PricingListMastersList}
                    viewPriceList={true}
                  />


                  {/* <Route path="*" component={PageNotFound} /> */}
                </DrawerLayout>
              </Switch>
            </Router>
            <ToastContainer />
          </PersistGate>
        </Provider>
      </LocalizationProvider>
    </MuiThemeProvider>
  );
}
