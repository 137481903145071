import React, { useDebugValue, useEffect, useState } from "react";
// @material-ui/core components
import {
  Box,
  Button,
  Dialog,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import axios from "axios";
import {
  CreateLedgerAPI,
  GetAllBillsAPI,
  UpdateBillAPI,
} from "../../config/api";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const billColumns = [
  { id: "party", label: "Party", minWidth: 170 },
  { id: "product", label: "Product", minWidth: 100 },
  { id: "discountPercent", label: "Discount Percent", minWidth: 100 },
  { id: "discountAmount", label: "Discount Amount", minWidth: 100 },
];

export default function RoundOffBill(props) {
  let authToken = useSelector((state) => state.userReducer.authToken);
  let userInfo = useSelector((state) => state.userReducer.userInfo);

  const classes = useStyles();
  const theme = useTheme();
  const { open, close, item } = props;

  useEffect(() => {
    console.log("Bhai barabr jaa rhas")
  }, [open])


  const { _id } = item;

  useEffect(() => {
    _getBills();
  }, [item]);

  console.log("I am in ledger requesttt")

  const [bills, setBills] = useState([]);

  async function _getBills() {
    try {
      let query = `&userId=${item._id}`;
      let res = await axios.get(GetAllBillsAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setBills(res.data.bills);
    } catch (err) {
      // console.log("error:", err);
      // toast.error(('Cannot fetch data'), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  async function _generate() {
    try {
      await Promise.all(
        bills.map(async (i) => {
          let res = await axios.patch(
            UpdateBillAPI(i._id),
            {
              paymentStatus: "Paid",
            },
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
        })
      );
      let challanIds = [],
        challanCodes = [];
      _.map(bills, (i) => {
        challanIds = _.concat(challanIds, i.challanIds);
        challanCodes = _.concat(challanCodes, i.challanCodes);
      });
      let data = {
        ids: challanIds,
        type: "Bill",
        date: moment(),
        amount: 0,
      };
      let res = await axios.patch(CreateLedgerAPI(), data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      close();
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <Card>
        <CardHeader color="primary">
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <h4 className={classes.cardTitleWhite}>Round Off ja</h4>
            <Button style={{ color: "white" }} onClick={close}>
              Close
            </Button>
            <Button style={{ color: "white" }} onClick={() => _generate()}>
              Round Off
            </Button>
          </Box>
          {/*<p className={classes.cardCategoryWhite}>Complete your profile</p>*/}
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {billColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bills.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {billColumns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </Dialog>
  );
}
