import React, { createRef, useEffect, useState } from "react";
import Dashboard from "@material-ui/icons/Dashboard";
import _ from "lodash";
import { Helmet } from "react-helmet";
import axios from "axios";
import DashboardPage from "../screens/Dashboard/Dashboard";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import PurchaseList from "../screens/Purchase/PurchaseList";
import AccountList from "../screens/Account/AccountList";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import DiscountList from "../screens/Discount/DiscountList";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import OrderList from "../screens/Order/OrderList";
import Master from "../screens/Master/Master";
import RepeatIcon from "@material-ui/icons/Repeat";
import TransactionHistory from "../screens/Transaction/TransactionHistory";
import logo from "../assets/img/reactlogo.png";
import Sidebar from "../../src/components/Sidebar/Sidebar.js";
import bgImage from "../assets/img/sidebar-2.jpg";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle";
import PerfectScrollbar from "perfect-scrollbar";
import Navbar from "../components/Navbars/Navbar";
import OrderListStatus from "../screens/Order/OrderListStatus";
import AccountingList from "../screens/Accounting/AccountingList";
import BankList from "../screens/Bank/BankList";
import PaymentList from "../screens/Payment/PaymentList";
import PrintAdList from "../screens/PrintAd/PrintAdList";
import BrandMastersList from "../screens/Brands/BrandMastersList";
import AccountVerificationList from "../screens/AccountVerification/AccountVerificationList";
import OrderListApp from "../screens/OrderApp/OrderList";
import OrderReturn from "../screens/OrderApp/OrderReturn";


import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../actions";
import CategoryMastersList from "../screens/Category/CategoryMastersList";
import OfferMastersList from "../screens/Offers/OfferMastersList";
import BrochureMastersList from "../screens/Brochure/BrochureMastersList";
import TrendingMastersList from "../screens/Trending/TrendingMastersList";
import PricingListMastersList from "../screens/PricingList/PricingListMastersList";
import EmployeeList from "../screens/Employee/EmployeeList";
import { TruckCheckOutline } from "mdi-material-ui";
import HomePageMastersList from "../screens/HomePage/HomePageMastersList";
import AccountUserList from "../screens/AccountUser/AccountUserList";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import InventoryIcon from "@mui/icons-material/Inventory";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import PaymentIcon from "@mui/icons-material/Payment";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import BookIcon from "@mui/icons-material/Book";
import CategoryIcon from "@mui/icons-material/Category";
import { GetEntityAPI } from "../config/api";
import ReadyMasterRangeList from "../screens/ReadyMasterRange/ReadyMasterRangeList";
import LedgerRequestList from "../screens/LedgerRequest/LedgerRequestList";
import PrescriptionList from "../screens/PrescriptionList/PrescriptionList";
import ReadyOrderList from "../screens/ReadyOrders/ReadyOrderList";
import LabMasterList from "../screens/labmaster/LabMasterList";
import CreditNote from "../screens/Payment/CreditNote.js";
import DebitNote from "../screens/Payment/DebitNote.js";
import Test from "../screens/Test/Test.js";
import ProdLogList from "../screens/ProdLog/ProdLogList.js";
const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    viewDashboard: true,
  },
  {
    path: "/home-page",
    name: "Home Page",
    icon: HomeIcon,
    component: HomePageMastersList,
    layout: "/admin",
    viewDashboard: true,
  },
  {
    path: "/system-user",
    name: "System Users",
    icon: AccountCircleIcon,
    component: EmployeeList,
    layout: "/admin",
    viewSystemUsers: true,
  },
  {
    path: "/account-user",
    name: "Account Users",
    icon: PersonIcon,
    component: AccountUserList,
    layout: "/admin",
    viewAccountUsers: true,
  },


  {
    path: "/account-list",
    name: "Accounts",
    icon: SupervisorAccountIcon,
    component: AccountList,
    layout: "/admin",
    viewAccounts: true,
  },
  {
    path: "/account-verfication",
    name: "Account Verification",
    icon: AdminPanelSettingsIcon,
    component: AccountVerificationList,
    layout: "/admin",
    viewAccountsVerification: true,
  },
  {
    path: "/purchase-list",
    name: "Purchase",
    icon: ShoppingCartIcon,
    component: PurchaseList,
    layout: "/admin",
    viewPurchase: true,
  },
  {
    path: "/discount-list",
    name: "Discounts",
    icon: LocalOfferIcon,
    component: DiscountList,
    layout: "/admin",
    viewDiscounts: true,
  },
  // {
  //   path: "/order-list",
  //   name: "Orders Web",
  //   icon: MoveToInboxIcon,
  //   component: OrderList,
  //   layout: "/admin",
  //   viewOrders: true,
  // },
  {
    path: "/order-app-list",
    name: "Orders App",
    icon: MoveToInboxIcon,
    component: OrderListApp,
    layout: "/admin",
    viewOrdersApp: true,
  },
  // {
  //   path: "/order-status",
  //   name: "Order status",
  //   icon: MoveToInboxIcon,
  //   component: OrderListStatus,
  //   layout: "/admin",  
  //   viewOrderStatus: true,
  // },
  {
    path: "/prescription-list",
    name: "Prescription Orders",
    icon: MoveToInboxIcon,
    component: PrescriptionList,
    layout: "/admin",
    viewOrders: true,
  },
  {
    path: "/readyorder-list",
    name: "Ready / Fitting Orders",
    icon: MoveToInboxIcon,
    component: ReadyOrderList,
    layout: "/admin",
    viewOrders: true,
  },
  {
    path: "/order-return-list",
    name: "Orders Return",
    icon: MoveToInboxIcon,
    component: OrderReturn,
    layout: "/admin",
    viewOrderReturn: true,
  },
  {
    path: "/master",
    name: "Master",
    icon: InventoryIcon,
    component: Master,
    layout: "/admin",
    viewMaster: true,
  },
  {
    path: "/readyMasterRange",
    name: "Ready Master Range",
    icon: InventoryIcon,
    component: ReadyMasterRangeList,
    layout: "/admin",
    viewMaster: true,
  },
  {
    path: "/transaction-history",
    name: "Transaction",
    icon: RepeatIcon,
    component: TransactionHistory,
    layout: "/admin",
    viewTransaction: true,
  },
  {
    path: "/prod-log-list",
    name: "Product Log",
    icon: LocalOfferIcon,
    component: ProdLogList,
    layout: "/admin",
    viewTransaction: true,
  },
  {
    path: "/accounting",
    name: "Accounting",
    icon: AccountBalanceIcon,
    component: AccountingList,
    layout: "/admin",
    viewAccounting: true,
  },


  {
    path: "/ledgerRequest",
    name: "Ledger Request",
    icon: AccountBalanceIcon,
    component: LedgerRequestList,
    layout: "/admin",
    viewAccounting: true,
  },


  {
    path: "/payment-list",
    name: "Payment List",
    icon: PaymentIcon,
    component: PaymentList,
    layout: "/admin",
    viewPaymentList: true,
  },
  {
    path: "/creditNote",
    name: "Credit Note",
    icon: AdminPanelSettingsIcon,
    component: CreditNote,
    layout: "/admin",
    viewAccountsVerification: true,
  },
  {
    path: "/test",
    name: "Stock Report",
    icon: AdminPanelSettingsIcon,
    component: Test,
    layout: "/admin",
    viewAccountsVerification: true,
  },
  {
    path: "/debitNote",
    name: "Debit Note",
    icon: AdminPanelSettingsIcon,
    component: DebitNote,
    layout: "/admin",
    viewAccountsVerification: true,
  },
  {
    path: "/brand-list",
    name: "Brands List",
    icon: RepeatIcon,
    component: BrandMastersList,
    layout: "/admin",
    viewBrandsList: true,
  },
  {
    path: "/category-list",
    name: "Category List",
    icon: CategoryIcon,
    component: CategoryMastersList,
    layout: "/admin",
    viewCategoryList: true,
  },
  {
    path: "/offer-list",
    name: "Offer List",
    icon: LocalOfferIcon,
    component: OfferMastersList,
    layout: "/admin",
    viewOfferList: true,
  },
  {
    path: "/trending-list",
    name: "Trending List",
    icon: TrendingUpIcon,
    component: TrendingMastersList,
    layout: "/admin",
    viewTrendingList: true,
  },
  {
    path: "/brochure-list",
    name: "Brochure List",
    icon: BookIcon,
    component: BrochureMastersList,
    layout: "/admin",
    viewBrochureList: true,
  },
  {
    path: "/pricing-list",
    name: "Price List",
    icon: BookIcon,
    component: PricingListMastersList,
    layout: "/admin",
    viewPriceList: true,
  },
  {
    path: "/print-ad-list",
    name: "Print Ad",
    icon: AddAPhotoIcon,
    component: PrintAdList,
    layout: "/admin",
    viewPrintAd: true,
  },
  {
    path: "/bank-list",
    name: "Bank List",
    icon: AccountBalanceIcon,
    component: BankList,
    layout: "/admin",
    viewBankList: true,
  },
  {
    path: "/lab-list",
    name: "Lab List",
    icon: AccountBalanceIcon,
    component: LabMasterList,
    layout: "/admin",
    viewLabList: true,
  },
];


const useStyles = makeStyles(styles);
let ps;


export const DrawerLayout = (props) => {
  const { children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [entityName, setEntityName] = useState("");


  const classes = useStyles();
  const mainPanel = createRef();
  let authToken = useSelector((state) => state.userReducer.authToken);
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };


  useEffect(() => {
    if (authToken) {
      var decoded = jwt_decode(authToken);
      if (decoded.exp < Date.now() / 1000) {
        dispatch(logout());
        history.push("/login");
      }
    }
  }, []);
  // initialize and destroy the PerfectScrollbar plugin
  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);


  let permissionObject = userInfo && Object.keys(userInfo);
  let updatedRoutes = _.filter(routes, (route) => {
    let routeObjects = Object.keys(route);
    let item = _.filter(
      routeObjects,
      (routeObject) => routeObject.indexOf("v") === 0
    );
    if (userInfo[item[0]] == route[item[0]]) {
      return true;
    }
    // if (_.includes(permissionObject, item[0])) {
    //   return true;
    // }
  });


  useEffect(() => {
    _getEntity();
  }, []);


  async function _getEntity() {
    try {
      let res = await axios.get(GetEntityAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setEntityName(res.data.name);
    } catch (err) {
      setEntityName("");
    }
  }



  return (
    <div className={classes.wrapper}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{entityName}</title>
      </Helmet>
      <Sidebar
        routes={updatedRoutes}
        logoText={entityName}
        logo={logo}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color="blue"
        {...props}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        <Navbar
          routes={updatedRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...props}
        />
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
};

