import React, { useEffect, useRef, useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import Barcode from "react-barcode";
import { Button, Grid, Box, makeStyles } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";

const styles = {
  wrapper: {
    width: 188,
    marginBottom: 8,
  },
  wrapperHalfWidth: {
    width: 94,
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 1,
    marginBottom: 8,
  },
  barcodeWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      fontSize: 5,
      margin: 0,
    },
    "& svg": {
      width: "100px",
      height: 33,
      marginTop: "-7px",
    },
  },
  rate: {
    fontSize: "8px!important",
    fontWeight: "400!important",
    marginBottom: "12px!important",
    marginLeft: "5px!important",
  },

  productWrapper: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "-10px",
    "& p": {
      margin: 0,
    },
  },
  productName: {
    fontSize: 7,
    fontWeight: 400,
  },
  index: {
    fontSize: 7,
    fontWeight: 400,
    marginLeft: "5px!important",
  },
  detailsWrapper: {
    justifyContent: "space-around",
    display: "flex",
    "& p": {
      fontSize: 5,
      margin: 0,
    },
  },
  parentWrapperHalfWidth: {
    display: "flex",
    // justifyContent: "center",
  },
};

const useStyles = makeStyles(styles);

const ProductBarcodes = (props) => {
  const classes = useStyles();

  const componentRef = useRef();
  let { products, pageType } = props.location.state;
  let [barcodes, setBarcodes] = useState([]);

  useEffect(() => {
    let barcodes = [];
    products.map((o) => {
      barcodes.push(o);
    });
    setBarcodes(barcodes);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "",
    pageStyle: ` 
    @media print {  
        @page {
            size: 50mm 10mm;
            margin: 0mm;
            padding: 0mm;
        } 
    }`,
  });

  return (
    <div>
      <Button color="primary" onClick={handlePrint}>
        Print Barcodes
      </Button>
      <Box ref={componentRef} style={{ padding: 0 }}>
        {pageType === "Normal" &&
          barcodes.map((i) => {
            return (
              <div className={classes.wrapper}>
                <div className={classes.barcodeWrapper}>
                  {i.productCode ? (
                    <Barcode
                      value={i.productCode}
                      margin={0}
                      fontSize={15}
                      height="25px"
                    />
                  ) : (
                    <Barcode
                      value={i.productName}
                      margin={0}
                      fontSize={15}
                      height="25px"
                    />
                  )}
                  <p className={classes.rate}>{i.rate}</p>
                </div>
                <div className={classes.productWrapper}>
                  <p className={classes.productName}>{i.productName}</p>
                  <p className={classes.index}>{i.index}</p>
                </div>
                <div className={classes.detailsWrapper}>
                  <div style={{ display: "flex" }}>
                    <p>
                      <b>S</b> -1.00
                    </p>
                    <p style={{ marginLeft: 10 }}>
                      <b>C</b> -0.75
                    </p>
                  </div>

                  <p>
                    <b>ADD</b> {i.addition}
                  </p>
                </div>
              </div>
            );
          })}
        {pageType === "Normal * 2" &&
          barcodes.map((i) => {
            return (
              <div className={classes.parentWrapperHalfWidth}>
                <div className={classes.wrapperHalfWidth}>
                  <div className={classes.barcodeWrapper}>
                    {i.productCode ? (
                      <Barcode
                        value={i.productCode}
                        margin={0}
                        fontSize={15}
                        height="25px"
                      />
                    ) : (
                      <Barcode
                        value={i.productName}
                        margin={0}
                        fontSize={15}
                        height="25px"
                      />
                    )}
                    <p className={classes.rate}>{i.rate}</p>
                  </div>
                  <div className={classes.productWrapper}>
                    <p className={classes.productName}>{i.productName}</p>
                    <p className={classes.index}>{i.index}</p>
                  </div>
                  <div className={classes.detailsWrapper}>
                    <div style={{ display: "flex" }}>
                      <p>
                        <b>S</b> -1.00
                      </p>
                      <p style={{ marginLeft: 10 }}>
                        <b>C</b> -0.75
                      </p>
                    </div>

                    <p>
                      <b>ADD</b> {i.addition}
                    </p>
                  </div>
                </div>
                <div className={classes.wrapperHalfWidth}>
                  <div className={classes.barcodeWrapper}>
                    {i.productCode ? (
                      <Barcode
                        value={i.productCode}
                        margin={0}
                        fontSize={15}
                        height="25px"
                      />
                    ) : (
                      <Barcode
                        value={i.productName}
                        margin={0}
                        fontSize={15}
                        height="25px"
                      />
                    )}
                    <p className={classes.rate}>{i.rate}</p>
                  </div>
                  <div className={classes.productWrapper}>
                    <p className={classes.productName}>{i.productName}</p>
                    <p className={classes.index}>{i.index}</p>
                  </div>
                  <div className={classes.detailsWrapper}>
                    <div style={{ display: "flex" }}>
                      <p>
                        <b>S</b> -1.00
                      </p>
                      <p style={{ marginLeft: 10 }}>
                        <b>C</b> -0.75
                      </p>
                    </div>

                    <p>
                      <b>ADD</b> {i.addition}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </Box>
    </div>
  );
};

export default ProductBarcodes;
