import React, { useEffect, useState } from "react";
// @material-ui/core components
import {
  Box,
  Button,
  Dialog,
  Fab,
  IconButton,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import {
  CreateChallanAPI,
  GetAllAccountsAPI,
  GetAllFittingMastersAPI,
  GetAllOrdersAPI,
  GetAllPrintAdsAPI,
  GetAllReadyMastersAPI,
  GetAllReadyMastersCodesAPI,
  GetEntityAPI,
  UpdateOrderAPI,
} from "../../config/api";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { ChallanOrdersDialog } from "./ChallanOrdersDialog.js";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { challan1Template } from "../../assets/html/challan1Template.js";
import { deliveryChallanTemplate } from "../../assets/html/deliveryChallanTemplate.js";
import { dataURItoBlob, htmlTemplateToDataString } from "../../utils/index.js";
import { toast } from "react-toastify";
const moment = extendMoment(Moment);

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 500,
});

const useStyles = makeStyles((theme) => ({
  fab: {
    marginTop: 26,
  },
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

let columns = [
  { id: "codeR", label: "Code R" },
  { id: "codeL", label: "Code L" },
  { id: "nameR", label: "Name R" },
  { id: "nameL", label: "Name L" },
  { id: "quantity", label: "Quantity" },
  { id: "rateR", label: "Rate R" },
  { id: "rateL", label: "Rate L" },
  { id: "rate", label: "Rate" },
  { id: "discountAmount", label: "Discount Amount" },
  { id: "discountPercent", label: "Discount Percent" },
  { id: "fitting", label: "Fitting" },
  { id: "fittingAmount", label: "Fitting Amount" },
  { id: "tint", label: "Tint" },
  { id: "amount", label: "Amount" },
  { id: "orderRef", label: "Order Ref" },
  { id: "action", label: "Action" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ChallanDCDialog(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { open, close, data, selectedRows } = props;
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);

  const [percent, setPercent] = useState("");
  const [entity, setEntity] = useState("");
  console.log("tityonen", entity)

  useEffect(() => {
    _getEntity();
  }, []);
  async function _getEntity() {
    try {
      let res = await axios.get(GetEntityAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setEntity(res.data);
    } catch (err) {
      setEntity("");
      // toast.error(('Cannot fetch data'), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  async function _onSave() {
    let tmp = [];
    selectedRows.map((o) => {
      let obj = _.find(data, { _id: o });
      tmp.push(obj);
    });


    tmp.map(async (i, index) => {
      let { party } = i;
      let challanCode = i.code;
      let items = [],
        totalQuantity = 0;
      _.map(i.items, async (item, index) => {
        let {
          codeL,
          codeR,
          nameL,
          nameR,
          rateR,
          rateL,
          quantity,
          amount,
          fitting,
          fittingAmount,
          discountAmount,
          discountPercent,
          tint,
          rate,
        } = item;
        let productName = "",
          price = 0;

        items.push({
          name: productName,
          rate: (rate * percent) / 100,
          codeR,
          codeL,
          nameL,
          nameR,
          rateR,
          rateL,
          fitting,
          fittingAmount,
          quantity,
          discountAmount,
          discountPercent,
          tint,
          amount,
        });
      });
      //pass enTITY
      let res = await axios.get(GetAllPrintAdsAPI(entity._id));
      let printAds = res.data.printAds;

      let ad = "";
      if (printAds && printAds[0]) {
        printAds.map((item) => {
          ad = ad + item.title + " ";
        });
      }
      let htmlData = {
        ad,
        clientAddress: party?.accountAddress ? party.accountAddress : "",
        clientName: party.accountName,
        clientGSTNo: party.gst,
        challanDate: moment().format("DD-MM-YYYY"),
        items: items,
        quantity: totalQuantity,
        challanCode,
        entityDetails: entity,
        percent
      };

      //   htmlData = {
      //     clientAddress: partyAddress,
      //     clientName: party.accountName,
      //     clientGSTNo: partyGstNumber,
      //     challanDate: moment().format("DD-MM-YYYY"),
      //     items: selectedItem.items,
      //     bank: res.data.banks[0],
      //     billCode: selectedItem.code,
      //     entityDetails: entity,
      //   };
      let options = {
        orientation: "p",
        unit: "pt",
        format: [850, 1066],
        // format:'letter',
        putOnlyUsedFonts: true,
        pagebreak: { mode: ['css', 'legacy'], before: "#pagebreak" },
      };
      console.log("tempdata", htmlData)
      let htmlTemplate = deliveryChallanTemplate(htmlData);
      console.log("gottemp")
      await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
        const preBlob = dataURItoBlob(dataString);
        window.open(URL.createObjectURL(preBlob, "_blank"));
      });
    });

    close();
  }

  return (
    <Dialog
      //   fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                >
                  <Button style={{ color: "white" }} onClick={close}>
                    Close
                  </Button>
                </Box>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <CustomInput
                    labelText="Enter Percent"
                    id="percent"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: percent,
                      onChange: (e) => setPercent(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => _onSave()}
                  >
                    Preview
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
