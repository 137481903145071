import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
// @material-ui/core components
import {
  Box,
  Dialog,
  Fab,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import {
  GetAllAccountsAPI,
  GetAllFittingMastersAPI,
  GetAllOrdersAPI,
  GetAllReadyMastersAPI,
  CreateBulkOrderAPI,
} from "../../config/api";
import DateUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { toast } from "react-toastify";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 500,
});

const styles = {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    flex: 1,
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

const columns = [
  { id: "party", label: "Party" },
  { id: "productNameR", label: "Product R" },
  { id: "productNameL", label: "Product L" },
  { id: "rSph", label: "Right Sph" },
  { id: "rCyl", label: "Right Cyl" },
  { id: "rAxis", label: "Right Axis" },
  { id: "rAddition", label: "Right Add" },
  { id: "lSph", label: "Left Sph" },
  { id: "lCyl", label: "Left Cyl" },
  { id: "lAxis", label: "Left Axis" },
  { id: "lAddition", label: "Left Add" },
  { id: "action", label: "Action" },
];

export default function FittingOrderAdd(props) {
  const classes = useStyles();
  const theme = useTheme();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);

  const readyMastersProducts = useSelector(
    (state) => state.userReducer.readyMasters
  );

  const { open, close, fetchData } = props;

  const [rLens, setRLens] = useState("");
  const [lLens, setLLens] = useState("");
  const [fitter, setFitter] = useState("");
  const [fitting, setFitting] = useState("");
  const [given, setGiven] = useState(new Date());
  const [received, setReceived] = useState(new Date());
  const [cg, setCg] = useState("");
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState("");
  const [partyRef, setPartyRef] = useState("");
  const [rRate, setRRate] = useState("");
  const [lRate, setLRate] = useState("");
  const [productNameR, setProductNameR] = useState("");
  const [productNameL, setProductNameL] = useState("");
  const [product, setProduct] = useState("");
  const [quote, setQuote] = useState("");
  const [dia, setDia] = useState("");
  const [rSph, setRSph] = useState("");
  const [rCyl, setRCyl] = useState("");
  const [rAxis, setRAxis] = useState("");
  const [rAddition, setRAddition] = useState("");
  const [lSph, setLSph] = useState("");
  const [lCyl, setLCyl] = useState("");
  const [lAxis, setLAxis] = useState("");
  const [lAddition, setLAddition] = useState("");
  const [party, setParty] = useState(null);

  const [accounts, setAccounts] = useState([]);
  const [fittings, setFittings] = useState([]);
  const [readyMasters, setReadyMasters] = useState([]);
  const [orders, setOrders] = useState([]);
  const [codes, setCodes] = useState([]);
  const [codesL, setCodesL] = useState([]);
  const [codesR, setCodesR] = useState([]);
  // console.log("tata>>>>", codesR);
  const [items, setItems] = useState([]);

  useEffect(() => {
    _getUsers();
    _getFittings();
    _getReady();
  }, []);

  useEffect(() => {
    _getOrders();
  }, [party]);

  async function _getUsers() {
    try {
      let res = await axios.get(GetAllAccountsAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      let saleUsers = _.filter(res.data.accounts, { partyType: "sale" });
      setAccounts(saleUsers);
    } catch (e) {
      // console.log("er:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  async function _getReady() {
    try {
      // console.log(userInfo.entity, "tata")
      let res = await axios.get(GetAllReadyMastersAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      // console.log(res.data.readyMasters, "readyMasters")
      setReadyMasters(res.data.readyMasters);
    } catch (err) {
      console.log(err, "177");
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  async function _getOrders() {
    try {
      if (party) {
        let res = await axios.get(
          GetAllOrdersAPI(userInfo.entity) +
            `&userId=${party._id}&orderType=Prescription`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setOrders(res.data.orders);
      }
    } catch (err) {
      // console.log("error:", err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  async function _getFittings() {
    try {
      let res = await axios.get(GetAllFittingMastersAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setFittings(res.data.fittingMasters);
    } catch (e) {
      // console.log("er:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  async function _onSave() {
    try {
      if (items.length === 0) {
        toast.error("Please add a Fitting order", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      let res = await axios.post(
        CreateBulkOrderAPI(),
        {
          data: JSON.stringify(items),
          entity: userInfo.entity,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setItems([]);
      toast.success("Successfully Added!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      close();
      fetchData();
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  useEffect(() => {
    _getCodes();
  }, [orders, readyMasters]);

  useEffect(() => {
    if (lLens) {
      let obj = _.find(orders, { code: lLens });
      let codes = [];
      let codesL = [];
      _.map(orders, (i) => {
        codes.push(i.code);
        codesL.push(i.code);
      });
      if (!obj) {
        codes = [];
        codesL = [];
        _.map(readyMasters, (i) => {
          codes.push(i.productCode);
          if (i.side === "B" || i.side === "L") {
            codesL.push(i.productCode);
          }
        });
        obj = _.find(readyMasters, { productCode: lLens });
      }
      setCodes(codes);
      setCodesL(codesL);

      if (obj) {
        if (obj.productName) {
          setLRate(obj.rate);
          setDia(obj.dia);
          setLSph(obj.sph);
          setLCyl(obj.cyl);
          setLAxis(obj.axis);
          setLAddition(obj.addition);
          setProductNameL(obj.productName);
          setProduct(obj);
        } else {
          if (obj.orderDetail) {
            setLRate(obj.rate);
            setDia(obj.orderDetail.dia);
            setLSph(obj.orderDetail.sphLeft);
            setLCyl(obj.orderDetail.cylLeft);
            setLAxis(obj.orderDetail.axisLeft);
            setLAddition(obj.orderDetail.addLeft);
            setProductNameL(obj.orderDetail.product.productName);
            setProduct(obj.orderDetail.product);
          }
        }
      }
    }
    if (!lLens) {
      if (!rLens) {
        _getCodes();
      }
      setLRate("");
      setDia("");
      setLSph("");
      setLCyl("");
      setLAxis("");
      setLAddition("");
      setProductNameR("");
      setProductNameL("");
      setProduct("");
    }
  }, [lLens]);

  useEffect(() => {
    console.log(rLens, "objectonselect");
    if (rLens) {
      let obj = _.find(orders, { code: rLens });
      console.log(obj, "objorder");
      let codes = [];
      let codesR = [];
      _.map(orders, (i) => {
        codes.push(i.code);
        codesR.push(i.code);
      });
      if (!obj) {
        codes = [];
        _.map(readyMasters, (i) => {
          codes.push(i.productCode);
          if (i.side === "B" || i.side === "R") {
            codesR.push(i.productCode);
          }
        });
        obj = _.find(readyMasters, { productCode: rLens });
        console.log(obj, "objrmr");
      }
      setCodes(codes);
      setCodesR(codesR);
      console.log(obj, "<<<<<objectonselect");
      if (obj) {
        if (obj.productName) {
          setRRate(obj.rate);
          setDia(obj.dia);
          setRSph(obj.sph);
          setRCyl(obj.cyl);
          setRAxis(obj.axis);
          setRAddition(obj.addition);
          setProductNameR(obj.productName);
          setProduct(obj);
        } else {
          if (obj.orderDetail) {
            setRRate(obj.rate);
            setDia(obj.orderDetail.dia);
            setRSph(obj.orderDetail.sphRight);
            setRCyl(obj.orderDetail.cylRight);
            setRAxis(obj.orderDetail.axisRight);
            setRAddition(obj.orderDetail.addRight);
            setProductNameR(obj.orderDetail.product.productName);
            setProduct(obj.orderDetail.product);
          }
        }
      }
    }
    if (!rLens) {
      if (!lLens) {
        _getCodes();
      }
      setRRate("");
      setDia("");
      setRSph("");
      setRCyl("");
      setRAxis("");
      setRAddition("");
      setProductNameR("");
      setProductNameL("");
      setProduct("");
    }
  }, [rLens]);

  // useEffect(() => {
  //   if (fitting) {
  //     setAmount(Number(amount) + Number(fitting.amount));
  //   } else {
  //     setAmount("");
  //   }
  // }, [fitting]);

  // useEffect(() => {
  //   if (rRate && lRate) {
  //     setAmount(amount + (Number(rRate) + Number(lRate)) / 2);
  //   } else {
  //     setAmount("");
  //   }
  // }, [rRate, lRate]);

  useEffect(() => {
    let updatedAmount = 0;

    if (fitting) {
      updatedAmount += Number(fitting.amount);
    }

    if (rRate && lRate) {
      updatedAmount += (Number(rRate) + Number(lRate)) / 2;
    } else if (rRate) {
      updatedAmount += Number(rRate);
    } else if (lRate) {
      updatedAmount += Number(lRate);
    }

    setAmount(updatedAmount || 0);
  }, [fitting, rRate, lRate]);

  function _getCodes() {
    if (!lLens && !rLens) {
      let codes = [];
      _.map(orders, (i) => {
        codes.push(i.code);
      });
      _.map(readyMasters, (i) => {
        codes.push(i.productCode);
        if (i.side === "B" || i.side === "R") {
          codesR.push(i.productCode);
        }
        if (i.side === "B" || i.side === "L") {
          codesL.push(i.productCode);
        }
      });
      setCodes(codes);
      setCodesL(codesL);
      setCodesR(codesR);
    }
  }

  const debouncingProducts = _.debounce(async (value, side) => {
    console.log("Debounce Ran 1", userInfo.entity);
    try {
      console.log("Debounce Ran 2", value);
      let query = `&productCode=${value}`;
      let res = await axios.get(
        GetAllReadyMastersAPI(userInfo.entity) + query,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Debounce Ran 3", res.data.readyMasters);
      _.map(res.data.readyMasters, (i) => {
        codes.push(i.productCode);
        if ((side === "r" && i.side === "B") || i.side === "R") {
          console.log("pushed to R");
          codesR.push(i.productCode);
        } else {
          console.log("no go wehre r");
        }

        if ((side === "l" && i.side === "B") || i.side === "L") {
          console.log("pushed to L");
          codesL.push(i.productCode);
        } else {
          console.log("no go wehre l");
        }
      });
      // let codesR1, codesL1;
      // // Reorder codesR array
      // codesR1 = codesR1.sort((a, b) => {
      //   // Move codes starting with 'o' to the end
      //   if (a.startsWith("o") && !b.startsWith("o")) {
      //     return 1;
      //   } else if (!a.startsWith("o") && b.startsWith("o")) {
      //     return -1;
      //   }
      //   return 0;
      // });
      setCodesR(codesR);
      // Reorder codesL array
      // codesL1 = codesL1.sort((a, b) => {
      //   // Move codes starting with 'o' to the end
      //   if (a.startsWith("o") && !b.startsWith("o")) {
      //     return 1;
      //   } else if (!a.startsWith("o") && b.startsWith("o")) {
      //     return -1;
      //   }
      //   return 0;
      // });
      setCodesL(codesL);
      // if (side === "R") {
      //   setCodesR(res.data.readyMasters);
      // } else if (side === 'L')  {
      //   setCodesL(res.data.readyMasters);
      // } else if( side === 'B'){
      //   setCodesR(res.data.readyMasters);
      //   setCodesL(res.data.readyMasters);
      // }
    } catch (err) {
      console.log(err, "Ran error");
    }
  });

  const afterSelectL = (codeL) => {
    console.log("after select L>> >> ", codeL, readyMasters);
    let obj = _.find(readyMasters, { productCode: codeL });
    if (obj) {
      console.log(obj, "this my product");
      if (obj) {
        console.log("hiiii");
        // setLName(obj?.productName)
        console.log(obj.productName, "byeee");
        console.log("814setnamecomplete", party);
        setLRate(obj?.rate);
        // setLProductCode(obj?.productCode);
        // setHsnCode(obj?.hsnCode);
        // setTaxPercent(obj?.taxPercent);

        let dissArray = party?.discounts;
        if (dissArray.length > 0 && obj) {
          console.log(dissArray, "diss");
          console.log(obj, "dissObj");
          let selectedDis = dissArray
            .reverse()
            .find((item) => item.type === "Range");
          console.log("this happening", selectedDis);
          if (selectedDis) {
            let fR = selectedDis?.product?.codeRanges?.find(
              (item) => item == codeL
            );
            if (fR != undefined) {
              console.log("comp316");
              // setNameL(selectedDis.product.productName)
              setLRate(selectedDis.discountedPrice);
              // setDiscountPercent(selectedDis.discountPercent);
            }
          } else {
            console.log("no range here");
            let selectedDis2 = dissArray
              .reverse()
              .find((item) => item.product.color === obj.color);
            console.log("forReal>>>>editse", selectedDis2, codeL, dissArray);
            console.log("comp330", selectedDis2);
            if (selectedDis2) {
              // setNameL(selectedDis2?.product?.productName)
              // setDiscountPercent(selectedDis2.discountPercent)
              setLRate(selectedDis2?.discountedPrice);
              // setDiscountPercent(selectedDis2?.discountPercent);
              // setDiscountAmount(selectedDis2.discountedPrice);
              // if (discountPercent > 0) {
              //   let newD =
              //     Number(discountPercent) +
              //     Number(selectedDis2?.discountPercent);
              //   //console.log("987", newD)
              //   let finD = newD / 2;
              //   //console.log("setfromdebounceR988", finD)
              //   // setDiscountPercent(finD);
              // } else {
              //   // setDiscountPercent(selectedDis2?.discountPercent);
              // }
            } else {
            }
            // //console.log("that happening")

            // let selectedDis2 = dissArray
            //   .reverse()
            //   .find((item) => item.product.color === obj.color);
            // setNameR(selectedDis2.product.productName)
            // setDiscountPercent(selectedDis2.discountPercent);

            // setDiscountAmount(selectedDis2.discountedPrice);
          }
          //// //console.log(selectedDis, "dis");
        }
      } else {
        if (obj.orderDetail) {
          //console.log(obj, "this my product");
          //console.log("856");

          // setQuantity(obj.quantity);
          let productCode = "";
          if (obj.orderType === "Fitting") {
            // if (obj.orderDetail.fitting) {
            //   // setFitting(obj.orderDetail.fitting);
            //   let fitting = _.find(fittings, {
            //     fittingType: obj.orderDetail.fitting,
            //   });
            //   // setFittingAmount(fitting.amount);
            // }
            setLRate(obj.orderDetail.lRate);
            productCode =
              obj.orderDetail.lSph +
              "/" +
              obj.orderDetail.lCyl +
              "/" +
              obj.orderDetail.lAxis +
              "/" +
              obj.orderDetail.lAddition;
            console.log("864orderDetailcomp");

            // setNameL(obj.orderDetail.productName + "-" + productCode);
          } else {
            setLRate(obj.rate);
            productCode =
              obj.orderDetail.lSph +
              "/" +
              obj.orderDetail.lCyl +
              "/" +
              obj.orderDetail.lAxis +
              "/" +
              obj.orderDetail.lAddition;
            if (obj.orderDetail.product) {
              console.log("396comp");
              // setNameL(
              //   obj.orderDetail.product.productName + "-" + productCode
              // );
            }
          }
          // setOrderRef(obj.code);
          // setLProductCode(productCode);
        }
      }
    }
  };

  const afterSelectR = (codeR) => {
    console.log("after select >> >> ", codeR, readyMasters);
    let obj = _.find(readyMasters, { productCode: codeR });
    if (obj) {
      console.log(obj, "this my product");
      if (obj) {
        console.log("hiiii");
        // setNameR(obj?.productName)
        console.log(obj.productName, "byeee");
        console.log("814setnamecomplete", party);
        setRRate(obj?.rate);
        // setRProductCode(obj?.productCode);
        // setHsnCode(obj?.hsnCode);
        // setTaxPercent(obj?.taxPercent);
        // setPackagingTaxPercent(obj?.taxPercent);

        let dissArray = party?.discounts;
        if (dissArray.length > 0 && obj) {
          console.log(dissArray, "diss");
          console.log(obj, "dissObj");
          let selectedDis = dissArray
            .reverse()
            .find((item) => item.type === "Range");
          console.log("this happening", selectedDis);
          if (selectedDis) {
            let fR = selectedDis?.product?.codeRanges?.find(
              (item) => item == codeR
            );
            console.log(fR, "fR");
            if (fR != undefined) {
              console.log(
                "comp316",
                selectedDis.product.productName,
                selectedDis.discountedPrice,
                selectedDis.discountPercent
              );
              // setNameR(selectedDis.product.productName)
              setRRate(selectedDis.discountedPrice);
              // setDiscountPercent(selectedDis.discountPercent);
              console.log("321");
            }
          } else {
            console.log("no range here");
            let selectedDis2 = dissArray
              .reverse()
              .find((item) => item.product.color === obj.color);
            console.log("forReal>>>>editse", selectedDis2, codeR, dissArray);
            console.log("comp330", selectedDis2);
            if (selectedDis2) {
              // setNameR(selectedDis2?.product?.productName)
              setRRate(selectedDis2?.discountedPrice);
              // setDiscountPercent(selectedDis2.discountPercent)
              // setDiscountPercent(selectedDis2?.discountPercent);
              // setDiscountAmount(selectedDis2.discountedPrice);
              // if (discountPercent > 0) {
              //   let newD =
              //     Number(discountPercent) +
              //     Number(selectedDis2?.discountPercent);
              //   //console.log("987", newD)
              //   let finD = newD / 2;
              //   //console.log("setfromdebounceR988", finD)
              //   // setDiscountPercent(finD);
              // } else {
              //   // setDiscountPercent(selectedDis2?.discountPercent);
              // }
            } else {
            }
            // //console.log("that happening")

            // let selectedDis2 = dissArray
            //   .reverse()
            //   .find((item) => item.product.color === obj.color);
            // setNameR(selectedDis2.product.productName)
            // setDiscountPercent(selectedDis2.discountPercent);

            // setDiscountAmount(selectedDis2.discountedPrice);
          }
          //// //console.log(selectedDis, "dis");
        }
      } else {
        if (obj.orderDetail) {
          //console.log(obj, "this my product");
          //console.log("856");

          // setQuantity(obj.quantity);
          let productCode = "";
          if (obj.orderType === "Fitting") {
            // if (obj.orderDetail.fitting) {
            //   // setFitting(obj.orderDetail.fitting);
            //   let fitting = _.find(fittingMasters, {
            //     fittingType: obj.orderDetail.fitting,
            //   });
            //   // setFittingAmount(fitting.amount);
            // }
            setRRate(obj.orderDetail.rRate);
            productCode =
              obj.orderDetail.rSph +
              "/" +
              obj.orderDetail.rCyl +
              "/" +
              obj.orderDetail.rAxis +
              "/" +
              obj.orderDetail.rAddition;
            console.log("864orderDetailcomp");

            // setNameR(obj.orderDetail.productName + "-" + productCode);
          } else {
            setRRate(obj.rate);
            productCode =
              obj.orderDetail.rSph +
              "/" +
              obj.orderDetail.rCyl +
              "/" +
              obj.orderDetail.rAxis +
              "/" +
              obj.orderDetail.rAddition;
            if (obj.orderDetail.product) {
              console.log("396comp");
              // setNameR(
              //   obj.orderDetail.product.productName + "-" + productCode
              // );
            }
          }
          // setOrderRef(obj.code);
          // setRProductCode(productCode);
        }
      }
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                // justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>
                  Add Ready / Fitting Order
                </h4>
                <Button color={"transparent"} onClick={() => close()}>
                  Close
                </Button>
                <Button color={"transparent"} onClick={() => _onSave()}>
                  Save
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={3} sm={3} md={3}>
                  <Autocomplete
                    className={classes.formControl}
                    options={accounts}
                    value={party}
                    onChange={(event, newValue) => {
                      setParty(newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    id="party"
                    renderInput={(params) => (
                      <TextField {...params} label="Party*" />
                    )}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <Autocomplete
                    filterOptions={filterOptions}
                    loading={codesR.length === 0 ? true : false}
                    className={classes.formControl}
                    options={codesR}
                    fullWidth
                    value={rLens}
                    onChange={(event, value) => {
                      setRLens(value);
                      afterSelectR(value);
                    }}
                    onInputChange={(e, value) => {
                      if (value.length > 5) {
                        debouncingProducts(value, "r");
                      }
                      return;
                    }}
                    getOptionLabel={(option) => option}
                    style={{ marginTop: 26 }}
                    id="rLens"
                    renderInput={(params) => (
                      <TextField {...params} label="R Lens" />
                    )}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <Autocomplete
                    filterOptions={filterOptions}
                    loading={codesL.length === 0 ? true : false}
                    className={classes.formControl}
                    options={codesL}
                    fullWidth
                    value={lLens}
                    onChange={(event, value) => {
                      setLLens(value);
                      afterSelectL(value);
                    }}
                    onInputChange={(e, value) => {
                      if (value.length > 5) {
                        debouncingProducts(value, "l");
                      }
                      return;
                    }}
                    // getOptionLabel={(option) =>
                    //   option.productName
                    //     ? `${option.productName} (${option.productCode})`
                    //     : null
                    // }
                    getOptionLabel={(option) => option}
                    style={{ marginTop: 26 }}
                    id="lLens"
                    renderInput={(params) => (
                      <TextField {...params} label="L Lens" />
                    )}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    labelText="Fitter"
                    id="fitter"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: fitter,
                      onChange: (event) => setFitter(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <Autocomplete
                    className={classes.formControl}
                    options={fittings}
                    fullWidth
                    value={fitting}
                    onChange={(event, value) => setFitting(value)}
                    getOptionLabel={(option) => option.fittingType}
                    style={{ marginTop: 26 }}
                    id="fitting"
                    renderInput={(params) => (
                      <TextField {...params} label="Fitting" />
                    )}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Total Rate*"
                    id="amount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      value: amount,
                      onChange: (event) => setAmount(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Quantity*"
                    id="quantity"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: quantity,
                      type: "number",
                      onChange: (event) => setQuantity(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="C/G"
                    id="cg"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: cg,
                      onChange: (event) => setCg(event.target.value),
                    }}
                  />
                </GridItem>
                <MuiPickersUtilsProvider utils={DateUtils}>
                  <GridItem xs={3} sm={3} md={3} style={{ marginTop: 26 }}>
                    <DatePicker
                      label="Given"
                      fullWidth
                      format={"DD-MM-YYYY"}
                      minDate={new Date()}
                      value={given}
                      onChange={(date) => setGiven(date)}
                      animateYearScrolling
                    />
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3} style={{ marginTop: 26 }}>
                    <DatePicker
                      label="Received"
                      fullWidth
                      minDate={given}
                      format={"DD-MM-YYYY"}
                      value={received}
                      onChange={(date) => setReceived(date)}
                      animateYearScrolling
                    />
                  </GridItem>
                </MuiPickersUtilsProvider>

                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    labelText="Party Ref"
                    id="partyRef"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: partyRef,
                      onChange: (event) => setPartyRef(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="R Rate"
                    id="rRate"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rRate,
                      onChange: (event) => setRRate(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="L Rate"
                    id="lRate"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lRate,
                      onChange: (event) => setLRate(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    labelText="Product Name R"
                    id="productNameR"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: productNameR,
                      onChange: (event) => setProductNameR(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    labelText="Product Name L"
                    id="productNameL"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: productNameL,
                      onChange: (event) => setProductNameL(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    labelText="Quote"
                    id="quote"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: quote,
                      onChange: (event) => setQuote(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Dia"
                    id="dia"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: dia,
                      onChange: (event) => setDia(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}></GridItem>
                <GridItem xs={2} sm={2} md={2}></GridItem>
                <GridItem xs={2} sm={2} md={2}></GridItem>
                <GridItem xs={2} sm={2} md={2}></GridItem>
                <GridItem xs={2} sm={2} md={2}></GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <p>Sph</p>
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <p>Cyl</p>
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <p>Axis</p>
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <p>Addn</p>
                </GridItem>
                <GridItem xs={2} sm={2} md={2}></GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <p>Right</p>
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText=""
                    id="rSph"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rSph,
                      onChange: (event) => setRSph(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText=""
                    id="rCyl"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rCyl,
                      onChange: (event) => setRCyl(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText=""
                    id="rAxis"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rAxis,
                      onChange: (event) => setRAxis(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText=""
                    id="rAddition"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rAddition,
                      onChange: (event) => setRAddition(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}></GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <p>Left</p>
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText=""
                    id="lSph"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lSph,
                      onChange: (event) => setLSph(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText=""
                    id="lCyl"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lCyl,
                      onChange: (event) => setLCyl(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText=""
                    id="lAxis"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lAxis,
                      onChange: (event) => setLAxis(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText=""
                    id="lAddition"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lAddition,
                      onChange: (event) => setLAddition(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <Box justifyContent={"center"} marginTop={4}>
                    <Fab
                      size={"small"}
                      color="primary"
                      aria-label="add"
                      onClick={() => {
                        if (
                          party === null ||
                          quantity === "" ||
                          amount === ""
                        ) {
                          toast.error("Please fill all the required fields", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          return;
                        }
                        let orderAmount = Number(amount) * Number(quantity);
                        let object = {
                          orderType: "Fitting",
                          party: {
                            accountCode: party.accountCode,
                            accountId: party._id,
                            accountName: party.name,
                            accountAddress: party.address,
                            accountGst: party.gst,
                          },
                          orderDetail: {
                            rLens,
                            lLens,
                            fitter,
                            fitting: fitting ? fitting.fittingType : "",
                            cg,
                            given,
                            received,
                            amount,
                            quantity,
                            partyRef,
                            rRate,
                            lRate,
                            product,
                            productNameR,
                            productNameL,
                            quote,
                            dia,
                            rSph,
                            rCyl,
                            rAxis,
                            rAddition,
                            lSph,
                            lCyl,
                            lAxis,
                            lAddition,
                          },
                          orderAmount,
                          price: amount,
                          quantity,
                          createdBy: userInfo._id,
                          createdByName: userInfo.name,
                          orderAdminStatus: "Pending",
                        };

                        let copy = _.clone(items);
                        copy.push(object);
                        setItems(copy);
                        setLRate("");
                        setDia("");
                        setLSph("");
                        setLCyl("");
                        setLAxis("");
                        setLAddition("");
                        setProductNameR("");
                        setProductNameL("");
                        setProduct("");
                        setRRate("");
                        setRSph("");
                        setRCyl("");
                        setRAxis("");
                        setRAddition("");

                        setRLens("");
                        setLLens("");
                        setFitter("");
                        setFitting("");
                        setGiven(new Date());
                        setReceived(new Date());
                        setCg("");
                        setAmount("");
                        setQuantity("");
                        setPartyRef("");

                        setQuote("");
                      }}
                    >
                      <AddIcon />
                    </Fab>
                  </Box>
                </GridItem>
                {_.isEmpty(items) ? null : (
                  <GridItem xs={12}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={"center"}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                let value = row.orderDetail[column.id];
                                if (column.id === "product") {
                                  value = row.orderDetail?.product?.productName
                                    ? row.orderDetail.product.productName
                                    : "";
                                }
                                if (column.id === "party") {
                                  value = row.party.accountName;
                                }
                                if (column.id === "action") {
                                  return (
                                    <TableCell align={"center"}>
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                      >
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() => {
                                            let copy = _.clone(items);
                                            copy.splice(index, 1);
                                            setItems(copy);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </TableCell>
                                  );
                                }
                                return (
                                  <TableCell key={column.id} align={"center"}>
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
