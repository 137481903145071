import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
// @material-ui/icons
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
// core components
import { ChallanList } from "../Challan/ChallanList";
import { BillList } from "../Bill/BillList";
import classNames from "classnames";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import { Box, Dialog, makeStyles, Slide, Tab, Tabs } from "@material-ui/core";
import CustomInput from "../../components/CustomInput/CustomInput";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import CardBody from "../../components/Card/CardBody";
import styles from "../../assets/jss/material-dashboard-react/components/customTabsStyle";
import DateUtils from "@date-io/moment"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { FilterDialog } from "../../components/common/FilterDialog";
import { GetAllAccountsAPI, GetBulkReadyMasterRangeAPI } from "../../config/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Search } from "@material-ui/icons";
let tabs = [
  {
    tabName: "Challans",
    tabIcon: BugReport,
    tabContent: <ChallanList />,
  },
  {
    tabName: "Bills",
    tabIcon: Code,
    tabContent: <BillList />,
  },
];
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function TransactionHistory(props) {
  const [value, setValue] = useState(0);
  let authToken = useSelector((state) => state.userReducer.authToken);
  let userInfo = useSelector((state) => state.userReducer.userInfo);

  const searchRef = useRef(null)

  const [searchTerm, setSearchTerm] = useState("");
  // const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [party, setParty] = useState([]);
  console.log("partayy", accounts)
  const classes = useStyles();
  const { rtlActive } = props;
  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive,
  });
  const [readyMasterRange, setReadyMasterRange] = useState([]);

  // async function _getAllAccounts() {
  //   try {
  //     let cachedAccounts = localStorage.getItem('cachedAccounts');
  //     if (cachedAccounts) {
  //       console.log("cachedAccounts");
  //       const accounts = JSON.parse(cachedAccounts);
  //       setAccounts(accounts);
  //       const party = accounts.map((i) => i.name);
  //       setParty(party);
  //     } else {
  //       console.log("account not cached");
  //       let res = await axios.get(GetAllAccountsAPI(userInfo.entity), {
  //         headers: {
  //           Authorization: `Bearer ${authToken}`,
  //         },
  //       });
  //       let accounts = res.data.accounts;
  //       localStorage.setItem('cachedAccounts', JSON.stringify(accounts));
  //       setAccounts(accounts);
  //       let party = accounts.map((i) => i.name);
  //       setParty(party);
  //     }

  //   } catch (e) {
  //     console.log(e);
  //     setAccounts([]);
  //     toast.error(("Something went wrong with _getAllAccounts, Please Contact Admin"), {
  //       position: "top-center",
  //       autoClose: 1500,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //     });
  //   }
  // }

  async function _getAllAccounts() {
    try {
      // const cacheValidityDuration = 5 * 60 * 1000; // 5 minutes in milliseconds
      // const cachedData = localStorage.getItem('cachedAccounts');
      // const cachedTimestamp = localStorage.getItem('cachedAccountsTimestamp');
      // const now = Date.now();

      // if (cachedData && cachedTimestamp && now - cachedTimestamp < cacheValidityDuration) {
      //   console.log("Using cached accounts");
      //   const accounts = JSON.parse(cachedData);
      //   setAccounts(accounts);
      //   const party = accounts.map((i) => i.name);
      //   setParty(party);
      // } else {
      console.log("Fetching fresh accounts");
      let res = await axios.get(GetAllAccountsAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      let accounts = res.data.accounts.filter(account => account.partyType === "sale");
      // localStorage.setItem('cachedAccounts', JSON.stringify(accounts));
      // localStorage.setItem('cachedAccountsTimestamp', now);
      setAccounts(accounts);
      let party = accounts.map((i) => i.name);
      setParty(party);
      // }
    } catch (e) {
      console.log(e);
      setAccounts([]);
      toast.error("Something went wrong with _getAllAccounts, Please Contact Admin", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }


  async function _getBulkReadyMasterRangeAPI() {
    try {

      let cachedRmrs = localStorage.getItem('cachedRmrs');
      if (cachedRmrs) {
        console.log("cachedRmrs");
        const crmrs = JSON.parse(cachedRmrs);
        setReadyMasterRange(crmrs);
      } else {

        let res = await axios.get(GetBulkReadyMasterRangeAPI(userInfo.entity), {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        localStorage.setItem('cachedRmrs', JSON.stringify(res.data.readyMasterRange));

        setReadyMasterRange(res.data.readyMasterRange);
      }
    } catch (err) {
      console.log(err);
      toast.error(("Something went wrong _getBulkReadyMasterRangeAPI, Please Contact Admin"), {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }


  useEffect(() => {
    _getAllAccounts();
    _getBulkReadyMasterRangeAPI()
  }, []);

  const handleChange = (event, value) => {
    setValue(value);
  };

  const handleCloseFilterModal = () => {
    setOpenFilter(false);
  };

  // function handleKeyPress(event) {
  //     setSearchTerm(event.target.value);
  // }
  function handleSearch() {
    const newValue = searchRef.current.value;
    setSearchTerm(newValue);
  }

  const handleInputChange = (e) => {

    if (e.target.value === '' || e.target.value === null) {
      setSearchTerm("")
    } else {
      searchRef.current.value = e.target.value;
    }
  };


  return (
    <Card plain={false}>
      <CardHeader color={"rose"} plain={false}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <div>
            <div className={cardTitle}>{"Transaction:"}</div>
            <Tabs
              value={value}
              onChange={handleChange}
              classes={{
                root: classes.tabsRoot,
                indicator: classes.displayNone,
                scrollButtons: classes.displayNone,
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabs.map((prop, key) => {
                let icon = {};
                if (prop.tabIcon) {
                  icon = {
                    icon: <prop.tabIcon />,
                  };
                }
                return (
                  <Tab
                    classes={{
                      root: classes.tabRootButton,
                      selected: classes.tabSelected,
                      wrapper: classes.tabWrapper,
                    }}
                    key={key}
                    label={prop.tabName}
                    {...icon}
                  />
                );
              })}
            </Tabs>
          </div>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>

            <CustomInput
              id="search"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                ref: searchRef,
                style: {
                  marginTop: 0,
                  paddingTop: 0,
                  marginRight: 10,
                  color: "white",
                },
                placeholder: "Search",
                onKeyDown: (e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    handleSearch();
                  }
                },
                onChange: (e) => handleInputChange(e),
              }}
            />
            <IconButton color={"secondary"} onClick={(e) => {
              // setSearchTerm(e.target.value),
              // handleKeyPress(e)
              // handleKeyPress()
              handleSearch()


            }}>
              <Search />
            </IconButton>
            <IconButton color={"secondary"} onClick={() => setOpenFilter(true)}>
              <FilterListIcon />
            </IconButton>
          </Box>
        </Box>
      </CardHeader>
      <CardBody>
        {value === 0 ? (
          <ChallanList searchTerm={searchTerm} filter={filter} readyMasterRange={readyMasterRange} accounts={accounts} />
        ) :
          (
            <BillList searchTerm={searchTerm} filter={filter} readyMasterRange={readyMasterRange} accounts={accounts} />
          )
        }
      </CardBody>
      <FilterDialog
        party={party}
        filter={filter}
        setFilter={setFilter}
        showFilterDialog={openFilter}
        handleCloseFilterDialog={handleCloseFilterModal}
      />
    </Card>
  );
}
