import React, { useEffect, useState } from "react";
import _, { set } from "lodash";
// @material-ui/core components
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Fab,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
} from "@material-ui/core";
import moment from "moment";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
// import Table from "../../components/Table/Table.js";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import OrderView from "./OrderView";
import PrescriptionOrderAdd from "./PrescriptionOrderAdd";
import ReadyOrderAdd from "./ReadyOrderAdd";
import FittingOrderAdd from "./FittingOrderAdd";
import axios from "axios";
import {
  BulkPrintOrdersAPI,
  DeleteOrderAPI,
  GetAllOrdersAPI,
} from "../../config/api";
import { ConfirmationDialog } from "../../components/common";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory } from "react-router-dom";
import CardHeader from "../../components/Card/CardHeader";
import People from "@material-ui/icons/People";
import CustomInput from "../../components/CustomInput/CustomInput";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import SendEmail from "./SendEmail.js";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const columns = [
  { id: "party", label: "Party" },
  { id: "code", label: "Order Code" },
  { id: "orderType", label: "Order Type" },
  { id: "product", label: "Product" },
  { id: "productType", label: "Product Type" },
  { id: "orderAmount", label: "Order Amount" },
  {
    id: "createdAt",
    label: "Order Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
  { id: "action", label: "Action" },
];

const orderTypes = ["Fitting", "Prescription"];

export default function OrderList(props) {
  const classes = useStyles();
  const history = useHistory();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);
  const [orders, setOrders] = useState([]);
  const [ordersGrouped, setOrdersGrouped] = useState([]);
  const [openReadyOrderAddDialog, setOpenReadyOrderAddDialog] = useState(false);
  const [openPrescriptionOrderAddDialog, setOpenPrescriptionOrderAddDialog] =
    useState(false);
  const [openFittingOrderAddDialog, setOpenFittingOrderAddDialog] =
    useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openViewDialog, setOpenViewDialog] = useState(null);
  const [openOrderTypeDialog, setOpenOrderTypeDialog] = useState(false);
  const [selectedType, setSelectedType] = useState(orderTypes[0]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  // const [columns , setColumns] = useState("");

  useEffect(() => {
    _getOrders();
  }, []);

  async function _getOrders() {
    try {
      let res = await axios.get(
        GetAllOrdersAPI(userInfo.entity) +
        "&sortBy=createdAt:desc&isOrderFromApp=false",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setOrders(res.data.orders);
      console.log("Reading orders", res.data.orders);
      setOrdersGrouped(res.data.ordersGrouped);
      console.log("Checking orders", orders);
    } catch (err) {
      // console.log("error:", err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _deleteItem(item) {
    try {
      let res = await axios.delete(DeleteOrderAPI(item._id), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      toast.success("Successfully Deleted!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      _getOrders();
      // window.location.reload();
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _onPrintAllOrders() {
    try {
      let res = await axios.post(
        BulkPrintOrdersAPI(),
        {
          orders: selectedRows,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      let tmp = [];
      selectedRows.map((i) => {
        let o = _.find(orders, { _id: i });
        o["printCustomerName"] = false;
        tmp.push(o);
      });

      history.push("/admin/order-print", { orders: tmp });
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _onPrintAllOrdersWithCustomerName() {
    try {
      let res = await axios.post(
        BulkPrintOrdersAPI(),
        {
          orders: selectedRows,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      let tmp = [];
      selectedRows.map((i) => {
        let o = _.find(orders, { _id: i });
        o["printCustomerName"] = true;
        tmp.push(o);
      });
      history.push("/admin/order-print", { orders: tmp });
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  const OrderTypeDialog = () => {
    return (
      <Dialog
        onClose={() => setOpenOrderTypeDialog(false)}
        aria-labelledby="simple-dialog-title"
        open={openOrderTypeDialog}
      >
        <DialogTitle id="simple-dialog-title">Select Order Type</DialogTitle>
        <List>
          {orderTypes.map((item) => (
            <ListItem
              button
              onClick={() => {
                setSelectedType(item);
                setOpenOrderTypeDialog(false);
                if (item === "Ready") {
                  setOpenReadyOrderAddDialog(true);
                } else if (item === "Fitting") {
                  setOpenFittingOrderAddDialog(true);
                } else {
                  setOpenPrescriptionOrderAddDialog(true);
                }
              }}
              key={item}
            >
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  };
  const handleOpenConfirmationModal = (object) => {
    setOpenConfirmationModal(object);
  };
  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function closeReadyOrderAddDialog() {
    setOpenReadyOrderAddDialog(false);
  }
  function closeEmailDialog() {
    setOpenEmailDialog(false);
  }

  function closeFittingOrderAddDialog() {
    setOpenFittingOrderAddDialog(false);
  }

  function closePrescriptionOrderAddDialog() {
    setOpenPrescriptionOrderAddDialog(false);
  }

  function closeViewDialog() {
    setOpenViewDialog(null);
  }

  function _renderTable(orderType, data) {
    if (searchTerm !== "") {
      data = _.filter(data, (i) => {
        return (
          i.code && i.code.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    }
    // if (orderType==='Prescription'){
    //     data = _.orderBy(data, ['createdAt'], ['desc']);
    // }
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {orderType === "Prescription" ? (
            <>
              <Button
                color="primary"
                onClick={() =>
                  handleOpenConfirmationModal({
                    msg: "Do you want to print all records",
                    onPress: () => _onPrintAllOrders(),
                  })
                }
              >
                Print All Records
              </Button>
              <Button
                color="primary"
                onClick={() =>
                  handleOpenConfirmationModal({
                    msg: "Do you want to print all records",
                    onPress: () => _onPrintAllOrdersWithCustomerName(),
                  })
                }
              >
                Print All Records With Customer Name
              </Button>
              <Button color="Primary" onClick={() => setOpenEmailDialog(true)}>
                Send Emails
              </Button>
            </>
          ) : (
            <div />
          )}
          <div>
            <CustomInput
              id="search"
              formControlProps={{
                fullWidth: false,
              }}
              inputProps={{
                style: {
                  color: "primary",
                  marginTop: 0,
                },
                placeholder: "Search",
                value: searchTerm,
                onChange: (e) => setSearchTerm(e.target.value),
                endAdornment: (
                  <InputAdornment position="end">
                    <People />
                  </InputAdornment>
                ),
              }}
            />
            <IconButton
              aria-label="add"
              onClick={() => setOpenOrderTypeDialog(true)}
            >
              <AddIcon />
            </IconButton>
          </div>
        </Box>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {orderType === "Prescription" ? (
                <Checkbox
                  style={{
                    color: "#512da8",
                  }}
                  onChange={(e) => {
                    let c = [];
                    if (e.target.checked) {
                      orders.map((i) => {
                        c.push(i._id);
                      });
                    }
                    setSelectedRows(c);
                  }}
                  checked={
                    selectedRows.length > 0 &&
                    orders.length === selectedRows.length
                  }
                  inputProps={{ "aria-labelledby": -1 }}
                />
              ) : null}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {orderType === "Prescription" ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          style={{
                            color: "#512da8",
                          }}
                          onChange={() => {
                            let copy = _.clone(selectedRows);
                            if (_.includes(copy, row._id)) {
                              let index = _.indexOf(copy, row._id);
                              copy.splice(index, 1);
                            } else {
                              copy.push(row._id);
                            }
                            setSelectedRows(copy);
                          }}
                          checked={_.includes(selectedRows, row._id)}
                          inputProps={{ "aria-labelledby": row._id }}
                        />
                      </TableCell>
                    ) : null}
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id === "product") {
                        if (row.orderType === "Fitting") {
                          value =
                            row.orderDetail && row.orderDetail.productName
                              ? row.orderDetail.productName
                              : "";
                        } else if (row.orderType === "Ready") {
                          value =
                            row.orderDetail && row.orderDetail.product
                              ? row.orderDetail.product.name
                              : "";
                        } else {
                          value =
                            row.orderDetail && row.orderDetail.product
                              ? row.orderDetail.product.productName
                              : "";
                        }
                      }
                      if (column.id === "productType") {
                        value = row.orderDetail.product.productType;
                      }
                      if (column.id === "action") {
                        return (
                          <TableCell align={"center"}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <IconButton
                                aria-label="view"
                                onClick={() =>
                                  setOpenViewDialog({
                                    open: true,
                                    item: row,
                                  })
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleOpenConfirmationModal({
                                    msg: "Do you want to delete this record?",
                                    title: "Delete Record",
                                    onPress: () => _deleteItem(row),
                                  })
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        );
                      }

                      if (column.id === "party") {
                        return (
                          <TableCell key={column.id} align={"center"}>
                            {value.accountName}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </GridItem>
    );
  }

  let pendingFittingOrders = _.filter(orders, {
    orderType: "Fitting",
    orderStatus: "Pending",
  });
  let completedFittingOrders = _.filter(orders, {
    orderType: "Fitting",
    orderStatus: "Completed",
  });
  let pendingPrescriptionOrders = _.filter(orders, {
    orderType: "Prescription",
    orderStatus: "Pending",
  });
  let completedPrescriptionOrders = _.filter(orders, {
    orderType: "Prescription",
    orderStatus: "Completed",
  });
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          title=""
          headerColor="primary"
          tabs={[
            {
              tabName: "Pending Fitting",
              tabIcon: BugReport,
              tabContent: _renderTable("Fitting", pendingFittingOrders),
            },
            {
              tabName: "Completed Fitting",
              tabIcon: BugReport,
              tabContent: _renderTable("Fitting", completedFittingOrders),
            },
            {
              tabName: "Pending Prescription",
              tabIcon: Code,
              tabContent: _renderTable(
                "Prescription",
                pendingPrescriptionOrders
              ),
            },
            {
              tabName: "Completed Prescription",
              tabIcon: Cloud,
              tabContent: _renderTable(
                "Prescription",
                completedPrescriptionOrders
              ),
            },
          ]}
        />
      </GridItem>

      <PrescriptionOrderAdd
        fetchData={_getOrders}
        open={openPrescriptionOrderAddDialog}
        close={closePrescriptionOrderAddDialog}
      />
      <ReadyOrderAdd
        fetchData={_getOrders}
        open={openReadyOrderAddDialog}
        close={closeReadyOrderAddDialog}
      />
      <FittingOrderAdd
        fetchData={_getOrders}
        open={openFittingOrderAddDialog}
        close={closeFittingOrderAddDialog}
      />
      <SendEmail
        fetchData={_getOrders}
        open={openEmailDialog}
        close={closeEmailDialog}
      />
      <OrderTypeDialog />
      {openViewDialog ? (
        <OrderView
          open={openViewDialog.open}
          close={closeViewDialog}
          item={openViewDialog.item}
        />
      ) : null}
      {openConfirmationModal ? (
        <ConfirmationDialog
          open={openConfirmationModal}
          onClose={handleCloseConfirmationModal}
          onConfirm={openConfirmationModal.onPress}
          title={openConfirmationModal.title}
          msg={openConfirmationModal.msg}
        />
      ) : null}
    </GridContainer>
  );
}
