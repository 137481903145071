import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { inWords2 } from '../../utils'; // Assuming inWords2 is the correct utility function
import _ from 'lodash';

// THIS IS THE A6 CHALLAN TEMPLATE

export const challan3Template = (data) => {
  console.log(data, "sata");
  const {
    clientAddress,
    clientName,
    challanDate,
    items,
    challanCode,
    runningChallanBalance,
    entityName,
    entityId,
    packagingCost,
    createdAt,
  } = data;

  // Determine code based on entityId
  const code = entityId === '62da46cf7eb6aa02db41297f' ? 'VO' :
    entityId === '62da46c57eb6aa02db41297d' ? 'IE' : 'VR';

  // `${item.nameR ? `R-${item?.nameR}` : ''} ${item.rProductCode ? `[${item.rProductCode.substring(3)}]` : ''}\n${item.nameL ? `L-${item?.nameL}` : ''} ${item?.lProductCode ? `[${item?.lProductCode.substring(3)}]` : ''}`,
  // Calculate total quantity and amount
  let totalQuantity = 0;
  let totalAmount = 0;
  const rows = items.map((item, index) => {
    totalQuantity += Number(item.quantity);
    totalAmount += Number(item.amount);
    return [
      index + 1,
      `${item.nameR ? `R-${item?.nameR} [${item.rProductCode.substring(3)}]` : ''}\n${item.nameL ? `L-${item?.nameL} [${item.lProductCode.substring(3)}]` : ''}`,
      item.orderRef,
      item.quantity,
      `${item?.rateR || ''}\n${item?.rateL || ''}`,
      (item?.rateL && item?.rateR) ? ((Number(item?.rateL) + Number(item?.rateR)) / 2).toFixed(2) :
        (item?.rateL ? (Number(item?.rateL) / 2).toFixed(2) : (item?.rateR ? (Number(item?.rateR) / 2).toFixed(2) : '')),
      item.discountAmount || item.discountPercent || '',
      _.round(item.amount, 2).toFixed(2),
    ];
  });

  const totalAmountWithPackaging = totalAmount + Number(packagingCost);
  const totalAmountInWords = inWords2(totalAmountWithPackaging);
  const oldBalance = _.round(runningChallanBalance - totalAmount, 2);

  // Initialize jsPDF document
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a6',
  });

  // Title and header information


  // Table headers
  const headers = [['#', 'Product Name', 'Odr Ref', 'Qty', 'Rate', 'Total', 'Disc', 'Amt']];

  // Adjusted cell widths to fit the content within the A6 page width
  doc.setFontSize(8);

  // Add table with the provided styling
  doc.autoTable({
    margin: { left: 2, right: 3, top: 2, },
    head: headers,
    body: rows,
    startY: 20,
    styles: {
      fontSize: 8,
      textColor: [0, 0, 0],
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
      fillColor: [255, 255, 255],
    },
    headStyles: {
      fontStyle: 'bold',
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      valign: 'middle',
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
      fontSize: 8,
    },
    // columnStyles: {
    //   // 1: {  }, // Increase font size for the "Product Name" column
    //   fontSize: 8
    // },
    footStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
    },
    tableLineWidth: 0.1,
    tableLineColor: [0, 0, 0],
    // columnStyles: {
    //   0: { cellWidth: columnWidths[0] },  // S.No
    //   1: { cellWidth: columnWidths[1] },  // Product Name
    //   2: { cellWidth: columnWidths[2] },  // Qty
    //   3: { cellWidth: columnWidths[3] },  // Rate
    //   4: { cellWidth: columnWidths[4] },  // Total
    //   5: { cellWidth: columnWidths[5] },  // Disc
    //   6: { cellWidth: columnWidths[6] },  // Amount
    // },
    didDrawPage: function (data) {
      if (data.pageCount === 1) {
        // Title and header information
        doc.text('Approval Memo', 5, 10);
        doc.text(code, 52.5, 10, { align: 'center' });
        doc.text(`Dt: ${createdAt}`, 100, 10, { align: 'right' });
        doc.text(`M/S: ${clientName}`, 5, 15);
        doc.text(`${challanCode.split('-')[0]}-${challanCode.split('-')[3]}`, 100, 15, { align: 'right' });
      }
      let footerStr = `Page ${data.pageCount}`;
      doc.text(footerStr, data.settings.margin.left, doc.internal.pageSize.height - 2);
    },
  });

  const finalY = doc.lastAutoTable.finalY;
  const secondTableStartY = doc.lastAutoTable.finalY;
  doc.autoTable({
    showHead: "never",
    margin: { left: 2, right: 3, top: 2 },
    head: headers,
    body: [
      ['Sub Total', '', `Qty:${totalQuantity}`, '', '', '', '', _.round(totalAmount, 2).toFixed(2)],
      ['Packaging Cost', '', '', '', '', '', '', _.round(Number(packagingCost), 2).toFixed(2)],
      ['Total Amount', '', '', '', '', '', '', _.round(totalAmount + Number(packagingCost), 2).toFixed(2)],
      ['Balance Up To Date:', '', '', '', '', '', '', `${_.round(runningChallanBalance, 2).toFixed(2)}`],
    ],
    startY: secondTableStartY,
    startX: 5,
    styles: {
      fontStyle: 'bold',
      fontSize: 7,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      fillColor: [255, 255, 255]
    },
    headStyles: {
      fontStyle: 'bold',
      cellPadding: 0.75,
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      valign: 'middle',
      lineColor: [0, 0, 0],
      fontSize: 7,
    },
    footStyles: {
      cellPadding: 0.75,
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      lineWidth: 0.05,
      lineColor: [0, 0, 0]
    },
    tableLineWidth: 0.1,
    tableLineColor: [0, 0, 0],
    columnStyles: {
      7: { halign: 'right' }, // Right align the last column (index 5)
      1: { halign: 'left' }
    }
  });
  // // Add totals and footer
  // doc.setFontSize(8);
  // doc.text(
  //   `Packaging Cost: Rs ${_.round(Number(packagingCost), 2).toFixed(2)}\nIn Words: ${totalAmountInWords}\nBalance Up To Date: Rs ${_.round(runningChallanBalance, 2).toFixed(2)}`,
  //   5,
  //   finalY + 5
  // );

  // Set different font size for the Total Amount
  doc.setFontSize(7);
  doc.text(`Total Amount in words:${totalAmountInWords} Rupees Only`, 5, finalY + 30);
  doc.text('E & OE', 5, finalY + 35);
  doc.text('Working Hours: 10.30 AM to 7.00 PM', 5, finalY + 40);
  const pdfBlob = doc.output('blob');

  // Open the PDF in a new browser tab
  const url = window.URL.createObjectURL(pdfBlob);
  window.open(url, '_blank');
};
