//Done by Dev
import axios from "axios";
import React, { useState } from "react";
import { Dialog, Box, Slide, makeStyles } from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button.js";
import { CreateUserAPI } from "../../config/api";
import { useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EmployeeMastersAdd(props) {
  const { open, close } = props;
  const classes = useStyles();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);

  const [employeeName, setEmployeeName] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [employeeMobile, setEmployeeMobile] = useState("");
  const [employeeAddress, setEmployeeAddress] = useState("");

  const [permissions, setPermissions] = useState({
    selectAll: false,
    viewPurchase: false,
    viewAccounts: false,
    viewAccountsVerification: false,
    viewDiscounts: false,
    viewOrders: false,
    viewOrdersApp: false,
    viewOrderReturn: false,
    viewTransaction: false,
    viewOrderStatus: false,
    viewPrintAd: false,
    viewBankList: false,
    viewPaymentList: false,
    viewPurchaseBarcode: false,
    viewProductBarcode: false,
    viewOrderPrintPage: false,
    viewBrandsList: false,
    viewOfferList: false,
    viewTrendingList: false,
    viewBrochureList: false,
    viewPriceList: false,
    viewLabList: false,
    viewMaster: false,
    viewCategoryList: false,
    viewAccounting: false,
    viewDashboard: false,
    viewSystemUsers: false,
    viewAccountUsers: false,
  });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setPermissions((prevPermissions) => {
      const newPermissions = { ...prevPermissions, [name]: checked };

      if (name === "selectAll") {
        Object.keys(newPermissions).forEach((key) => {
          newPermissions[key] = checked;
        });
      } else {
        newPermissions.selectAll = Object.keys(newPermissions)
          .filter((key) => key !== "selectAll")
          .every((key) => newPermissions[key]);
      }

      return newPermissions;
    });
  };

  async function _onSave() {
    try {
      if (employeeName === "" || employeeEmail === "" || employeeMobile === "" || employeeAddress === "") {
        toast.error("Please fill all the fields", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      let res = await axios.post(
        CreateUserAPI(),
        {
          name: employeeName,
          email: employeeEmail,
          mobile: employeeMobile,
          ...permissions,
          entity: userInfo.entity,
          password: "111111",
          address: employeeAddress,
          isSystemUser: true,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success("Successfully Added!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      close();
    } catch (e) {
      toast.error("Something went wrong, Please Contact Admin", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const permissionLabels = {
    viewDashboard: "View Dashboard",
    viewSystemUsers: "View System Users",
    viewAccountUsers: "View Account Users",
    viewPurchase: "View Purchase",
    viewAccounts: "View Accounts",
    viewAccountsVerification: "View Accounts Verification",
    viewDiscounts: "View Discounts",
    viewOrders: "View Orders",
    viewOrdersApp: "View Orders App",
    viewOrderReturn: "View Orders Return",
    viewTransaction: "View Transaction",
    viewOrderStatus: "View Order Status",
    viewAccounting: "View Accounting",
    viewPrintAd: "View Print Ad",
    viewBankList: "View Bank List",
    viewPaymentList: "View Payment List",
    viewPurchaseBarcode: "View Purchase Barcode",
    viewProductBarcode: "View Product Barcode",
    viewOrderPrintPage: "View Order Print Page",
    viewBrandsList: "View Brands List",
    viewCategoryList: "View Category List",
    viewOfferList: "View Offer List",
    viewTrendingList: "View Trending List",
    viewBrochureList: "View Brochure List",
    viewPriceList: "View Price List",
    viewLabList: "View Lab List",
    viewMaster: "View Master",
  };

  return (
    <Dialog fullScreen open={open} onClose={close} TransitionComponent={Transition}>
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <h4 className={classes.cardTitleWhite}>Add Employee</h4>
                <Button color={"transparent"} onClick={() => _onSave()}>
                  Save
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="Employee Name*"
                    id="EmployeeName"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{ value: employeeName, onChange: (e) => setEmployeeName(e.target.value) }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="Employee Email*"
                    id="EmployeeEmail"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{ value: employeeEmail, onChange: (e) => setEmployeeEmail(e.target.value) }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="Employee Mobile*"
                    id="EmployeeMobile"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{ type: "number", value: employeeMobile, onChange: (e) => setEmployeeMobile(e.target.value) }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Employee Address*"
                    id="EmployeeAddress"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{ value: employeeAddress, onChange: (e) => setEmployeeAddress(e.target.value) }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                    label="Select All"
                    control={<Checkbox checked={permissions.selectAll} onChange={handleCheckboxChange} name="selectAll" />}
                  />
                </GridItem>
                {Object.keys(permissionLabels).map((permission) => (
                  <GridItem key={permission} xs={12} sm={6} md={4}>
                    <FormControlLabel
                      label={permissionLabels[permission]}
                      control={<Checkbox checked={permissions[permission]} onChange={handleCheckboxChange} name={permission} />}
                    />
                  </GridItem>
                ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}


// import axios from "axios";
// import React, { useState, useEffect } from "react";
// import { Dialog, Box, Slide, makeStyles } from "@material-ui/core";
// import GridContainer from "../../components/Grid/GridContainer";
// import GridItem from "../../components/Grid/GridItem";
// import Card from "../../components/Card/Card";
// import CardHeader from "../../components/Card/CardHeader";
// import CardBody from "../../components/Card/CardBody";
// import CustomInput from "../../components/CustomInput/CustomInput";
// import Button from "../../components/CustomButtons/Button.js";
// import { CreateUserAPI } from "../../config/api";
// import { useSelector } from "react-redux";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import { toast } from "react-toastify"

// const useStyles = makeStyles((theme) => ({
//   fab: {
//     position: "absolute",
//     bottom: theme.spacing(2),
//     right: theme.spacing(2),
//   },
//   cardCategoryWhite: {
//     "&,& a,& a:hover,& a:focus": {
//       color: "rgba(255,255,255,.62)",
//       margin: "0",
//       fontSize: "14px",
//       marginTop: "0",
//       marginBottom: "0",
//     },
//     "& a,& a:hover,& a:focus": {
//       color: "#FFFFFF",
//     },
//   },
//   cardTitleWhite: {
//     color: "#FFFFFF",
//     marginTop: "0px",
//     minHeight: "auto",
//     fontWeight: "300",
//     fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
//     marginBottom: "3px",
//     textDecoration: "none",
//     "& small": {
//       color: "#777",
//       fontSize: "65%",
//       fontWeight: "400",
//       lineHeight: "1",
//     },
//   },
// }));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// export default function EmployeeMastersAdd(props) {
//   const { open, close } = props;
//   const classes = useStyles();
//   let userInfo = useSelector((state) => state.userReducer.userInfo);
//   let authToken = useSelector((state) => state.userReducer.authToken);

//   const [employeeName, setEmployeeName] = useState("");
//   const [employeeEmail, setEmployeeEmail] = useState("");
//   const [employeeMobile, setEmployeeMobile] = useState("");
//   const [employeeAddress, setEmployeeAddress] = useState("");

//   const [viewPurchase, setViewPurchase] = useState(false);
//   const [viewAccounts, setViewAccounts] = useState(false);
//   const [viewAccountsVerification, setViewAccountsVerification] =
//     useState(false);
//   const [viewDiscounts, setViewDiscounts] = useState(false);
//   const [viewOrders, setViewOrders] = useState(false);
//   const [viewOrdersApp, setViewOrdersApp] = useState(false);
//   const [viewOrderReturn, setViewOrderReturn] = useState(false);
//   const [viewTransaction, setViewTransaction] = useState(false);
//   const [viewOrderStatus, setViewOrderStatus] = useState(false);
//   const [viewPrintAd, setViewPrintAd] = useState(false);
//   const [viewBankList, setViewBankList] = useState(false);
//   const [viewPaymentList, setViewPaymentList] = useState(false);
//   const [viewPurchaseBarcode, setViewPurchaseBarcode] = useState(false);
//   const [viewProductBarcode, setViewProductBarcode] = useState(false);
//   const [viewOrderPrintPage, setViewOrderPrintPage] = useState(false);
//   const [viewBrandsList, setViewBrandsList] = useState(false);
//   const [viewOfferList, setViewOfferList] = useState(false);
//   const [viewTrendingList, setViewTrendingList] = useState(false);
//   const [viewBrochureList, setViewBrochureList] = useState(false);
//   const [viewPriceList, setViewPriceList] = useState(false);
//   const [viewMaster, setViewMaster] = useState(false);
//   const [viewCategoryList, setViewCategoryList] = useState(false);
//   const [viewAccounting, setViewAccounting] = useState(false);
//   const [viewDashboard, setViewDashboard] = useState(false);
//   const [viewSystemUsers, setViewSystemUsers] = useState(false);
//   const [viewAccountUsers, setViewAccountUsers] = useState(false);
//   const [viewLabList, setViewLabList] = useState(false)

//   const [selectAll, setSelectAll] = useState(false);

//   async function _onSave() {
//     try {
//       if (employeeName === "" || employeeEmail === "" || employeeMobile === "" || employeeAddress === "") {
//         toast.error("Please fill all the fields", {
//           position: "top-center",
//           autoClose: 1500,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         });
//         return;
//       }
//       let res = await axios.post(
//         CreateUserAPI(),
//         {
//           name: employeeName,
//           email: employeeEmail,
//           mobile: employeeMobile,
//           viewDashboard,
//           viewSystemUsers,
//           viewAccountUsers,
//           viewPurchase,
//           viewAccounts,
//           viewAccountsVerification,
//           viewDiscounts,
//           viewOrders,
//           viewOrdersApp,
//           viewOrderReturn,
//           viewMaster,
//           viewTransaction,
//           viewOrderStatus,
//           viewAccounting,
//           viewPrintAd,
//           viewBankList,
//           viewPaymentList,
//           viewPurchaseBarcode,
//           viewProductBarcode,
//           viewOrderPrintPage,
//           viewBrandsList,
//           viewCategoryList,
//           viewOfferList,
//           viewTrendingList,
//           viewBrochureList,
//           viewPriceList,
//           viewLabList,
//           entity: userInfo.entity,
//           password: "111111",
//           address: employeeAddress,
//           isSystemUser: true,
//         },


//         {
//           headers: {
//             Authorization: `Bearer ${authToken}`,
//           },
//         }
//       );
//       toast.success('Successfully Added!', {
//         position: "top-center",
//         autoClose: 1500,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//       });
//       close();
//     } catch (e) {
//       // console.log(e);
//       // toast.error(("Something went wrong, Please Contact Admin") , {
//       //   position: "top-center",
//       //   autoClose: 1500,
//       //   hideProgressBar: false,
//       //   closeOnClick: true,
//       //   pauseOnHover: true,
//       //   draggable: true,
//       //   progress: undefined,
//       //   theme: "light",
//       //   }); 
//     }
//   }

//   return (
//     <Dialog
//       fullScreen
//       open={open}
//       onClose={close}
//       TransitionComponent={Transition}
//     >
//       <GridContainer>
//         <GridItem xs={12} sm={12}>
//           <Card>
//             <CardHeader color="primary">
//               <Box
//                 display={"flex"}
//                 alignItems={"center"}
//                 justifyContent={"space-between"}
//               >
//                 <h4 className={classes.cardTitleWhite}>Add Employee</h4>
//                 <Button color={"transparent"} onClick={() => _onSave()}>
//                   Save
//                 </Button>
//               </Box>
//             </CardHeader>
//             <CardBody>
//               <GridContainer>
//                 <GridItem xs={6} sm={6} md={4}>
//                   <CustomInput
//                     labelText="Employee Name*"
//                     id="EmployeeName"
//                     formControlProps={{
//                       fullWidth: true,
//                     }}
//                     inputProps={{
//                       value: employeeName,
//                       onChange: (event) => setEmployeeName(event.target.value),
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={4}>
//                   <CustomInput
//                     labelText="Employee Email*"
//                     id="EmployeeEmail"
//                     formControlProps={{
//                       fullWidth: true,
//                     }}
//                     inputProps={{
//                       value: employeeEmail,
//                       onChange: (event) => setEmployeeEmail(event.target.value),
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={4}>
//                   <CustomInput
//                     labelText="Employee Mobile*"
//                     id="EmployeeMobile"
//                     formControlProps={{
//                       fullWidth: true,
//                     }}
//                     inputProps={{
//                       type: "number",
//                       value: employeeMobile,
//                       onChange: (event) =>
//                         setEmployeeMobile(event.target.value),
                        
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={12}>
//                   <CustomInput
//                     labelText="Employee Address*"
//                     id="EmployeeAddress"
//                     formControlProps={{
//                       fullWidth: true,
//                     }}
//                     inputProps={{
//                       value: employeeAddress,
//                       onChange: (event) =>
//                         setEmployeeAddress(event.target.value),
//                     }}
//                   />
//                 </GridItem>
//                 <GridItem xs={12} sm={12} md={12}>
//                   <FormControlLabel
//                     label="Select All"
//                     control={
//                       <Checkbox
//                         checked={selectAll}
//                         onChange={(e) => {
//                           setSelectAll(e.target.checked);
//                           setViewDashboard(e.target.checked);
//                           setViewSystemUsers(e.target.checked);
//                           setViewAccountUsers(e.target.checked);

//                           setViewPurchase(e.target.checked);
//                           setViewAccounts(e.target.checked);
//                           setViewAccountsVerification(e.target.checked);
//                           setViewDiscounts(e.target.checked);
//                           setViewOrders(e.target.checked);
//                           setViewOrdersApp(e.target.checked);
//                           setViewOrderReturn(e.target.checked);
//                           setViewMaster(e.target.checked);
//                           setViewTransaction(e.target.checked);
//                           setViewOrderStatus(e.target.checked);
//                           setViewAccounting(e.target.checked);
//                           setViewPrintAd(e.target.checked);
//                           setViewBankList(e.target.checked);
//                           setViewPaymentList(e.target.checked);
//                           setViewPurchaseBarcode(e.target.checked);
//                           setViewProductBarcode(e.target.checked);
//                           setViewOrderPrintPage(e.target.checked);
//                           setViewBrandsList(e.target.checked);
//                           setViewCategoryList(e.target.checked);
//                           setViewOfferList(e.target.checked);
//                           setViewTrendingList(e.target.checked);
//                           setViewBrochureList(e.target.checked);
//                           setViewPriceList(e.target.checked);
//                           setViewLabList(e.target.checked);
//                         }}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View Dashboard"
//                     control={
//                       <Checkbox
//                         checked={viewDashboard}
//                         onChange={(e) => setViewDashboard(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View System Users"
//                     control={
//                       <Checkbox
//                         checked={viewSystemUsers}
//                         onChange={(e) => setViewSystemUsers(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View Account Users"
//                     control={
//                       <Checkbox
//                         checked={viewAccountUsers}
//                         onChange={(e) => setViewAccountUsers(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View Purchase"
//                     control={
//                       <Checkbox
//                         checked={viewPurchase}
//                         onChange={(e) => setViewPurchase(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View Accounts"
//                     control={
//                       <Checkbox
//                         checked={viewAccounts}
//                         onChange={(e) => setViewAccounts(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View AccountsVerification"
//                     control={
//                       <Checkbox
//                         checked={viewAccountsVerification}
//                         onChange={(e) =>
//                           setViewAccountsVerification(e.target.checked)
//                         }
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View Discounts"
//                     control={
//                       <Checkbox
//                         checked={viewDiscounts}
//                         onChange={(e) => setViewDiscounts(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View Orders"
//                     control={
//                       <Checkbox
//                         checked={viewOrders}
//                         onChange={(e) => setViewOrders(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View OrdersApp"
//                     control={
//                       <Checkbox
//                         checked={viewOrdersApp}
//                         onChange={(e) => setViewOrdersApp(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View Orders Return"
//                     control={
//                       <Checkbox
//                         checked={viewOrderReturn}
//                         onChange={(e) => setViewOrderReturn(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View Master"
//                     control={
//                       <Checkbox
//                         checked={viewMaster}
//                         onChange={(e) => setViewMaster(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View Transaction"
//                     control={
//                       <Checkbox
//                         checked={viewTransaction}
//                         onChange={(e) => setViewTransaction(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View OrderStatus"
//                     control={
//                       <Checkbox
//                         checked={viewOrderStatus}
//                         onChange={(e) => setViewOrderStatus(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View Accounting"
//                     control={
//                       <Checkbox
//                         checked={viewAccounting}
//                         onChange={(e) => setViewAccounting(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View PrintAd"
//                     control={
//                       <Checkbox
//                         checked={viewPrintAd}
//                         onChange={(e) => setViewPrintAd(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View BankList"
//                     control={
//                       <Checkbox
//                         checked={viewBankList}
//                         onChange={(e) => setViewBankList(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View PaymentList"
//                     control={
//                       <Checkbox
//                         checked={viewPaymentList}
//                         onChange={(e) => setViewPaymentList(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View Purchase Barcode"
//                     control={
//                       <Checkbox
//                         checked={viewPurchaseBarcode}
//                         onChange={(e) =>
//                           setViewPurchaseBarcode(e.target.checked)
//                         }
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View Product Barcode"
//                     control={
//                       <Checkbox
//                         checked={viewProductBarcode}
//                         onChange={(e) =>
//                           setViewProductBarcode(e.target.checked)
//                         }
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View Order Print Page "
//                     control={
//                       <Checkbox
//                         checked={viewOrderPrintPage}
//                         onChange={(e) =>
//                           setViewOrderPrintPage(e.target.checked)
//                         }
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View BrandsList"
//                     control={
//                       <Checkbox
//                         checked={viewBrandsList}
//                         onChange={(e) => setViewBrandsList(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View CategoryList"
//                     control={
//                       <Checkbox
//                         checked={viewCategoryList}
//                         onChange={(e) => setViewCategoryList(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View OfferList"
//                     control={
//                       <Checkbox
//                         checked={viewOfferList}
//                         onChange={(e) => setViewOfferList(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View TrendingList"
//                     control={
//                       <Checkbox
//                         checked={viewTrendingList}
//                         onChange={(e) => setViewTrendingList(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View BrochureList"
//                     control={
//                       <Checkbox
//                         checked={viewBrochureList}
//                         onChange={(e) => setViewBrochureList(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View PriceList"
//                     control={
//                       <Checkbox
//                         checked={viewPriceList}
//                         onChange={(e) => setViewPriceList(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//                 <GridItem xs={6} sm={6} md={3}>
//                   <FormControlLabel
//                     label="View LabList"
//                     control={
//                       <Checkbox
//                         checked={viewLabList}
//                         onChange={(e) => setViewLabList(e.target.checked)}
//                       />
//                     }
//                   />
//                 </GridItem>
//               </GridContainer>
//             </CardBody>
//           </Card>
//         </GridItem>
//       </GridContainer>
//     </Dialog>
//   );
// }
