import React, { useEffect, useState } from "react";
// @material-ui/core components
import {
  Dialog,
  makeStyles,
  Slide,
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import { useSelector } from "react-redux";

// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";

import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import Tabs from "../../components/CustomTabs/CustomTabs.js";
import PouchDB from "pouchdb";
import _ from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import axios from "axios";
import {
  CreateBillAPI,
  CreateChallanAPI,
  GetAllBillsAPI,
  GetAllChallansAPI,
} from "../../config/api";
import { pointToPointBillTemplate } from "../../assets/html/pointToPointBillTemplate.js";
import { dataURItoBlob, htmlTemplateToDataString } from "../../utils";
import { toast } from "react-toastify";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    flex: 1,
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const billColumns = [
  { id: "code", label: "Code", minWidth: 100 },
  { id: "party", label: "Party", minWidth: 100 },
  { id: "challanCodes", label: "Challan Codes", minWidth: 100 },
  {
    id: "createdAt",
    label: "Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
];

export default function PointToPointBill(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { open, close, item, startDate, endDate } = props;
  let authToken = useSelector((state) => state.userReducer.authToken);
  let userInfo = useSelector((state) => state.userReducer.userInfo);

  const { _id, name, address, gst } = item;

  useEffect(() => {
    _getBills(startDate, endDate);
  }, [item]);

  const [bills, setBills] = useState([]);

  async function _getBills(startDate, endDate) {
    try {
      let query = `&userId=${item._id}`;
      if (startDate && endDate) {
        let sd = moment(startDate).format("DD-MM-YYYY");
        let ed = moment(endDate).format("DD-MM-YYYY");
        query += `&startDate=${sd}&endDate=${ed}`;
      }
      let res = await axios.get(GetAllBillsAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setBills(res.data.bills);
    } catch (err) {
      // console.log("error:", err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _generateBill() {
    try {
      //   let challanIds = [],
      //     challanCodes = [];
      //   _.map(bills, (i) => {
      //     challanIds = _.concat(challanIds, i.challanIds);
      //     challanCodes = _.concat(challanCodes, i.challanCodes);
      //   });
      //   let bill = {
      //     challanIds,
      //     challanCodes,
      //     isCombined: true,
      //     party: {
      //       accountId: _id,
      //       accountName: name,
      //       accountAddress: address,
      //       accountGst: gst,
      //     },
      //   };

      const htmlData = {
        // challanDate: moment().format("DD-MM-YYYY"),
        bills: bills,
        startDate: startDate,
        endDate: endDate,
      };

      let options = {
        orientation: "p",
        unit: "pt",
        format: [850, 1066],
        // format:'letter',
        putOnlyUsedFonts: true,
      };

      const htmlTemplate = pointToPointBillTemplate(htmlData);

      await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
        const preBlob = dataURItoBlob(dataString);
        window.open(URL.createObjectURL(preBlob));
      });

      //   let res = await axios.post(CreateBillAPI(), bill);
      close();
    } catch (e) {
      // console.log("err:", e);
    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <Card>
        <CardHeader color="primary">
          <Box
            display={"flex"}
            alignItems={"center"}
          // justifyContent={"space-between"}
          >
            <h4 className={classes.cardTitleWhite}>Point To Point</h4>
            <Button style={{ color: "white" }} onClick={close}>
              Close
            </Button>

            <Button style={{ color: "white" }} onClick={() => _generateBill()}>
              Generate
            </Button>
          </Box>
          {/*<p className={classes.cardCategoryWhite}>Complete your profile</p>*/}
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {billColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bills.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {billColumns.map((column) => {
                          let value = row[column.id];
                          if (column.id === "party") {
                            value = row.party.accountName;
                          }
                          if (column.id === "challanCodes") {
                            let codes = "";
                            row["challanCodes"].map((i, index) => {
                              if (row["challanCodes"].length === index + 1) {
                                codes += i;
                              } else {
                                codes = codes + i + ", ";
                              }
                            });
                            value = codes;
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </Dialog>
  );
}
