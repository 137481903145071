import React, { useState, useEffect } from "react";
import axios from "axios";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import { ChallanList } from "../Challan/ChallanList";
import { BillList } from "../Bill/BillList";
import classNames from "classnames";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Slide,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import CustomInput from "../../components/CustomInput/CustomInput";
import IconButton from "@material-ui/core/IconButton";
// import FilterListIcon from "@material-ui/icons/FilterList";
import CardBody from "../../components/Card/CardBody";
import styles from "../../assets/jss/material-dashboard-react/components/customTabsStyle";
// import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { FilterDialog } from "../../components/common/FilterDialog";
import {
  GetAllAccountsAPI,
  GetBulkReadyMasterRangeAPI,
  GetAllBrandsAPI,
  GetAllReadyMastersAPI,
  GetStockReportAPI,
} from "../../config/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ChallanListCredit } from "../Challan/ChallanListCredit";
import { Search, FilterList as FilterIcon } from "@material-ui/icons";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import Autocomplete from "@mui/material/Autocomplete";
import { StockList } from "./StockList";
import StockListPageM from "../../assets/html/StockListPageM";
import { dataURItoBlob, htmlTemplateToDataString } from "../../utils";
import PrintIcon from "@mui/icons-material/Print";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const tabs = [
  {
    tabName: "Challans",
    tabIcon: BugReport,
    tabContent: <ChallanListCredit />,
  },
];

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Test(props) {
  const [brands, setBrands] = useState([]);
  const [value, setValue] = useState(0);
  const authToken = useSelector((state) => state.userReducer.authToken);
  const userInfo = useSelector((state) => state.userReducer.userInfo);
  const [product, setProduct] = useState("");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [colorCode, setColorCode] = useState('');
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [party, setParty] = useState([]);
  const [dataaa, setDataaa] = useState([]);
  const [tota, setTota] = useState(0);
  const [dataToPass, setDatatoPass] = useState({});

  const [openFilter, setOpenFilter] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleOpenFilter = () => setOpenFilter(true);
  const handleCloseFilter = () => setOpenFilter(false);

  function handleApplyFilter(accounts, startDate, endDate) {
    // Convert start and end dates to Date objects
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    console.log(accounts, startDate, endDate, "<<<<<");

    // Filter the array based on whether each date's `createdAt` falls within the range
    const filteredAccounts = accounts.filter((item) => {
      const dbDate = new Date(item.createdAt); // Access the `createdAt` field
      console.log("Checking:", dbDate);
      return !isNaN(dbDate) && dbDate >= startDate && dbDate <= endDate;
    });
    // Log and return filtered accounts
    console.log("Filtered Accounts:", filteredAccounts);
    // Update the accounts with the filtered list, close the modal, and update total count
    setDataaa(filteredAccounts);
    setOpenFilter(false); // Close the modal
  }

  const classes = useStyles();
  const { rtlActive } = props;
  const cardTitle = classNames({
    [classes.cardTitle]: true,
    [classes.cardTitleRTL]: rtlActive,
  });
  const [readyMasterRange, setReadyMasterRange] = useState([]);
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 500,
  });

  function getAllThings() {
    Promise.all([
      axios.get(GetAllAccountsAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }),
      axios.get(GetBulkReadyMasterRangeAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }),
      axios.get(GetAllBrandsAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }),
    ])
      .then((responses) => {
        const [accountsResponse, readyMasterRangeResponse, brands] = responses;

        // Handle accounts data
        const accountsData = accountsResponse.data.accounts;
        setAccounts(accountsData);
        const partyData = accountsData.map((account) => account.name);
        setParty(partyData);

        // Handle readyMasterRange data
        const readyMasterRangeData =
          readyMasterRangeResponse.data.readyMasterRange;
        setReadyMasterRange(readyMasterRangeData);

        //HAndle brands

        const brandsData = brands.data.brandMasters;
        setBrands(brandsData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        toast.error("Something went wrong, Please Contact Admin", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  useEffect(() => {
    getAllThings();
  }, [authToken, userInfo.entity]);

  const handleChange = (event, value) => {
    setValue(value);
  };

  const handleCloseFilterModal = () => {
    setOpenFilter(false);
  };

  function handleSearch() {
    setDatatoPass({
      category: category,
      brand: brand,
      product: product,
      colorCode: colorCode,
    });
  }

  async function _onPreview(startDate, endDate) {
    try {
        const doc = new jsPDF();

        // Check if `dataaa` is empty
        if (accounts.length === 0) {
            doc.text("No product data available.", 10, 10);
            const pdfBlob = doc.output("blob");
            const url = window.URL.createObjectURL(pdfBlob);
            window.open(url, "_blank");
            return;
        }

        // Define title and date range
        const title = "Stock Report";
        const dateRange = `From: ${startDate} To: ${endDate}`;
        const TITLE_FONT_SIZE = 14;
        const DATE_FONT_SIZE = 10;

        // Add title to the top of the document
        doc.setFontSize(TITLE_FONT_SIZE);
        const titleWidth = (doc.getStringUnitWidth(title) * TITLE_FONT_SIZE) / doc.internal.scaleFactor;
        const titleXPosition = (doc.internal.pageSize.getWidth() - titleWidth) / 2;
        doc.text(title, titleXPosition, 20);

        // Add date range below the title
        doc.setFontSize(DATE_FONT_SIZE);
        const dateRangeWidth = (doc.getStringUnitWidth(dateRange) * DATE_FONT_SIZE) / doc.internal.scaleFactor;
        const dateXPosition = (doc.internal.pageSize.getWidth() - dateRangeWidth) / 2;
        doc.text(dateRange, dateXPosition, 30);

        // Define starting Y-position for product details after the title and date range
        let currentYPosition = 40;

        // Font size constants for product details and tables
        const PRODUCT_FONT_SIZE = 12;
        const TABLE_FONT_SIZE = 10;
        const HEADER_FONT_SIZE = 8;

        console.log(accounts, "accounts");

        // Add each product's details
        for (const item of accounts) {
            const productLabel = `${item.productName} [${item.productCode}]`;

            // Set the position for the product name
            const textWidth = (doc.getStringUnitWidth(productLabel) * PRODUCT_FONT_SIZE) / doc.internal.scaleFactor;
            const xPosition = (doc.internal.pageSize.getWidth() - textWidth) / 2;

            // Add product name
            doc.setFontSize(PRODUCT_FONT_SIZE);
            doc.text(productLabel, xPosition, currentYPosition);

            // Prepare table data
            const tableData = [
                [
                    item.sph,
                    item.cyl,
                    item.axis,
                    item.addition,
                    item.threshold,
                    item.quantity,
                    item.threshold - item.quantity, // Order Quantity
                ],
            ];

            // Add the table below the product name
            doc.autoTable({
                head: [["SPH", "CYL", "AXIS", "Addition", "Threshold", "Stock", "Order Quantity"]],
                body: tableData,
                startY: currentYPosition + 10, // Position the table below the product name
                styles: {
                    fontSize: TABLE_FONT_SIZE,
                    cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
                    textColor: [0, 0, 0],
                    lineWidth: 0.1,
                    lineColor: [0, 0, 0],
                    fillColor: [255, 255, 255],
                },
                headStyles: {
                    fontStyle: "bold",
                    fillColor: [200, 200, 200],
                    textColor: [0, 0, 0],
                    valign: "middle",
                    lineWidth: 0.1,
                    lineColor: [0, 0, 0],
                    fontSize: HEADER_FONT_SIZE,
                },
            });

            // Update Y position for the next product's details to avoid overlap
            currentYPosition = doc.autoTable.previous.finalY + 10;
        }

        // Output the PDF as a Blob
        const pdfBlob = doc.output("blob");
        const url = window.URL.createObjectURL(pdfBlob);
        window.open(url, "_blank");
    } catch (error) {
        console.error("Error generating PDF:", error);
    }
}

  
  
  
  

  return (
    <Card plain={false}>
      <CardHeader color={"rose"} plain={false}>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <div>
            {/* <Tabs
                            value={value}
                            onChange={handleChange}
                            classes={{
                                root: classes.tabsRoot,
                                indicator: classes.displayNone,
                                scrollButtons: classes.displayNone,
                            }}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {tabs.map((prop, key) => {
                                let icon = {};
                                if (prop.tabIcon) {
                                    icon = {
                                        icon: <prop.tabIcon />,
                                    };
                                }
                                return (
                                    <Tab
                                        classes={{
                                            root: classes.tabRootButton,
                                            selected: classes.tabSelected,
                                            wrapper: classes.tabWrapper,
                                        }}
                                        key={key}
                                        label={prop.tabName}
                                        {...icon}
                                    />
                                );
                            })}
                        </Tabs> */}
          </div>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: 50,
            }}
          >
            <Autocomplete
              disablePortal
              id="category-autocomplete"
              options={["Color", "Range"]}
              fullWidth
              value={category}
              onChange={(event, value) => setCategory(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  style={{
                    backgroundColor: "#FFFFFF",
                    opacity: "50%",
                    borderRadius: 5,
                  }}
                />
              )}
            />

             {/* Conditionally render TextField for color code */}
      {category === "Color" && (
        <TextField
          label="Color Code"
                  style={{
                    backgroundColor: "#FFFFFF",
                    opacity: "50%",
                    borderRadius: 5,
                  }}
          value={colorCode}
          onChange={(e) => setColorCode(e.target.value)}
          fullWidth
          // style={{
          //   marginTop: '16px',
          //   backgroundColor: "#FFFFFF",
          //   borderRadius: 5,
          // }}
        />
      )}

            <Autocomplete
              id="product"
              filterOptions={filterOptions}
              loading={readyMasterRange.length === 0 ? true : false}
              className={classes.formControl}
              options={readyMasterRange}
              fullWidth
              onChange={(event, value) => {
                // Check if any product already has a discount
                setProduct(value ? value.productName : "");
              }}
              getOptionLabel={(option) => `${option.productName}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Product"
                  style={{
                    backgroundColor: "#FFFFFF",
                    opacity: "50%",
                    borderRadius: 5,
                  }}
                />
              )}
            />

            <Autocomplete
              disablePortal
              id="brand-autocomplete"
              options={brands}
              fullWidth
              onChange={(event, value) => setBrand(value ? value._id : "")}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Brands"
                  style={{
                    backgroundColor: "#FFFFFF",
                    opacity: "50%",
                    borderRadius: 5,
                  }}
                />
              )}
            />
            <IconButton color={"secondary"} onClick={(e) => handleSearch()}>
              <Search />
            </IconButton>
            <IconButton color="secondary" onClick={handleOpenFilter}>
              <FilterIcon />
            </IconButton>
            <IconButton color={"secondary"} onClick={() => _onPreview()}>
              <PrintIcon />
            </IconButton>
          </Box>
        </Box>
      </CardHeader>
      <CardBody>
        <StockList
          filter={filter}
          accounts={accounts}
          readyMasterRange={readyMasterRange}
          dataToPass={dataToPass}
          setDataaa={setDataaa}
        />
      </CardBody>
      {/* <FilterDialog
                party={party}
                filter={filter}
                setFilter={setFilter}
                showFilterDialog={openFilter}
                handleCloseFilterDialog={handleCloseFilterModal}
            /> */}
      <Dialog open={openFilter} onClose={handleCloseFilter}>
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2}>
            <DatePicker
              label="Start Date"
              value={startDate}
              sx={{paddingBottom: 2}}
              onChange={(e) => setStartDate(e)}
              renderInput={(props) => <TextField {...props} fullWidth />}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(props) => <TextField {...props} fullWidth />}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFilter}>Cancel</Button>
          <Button
            onClick={() => handleApplyFilter(accounts, startDate, endDate)}
            color="primary"
          >
            Apply Filter
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
