// export const baseUrl = process.env.SERVER_URL;
// export const baseUrl = "http://localhost:3000";

// export const baseUrl = process.env.SERVER_URL;
export const baseUrl = "https://server.astralenses.com";
// export NODE_OPTIONS=--openssl-legacy-provider
//User
export const GetAllUserAPI = (entity) => baseUrl + `/users?entity=${entity}`;
export const CreateUserAPI = () => baseUrl + `/users/register`;
export const UserRegisterAdminAPI = () => baseUrl + `/users/register/admin`;
export const UpdateUserAPI = (id) => baseUrl + `/users/${id}`;
export const DeleteUserAPI = (id) => baseUrl + `/users/${id}`;
export const GetUserAPI = (id) => baseUrl + `/users/${id}`;
export const LoginAPI = () => baseUrl + `/users/login`;
export const LogoutAPI = () => baseUrl + `/users/logout`;
export const UpdateLoggedInUserAPI = () => baseUrl + `/users/me`;
export const ForgotPasswordAPI = () =>
  baseUrl + `/users/me/reset-password-request`;
export const ResetPasswordAPI = (id) =>
  baseUrl + `/users/me/reset-password?id=${id}`;

//Employee
// export const CreateEmployeeAPI = () => baseUrl + `/employee/register`;
// export const GetAllEmployeeAPI = () => baseUrl + `/employee`;
// export const UpdateEmployeeAPI = (id) => baseUrl + `/employee/${id}`;
// export const DeleteEmployeeAPI = (id) => baseUrl + `/employee/${id}`;
// export const GetEmployeeAPI = (id) => baseUrl + `/employee/${id}`;
// export const EmployeeLoginAPI = (id) => baseUrl + `/employee/login`;
// export const UpdateLoggedInEmployeeAPI = () => baseUrl + `/employee/me`;
// export const LogoutEmployeeAPI = () => baseUrl + `/employee/logout`;
// export const EmployeeRegisterAdminAPI = () =>
//   baseUrl + `/employee/register/admin`;

export const GetAllProdLogAPI = () => baseUrl + "/productLogs";

//Account

export const GetAllAccountsAPI = (entity) =>
  baseUrl + `/accounts?entity=${entity}`;
export const CreateAccountAPI = () => baseUrl + `/accounts`;
export const UpdateAccountAPI = (id) => baseUrl + `/accounts/${id}`;
export const GetAccountAPI = (id) => baseUrl + `/accounts/${id}`;
export const DeleteAccountAPI = (id) => baseUrl + `/accounts/${id}`;
export const RoundOffAPI = (id) => baseUrl + `/accounts/round-off/${id}`;
export const AccountVerificationAPI = (id) =>
  baseUrl + `/accounts/account-verification/${id}`;
//Purchase
export const GetAllPurchasesAPI = (entity) => baseUrl + `/purchases`;
export const GetPartyPurchasesAPI = (id) => baseUrl + `/purchases/party/${id}`;
export const CreatePurchaseAPI = () => baseUrl + `/purchases`;
export const UpdatePurchaseAPI = (id) => baseUrl + `/purchases/${id}`;
export const GetPurchaseAPI = (id) => baseUrl + `/purchases/${id}`;
export const DeletePurchaseAPI = (id) => baseUrl + `/purchases/${id}`;

//Product Master
export const GetAllProductMastersAPI = (entity) =>
  baseUrl + `/productMasters?entity=${entity}`;
export const CreateProductMasterAPI = () => baseUrl + `/productMasters`;
export const UpdateProductMasterAPI = (id) => baseUrl + `/productMasters/${id}`;
export const GetProductMasterAPI = (id) => baseUrl + `/productMasters/${id}`;
export const DeleteProductMasterAPI = (id) => baseUrl + `/productMasters/${id}`;
export const DeleteAllProductMasterAPI = (entity) =>
  baseUrl + `/productMasters/delete-all?entity=${entity}`;

//Fitting Master
export const GetAllFittingMastersAPI = (entity) =>
  baseUrl + `/fittingMasters?entity=${entity}`;
export const CreateFittingMasterAPI = () => baseUrl + `/fittingMasters`;
export const UpdateFittingMasterAPI = (id) => baseUrl + `/fittingMasters/${id}`;
export const GetFittingMasterAPI = (id) => baseUrl + `/fittingMasters/${id}`;
export const DeleteFittingMasterAPI = (id) => baseUrl + `/fittingMasters/${id}`;
export const DeleteAllFittingMasterAPI = (entity) =>
  baseUrl + `/fittingMasters/delete-all?entity=${entity}`;

//Ready Master
export const GetAllReadyMastersAPI = (entity) =>
  baseUrl + `/readyMasters?entity=${entity}`;
export const GetAllReadyMastersCodesAPI = (entity, productCode, side) =>
  baseUrl +
  `/readyMasters/codes?entity=${entity}&productCode=${productCode}&side=${side}`;
export const CreateBulkReadyMasterAPI = () =>
  baseUrl + `/readyMasters/bulk-upload`;
export const CreateReadyMasterAPI = () => baseUrl + `/readyMasters`;
export const UpdateReadyMasterAPI = (id) => baseUrl + `/readyMasters/${id}`;
export const GetReadyMasterAPI = (id) => baseUrl + `/readyMasters/${id}`;
export const DeleteReadyMasterAPI = (id) => baseUrl + `/readyMasters/${id}`;
export const DeleteAllReadyMasterAPI = (entity) =>
  baseUrl + `/readyMasters/delete-all?entity=${entity}`;

// Ready Master Range
export const GetBulkReadyMasterRangeAPI = (entity) =>
  baseUrl + `/readyMasterRange?entity=${entity}`;
export const CreateBulkReadyMasterRangeAPI = () =>
  baseUrl + `/readyMasterRange`;
export const GetStockReportAPI = () =>
  baseUrl + `/readyMasterRange/getStockReport`;
export const UpdateBulkReadyMasterRangeAPI = () =>
  baseUrl + `/readyMasterRange/`;
export const EditImageUploadAPI = () =>
  baseUrl + `/readyMasterRange/imageUpload`;
export const DeleteReadyMasterRangeAPI = (id) =>
  baseUrl + `/readyMasterRange/${id}`;
export const GetReadyMasterRangeProducts = (
  entity,
  startSph,
  endSph,
  startCyl,
  endCyl,
  startAddition,
  endAddition,
  axis,
  color,
  productName
) =>
  baseUrl +
  `/readyMasterRange/readyMasterRangeProducts?entity=${entity}&startSph=${startSph}&endSph=${endSph}&startCyl=${startCyl}&endCyl=${endCyl}&startAddition=${startAddition}&endAddition=${endAddition}&axis=${axis}&color=${color}&productName=${productName}`;

//Order
export const GetAllOrdersAPI = (entity) => baseUrl + `/orders?entity=${entity}`;
export const CreateOrderAPI = () => baseUrl + `/orders`;
export const CreateBulkOrderAPI = () => baseUrl + `/orders/bulk-order`;
export const UpdateOrderAPI = (id) => baseUrl + `/orders/${id}`;
export const UpdateMultipleOrders = () =>
  baseUrl + `/orders/updateMultipleOrders`;
export const GetOrderAPI = (id) => baseUrl + `/orders/${id}`;
export const DeleteOrderAPI = (id) => baseUrl + `/orders/${id}`;
export const BulkPrintOrdersAPI = () => baseUrl + `/orders/bulk-print`;
export const SendEmailAPI = () => baseUrl + `/orders/send-email`;

//Challan
export const GetAllChallansAPI = (entity) =>
  baseUrl + `/challans?entity=${entity}`;
export const GetAllCreditsAPI = (entity) =>
  baseUrl + `/credits?entity=${entity}`;
export const GetAllDebitsAPI = (entity) => baseUrl + `/debits?entity=${entity}`;
export const CreateChallanAPI = () => baseUrl + `/challans`;
export const CreateCreditNoteAPI = () => baseUrl + `/credits`;
export const UpdateCreditAPI = (id) => baseUrl + `/credits/${id}`;
export const UpdateDebitAPI = (id) => baseUrl + `/debits/${id}`;
export const DeleteDebitAPI = (id) => baseUrl + `/debits/${id}`;
export const DeleteCreditAPI = (id) => baseUrl + `/credits/${id}`;
export const CreateDebitNoteAPI = () => baseUrl + `/debits`;
export const UpdateChallanBillStatusAPI = (id) =>
  baseUrl + `/challans/update-challan-billed-status/${id}`;

export const CheckQuantity = baseUrl + `/challans/checkQuantity`;
export const UpdateChallanAPI = (id) => baseUrl + `/challans/${id}`;
export const UpdateChallanBilledStatusAPI = (id) => baseUrl + `/challans/update-challan-billed-status/${id}`;
export const GetChallanAPI = (id) => baseUrl + `/challans/${id}`;
export const DeleteChallanAPI = (id) => baseUrl + `/challans/delete/${id}`;
export const RoundOffChallansApi = () => baseUrl + `/challans/roundoff`;

//Bill
export const GetAllBillsAPI = (entity) => baseUrl + `/bills?entity=${entity}`;
export const GetAllCreditBillsAPI = (entity) =>
  baseUrl + `/credit-bills?entity=${entity}`;
export const GetAllDebitBillsAPI = (entity) =>
  baseUrl + `/debit-bills?entity=${entity}`;
export const CreateBillAPI = () => baseUrl + `/bills`;
export const CreateCreditBillAPI = () => baseUrl + `/credit-bills`;
export const CreateDebitBillAPI = () => baseUrl + `/debit-bills`;
export const UpdateBillAPI = (id) => baseUrl + `/bills/${id}`;
export const UpdateCreditBillAPI = (id) => baseUrl + `/credit-bills/${id}`;
export const UpdateDebitBillAPI = (id) => baseUrl + `/debit-bills/${id}`;
export const GetBillAPI = (id) => baseUrl + `/bills/${id}`;
export const GetCreditBillAPI = (id) => baseUrl + `/credit-bills/${id}`;
export const GetDebitBillAPI = (id) => baseUrl + `/debit-bills/${id}`;
export const DeleteBillAPI = (id) => baseUrl + `/bills/${id}`;
export const DeleteCreditBillAPI = (id) => baseUrl + `/credit-bills/${id}`;
export const DeleteDebitBillAPI = (id) => baseUrl + `/debit-bills/${id}`;

//Ledger
export const GetAllLedgersAPI = (entity) =>
  baseUrl + `/ledgers?entity=${entity}`;
export const CreateLedgerAPI = () => baseUrl + `/ledgers`;
export const UpdateLedgerAPI = (id) => baseUrl + `/ledgers/${id}`;
export const GetLedgerAPI = (id) => baseUrl + `/ledgers/${id}`;
export const DeleteLedgerAPI = (id) => baseUrl + `/ledgers/${id}`;

//Bank
export const GetAllBanksAPI = (entity) => baseUrl + `/banks?entity=${entity}`;
export const CreateBankAPI = () => baseUrl + `/banks`;
export const UpdateBankAPI = (id) => baseUrl + `/banks/${id}`;
export const GetBankAPI = (id) => baseUrl + `/banks/${id}`;
export const DeleteBankAPI = (id) => baseUrl + `/banks/${id}`;

//Labs
export const GetAllLabsAPI = (entity) => baseUrl + `/lab?entity=${entity}`;
export const CreateLabAPI = () => baseUrl + `/lab`;
export const DeleteLabAPI = (id) => baseUrl + `/lab/${id}`;
export const EditLabAPI = (id) => baseUrl + `/lab/${id}`;

//Bank
export const GetAllPrintAdsAPI = (entity) =>
  baseUrl + `/printAds?entity=${entity}`;
export const CreatePrintAdAPI = () => baseUrl + `/printAds`;
export const UpdatePrintAdAPI = (id) => baseUrl + `/printAds/${id}`;
export const GetPrintAdAPI = (id) => baseUrl + `/printAds/${id}`;
export const DeletePrintAdAPI = (id) => baseUrl + `/printAds/${id}`;

//Payment
export const GetAllPaymentsAPI = (entity) =>
  baseUrl + `/payments?entity=${entity}`;
export const CreatePaymentAPI = () => baseUrl + `/payments`;
export const UpdatePaymentAPI = (id) => baseUrl + `/payments/${id}`;
export const GetPaymentAPI = (id) => baseUrl + `/payments/${id}`;
export const DeletePaymentAPI = (id) => baseUrl + `/payments/${id}`;

// Brands
export const GetAllBrandsAPI = (entity) =>
  baseUrl + `/brandMasters?entity=${entity}`;
export const CreateBrandsAPI = () => baseUrl + "/brandMasters";
export const UpdateBrandsAPI = (id) => baseUrl + `/brandMasters/${id}`;
export const GetBrandsAPI = (id) => baseUrl + `/brandMasters/${id}`;
export const DeleteBrandsAPI = (id) => baseUrl + `/brandMasters/${id}`;

// Category
export const GetAllCategorysAPI = (entity) =>
  baseUrl + `/categoryMasters?entity=${entity}`;
export const CreateCategorysAPI = () => baseUrl + "/categoryMasters";
export const UpdateCategorysAPI = (id) => baseUrl + `/categoryMasters/${id}`;
export const GetCategorysAPI = (id) => baseUrl + `/categoryMasters/${id}`;
export const DeleteCategorysAPI = (id) => baseUrl + `/categoryMasters/${id}`;

// Offer
export const GetAllOffersAPI = (entity) =>
  baseUrl + `/offersMasters?entity=${entity}`;
export const CreateOffersAPI = () => baseUrl + "/offersMasters";
export const UpdateOffersAPI = (id) => baseUrl + `/offersMasters/${id}`;
export const GetOffersAPI = (id) => baseUrl + `/offersMasters/${id}`;
export const DeleteOffersAPI = (id) => baseUrl + `/offersMasters/${id}`;

// Trending
export const GetAllTrendingAPI = (entity) =>
  baseUrl + `/trendingMasters?entity=${entity}`;
export const CreateTrendingAPI = () => baseUrl + "/trendingMasters";
export const UpdateTrendingAPI = (id) => baseUrl + `/trendingMasters/${id}`;
export const GetTrendingAPI = (id) => baseUrl + `/trendingMasters/${id}`;
export const DeleteTrendingAPI = (id) => baseUrl + `/trendingMasters/${id}`;

// Brochure
export const GetAllBrochuresAPI = (entity) =>
  baseUrl + `/brochureMasters?entity=${entity}`;
export const CreateBrochuresAPI = () => baseUrl + "/brochureMasters";
export const UpdateBrochuresAPI = (id) => baseUrl + `/brochureMasters/${id}`;
export const GetBrochuresAPI = (id) => baseUrl + `/brochureMasters/${id}`;
export const DeleteBrochuresAPI = (id) => baseUrl + `/brochureMasters/${id}`;

// Prcing List
export const GetAllPricingListAPI = (entity) =>
  baseUrl + `/pricingListMasters?entity=${entity}`;
export const CreatePricingListAPI = () => baseUrl + "/pricingListMasters";
export const UpdatePricingListAPI = (id) =>
  baseUrl + `/pricingListMasters/${id}`;
export const GetPricingListAPI = (id) => baseUrl + `/pricingListMasters/${id}`;
export const DeletePricingListAPI = (id) =>
  baseUrl + `/pricingListMasters/${id}`;

// HomePage
export const GetAllHomePageAPI = (entity) =>
  baseUrl + `/HomePageMasters?entity=${entity}`;
export const CreateHomePageAPI = () => baseUrl + "/HomePageMasters";
export const UpdateHomePageAPI = (id) => baseUrl + `/HomePageMasters/${id}`;
export const GetHomePageAPI = (id) => baseUrl + `/HomePageMasters/${id}`;
export const DeleteHomePageAPI = (id) => baseUrl + `/HomePageMasters/${id}`;

// Entity
export const GetAllEntityAPI = () => baseUrl + "/entity";
export const CreateEntityAPI = () => baseUrl + "/entity";
export const UpdateEntityAPI = (id) => baseUrl + `/entity/${id}`;
export const GetEntityAPI = (id) => baseUrl + `/entity/${id}`;
export const DeleteEntityAPI = (id) => baseUrl + `/entity/${id}`;

// Ledger Request
export const GetAllLedgerRequestsAPI = (entity) =>
  baseUrl + `/ledgerRequest?entity=${entity}`;
export const UpdateLedgerRequestAPI = (id) => baseUrl + `/ledgerRequest/${id}`;
export const DeleteLedgerRequestAPI = (id) => baseUrl + `/ledgerRequest/${id}`;
