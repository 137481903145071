import axios from "axios";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import React, { useState, useEffect } from "react";
import {
  Dialog,
  Box,
  Slide,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Fab,
  TextField,
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button.js";
import { CreateOffersAPI, GetBulkReadyMasterRangeAPI } from "../../config/api";
import { useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { toast } from "react-toastify"

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const columns = [
  { id: "offerName", label: "Offer Name*" },

  { id: "selectedReadyMasterProduct", label: "Product" },
  { id: "productPrice", label: "Product Rate" },

  { id: "offerAmount", label: "Offer Amount" },
  { id: "action", label: "Action" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OfferMastersAdd(props) {
  const { open, close } = props;
  const classes = useStyles();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);

  const [offerName, setOfferName] = useState("");
  const [offerPercent, setOfferPercent] = useState("");
  const [offerAmount, setOfferAmount] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [selectedOfferType, setSelectedOfferType] = useState("");

  const [product, setProduct] = useState([]);
  const [readyMasterProduct, setReadyMasterProduct] = useState([]);
  const [selectedReadyMasterProduct, setSelectedReadyMasterProduct] =
    useState("");
  useEffect(() => {
    _getBulkReadyMasterRangeAPI();
  }, []);

  useEffect(() => {
    if (
      (offerAmount === "" || offerPercent === "") &&
      selectedReadyMasterProduct !== ""
    ) {
      setProductPrice(selectedReadyMasterProduct.rate);
    }

    if (offerAmount !== "" && selectedReadyMasterProduct !== "") {
      let value = selectedReadyMasterProduct.rate - offerAmount;
      setProductPrice(value);
    }

    if (offerPercent !== "" && selectedReadyMasterProduct !== "") {
      let offerAmount = (selectedReadyMasterProduct.rate * offerPercent) / 100;
      let value = selectedReadyMasterProduct.rate - offerAmount;
      setProductPrice(value);
    }
  }, [offerPercent, offerAmount]);

  async function _getBulkReadyMasterRangeAPI() {
    try {
      let res = await axios.get(GetBulkReadyMasterRangeAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });


      let products = _.filter(res.data.readyMasterRange, (rmr) => {
        if (rmr?.offer) {
          return false;
        }
        return true;
      });

      setReadyMasterProduct(products);
    } catch (e) {
      // console.log(e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _onSave() {
    try {
      if (product.length === 0) {
        toast.error("Please Add a offer", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      let res = await axios.post(
        CreateOffersAPI(),
        {
          product,
          entity: userInfo.entity,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }

      );

      close();
      toast.success('Successfully Added!', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (e) {
      // console.log(e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Add Offer</h4>
                <Button color={"transparent"} onClick={() => _onSave()}>
                  Save
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={3} md={3}>
                  <CustomInput
                    labelText="Offer Name*"
                    id="OfferName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: offerName,
                      onChange: (event) => setOfferName(event.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={6} sm={3} md={3} style={{ marginTop: 26 }}>
                  <Autocomplete
                    id="product"
                    className={classes.formControl}
                    options={readyMasterProduct}
                    value={selectedReadyMasterProduct}
                    onChange={(event, value) => {
                      setSelectedReadyMasterProduct(value);
                      let product = _.filter(readyMasterProduct, {
                        productName: value.productName,
                      });

                      if (product.length > 0) {
                        setProductPrice(product[0].rate);
                      }
                    }}
                    getOptionLabel={(option) => option.productName}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Product*" />
                    )}
                  />
                </GridItem>

                <GridItem xs={6} sm={3} md={3} style={{ marginTop: 26 }}>
                  <Autocomplete
                    id="offerType"
                    className={classes.formControl}
                    options={["Offer Amount", "Offer Percent"]}
                    value={selectedOfferType}
                    onChange={(event, value) => {
                      setSelectedOfferType(value);
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Option*" />
                    )}
                  />
                </GridItem>

                {selectedOfferType === "Offer Percent" && (
                  <GridItem xs={6} sm={2} md={2}>
                    <CustomInput
                      labelText="Offer Discount Percent"
                      id="OfferDiscountPercent"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: offerPercent,
                        onChange: (event) =>
                          setOfferPercent(event.target.value),
                      }}
                    />
                  </GridItem>
                )}
                {selectedOfferType === "Offer Amount" && (
                  <GridItem xs={6} sm={2} md={2}>
                    <CustomInput
                      labelText="Offer Discount Amount"
                      id="OfferDiscountAmount"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: offerAmount,
                        onChange: (event) => setOfferAmount(event.target.value),
                      }}
                    />
                  </GridItem>
                )}

                {selectedReadyMasterProduct && (
                  <GridItem xs={6} sm={2} md={2}>
                    <CustomInput
                      labelText="Product price"
                      id="productPrice"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: true,
                        value: selectedReadyMasterProduct.rate,
                      }}
                    />
                  </GridItem>
                )}

                {selectedReadyMasterProduct && (
                  <GridItem xs={6} sm={2} md={2}>
                    <CustomInput
                      labelText="Final Product price"
                      id="productPrice"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: true,
                        value: productPrice,
                      }}
                    />
                  </GridItem>
                )}

                <GridItem xs={2} sm={2} md={2}>
                  <Box justifyContent={"center"} marginTop={4}>
                    <Fab
                      size={"small"}
                      color="primary"
                      aria-label="add"
                      onClick={() => {
                        if (selectedReadyMasterProduct.length === 0 || offerName === "") {
                          toast.error("Please fill all the fields", {
                            position: "top-center",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                          return;
                        }

                        let data = {
                          offerName,
                          selectedReadyMasterProduct,
                          offerAmount: productPrice,
                          productPrice: selectedReadyMasterProduct.rate,
                        };
                        let copy = _.clone(product);
                        copy.push(data);
                        setProduct(copy);
                        setSelectedOfferType("");
                        setOfferName("");
                        setOfferAmount("");
                        setProductPrice("");
                        setSelectedReadyMasterProduct("");

                        let updatedProducts = _.filter(
                          readyMasterProduct,
                          (mrm) => {
                            if (
                              mrm.productName ===
                              selectedReadyMasterProduct.productName
                            ) {
                              return false;
                            }
                            return true;
                          }
                        );

                        setReadyMasterProduct(updatedProducts);
                      }}
                    >
                      <AddIcon />
                    </Fab>
                  </Box>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  {_.isEmpty(product) ? null : (
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {product.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                let value = row[column.id];

                                if (column.id === "action") {
                                  return (
                                    <TableCell align={"center"}>
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                      >
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() => {
                                            let copy = _.clone(product);
                                            copy.splice(index, 1);
                                            setProduct(copy);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </TableCell>
                                  );
                                }

                                if (
                                  column.id === "selectedReadyMasterProduct"
                                ) {
                                  return (
                                    <TableCell
                                      key={column.id}
                                      align={column.align}
                                    >
                                      {value.productName}
                                    </TableCell>
                                  );
                                }
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
