import React, { useState, useEffect } from "react";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import {
  makeStyles,
  IconButton,
  InputAdornment,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  Checkbox,
  Box,
  Switch,
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import People from "@material-ui/icons/People";

// import TrendingMastersView from "./TrendingMastersView";
// import TrendingMastersAdd from "./TrendingMastersAdd";
// import TrendingMastersEdit from "./TrendingMastersEdit";
import {
  GetAllReadyMastersAPI,
  GetBulkReadyMasterRangeAPI,
  DeleteReadyMasterRangeAPI,
} from "../../config/api";
import { ConfirmationDialog } from "../../components/common";
import { useSelector } from "react-redux";
import ReadyMasterRangeAdd from "./ReadyMasterRangeAdd";
import ReadyMasterRangeView from "./ReadyMasterRangeView";
import ReadyMasterRangeEdit from "./ReadyMasterRangeEdit";
import { toast } from "react-toastify";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const columns = [
  { id: "productName", label: "Product" },
  { id: "startSph", label: "Start Sph" },
  { id: "endSph", label: "End Sph" },
  { id: "startCyl", label: "Start Cyl" },
  { id: "endCyl", label: "End Cyl" },
  { id: "startAddition", label: "Start Addn" },
  { id: "endAddition", label: "End Addn" },
  {
    id: "axis",
    label: "Axis",
    format: (value) =>
      value.map((v, index) => (index === value.length - 1 ? v : v + ",")),
  },
  { id: "index", label: "Index" },
  { id: "side", label: "Side" },
  { id: "rate", label: "Rate" },
  { id: "type", label: "Type" },
  { id: "brand", label: "Brand" },
  { id: "category", label: "Category" },
  { id: "action", label: "Action" },
];

export default function ReadyMasterRangeList(props) {
  const classes = useStyles();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [ready, setReady] = useState([]);
  const [total, setTotal] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [readyMasterRange, setReadyMasterRange] = useState([]);
  console.log("121", readyMasterRange)
  const [tota, setTota] = useState(0);
  const [openMultipleProductAddDialog, setOpenMultipleProductAddDialog] =
    useState(false);
  useEffect(() => {
    _getBulkReadyMasterRangeAPI(page, rowsPerPage);
  }, [openMultipleProductAddDialog, openEditDialog, page, rowsPerPage]);

  useEffect(() => {
    _getReady();
  }, [page, rowsPerPage]);

  async function searchData(term) {
    setSearchTerm(term);
    let res = await axios.get(
      GetBulkReadyMasterRangeAPI(userInfo.entity) +
      `&limit=${rowsPerPage}&skip=${page * rowsPerPage}&productName=${term}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    console.log("helo", res.data.readyMasterRange)
    setReadyMasterRange(res.data.readyMasterRange);
    setTota(res.data.total)
  }

  async function _getBulkReadyMasterRangeAPI(page, rowsPerPage) {
    try {
      let res = await axios.get(GetBulkReadyMasterRangeAPI(userInfo.entity) +
        `&limit=${rowsPerPage}&skip=${page * rowsPerPage}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("aaya", res.data)
      setReadyMasterRange(res.data.readyMasterRange);
      setTota(res.data.total)
      // window.location.refresh()
    } catch (err) {
      // console.log(err);
      toast.error(("Something wenthhhhh wrong, Please Contact Admin"), {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  async function _deleteItem(item) {
    try {
      let res = await axios.delete(DeleteReadyMasterRangeAPI(item._id), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      window.location.reload();
      // _getReady()
    } catch (e) {
      // console.log("err:", e);
      toast.error(("Something went wrong, Please Contact Admin"), {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  async function _getReady() {
    console.log("202")
    try {
      let res = await axios.get(
        GetAllReadyMastersAPI(userInfo.entity) +
        `&limit=${rowsPerPage}&skip=${page * rowsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setReady(res.data.readyMasters);
      setTotal(res.data.total);
    } catch (err) {
      // console.log(err);
      toast.error(("Something went wrong, Please Contact Admin"), {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  const handleOpenConfirmationModal = (object) => {
    setOpenConfirmationModal(object);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function closeMultipleProductAddDialog() {
    setOpenMultipleProductAddDialog(false);
  }

  function closeViewDialog() {
    setOpenViewDialog(null);
  }

  function closeEditDialog() {
    setOpenEditDialog(null);
  }

  let data = readyMasterRange;
  // if (searchTerm !== "") {
  //   data = _.filter(data, (i) => {
  //     return i.productName && i.productName.toLowerCase().includes(searchTerm.toLowerCase());
  //   });
  //   console.log("filter k vbaad", data);
  //   if (data.length === 0) {
  //     // searchChallan(searchTerm)
  //     console.log("nai hai")
  //   }
  // }

  function handleSearch() {
    searchData(search);
  }

  return (
    <GridContainer style={{ overflow: 'auto' }}>
      <GridItem
      // xs={12} sm={12} md={12}
      >
        <Card>
          <CardHeader color="primary">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4 className={classes.cardTitleWhite}>Ready Master Range</h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <CustomInput
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    style: {
                      color: "primary",
                      marginTop: 0,
                    },
                    placeholder: "Search",
                    value: search,
                    // onChange: (e) => searchData(e.target.value),
                    // onChange: (e) => setSearch(e.target.value),
                    onChange: (e) => {
                      setSearch(e.target.value);
                      if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
                        _getReady();
                      }

                    },
                    // onChange: (e) => handleInputChange(e),
                    onKeyDown: (e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        handleSearch();
                      }
                    },
                    // onSubmit: (e) => searchData(e.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton color={"secondary"} onClick={(e) => {
                          // setSearchTerm(e.target.value),
                          // handleKeyPress(e)
                          // handleKeyPress()
                          handleSearch()
                        }}>
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <IconButton
                  aria-label="add"
                  style={{ color: "white" }}
                  onClick={() => setOpenMultipleProductAddDialog(true)}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </div>
          </CardHeader>

          <CardBody>
            <Table stickyHeader aria-label="sticky table" style={{ width: '50%' }} xs={12} sm={12} md={12}>
              <TableHead>
                <TableRow>
                  {/* <Checkbox
                    style={{
                      color: "#512da8",
                    }}
                    onChange={(e) => {
                      let c = [];
                      if (e.target.checked) {
                        data.map((i) => {
                          c.push(i._id);
                        });
                      }
                      setSelectedRows(c);
                    }}
                    checked={
                      selectedRows.length > 0 &&
                      data.length === selectedRows.length
                    }
                    inputProps={{ "aria-labelledby": -1 }}
                  /> */}
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={"center"}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            style={{
                              color: "#512da8",
                            }}
                            onChange={() => {
                              let copy = _.clone(selectedRows);
                              if (_.includes(copy, row._id)) {
                                let index = _.indexOf(copy, row._id);
                                copy.splice(index, 1);
                              } else {
                                copy.push(row._id);
                              }
                              setSelectedRows(copy);
                            }}
                            checked={_.includes(selectedRows, row._id)}
                            inputProps={{ "aria-labelledby": row._id }}
                          />
                        </TableCell> */}
                        {columns.map((column) => {
                          const value = row[column.id];

                          if (column.id === "action") {
                            return (
                              <TableCell align={"center"}>
                                <Box display={"flex"} justifyContent={"center"}>
                                  <IconButton
                                    onClick={() =>
                                      setOpenViewDialog({
                                        open: true,
                                        item: row,
                                      })
                                    }
                                    aria-label="view"
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() =>
                                      setOpenEditDialog({
                                        open: true,
                                        item: row,
                                      })
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      handleOpenConfirmationModal({
                                        title: "Delete Record",
                                        msg: "Deleting this will result in total deletion of the product from this and all other related modules where this product has been linked. Do you want to delete this record?",
                                        onPress: () => _deleteItem(row),
                                      })
                                    }
                                    aria-label="delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            );
                          }
                          if (column.id === "status") {
                            return (
                              <TableCell align={'center'}>
                                <Switch defaultChecked={value === "Active" ? true : false} color="purple" />
                              </TableCell>
                            )
                          }

                          if (
                            column.id === "brand" ||
                            column.id === "category"
                          ) {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {value?.name}
                              </TableCell>
                            );
                          }

                          return (
                            <TableCell key={column.id} align={"center"}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={tota}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      </GridItem>
      <ReadyMasterRangeAdd
        fetchData={_getReady}
        open={openMultipleProductAddDialog}
        close={closeMultipleProductAddDialog}
      />
      {openViewDialog ? (
        <ReadyMasterRangeView
          open={openViewDialog.open}
          close={closeViewDialog}
          item={openViewDialog.item}
        />
      ) : null}

      {openEditDialog ? (
        <ReadyMasterRangeEdit
          open={openEditDialog.open}
          close={closeEditDialog}
          item={openEditDialog.item}
          fetchData={_getReady}
        />
      ) : null}

      {openConfirmationModal ? (
        <ConfirmationDialog
          open={openConfirmationModal}
          onClose={handleCloseConfirmationModal}
          onConfirm={openConfirmationModal.onPress}
          title={"Delete Record"}
          msg={
            "Deleting this will result in total deletion of the product from this and all other related modules where this product has been linked. Do you want to delete this record?"
          }
        />
      ) : null}
    </GridContainer>
  );
}
