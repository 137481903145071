import React, { useState } from "react";
// @material-ui/core components
import {
  Dialog,
  makeStyles,
  useMediaQuery,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import axios from "axios";
import {
  CreateBankAPI,
  GetAccountAPI,
  GetAllLedgersAPI,
  GetAllPaymentsAPI,
  RoundOffAPI,
} from "../../config/api";
import DateUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CustomInput from "../../components/CustomInput/CustomInput";
import { ClearIcon } from "@mui/x-date-pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ledgerTemplate } from "../../assets/html/ledgerTemplate.js";
import { useSelector } from "react-redux";
import _ from "lodash";
import { dataURItoBlob, htmlTemplateToDataString } from "../../utils/index.js";
import moment from "moment";
import { toast } from "react-toastify";
import { within } from "@testing-library/react";

const styles = {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
const useStyles = makeStyles(styles);

export default function Ledger(props) {
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  let types = [
    {
      label: "Challan",
      value: "Challan",
    },
    {
      label: "Bill",
      value: "Bill",
    },
    // {
    //   label: "Purchase Challan",
    //   value: "PurchaseChallan",
    // },
    // {
    //   label: "Purchase Bill",
    //   value: "PurchaseBill",
    // },
    // {
    //   label: "Credit Note",
    //   value: "CreditNote",
    // },
    // {
    //   label: "Debit Note",
    //   value: "DebitNote",
    // },
  ];
  const {
    openLedgerSelectionDialog,
    setOpenLedgerSelectionDialog,
    setOpenLedgerDialog,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    ledgerType,
    setLedgerType,
    item,
    pay,
  } = props;
  console.log(">>>>SD",item, startDate, endDate);

  const [openingBalance, setOpeningBalance] = useState();
  console.log(">>>>openingBalance", openingBalance);

  // const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const authToken = useSelector((state) => state.userReducer.authToken);
  const userInfo = useSelector((state) => state.userReducer.userInfo);

  //   const [startDate, setStartDate] = useState(null);
  //   const [endDate, setEndDate] = useState(null);

  const [selectedDate, setSelectedDate] = useState([null, null]);

  const handleDateChange = (dateRange) => {
    setSelectedDate(dateRange);
  };

  async function _generateLedger(fd, backBalance, ongoingBalance, tempBalance) {
    console.log(fd, "<<<<fd");
    if (fd.length < 1) {
      toast.error("No records found to generate the ledger");
      return;
    }

    let htmlData;

    if(pay){

      htmlData = {
        ledgers: fd,
        ledgerType: "payment",
        startDate: startDate,
        endDate: endDate,
        clientName: item.bankName,
        pay: true,
        // closingBalance: tempBalance,
        // openingBalance: backBalance,
        // openingBalance: item.partyType == "purchase" ? -backBalance : backBalance,
      };

    }else{


    htmlData = {
      ledgers: fd,
      ledgerType: fd[0]?.transactionType ? fd[0]?.transactionType : "",
      startDate: startDate,
      endDate: endDate,
      clientName: item.name,
      closingBalance: tempBalance,
      openingBalance: backBalance,
      // openingBalance: item.partyType == "purchase" ? -backBalance : backBalance,
    };
  }


    let options = {
      orientation: "p",
      unit: "pt",
      format: [850, 1066],
      // format:'letter',
      putOnlyUsedFonts: true,
    };

    const htmlTemplate = ledgerTemplate(htmlData);

    await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
      const preBlob = dataURItoBlob(dataString);
      window.open(URL.createObjectURL(preBlob));
    });
  }

  async function _getLedgers(startDate, endDate) {
    try {
      if(pay){
        let bankName = item.bankName;
        let query = `&bankName=${bankName}`;

        let res = await axios.get(GetAllPaymentsAPI(userInfo.entity) + query, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
      console.log(res.data, "<<<<res")


        const filteredRecords = res.data.payments.filter((record) =>
          moment(record.createdAt).isBetween(startDate, endDate, null, '[]')
      );
      console.log(filteredRecords, "<<<<res")

      _generateLedger(filteredRecords,0,0,0);



      }else{

      // 1. Get all ledgers of the party
      // 2. Filter in 2 ways
      //   - All records before start date - [openingBalance]
      //   - All records within date Range - [og data]
      // 3. Sum of amount for All records before start date - [openingBalance]
      // 4. List and Sum of amount for All records within date Range - [Closing Balance]
      // 5. Show the list and add the point 3 amount in the final balance - [closing balance = 3+4]

      let query;

      if (ledgerType === "Challan") {
        query = `&accountId=${item._id}&transactionType=${"challan"}&transactionType=${"credit"}&transactionType=${"debit"}`;
      } else if (ledgerType === "Bill") {
        query = `&accountId=${item._id}&transactionType=${"bill"}&transactionType=${"credit"}&transactionType=${"debit"}`;
      }

      let res = await axios.get(GetAllLedgersAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      const filteredRecords = res.data.ledgers.filter((record) =>
        moment(record.date).isBefore(startDate)
      );

      let currentOpeningChallanBalance = item.openingChallanBalance;
      let currentOpeningBillBalance = item.openingBillBalance;
      
      let backBalance = filteredRecords.reduce(
        (sum, record) => {
          if(record.type == 'credit' && record.activityType == 'sale' && record.transactionType == 'challan'  ) {
            return sum - Number(record.amount)
          } 
          else if(record.type == 'credit' && record.activityType == 'purchase'  && record.transactionType == 'challan' ) {
            return sum + Number(record.amount)
          } 
          else if(record.type == 'debit' && record.activityType == 'sale'  && record.transactionType == 'challan' ) {
            return sum + Number(record.amount)
          }else if(record.type == 'debit' && record.activityType == 'purchase'  && record.transactionType == 'challan' ) {
            return sum - Number(record.amount)
          }else if(record.type == 'credit' && record.activityType == 'sale' && record.transactionType == 'bill'  ) {
            return sum - Number(record.amount)
          } 
          else if(record.type == 'credit' && record.activityType == 'purchase'  && record.transactionType == 'bill' ) {
            return sum + Number(record.amount)
          } 
          else if(record.type == 'debit' && record.activityType == 'sale'  && record.transactionType == 'bill' ) {
            return sum + Number(record.amount)
          }else if(record.type == 'debit' && record.activityType == 'purchase'  && record.transactionType == 'bill' ) {
            return sum - Number(record.amount)
          }
        },
        0
      );

      if(ledgerType === "Challan"){
        backBalance = backBalance + currentOpeningChallanBalance;
      }else if(ledgerType === "Bill"){
        backBalance = backBalance + currentOpeningBillBalance;
      }

      const filteredRecords2 = res.data.ledgers.filter((record) =>
        moment(record.date).isBetween(startDate, endDate, null, '[]')
      );

      const ongoingBalance = filteredRecords2.reduce(
        (sum, record) => {
          if(record.type == 'credit' && record.activityType == 'sale' && record.transactionType == 'challan'  ) {
            return sum - Number(record.amount)
          } 
          else if(record.type == 'credit' && record.activityType == 'purchase'  && record.transactionType == 'challan' ) {
            return sum + Number(record.amount)
          } 
          else if(record.type == 'debit' && record.activityType == 'sale'  && record.transactionType == 'challan' ) {
            return sum + Number(record.amount)
          }else if(record.type == 'debit' && record.activityType == 'purchase'  && record.transactionType == 'challan' ) {
            return sum - Number(record.amount)
          }else if(record.type == 'credit' && record.activityType == 'sale' && record.transactionType == 'bill'  ) {
            return sum - Number(record.amount)
          } 
          else if(record.type == 'credit' && record.activityType == 'purchase'  && record.transactionType == 'bill' ) {
            return sum + Number(record.amount)
          } 
          else if(record.type == 'debit' && record.activityType == 'sale'  && record.transactionType == 'bill' ) {
            return sum + Number(record.amount)
          }else if(record.type == 'debit' && record.activityType == 'purchase'  && record.transactionType == 'bill' ) {
            return sum - Number(record.amount)
          }
        },
        0
      );

      let fd = _.sortBy(filteredRecords2, (obj) => obj.date);
      let tempBalance = Number(backBalance) + Number(ongoingBalance);
      
      console.log("res.data.ledgers", res.data.ledgers);
      console.log("tempBalance after", tempBalance);

      setOpeningBalance(tempBalance);
      _generateLedger(fd, backBalance, ongoingBalance, tempBalance);
    }
    } catch (err) {
      // toast.error(('Cannot fetch data'), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }
  return (
    <Dialog
      onClose={() => setOpenLedgerSelectionDialog(false)}
      aria-labelledby="simple-dialog-title"
      open={openLedgerSelectionDialog}
      fullScreen={fullScreen}
    >
      <GridContainer style={{ margin: "0px !important" }}>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Ledger</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                {!pay && (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={ledgerType}
                      label="Type"
                      onChange={(e) => {
                        setLedgerType(e.target.value);
                      }}
                    >
                      {types.map(({ label, value }) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                </GridItem>
                <MuiPickersUtilsProvider utils={DateUtils}>
                  <GridItem
                    xs={12}
                    style={{
                      marginTop: 20,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DatePicker
                      label="Start Date"
                      fullWidth
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      animateYearScrolling
                    />
                    <IconButton onClick={() => setStartDate(null)} edge="end">
                      <ClearIcon />
                    </IconButton>
                  </GridItem>
                  <GridItem
                    xs={12}
                    style={{
                      marginTop: 20,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DatePicker
                      label="End Date"
                      fullWidth
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      animateYearScrolling
                    />
                    <IconButton onClick={() => setEndDate(null)} edge="end">
                      <ClearIcon />
                    </IconButton>
                  </GridItem>
                </MuiPickersUtilsProvider>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ justifyContent: "center" }}>
              <Button
                type="button"
                color="primary"
                onClick={() => {
                  if (startDate && endDate && startDate < endDate) {
                    // Validate dates
                    _getLedgers(startDate, endDate);
                    // setOpenLedgerDialog(true);
                    // setOpenLedgerSelectionDialog(false);
                  } else {
                    alert("Please provide valid start and end dates.");
                  }
                }}
                disabled={!startDate || !endDate} // Disable button if either date is missing
              >
                Submit
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
