import React, { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import { Chip } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
// @material-ui/core components
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
// import Table from "../../components/Table/Table.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import PouchDB from "pouchdb";
import EditIcon from "@material-ui/icons/Edit";
import { ConfirmationDialog } from "../../components/common";
import axios from "axios";
import { GetAllOrdersAPI, UpdateOrderAPI } from "../../config/api";
import OrderView from "./OrderView";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CustomInput from "../../components/CustomInput/CustomInput";
import People from "@material-ui/icons/People";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Filter } from "@material-ui/icons";
import FilterListIcon from "@material-ui/icons/FilterList";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const columns = [
  { id: "partyName", label: "Party" },
  { id: "productName", label: "Product Name" },

  { id: "code", label: "Order Code" },
  { id: "orderType", label: "Order Type" },
  { id: "orderStatus", label: "Order Status" },
  {
    id: "createdAt",
    label: "Order Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
  { id: "action", label: "Action" },
];

let orderStatuses = ["Pending", "Order Received", "Completed"];

export default function OrderListStatus() {
  const classes = useStyles();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);
  const [orders, setOrders] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const [openViewDialog, setOpenViewDialog] = useState(null);
  const [openOrderStatusDialog, setOpenOrderStatusDialog] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [filter, setFilter] = useState("");
  const [showFilterDialog, setShowFilterDialog] = useState(false);

  const handleCloseFilterDialog = () => {
    setShowFilterDialog(false);
  };
  useEffect(() => {
    _getOrders();
  }, []);

  async function _getOrders() {
    try {
      let res = await axios.get(GetAllOrdersAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setOrders(res.data.orders);
      console.log("checking the data orders", res.data.orders);
    } catch (err) {
      // console.log("error:", err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _onUpdate(status) {
    try {
      if (!selectedItem) {
        return;
      }
      let obj = selectedItem;
      obj.orderStatus = status;
      let res = await axios.patch(UpdateOrderAPI(obj.code), obj, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setOpenOrderStatusDialog(false);
      setSelectedStatus(null);
      _getOrders();
      // window.location.reload();
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  const FilterDialog = (props) => {
    const { filter, setFilter, showFilterDialog, handleCloseFilterDialog } =
      props;

    const [selectedType, setSelectedType] = useState(
      filter?.type ? filter.type : null
    );

    const [status, setStatus] = useState(filter?.status ? filter.status : null);

    return (
      <Dialog
        maxWidth={"sm"}
        fullWidth
        onClose={handleCloseFilterDialog}
        aria-labelledby="filter"
        open={showFilterDialog}
      >
        <DialogTitle id="filter">Filter</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                id="tags-filled"
                options={["Fitting", "Prescription"]}
                value={selectedType}
                onChange={(event, value) => setSelectedType(value)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Type"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id="tags-filled"
                options={["Pending", "Order Received", "Completed"]}
                value={status}
                onChange={(event, value) => setStatus(value)}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Status"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setFilter(null);
              handleCloseFilterDialog();
            }}
            color="primary"
          >
            Clear
          </Button>
          <Button
            onClick={() => {
              let filter = {
                type: selectedType,
                status: status,
              };
              setFilter(filter);
              handleCloseFilterDialog();
            }}
            color="primary"
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  function OrderStatusDialog() {
    return (
      <Dialog
        onClose={() => setOpenOrderStatusDialog(false)}
        aria-labelledby="simple-dialog-title"
        open={openOrderStatusDialog}
      >
        <DialogTitle id="simple-dialog-title">Select Order Status</DialogTitle>
        <List>
          {orderStatuses.map((item) => (
            <ListItem
              button
              onClick={() => {
                setOpenOrderStatusDialog(false);
                _onUpdate(item);
              }}
              key={item}
            >
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  }
  const handleCloseConfirmationModal = () => {
    setSelectedItem(null);
    setOpenConfirmationModal(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function closeViewDialog() {
    setOpenViewDialog(null);
  }

  let data = orders;

  if (searchTerm !== "") {
    data = _.filter(data, (i) => {
      return i.code && i.code.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }

  if (filter) {
    let { type, status } = filter;

    if (type) {
      data = _.filter(data, (i) => {
        return i.orderType === type;
      });
    }
    if (status) {
      data = _.filter(data, (i) => {
        return i.orderStatus === status;
      });
    }
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4 className={classes.cardTitleWhite}>Orders</h4>
              <div style={{ display: "flex" }}>
                <CustomInput
                  id="search"
                  formControlProps={{
                    fullWidth: false,
                  }}
                  inputProps={{
                    style: {
                      marginTop: 0,
                      marginRight: 10,
                      color: "white",
                    },
                    placeholder: "Search",
                    value: searchTerm,
                    onChange: (e) => setSearchTerm(e.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <People />
                      </InputAdornment>
                    ),
                  }}
                />
                <IconButton
                  style={{ color: "white" }}
                  onClick={() => {
                    setShowFilterDialog(true);
                  }}
                >
                  <FilterListIcon />
                </IconButton>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={"center"}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          let value = row[column.id];
                          if (column.id === "partyName") {
                            value = row.party.accountName;
                          }
                          if (column.id === "productName") {
                            value = row?.orderDetail
                              ? row.orderDetail.productName
                              : "";
                          }
                          if (column.id === "action") {
                            return (
                              <TableCell align={"center"}>
                                <Box display={"flex"} justifyContent={"center"}>
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                      setSelectedItem(row);
                                      setOpenOrderStatusDialog(true);
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="view"
                                    onClick={() =>
                                      setOpenViewDialog({
                                        open: true,
                                        item: row,
                                      })
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={column.id} align={"center"}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      </GridItem>

      {openViewDialog ? (
        <OrderView
          open={openViewDialog.open}
          close={closeViewDialog}
          item={openViewDialog.item}
        />
      ) : null}
      <ConfirmationDialog
        open={openConfirmationModal}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => _onUpdate()}
        title={"Update Order Status"}
        msg={"Do you want Change the order status to Received?"}
      />
      {showFilterDialog && (
        <FilterDialog
          filter={filter}
          setFilter={setFilter}
          showFilterDialog={showFilterDialog}
          handleCloseFilterDialog={handleCloseFilterDialog}
        />
      )}
      <OrderStatusDialog />
    </GridContainer>
  );
}
