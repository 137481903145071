import React, { useEffect, useRef, useState } from "react";
import GridItem from "../../components/Grid/GridItem";
import Barcode from "react-barcode";
import { Button, Grid, Typography, Box } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";

const PurchaseBarcodes = (props) => {
  const componentRef = useRef();
  let { purchases } = props.location.state;
  let [barcodes, setBarcodes] = useState([]);
  let [barcodeQuantity, setBarcodeQuantity] = useState();

  console.log("purr", barcodes)

  useEffect(() => {
    let barcodes = [];
    let bcq = 0;
    purchases.map((i) => {
      i.products.map((o) => {
        barcodes.push(o);
        bcq = Number(o.quantity) * 2;
      });
    });
    setBarcodes(barcodes);
    setBarcodeQuantity(bcq)
  }, []);


  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: ` 
    @media print {  
        @page {
            size: 300px 94px;
            margin: 0mm;
            padding: 0mm;
            font-size: 14px;
        } 
    }`,
  });

  return (
    <div>
      <Button color="primary" onClick={handlePrint}>
        Print Barcodes
      </Button>
      <Grid ref={componentRef} container style={{ alignItems: "center" }}>
        {Array.from({ length: barcodeQuantity }, (_, index) => (
          <React.Fragment key={index}>
            {barcodes.map((i) => {
              return (
                <>
                  <GridItem xs={12} md={12} sm={12} style={{ width: 300 }}>

                    <Barcode value={i.productCode} height={24} width={2} fontSize={11} />

                    <GridItem xs={12} md={12} sm={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 300 }}>
                      <GridItem xs={1} sm={1} md={1} >
                        {/* <Typography style={{ fontSize: "8px" }}>SPH</Typography> */}
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2} >
                        <Typography style={{ fontSize: "8px" }}>SPH</Typography>
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2}>
                        <Typography style={{ fontSize: "8px" }}>CYL</Typography>
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2}>
                        <Typography style={{ fontSize: "8px" }}>AXIS</Typography>
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2}>
                        <Typography style={{ fontSize: "8px" }}>ADD</Typography>
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2}>
                        <Typography style={{ fontSize: "8px" }}>SIDE</Typography>
                      </GridItem>

                    </GridItem>
                    <GridItem xs={12} md={12} sm={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 300 }}>
                      <GridItem xs={1} sm={1} md={1} >
                        {/* <Typography style={{ fontSize: "8px" }}>SPH</Typography> */}
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2} >
                        <Typography style={{ fontSize: "8px" }}>{i.sph}</Typography>
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2}>
                        <Typography style={{ fontSize: "8px" }}>{i.cyl}</Typography>
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2}>
                        <Typography style={{ fontSize: "8px" }}>{i.axis}</Typography>
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2}>
                        <Typography style={{ fontSize: "8px" }}>{i.addition}</Typography>
                      </GridItem>
                      <GridItem xs={2} sm={2} md={2}>
                        <Typography style={{ fontSize: "8px" }}>{i.side}</Typography>
                      </GridItem>

                    </GridItem>
                    <Box xs={12} md={12} sm={12} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: 300 }}>
                      <Typography variant="bold" style={{ fontSize: "13px" }}><b>{i.productName}</b></Typography>
                    </Box>
                  </GridItem>
                </>
              );
            })}
          </React.Fragment>
        ))}
        <hr />

      </Grid>
    </div>
  );
};

export default PurchaseBarcodes;
