import axios from "axios";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Dialog, Box, Slide, makeStyles } from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button.js";
import { CreateBrochuresAPI } from "../../config/api";
import { useSelector } from "react-redux";
import { FilePicker } from "../../components/common/FilePicker";
import { toast } from "react-toastify";
const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BrochureMastersAdd(props) {
  const { open, close } = props;
  const classes = useStyles();
  let authToken = useSelector((state) => state.userReducer.authToken);
  let userInfo = useSelector((state) => state.userReducer.userInfo);

  const [brochureName, setBrochureName] = useState("");
  const [brochureImage, setBrochureImage] = useState([]);
  const [brochureFile, setBrochureFile] = useState([]);

  async function _onSave() {
    try {
      let formData = new FormData();
      formData.append("name", brochureName);
      formData.append("entity", userInfo.entity);
      if (!_.isEmpty(brochureImage)) {
        formData.append("brochureImage", brochureImage[0].path);
      }
      if (!_.isEmpty(brochureFile)) {
        formData.append("brochureFile", brochureFile[0].path);
      }
      if (brochureName === "" || brochureFile.length === 0 || brochureImage.length === 0) {
        toast.error("Please fill all the required fields", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      let res = await axios.post(CreateBrochuresAPI(), formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success('Successfully Added!', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log("Checking file")
      close();
    } catch (e) {
      // console.log(e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  function _setBrochureImage(files) {
    setBrochureImage(files);
  }

  function _setBrochureFile(files) {
    setBrochureFile(files);
  }


  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Add Brochure</h4>
                <Button color={"transparent"} onClick={() => _onSave()}>
                  Save
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Brochure Name*"
                    id="brochureName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: brochureName,
                      onChange: (event) => setBrochureName(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <FilePicker
                    files={brochureImage}
                    buttonLabel={"Upload Brochure Photo*"}
                    id={"Contract Photo"}
                    maxFiles={1}
                    setNewFiles={_setBrochureImage}
                    filetype={".png,.jpg,.jpeg"}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <FilePicker
                    files={brochureFile}
                    buttonLabel={"Upload Brochure File*"}
                    id={"Contract Photo"}
                    maxFiles={1}
                    setNewFiles={_setBrochureFile}
                    filetype={".pdf"}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
