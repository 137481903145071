import jsPDF from 'jspdf';
import 'jspdf-autotable';
import _ from 'lodash';
import { inWords2 } from '../../utils';

export const dnoteTemp = (data) => {
  let {
    clientAddress,
    clientName,
    challanDate,
    items,
    challanCode,
    runningChallanBalance,
    entityName,
    entityId,
    packagingCost,
    createdAt,
  } = data;

  console.log("pageData", data);

  let code = "";
  if (entityId === "62da46cf7eb6aa02db41297f") {
    code = `VO`;
  } else if (entityId === "62da46c57eb6aa02db41297d") {
    code = `IE`;
  } else {
    code = `VR`;
  }

  // Update challan code format
  let challanCodeUpdate = challanCode.split("-");
  let challanCodeValue = challanCodeUpdate[0] + "-" + challanCodeUpdate[3];

  let totalQuantity = 0;
  let totalAmount = 0;

  const doc = new jsPDF();

  // Add Header

  // doc.text(challanDate, 200, 20, { align: 'right' });
  // doc.text(clientAddress, 10, 22);

  // Table Columns
  const headers = [['No', 'Product Name', 'Qty', 'Rate', 'Total', 'Disc', 'Amt']];

  // Table Rows
  let rows = [];
  items.forEach((o, index) => {
    console.log(o, "oo");
    let {
      nameR,
      nameL,
      lProductCode,
      rProductCode,
      quantity,
      rateR,
      rateL,
      amount,
      discountAmount,
      fitting,
      fittingAmount,
      tint,
      arc
    } = o;
    doc.setFontSize(10);

    totalQuantity += Number(quantity);
    totalAmount += Number(amount);

    rows.push([
      index + 1,
      `${nameR ? `R-${nameR}` : ''} ${rProductCode
        ? "[" + rProductCode.substring(3, rProductCode.length) + "]"
        : ""
      }\n${nameL ? `L-${nameL}` : ''} ${lProductCode
        ? "[" + lProductCode.substring(3, lProductCode.length) + "]"
        : ""
      }`,
      quantity,
      `${nameR ? rateR : ''}\n${nameL ? rateL : ''}`,
      (rateL && rateR) ? ((Number(rateL) + Number(rateR)) / 2).toFixed(2) : (rateL ? (Number(rateL) / 2).toFixed(2) : (rateR ? (Number(rateR) / 2).toFixed(2) : '')),
      discountAmount,
      _.round(amount, 2).toFixed(2),
    ]);
  });

  doc.text('DEBIT NOTE', 10, 10);
  doc.text(`${code}`, 100, 10, { align: 'center' });
  doc.text(`Dt: ${createdAt}`, 200, 10, { align: 'right' });

  doc.text(`M/S: ${clientName}`, 10, 15);
  doc.text(challanCodeValue, 200, 15, { align: 'right' });

  // Add Table
  doc.autoTable({
    head: headers,
    body: rows,
    startY: 20,
    startX: 10,
    margin: { right: 2, left: 2, top: 2 },
    styles: {
      fontSize: 10,
      cellPadding: { top: 2, right: 2, bottom: 2, left: 2 },
      textColor: [0, 0, 0],
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
      fillColor: [255, 255, 255]
    },
    headStyles: {
      // fontStyle: 'bold',
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      valign: 'middle',
      lineWidth: 0.1,
      lineColor: [0, 0, 0],
      fontSize: 8,
    },
    footStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      lineWidth: 0.1,
      lineColor: [0, 0, 0]
    },
    tableLineWidth: 0.1,
    tableLineColor: [0, 0, 0],
    columnStyles: {
      0: { cellWidth: 10 },
      1: { cellWidth: 'auto' },
      2: { cellWidth: 'auto' },
      3: { cellWidth: 'auto' },
      4: { cellWidth: 'auto' },
      5: { cellWidth: 'auto' },
      6: { cellWidth: 'auto' },
      7: { cellWidth: 'auto' },
      8: { cellWidth: 'auto' },
      9: { cellWidth: 'auto' },
      10: { cellWidth: 'auto' },
      11: { cellWidth: 'auto' },
      12: { cellWidth: 'auto' },
      13: { cellWidth: 15 },
    },
    didDrawCell: (data) => {
      // Add cell content wrapping logic if necessary
      // Example: doc.splitTextToSize(text, cellWidth);
      // if (data.pageCount === 1) {


      // }
      // Footer
      let footerStr = `Page ${data.pageCount}`;
      doc.text(footerStr, data.settings.margin.left, doc.internal.pageSize.height - 10);
    }
  });
  let finalY = doc.lastAutoTable.finalY;
  // Total Amount and In Words
  let toWord = _.round(totalAmount + Number(packagingCost), 2).toFixed(2);
  let totalAmountInWords = inWords2(totalAmount);
  let oldBalance = _.round(runningChallanBalance - totalAmount, 2);

  const secondTableStartY = doc.lastAutoTable.finalY + 0; // Adjust the spacing as needed
  doc.autoTable({
    showHead: "never",
    head: headers,
    body: [
      ['', 'Total', '', '', `Qty:${totalQuantity}`, '', `Amt:${_.round(totalAmount, 2).toFixed(2)}`],
    ],
    startY: secondTableStartY,
    margin: { right: 2, left: 2 },
    startX: 5,
    styles: {
      // fontStyle: 'bold',
      fontSize: 10,
      textColor: [0, 0, 0],
      lineColor: [0, 0, 0],
      fillColor: [255, 255, 255]
    },
    headStyles: {
      // fontStyle: 'bold',
      cellPadding: 0.75,
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      valign: 'middle',
      lineColor: [0, 0, 0],
      fontSize: 10,
    },
    footStyles: {
      cellPadding: 0.75,
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      lineWidth: 0.05,
      lineColor: [0, 0, 0]
    },
    tableLineWidth: 0.1,
    tableLineColor: [0, 0, 0],
    columnStyles: {
      6: { halign: 'right' }, // Right align the last column (index 5)
    },
    didDrawPage: function (data) {

    }
  });
  doc.text(`In Words: ${totalAmountInWords} Rupees Only`, 15, secondTableStartY + 15);
  // doc.text(`Total Amount: ${ _.round(totalAmount, 2).toFixed(2) }`, 120, secondTableStartY + 10);

  doc.text(`WORKING HOURS 10: 30 AM TO 07:00 PM`, 15, secondTableStartY + 20);
  doc.text(`Signature`, 180, secondTableStartY + 25);
  doc.text(`Kindly Note: Please send all return goods within 6 days of receipt.`, 15, secondTableStartY + 25);
  doc.text(`I / We hereby certify that my / our registration certificate under the Maharashtra Value Added Tax Act 2003 is in force...`, 15, secondTableStartY + 30);
  doc.text(`SUBJECT TO MUMBAI JURISDICTION`, 15, secondTableStartY + 35);

  // Save the PDF
  const pdfBlob = doc.output('blob');

  // Open the PDF in a new browser tab
  const url = window.URL.createObjectURL(pdfBlob);
  window.open(url, '_blank');
};
