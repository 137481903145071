import React, { useRef } from "react";
import { Button, Grid } from "@material-ui/core";
import { useReactToPrint } from "react-to-print";
import GridItem from "../../components/Grid/GridItem";

const OrderItem = (props) => {
  let { orderNo, orderDetail, party, code, printCustomerName } = props.item;
  let {
    partyRef,
    lab,
    index,
    glassType,
    product,
    coat,
    rSph,
    rCyl,
    rAxis,
    rAddition,
    lSph,
    lCyl,
    lAxis,
    lAddition,
    ed,
    pd,
    prism,
    dia,
    fittingHeight,
    frameSection,
    quantity,
    note,
    clientName,
  } = orderDetail;
  return (
    <>
      <div style={{ marginBottom: 10 }} />
      <div className="text-center">
        <table className="table" border="1">
          <thead>
            <tr>
              <th colSpan="1" className="text-center">
                Order # : {code.substring(0, code.length - 6)}
              </th>
              <th colSpan="3" className="text-center">
                Party Reference : {partyRef}
              </th>
              <th colSpan="1" className="text-center">
                Brand : {lab ? lab : ""}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th colSpan="2" className="text-center">
                Optician : {party.accountName}
              </th>
              {printCustomerName && (
                <th colSpan="3" className="text-center">
                  Customer Name : {clientName}
                </th>
              )}
            </tr>
            <tr>
              <th colSpan="1" className="text-center">
                Index : {index}
              </th>
              <th colSpan="1" className="text-center">
                Glass : {glassType}
              </th>
              <th colSpan="2" className="text-center">
                Product : {product?.productName ? product.productName : ""}
              </th>
              <th colSpan="1" className="text-center">
                Coat : {coat ? coat : ""}
              </th>
            </tr>
            <tr>
              <th colSpan="1" className="text-center">
                SIDE
              </th>
              <th colSpan="1" className="text-center">
                SPH
              </th>
              <th colSpan="1" className="text-center">
                CYL
              </th>
              <th colSpan="1" className="text-center">
                AXIS
              </th>
              <th colSpan="1" className="text-center">
                ADD
              </th>
            </tr>
            <tr>
              <th colSpan="1" className="text-center">
                R
              </th>
              <td colSpan="1" className="text-center">
                {rSph}
              </td>
              <td colSpan="1" className="text-center">
                {rCyl}
              </td>
              <td colSpan="1" className="text-center">
                {rAxis}
              </td>
              <td colSpan="1" className="text-center">
                {rAddition}
              </td>
            </tr>
            <tr>
              <th colSpan="1" className="text-center">
                L
              </th>
              <td colSpan="1" className="text-center">
                {lSph}
              </td>
              <td colSpan="1" className="text-center">
                {lCyl}
              </td>
              <td colSpan="1" className="text-center">
                {lAxis}
              </td>
              <td colSpan="1" className="text-center">
                {lAddition}
              </td>
            </tr>
            <tr>
              <th colSpan="1" className="text-center">
                ED : {ed}
              </th>
              <th colSpan="1" className="text-center">
                PD : {pd}
              </th>
              <th colSpan="1" className="text-center">
                PRISM : {prism}
              </th>
              <th colSpan="1" className="text-center">
                DIA : {dia}
              </th>
              <th colSpan="1" className="text-center">
                FTH : {fittingHeight}
              </th>
            </tr>
            <tr>
              <th colSpan="3" className="text-center">
                Frame : {frameSection}
              </th>
              <th colSpan="2" className="text-center">
                Pair : {quantity}
              </th>
            </tr>
            <tr>
              <td colSpan="5" className="text-center">
                <b>Remark : </b>
                {note}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <br />
    </>
  );
};

const OrderPrintPage = (props) => {
  const componentRef = useRef();
  let { orders } = props.location.state;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <Button color="primary" onClick={handlePrint}>
        Print Orders
      </Button>

      <Grid ref={componentRef} container style={{ alignItems: "center" }}>
        {orders.map((o) => {
          return (
            <GridItem xs={12} md={12} sm={12}>
              <OrderItem item={o} />
            </GridItem>
          );
        })}
      </Grid>
    </div>
  );
};

export default OrderPrintPage;
