import React, { useEffect, useState } from "react";
// @material-ui/core components
import {
  Box,
  Button,
  Dialog,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import moment from "moment";
import axios from "axios";
import { CreateChallanAPI, GetAllChallansAPI } from "../../config/api";
import _ from "lodash";
import { pointToPointTemplate } from "../../assets/html/pointToPointTemplate.js";
import { dataURItoBlob, htmlTemplateToDataString } from "../../utils";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { challan3Template } from "../../assets/html/challan3Template.js";
import { challan1Template } from "../../assets/html/challan1Template.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    flex: 1,
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const challanColumns = [
  {
    id: "No",
    label: "Sr No",
  },
  { id: "codeR", label: "Code R" },
  { id: "codeL", label: "Code L" },
  { id: "nameR", label: "Name R" },
  { id: "nameL", label: "Name L" },
  {
    id: "createdAt",
    label: "Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
  {
    id: "amount",
    label: "Amount",
    format: (value) =>
      Number(value).toLocaleString("en-IN", {
        maximumFractionDigits: 2,
        style: "currency",
        currency: "INR",
      }),
  },
  { id: "quantity", label: "quantity" },
];

export default function PointToPointChallan(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { open, close, item, startDate, endDate } = props;
  console.log(props, "[[");
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  const authToken = useSelector((state) => state.userReducer.authToken);
  const { _id, name, address, gst } = item;

  useEffect(() => {
    _getChallans();
  }, [item]);

  const [challans, setChallans] = useState([]);
  console.log(challans, "ccc");
  async function _getChallans() {
    try {
      let query = `&userId=${
        item._id
      }&isBilled=${false}&skip=undefined&limit=undefined`;
      if (startDate && endDate) {
        let sd = moment(startDate).format("DD-MM-YYYY");
        let ed = moment(endDate).format("DD-MM-YYYY");
        query += `&startDate=${sd}&endDate=${ed}`;
      }
      let res = await axios.get(GetAllChallansAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      let data = res.data.challans.reverse();
      setChallans(data);
    } catch (err) {
      // toast.error(('Cannot fetch data'), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  async function _generateChallan() {
    try {
      const htmlData = {
        challanDate: moment().format("DD-MM-YYYY"),
        challans: challans,
        // items: challans,
        createdAt: moment().format("DD-MM-YYYY"),
        startDate: startDate,
        endDate: endDate,
      };
      // htmlData = {
      //   clientAddress, clientName, challanDate, items, quantity, entityId, challanCode, entityName, packagingCost, runningChallanBalance, createdAt
      // };
      let options = {
        orientation: "p",
        unit: "pt",
        format: [850, 1066],
        // format:'letter',
        putOnlyUsedFonts: true,
      };
      const htmlTemplate = pointToPointTemplate(htmlData);

      await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
        const preBlob = dataURItoBlob(dataString);
        window.open(URL.createObjectURL(preBlob));
      });
      close();
    } catch (e) {
      // console.log("err:", e);
    }
  }

  console.log(challans, "PTP CHALLANS");

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <Card>
        <CardHeader color="primary">
          <Box
            display={"flex"}
            alignItems={"center"}
            // justifyContent={"space-between"}
          >
            <h4 className={classes.cardTitleWhite}>Point To Point</h4>

            <Button
              style={{ color: "white" }}
              onClick={() => _generateChallan()}
            >
              Generate
            </Button>
            <Button style={{ color: "white" }} onClick={close}>
              Close
            </Button>
          </Box>
          {/*<p className={classes.cardCategoryWhite}>Complete your profile</p>*/}
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {challanColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {challans.map((row, index) => {
                    return row.items.map((i, rowindex) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={i._id}
                        >
                          {challanColumns.map((column) => {
                            if (rowindex == 0 && column.id == "No") {
                              return (
                                <TableCell key={"No"} align={column.align}>
                                  {index + 1}
                                </TableCell>
                              );
                            }
                            if (column.id == "createdAt") {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {moment(value).format("DD-MM-YYYY")}
                                </TableCell>
                              );
                            } else {
                              const value = i[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    });
                  })}
                </TableBody>
              </Table>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </Dialog>
  );
}
