import React, { useEffect, useState } from "react";
import _ from "lodash";
// @material-ui/core components
import {
  Box,
  Button,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import moment from "moment";
import axios from "axios";
import { GetAllLedgersAPI, GetAccountAPI } from "../../config/api";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateUtils from "@date-io/moment";
import CardFooter from "../../components/Card/CardFooter";
import FilterListIcon from "@material-ui/icons/FilterList";
import { ledgerTemplate } from "../../assets/html/ledgerTemplate.js";
import { dataURItoBlob, htmlTemplateToDataString } from "../../utils";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const styles = {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  {
    id: "date",
    label: "Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
  { id: "codes", label: "Codes" },
  { id: "Credit", label: "Credit" },
  { id: "Debit", label: "Debit" },
  { id: "Balance", label: "Balance" },
];

export default function LedgerList(props) {
  const classes = useStyles();
  const theme = useTheme();
  console.log("helo", props.item);
  const {
    open,
    close,
    item,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    ledgerType,
  } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const authToken = useSelector((state) => state.userReducer.authToken);
  const userInfo = useSelector((state) => state.userReducer.userInfo);

  console.log("sab lelo", item);

  const [openingBalance, setOpeningBalance] = useState(0);
  const [runningBalance, setRunningBalance] = useState(0);

  // console.log(openingBalance, "MY ledger type in list")

  const { _id, name, address, gst } = item;
  const [openDateSelectionDialog, setOpenDateSelectionDialog] = useState(false);

  useEffect(() => {
    _getLedgers(startDate, endDate);
    console.log("getLedger110");

    // _getLedgers2()
    if (item.partyType === "purchase") {
      // setOpeningBalance(item.openingPurchaseBalance);
      setRunningBalance(item.runningPurchaseBalance);
    } else {
      // setOpeningBalance(item.openingChallanBalance);
      setRunningBalance(item.runningChallanBalance);
    }
  }, [item]);

  const [ledgers, setLedgers] = useState([]); //filtered ledgers
  const [ledgers2, setLedgers2] = useState([]); //all ledgers
  console.log("ledgers2>>>>", ledgers2);
  console.log("ledgers1>>>>", ledgers);
  // / Check if the array is not empty
  if (ledgers.length > 0) {
    // Get the last element
    const lastLedger = ledgers[ledgers.length - 1];

    // Get the amount field of the last element
    const lastAmount = lastLedger.amount;

    // Output the amount
    console.log("The amount of the last ledger is:", lastAmount);
  } else {
    console.log("The ledgers array is empty.");
  }

  function findObjectsBeforeMatch(array1, array2) {
    const resultArray = [];
    const matchingObject = array2[0]; // Only check the first element of array2
    const indexInArray1 = array1.findIndex(
      (obj) => JSON.stringify(obj) === JSON.stringify(matchingObject)
    );
    console.log("Result:", indexInArray1);

    if (indexInArray1 !== -1) {
      resultArray.push(...array1.slice(0, indexInArray1));
    } else {
      // If no match is found, return array1 entirely or an empty array
      return array1; // or return [];
    }

    return resultArray;
  }

  useEffect(() => {
    const result = findObjectsBeforeMatch(ledgers2, ledgers);
    const res = result.reduce((sum, obj) => sum + parseInt(obj?.amount), 0);
    if(item.partyType === "purchase"){
      setOpeningBalance(Number(item.openingPurchaseBalance) + res)
    }else{
      setOpeningBalance(Number(item.openingChallanBalance) + res)
    }
    
    const val = ledgers.reduce((sum, obj) => sum + parseInt(obj?.amount), 0)
    let runnBal = res + val

    if(item.partyType === "purchase"){
      setOpeningBalance(Number(item.openingPurchaseBalance) + runnBal)
    }else{
      setOpeningBalance(Number(item.openingChallanBalance) + runnBal)
    }

  }, [ledgers, ledgers2]);

  // Function to calculate the sum of 'amount' field in objects
  // function sumAmounts(array) {
  //   return array.reduce((sum, obj) => sum + parseInt(obj.amount), 0);
  // }

  // const sumOfAmounts = sumAmounts(result);
  // console.log("Sum of amounts:", sumOfAmounts);

  async function _getLedgers(startDate, endDate) {
    console.log("accountId", item._id);
    try {
      let query;
      // if (ledgerType === "PurchaseBill") {
      //   query = `&accountId=${item._id}&activityType=${"purchase"}&transactionType=${"bill"}`;
      // }
      // else if (ledgerType === "PurchaseChallan") {
      //   query = `&accountId=${item._id}&activityType=${"purchase"}&transactionType=${"challan"}`;
      // }
      // else
      if (ledgerType === "Challan") {
        query = `&accountId=${
          item._id
        }&activityType=sale&transactionType=${"challan"}&transactionType=${"credit"}&transactionType=${"debit"}&activityType=${"purchase"}`;
      } else if (ledgerType === "Bill") {
        query = `&accountId=${
          item._id
        }&activityType=sale&transactionType=${"bill"}&activityType=${"purchase"}&transactionType=${"credit"}&transactionType=${"debit"}`;
      }
      // else if (ledgerType === "CreditNote") {
      //   query = `&accountId=${item._id}&activityType=sale&transactionType=${"credit"}`;
      // } else if (ledgerType === "DebitNote") {
      //   query = `&accountId=${item._id}&activityType=sale&transactionType=${"debit"}`;
      // }
      if (startDate && endDate) {
        let sd = moment(startDate).format("DD-MM-YYYY");
        let ed = moment(endDate).format("DD-MM-YYYY");
        query += `&startDate=${sd}&endDate=${ed}`;
      }
      console.log(userInfo.entity, query, "<<<<<<<<<infotity");
      let res = await axios.get(GetAllLedgersAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("space", res.data.ledgers.length);

      console.log("tempBalance before", res.data);

      let fd = _.sortBy(res.data.ledgers, (obj) => obj.date);
      setLedgers(fd);
      // let tempBalance = item[`opening${ledgerType}Balance`]

      // res.data.ledgers.map((l) => {
      //   tempBalance = tempBalance - Number(l.amount)
      // })

      // console.log('res.data.ledgers', res.data.ledgers)
      // console.log('tempBalance after', tempBalance)

      // setOpeningBalance(tempBalance)
    } catch (err) {
      // toast.error(('Cannot fetch data'), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }


  async function _getLedgers2() {
    try {
      let query;
      if (ledgerType === "Challan") {
        query = `&accountId=${
          item._id
        }&activityType=sale&transactionType=${"challan"}`;
      }
      let res = await axios.get(GetAllLedgersAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      setLedgers2(res.data.ledgers);
      let tempBalance = item[`opening${ledgerType}Balance`]
      console.log('tempBalance before', tempBalance)

      res.data.ledgers.map((l) => {
        tempBalance = tempBalance - Number(l.amount)
      })

      console.log('res.data.ledgers', res.data.ledgers)
      console.log('tempBalance after', tempBalance)

      setOpeningBalance(tempBalance)
    } catch (err) {
      // toast.error(('Cannot fetch data'), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }
  console.log(openingBalance, "ball");

  async function _generateLedger() {
    let res = await axios.get(GetAccountAPI(item._id), {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    const htmlData = {
      // challanDate: moment().format("DD-MM-YYYY"),
      ledgers: ledgers,
      ledgerType: ledgers[0].transactionType,
      startDate: startDate,
      endDate: endDate,
      clientName: res.data.name,
      // closingBalance: item[`running${titleCase(ledgerType)}Balance`],
      closingBlance: item.runningChallanBalance,
      // openingBalance: res.data.partyType === "sale" ? _.round(item.openingChallanBalance, 2) : _.round(item.openingChallanBalance, 2)
      openingBalance: openingBalance,
    };

    let options = {
      orientation: "p",
      unit: "pt",
      format: [850, 1066],
      // format:'letter',
      putOnlyUsedFonts: true,
    };

    const htmlTemplate = ledgerTemplate(htmlData);

    await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
      const preBlob = dataURItoBlob(dataString);
      window.open(URL.createObjectURL(preBlob));
    });

    //   let res = await axios.post(CreateBillAPI(), bill);
    close();
  }

  function DateSelectionDialog(sd, ed) {
    const [startDate, setStartDate] = useState(sd.sd);
    const [endDate, setEndDate] = useState(sd.ed);

    function _onSubmit() {
      _getLedgers(startDate, endDate);
      _getLedgers2()
      console.log("getLedger323");

      setOpenDateSelectionDialog(false);
    }

    return (
      <Dialog
        fullScreen={fullScreen}
        open={openDateSelectionDialog}
        onClose={() => setOpenDateSelectionDialog(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>Date Selection</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <MuiPickersUtilsProvider utils={DateUtils}>
                    <GridItem xs={12}>
                      <DatePicker
                        label="Start Date"
                        fullWidth
                        value={startDate}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        animateYearScrolling
                      />
                    </GridItem>
                    <GridItem xs={12} style={{ marginTop: 20 }}>
                      <DatePicker
                        label="End Date"
                        fullWidth
                        value={endDate}
                        onChange={(date) => setEndDate(date)}
                        animateYearScrolling
                      />
                    </GridItem>
                  </MuiPickersUtilsProvider>
                </GridContainer>
              </CardBody>
              <CardFooter style={{ justifyContent: "center" }}>
                <Button color="primary" onClick={_onSubmit}>
                  Submit
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </Dialog>
    );
  }

  // let balance = 0;
  function titleCase(string) {
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
  }

  let balance = 0; // Initialize balance here

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <Card>
        <CardHeader color="primary">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <h4 className={classes.cardTitleWhite}>Ledgers</h4>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button style={{ color: "white" }}>
              {ledgerType} Opening Balance :{" "}
                {
                  item.partyType === "purchase" && ledgerType === "Challan"
                  ?
                  _.round(item.openingChallanBalance, 2)
                  :
                  item.partyType === "purchase" && ledgerType === "Bill"
                  ?
                  _.round(item.openingBillBalance, 2)
                  :
                  item.partyType === "sale" && ledgerType === "Challan"
                  ?
                  _.round(item.openingChallanBalance, 2)
                  :
                  _.round(item.openingBillBalance, 2)
                }
              </Button>

              <Button style={{ color: "white" }}>
              {ledgerType} Running Balance :{" "}
                {/* {_.round(item[`running${ledgerType}Balance`], 2)} */}
                {
                  item.partyType === "purchase" && ledgerType === "Challan"
                  ?
                  _.round(item.runningChallanBalance, 2)
                  :
                  item.partyType === "purchase" && ledgerType === "Bill"
                  ?
                  _.round(item.runningBillBalance, 2)
                  :
                  item.partyType === "sale" && ledgerType === "Challan"
                  ?
                  _.round(item.runningChallanBalance, 2)
                  :
                  _.round(item.runningBillBalance, 2)
                }
              </Button>

              <Button
                style={{ color: "white" }}
                onClick={() => _generateLedger()}
              >
                Generate
              </Button>
              <Button style={{ color: "white" }} onClick={close}>
                Close
              </Button>
            </Box>
          </Box>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ledgers.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          let value;

                          if (column.id !== "Credit" && column.id !== "Debit") {
                            value = row[column.id];
                          }

                          // Handle Credit
                          if (
                            column.id === "Credit" &&
                            row["type"] === "credit"
                          ) {
                            return (
                              <TableCell key="Credit">
                                {row["amount"]}
                              </TableCell>
                            );
                          }

                          // Handle Debit
                          if (
                            column.id === "Debit" &&
                            row["type"] === "debit"
                          ) {
                            return (
                              <TableCell key="Debit">{row["amount"]}</TableCell>
                            );
                          }

                          // Handle Balance (Debit)
                          if (
                            column.id === "Balance" &&
                            row["type"] === "debit"
                          ) {
                            balance += Number(row["amount"]); // Add debit to balance
                            return (
                              <TableCell key="Balance">
                                {balance.toFixed(2)}
                              </TableCell>
                            );
                          }

                          // Handle Balance (Credit)
                          if (
                            column.id === "Balance" &&
                            row["type"] === "credit"
                          ) {
                            balance -= Number(row["amount"]); // Subtract credit from balance
                            return (
                              <TableCell key="Balance">
                                {balance.toFixed(2)}
                              </TableCell>
                            );
                          }

                          // Handle formatted date column
                          if (column.id === "date") {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : moment(value).format("DD-MM-YYYY")}
                              </TableCell>
                            );
                          }

                          // Handle 'codes' array column
                          if (column.id === "codes") {
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {value.map((item, index) => {
                                  return index + 1 === value.length
                                    ? ` ${item} `
                                    : ` ${item}, `;
                                })}
                              </TableCell>
                            );
                          }

                          // Default case for other columns
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <DateSelectionDialog sd={startDate} ed={endDate} />
    </Dialog>
  );

  // return (
  //   <Dialog
  //     fullScreen
  //     open={open}
  //     onClose={close}
  //     TransitionComponent={Transition}
  //   >
  //     <Card>
  //       <CardHeader color="primary">
  //         <Box
  //           display={"flex"}
  //           alignItems={"center"}
  //           justifyContent={"space-between"}
  //         >
  //           <h4 className={classes.cardTitleWhite}>Ledgers</h4>
  //           <Box
  //             display={"flex"}
  //             alignItems={"center"}
  //             justifyContent={"space-between"}
  //           >
  //             {/* <IconButton
  //               style={{ color: "white" }}
  //               aria-label="edit"
  //               onClick={() => setOpenDateSelectionDialog(true)}
  //             >
  //               <FilterListIcon />
  //             </IconButton> */}

  //             <Button style={{ color: "white" }}>
  //               Opening Balance:{" "}
  //               {item.partyType === "purchase"
  //                 ? _.round(item.openingPurchaseBalance, 2)
  //                 : _.round(item.openingChallanBalance, 2)}
  //             </Button>

  //             <Button style={{ color: "white" }}>
  //               Balance:{" "}
  //               {/* {_.round(item[`running${ledgerType}Balance`], 2)} */}
  //               {item.partyType === "purchase"
  //                 ? _.round(item.runningPurchaseChallanBalance, 2)
  //                 : _.round(item.runningChallanBalance, 2)}
  //               {/* {_.round(item.runningChallanBalance, 2)} */}
  //             </Button>

  //             <Button
  //               style={{ color: "white" }}
  //               onClick={() => _generateLedger()}
  //             >
  //               Generate
  //             </Button>
  //             <Button style={{ color: "white" }} onClick={close}>
  //               Close
  //             </Button>
  //           </Box>
  //         </Box>
  //         {/*<p className={classes.cardCategoryWhite}>Complete your profile</p>*/}
  //       </CardHeader>
  //       <CardBody>
  //         <GridContainer>
  //           <GridItem xs={12}>
  //             <Table stickyHeader aria-label="sticky table">
  //               <TableHead>
  //                 <TableRow>
  //                   {columns.map((column) => (
  //                     <TableCell
  //                       key={column.id}
  //                       align={column.align}
  //                       style={{ minWidth: column.minWidth }}
  //                     >
  //                       {column.label}
  //                     </TableCell>
  //                   ))}
  //                 </TableRow>
  //               </TableHead>
  //               <TableBody>
  //                 {ledgers.map((row, index) => {
  //                   return (
  //                     <TableRow
  //                       hover
  //                       role="checkbox"
  //                       tabIndex={-1}
  //                       key={row.code}
  //                     >
  //                       {columns.map((column) => {
  //                         let value;
  //                         if (column.id !== "Credit" && column.id !== "Debit") {
  //                           value = row[column.id];
  //                         }

  //                         if (
  //                           column.id === "Credit" &&
  //                           row["type"] === "credit"
  //                         ) {
  //                           return (
  //                             <TableCell key="Credit">
  //                               {row["amount"]}
  //                             </TableCell>
  //                           );
  //                         }

  //                         if (
  //                           column.id === "Debit" &&
  //                           row["type"] === "debit"
  //                         ) {
  //                           return (
  //                             <TableCell key="Debit">{row["amount"]}</TableCell>
  //                           );
  //                         }

  //                         if (
  //                           column.id === "Balance" &&
  //                           row["type"] === "debit"
  //                         ) {
  //                           balance += Number(row["amount"]);
  //                           return (
  //                             <TableCell key="Balance">{balance}</TableCell>
  //                           );
  //                         }

  //                         if (
  //                           column.id === "Balance" &&
  //                           row["type"] === "credit"
  //                         ) {
  //                           balance -= Number(row["amount"]);

  //                           return (
  //                             <TableCell key="Balance">{balance}</TableCell>
  //                           );
  //                         }

  //                         if (column.id == "date") {
  //                           return (
  //                             <TableCell key={column.id} align={column.align}>
  //                               {column.format && typeof value === "number"
  //                                 ? column.format(value)
  //                                 : moment(value).format("DD-MM-YYYY")}
  //                             </TableCell>
  //                           );
  //                         } else if (column.id == "codes") {
  //                           return (
  //                             <TableCell key={column.id} align={column.align}>
  //                               {value.map((item, index) => {
  //                                 if (index + 1 == value.length) {
  //                                   return ` ${item} `;
  //                                 } else {
  //                                   return ` ${item}, `;
  //                                 }
  //                               })}
  //                             </TableCell>
  //                           );
  //                         } else {
  //                           return (
  //                             <TableCell key={column.id} align={column.align}>
  //                               {column.format && typeof value === "number"
  //                                 ? column.format(value)
  //                                 : value}
  //                             </TableCell>
  //                           );
  //                         }
  //                       })}
  //                     </TableRow>
  //                   );
  //                 })}
  //               </TableBody>
  //             </Table>
  //           </GridItem>
  //         </GridContainer>
  //       </CardBody>
  //     </Card>
  //     <DateSelectionDialog sd={startDate} ed={endDate} />
  //   </Dialog>
  // );
}
