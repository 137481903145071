import axios from "axios";
import React, { useState, useEffect } from "react";
import { Dialog, Box, Slide, makeStyles } from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button.js";
import { UpdateUserAPI } from "../../config/api";
import { useSelector } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { updateUserInfo } from "../../actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify"

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EmployeeMastersEdit(props) {
  const { open, close, item } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);

  const [employeeName, setEmployeeName] = useState(item.name);
  const [employeeEmail, setEmployeeEmail] = useState(item.email);
  const [employeeMobile, setEmployeeMobile] = useState(item.mobile);
  const [employeeAddress, setEmployeeAddress] = useState(item.address);

  const [viewPurchase, setViewPurchase] = useState(item.viewPurchase);
  const [viewAccounts, setViewAccounts] = useState(item.viewAccounts);
  const [viewAccountsVerification, setViewAccountsVerification] = useState(
    item.viewAccountsVerification
  );
  const [viewDiscounts, setViewDiscounts] = useState(item.viewDiscounts);
  const [viewOrders, setViewOrders] = useState(item.viewOrders);
  const [viewOrdersApp, setViewOrdersApp] = useState(item.viewOrdersApp);
  const [viewOrderReturn, setViewOrderReturn] = useState(item.viewOrderReturn);
  const [viewTransaction, setViewTransaction] = useState(item.viewTransaction);
  const [viewOrderStatus, setViewOrderStatus] = useState(item.viewOrderStatus);
  const [viewPrintAd, setViewPrintAd] = useState(item.viewPrintAd);
  const [viewBankList, setViewBankList] = useState(item.viewBankList);
  const [viewPaymentList, setViewPaymentList] = useState(item.viewPaymentList);
  const [viewPurchaseBarcode, setViewPurchaseBarcode] = useState(
    item.viewPurchaseBarcode
  );
  const [viewProductBarcode, setViewProductBarcode] = useState(
    item.viewProductBarcode
  );
  const [viewOrderPrintPage, setViewOrderPrintPage] = useState(
    item.viewOrderPrintPage
  );
  const [viewBrandsList, setViewBrandsList] = useState(item.viewBrandsList);
  const [viewOfferList, setViewOfferList] = useState(item.viewOfferList);
  const [viewTrendingList, setViewTrendingList] = useState(
    item.viewTrendingList
  );
  const [viewBrochureList, setViewBrochureList] = useState(
    item.viewBrochureList
  );
  const [viewPriceList, setViewPriceList] = useState(item.viewPriceList);
  const [viewMaster, setViewMaster] = useState(item.viewMaster);
  const [viewCategoryList, setViewCategoryList] = useState(
    item.viewCategoryList
  );
  const [viewAccounting, setViewAccounting] = useState(item.viewAccounting);
  const [viewDashboard, setViewDashboard] = useState(item.viewDashboard);
  const [viewSystemUsers, setViewSystemUsers] = useState(item.viewSystemUsers);
  const [viewAccountUsers, setViewAccountUsers] = useState(
    item.viewAccountUsers
  );

  const [viewLabList, setViewLabList] = useState(item.viewLabList)

  const [selectAll, setSelectAll] = useState(false);

  async function _onSave() {
    try {
      if (employeeName === "" || employeeEmail === "" || employeeMobile === "" || employeeAddress === "") {
        toast.error("Please fill all the fields", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      let res = await axios.patch(
        UpdateUserAPI(item._id),
        {
          name: employeeName,
          email: employeeEmail,
          mobile: employeeMobile,
          address: employeeAddress,
          viewDashboard,
          viewSystemUsers,
          viewAccountUsers,
          viewPurchase,
          viewAccounts,
          viewAccountsVerification,
          viewDiscounts,
          viewOrders,
          viewOrdersApp,
          viewOrderReturn,
          viewMaster,
          viewTransaction,
          viewOrderStatus,
          viewAccounting,
          viewPrintAd,
          viewBankList,
          viewPaymentList,
          viewPurchaseBarcode,
          viewProductBarcode,
          viewOrderPrintPage,
          viewBrandsList,
          viewCategoryList,
          viewOfferList,
          viewTrendingList,
          viewBrochureList,
          viewPriceList,
          viewLabList
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      // dispatch(updateUserInfo({ userInfo: res.data.user }));
      toast.success('Successfully Edited!', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      close();
    } catch (e) {
      // console.log(e);
      // toast.error(("Something went wrong, Please Contact Admin"), {
      // position: "top-center",
      // autoClose: 1500,
      // hideProgressBar: false,
      // closeOnClick: true,
      // pauseOnHover: true,
      // draggable: true,
      // progress: undefined,
      // theme: "light",
      // }); 
    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Edit Employee</h4>
                <Button color={"transparent"} onClick={() => _onSave()}>
                  Save
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="Employee Name*"
                    id="EmployeeName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: employeeName,
                      onChange: (event) => setEmployeeName(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="Employee Email*"
                    id="EmployeeEmail"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: employeeEmail,
                      onChange: (event) => setEmployeeEmail(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={4}>
                  <CustomInput
                    labelText="Employee Mobile*"
                    id="EmployeeMobile"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: employeeMobile,
                      onChange: (event) =>
                        setEmployeeMobile(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Employee Address*"
                    id="EmployeeAddress"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: employeeAddress,
                      onChange: (event) =>
                        setEmployeeAddress(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                    label="Select All"
                    control={
                      <Checkbox
                        checked={selectAll}
                        onChange={(e) => {
                          setSelectAll(e.target.checked);
                          setViewDashboard(e.target.checked);
                          setViewSystemUsers(e.target.checked);
                          setViewSystemUsers(e.target.checked);

                          setViewPurchase(e.target.checked);
                          setViewAccounts(e.target.checked);
                          setViewAccountsVerification(e.target.checked);
                          setViewDiscounts(e.target.checked);
                          setViewOrders(e.target.checked);
                          setViewOrdersApp(e.target.checked);
                          setViewOrderReturn(e.target.checked);
                          setViewMaster(e.target.checked);
                          setViewTransaction(e.target.checked);
                          setViewOrderStatus(e.target.checked);
                          setViewAccounting(e.target.checked);
                          setViewPrintAd(e.target.checked);
                          setViewBankList(e.target.checked);
                          setViewPaymentList(e.target.checked);
                          setViewPurchaseBarcode(e.target.checked);
                          setViewProductBarcode(e.target.checked);
                          setViewOrderPrintPage(e.target.checked);
                          setViewBrandsList(e.target.checked);
                          setViewCategoryList(e.target.checked);
                          setViewOfferList(e.target.checked);
                          setViewTrendingList(e.target.checked);
                          setViewBrochureList(e.target.checked);
                          setViewPriceList(e.target.checked);
                        }}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Dashboard"
                    control={
                      <Checkbox
                        checked={viewDashboard}
                        onChange={(e) => setViewDashboard(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View System Users"
                    control={
                      <Checkbox
                        checked={viewSystemUsers}
                        onChange={(e) => setViewSystemUsers(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Account Users"
                    control={
                      <Checkbox
                        checked={viewAccountUsers}
                        onChange={(e) => setViewAccountUsers(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Purchase"
                    control={
                      <Checkbox
                        checked={viewPurchase}
                        onChange={(e) => setViewPurchase(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Accounts"
                    control={
                      <Checkbox
                        checked={viewAccounts}
                        onChange={(e) => setViewAccounts(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View AccountsVerification"
                    control={
                      <Checkbox
                        checked={viewAccountsVerification}
                        onChange={(e) =>
                          setViewAccountsVerification(e.target.checked)
                        }
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Discounts"
                    control={
                      <Checkbox
                        checked={viewDiscounts}
                        onChange={(e) => setViewDiscounts(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Orders"
                    control={
                      <Checkbox
                        checked={viewOrders}
                        onChange={(e) => setViewOrders(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View OrdersApp"
                    control={
                      <Checkbox
                        checked={viewOrdersApp}
                        onChange={(e) => setViewOrdersApp(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Orders Return"
                    control={
                      <Checkbox
                        checked={viewOrderReturn}
                        onChange={(e) => setViewOrderReturn(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Master"
                    control={
                      <Checkbox
                        checked={viewMaster}
                        onChange={(e) => setViewMaster(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Transaction"
                    control={
                      <Checkbox
                        checked={viewTransaction}
                        onChange={(e) => setViewTransaction(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View OrderStatus"
                    control={
                      <Checkbox
                        checked={viewOrderStatus}
                        onChange={(e) => setViewOrderStatus(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Accounting"
                    control={
                      <Checkbox
                        checked={viewAccounting}
                        onChange={(e) => setViewAccounting(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View PrintAd"
                    control={
                      <Checkbox
                        checked={viewPrintAd}
                        onChange={(e) => setViewPrintAd(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View BankList"
                    control={
                      <Checkbox
                        checked={viewBankList}
                        onChange={(e) => setViewBankList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View PaymentList"
                    control={
                      <Checkbox
                        checked={viewPaymentList}
                        onChange={(e) => setViewPaymentList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Purchase Barcode"
                    control={
                      <Checkbox
                        checked={viewPurchaseBarcode}
                        onChange={(e) =>
                          setViewPurchaseBarcode(e.target.checked)
                        }
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Product Barcode"
                    control={
                      <Checkbox
                        checked={viewProductBarcode}
                        onChange={(e) =>
                          setViewProductBarcode(e.target.checked)
                        }
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Order Print Page"
                    control={
                      <Checkbox
                        checked={viewOrderPrintPage}
                        onChange={(e) =>
                          setViewOrderPrintPage(e.target.checked)
                        }
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View BrandsList"
                    control={
                      <Checkbox
                        checked={viewBrandsList}
                        onChange={(e) => setViewBrandsList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View CategoryList"
                    control={
                      <Checkbox
                        checked={viewCategoryList}
                        onChange={(e) => setViewCategoryList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View OfferList"
                    control={
                      <Checkbox
                        checked={viewOfferList}
                        onChange={(e) => setViewOfferList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View TrendingList"
                    control={
                      <Checkbox
                        checked={viewTrendingList}
                        onChange={(e) => setViewTrendingList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View BrochureList"
                    control={
                      <Checkbox
                        checked={viewBrochureList}
                        onChange={(e) => setViewBrochureList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View PriceList"
                    control={
                      <Checkbox
                        checked={viewPriceList}
                        onChange={(e) => setViewPriceList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={3}>
                  <FormControlLabel
                    label="View Lablist"
                    control={
                      <Checkbox
                        checked={viewLabList}
                        onChange={(e) => setViewLabList(e.target.checked)}
                      />
                    }
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
