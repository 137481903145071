import React, { useState } from "react";
// @material-ui/core components
import {
  Box,
  Dialog,
  Fab,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import axios from "axios";
import { UpdatePaymentAPI } from "../../config/api";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const styles = {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const columns = [
  { id: "paymentMode", label: "Payment Mode" },
  { id: "paymentAmount", label: "Payment Amount" },
  // {id: 'createdAt', label: 'Created At', format: value => value ? moment(value).format('DD-MM-YYYY') : ''},
  { id: "action", label: "Action" },
];
const sides = ["B", "L", "R"];
const paymentModes = ["Cheque", "Cash and Direct"];

export default function PaymentEdit(props) {
  let { item } = props;
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);
  const classes = useStyles();
  const theme = useTheme();
  const { open, close, fetchData } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [payments, setPayments] = useState(item.payments);
  console.log(">>>>", payments);
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentAmount, setPaymentAmount] = useState("");
  const [chequeNo, setChequeNo] = useState("");
  const [chequeDate, setChequeDate] = useState("");
  const [chequeAmount, setChequeAmount] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentDetails, setPaymentDetails] = useState("");
  const [refNo, setRefNo] = useState("");

  async function _onSave() {
    try {
      let paidAmount = 0;
      _.map(payments, (o) => {
        paidAmount += Number(o.paymentAmount);
      });

      let paymentStatus = "Pending";
      if (paymentAmount >= paidAmount) {
        paymentStatus = "Paid";
      }
      let payment = {
        payments,
        paymentDetails,
        paymentStatus,
      };
      let res = await axios.patch(UpdatePaymentAPI(item._id), payment, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      close();
      //   window.location.reload();
      fetchData();
    } catch (e) {
      // console.log("err:", e);
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Update Payment</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <Autocomplete
                    id="paymentMode"
                    className={classes.formControl}
                    options={paymentModes}
                    value={paymentMode}
                    onChange={(event, value) => setPaymentMode(value)}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Payment Mode" />
                    )}
                  />
                </GridItem>
                <GridItem xs={5} sm={5} md={5}>
                  <CustomInput
                    labelText="Payment Amount"
                    id="paymentAmount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      value: paymentAmount,
                      onChange: (event) => setPaymentAmount(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <Box justifyContent={"center"} marginTop={4}>
                    <Fab
                      size={"small"}
                      color="primary"
                      aria-label="add"
                      onClick={() => {
                        if (paymentMode === "") {
                          return alert("Please select payment mode");
                        }
                        if (paymentAmount === "") {
                          return alert("Please enter payment amount");
                        }

                        let data = {
                          paymentMode,
                          paymentAmount,
                          chequeNo,
                          chequeDate,
                          chequeAmount,
                          bankName,
                          branchName,
                          paymentMethod,
                          refNo,
                          createdAt: new Date(),
                          isNew: true,
                        };
                        let copy = _.clone(payments);
                        copy.push(data);
                        setPayments(copy);
                        setPaymentMode("");
                        setPaymentAmount("");
                      }}
                    >
                      <AddIcon />
                    </Fab>
                  </Box>
                </GridItem>
                {paymentMode ? (
                  paymentMode === paymentModes[0] ? (
                    <>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="Cheque No."
                          id="chequeNo"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: chequeNo,
                            onChange: (event) =>
                              setChequeNo(event.target.value),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="Cheque Date"
                          id="chequeDate"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: chequeDate,
                            onChange: (event) =>
                              setChequeDate(event.target.value),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={4} sm={4} md={4}>
                        <CustomInput
                          labelText="Cheque Amount"
                          id="chequeAmount"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: chequeAmount,
                            onChange: (event) =>
                              setChequeAmount(event.target.value),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                          labelText="Bank Name"
                          id="bankName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: bankName,
                            onChange: (event) =>
                              setBankName(event.target.value),
                          }}
                        />
                      </GridItem>
                      <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                          labelText="Branch Name"
                          id="branchName"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: branchName,
                            onChange: (event) =>
                              setBranchName(event.target.value),
                          }}
                        />
                      </GridItem>
                    </>
                  ) : (
                    <>
                      <GridItem xs={6} sm={6} md={12}>
                        <CustomInput
                          labelText="Payment Method"
                          id="paymentMethod"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: paymentMethod,
                            onChange: (event) =>
                              setPaymentMethod(event.target.value),
                          }}
                        />
                      </GridItem>
                      {/* <GridItem xs={6} sm={6} md={6}>
                        <CustomInput
                          labelText="Ref No."
                          id="refNo"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: refNo,
                            onChange: (event) => setRefNo(event.target.value),
                          }}
                        />
                      </GridItem> */}
                    </>
                  )
                ) : null}
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Payment Details"
                    id="paymentDetails"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: paymentDetails,
                      onChange: (event) =>
                        setPaymentDetails(event.target.value),
                    }}
                  />
                </GridItem>

                {_.isEmpty(payments) ? null : (
                  <GridItem xs={12}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {payments.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                              style={{
                                textDecoration: row.isNegative
                                  ? "line-through"
                                  : "none",
                              }}
                            >
                              {columns.map((column) => {
                                let value = row[column.id];
                                if (column.id === "action") {
                                  return (
                                    <TableCell align={"center"}>
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                      >
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() => {
                                            let copy = _.clone(payments);
                                            // copy.splice(index, 1);
                                            copy[index].paymentAmount = (-copy[
                                              index
                                            ].paymentAmount).toString();
                                            copy[index].isNegative = true;
                                            copy[index].isNew = true;
                                            setPayments(copy);
                                          }}
                                          disabled={row.isNegative}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </TableCell>
                                  );
                                }
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
            <CardFooter style={{ justifyContent: "center" }}>
              <Button color="primary" onClick={() => _onSave()}>
                Update Payment
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
