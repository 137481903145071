import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import React, { useEffect, useMemo, useState } from "react";
import _, { update } from "lodash";
import Moment from "moment";
import { invoiceTemplate } from "../../assets/html/invoiceTemplate";
import { challan1Template } from "../../assets/html/challan1Template";
import { challan2Template } from "../../assets/html/challan2Template";
import { challan3Template } from "../../assets/html/challan3Template";
import { dataURItoBlob, htmlTemplateToDataString } from "../../utils";
import { CloudDownload, CloudUpload, Receipt } from "@material-ui/icons";
import AccountView from "../Account/AccountView";
import PrintIcon from "@mui/icons-material/Print";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import {
  DeleteChallanAPI,
  GetAllChallansAPI,
  GetAllDebitsAPI,
  GetAllPrintAdsAPI,
  DeleteDebitAPI,
  GetEntityAPI,
} from "../../config/api";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ChallanView from "./ChallanView";
import ChallanAddDialog from "./ChallanAddDialog";
import ChallanEditDialog from "./ChallanEditDialog";

import { extendMoment } from "moment-range";
import { useSelector } from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";

import Checkbox from "@material-ui/core/Checkbox";
import ChallanDCDialog from "./ChallanDCDialog";
import { toast } from "react-toastify";
import { ConfirmationDialog } from "../../components/common";
import { dnoteTemp } from "../../assets/html/dNoteTemp";

const moment = extendMoment(Moment);

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(-24),
    right: theme.spacing(2),
  },
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

let invoiceTemplates = [
  // {
  //     label: "Thermal",
  //     value: "challan1",
  // },
  // {
  //     label: "Challan",
  //     value: "challan2",
  // },
  // {
  //     label: "A6",
  //     value: "challan3",
  // },
  {
    label: "Debit Note",
    value: "dnote",
  },
];

const columns = [
  { id: "code", label: "Code" },
  {
    id: "amount",
    label: "Amount",
    format: (value) =>
      value.toLocaleString("en-IN", {
        maximumFractionDigits: 2,
        style: "currency",
        currency: "INR",
      }),
  },
  { id: "party", label: "Party", minWidth: 100 },
  { id: "isBilled", label: "Is Billed" },

  {
    id: "createdAt",
    label: "Created At",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
  // { id: "paymentStatus", label: "Payment Status", minWidth: 100 },

  { id: "action", label: "Action" },
  { id: "collapse" },
];
const itemColumns = [
  { id: "nameR", label: "Name R" },
  { id: "nameL", label: "Name L" },
  { id: "quantity", label: "Quantity" },
  {
    id: "amount",
    label: "Amount",
    format: (value) =>
      Number(value).toLocaleString("en-IN", {
        maximumFractionDigits: 2,
        style: "currency",
        currency: "INR",
      }),
  },
];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ChallanListDebit = (props) => {
  const classes = useStyles();
  const { searchTerm, filter, readyMasterRange, accounts } = props;
  console.log("rmr", readyMasterRange);

  const authToken = useSelector((state) => state.userReducer.authToken);
  const userInfo = useSelector((state) => state.userReducer.userInfo);
  console.log(userInfo, "my user info");

  const [challans, setChallans] = useState([]);
  const [tota, setTota] = useState("");

  console.log(">>>>", challans);
  const [openChallanAddDialog, setOpenChallanAddDialog] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openAccountViewDialog, setOpenAccountViewDialog] = useState(null);
  const [showInvoiceSelectionDialog, setShowInvoiceSelectionDialog] =
    useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDCDialog, setOpenDCDialog] = useState(false);
  const [entityDetails, setEntityDetails] = useState({});

  const [partyWithCashPreference, setPartyWithCashPreference] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedChallan, setSelectedChallan] = useState(null);

  useEffect(() => {
    _getChallans(page, rowsPerPage);
    _getEntity();
  }, [openAddDialog, openEditDialog, openDCDialog, page, rowsPerPage]);

  // async function _getChallans() {
  //   try {
  //     let query = `&isBilled=${false}`;
  //     let res = await axios.get(GetAllDebitsAPI(userInfo.entity) + query, {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });
  //     console.log(res.data.debits, "These are my debits");
  //     setChallans(res.data.debits);
  //   } catch (err) {
  //     // console.log(err);
  //     // toast.error(("Something went wrong, Please Contact Admin") , {
  //     //   position: "top-center",
  //     //   autoClose: 1500,
  //     //   hideProgressBar: false,
  //     //   closeOnClick: true,
  //     //   pauseOnHover: true,
  //     //   draggable: true,
  //     //   progress: undefined,
  //     //   theme: "light",
  //     //   });
  //   }
  // }

  const _getChallans = useMemo(
    () => async (page, rowsPerPage) => {
      let skipm = page;
      console.log("limit & skip", rowsPerPage, page, skipm);

      if (rowsPerPage === undefined || rowsPerPage === null || rowsPerPage === NaN) {
        rowsPerPage = 10;
      }

      if (skipm === undefined || skipm === null || skipm === NaN) {
        skipm = 0;
      }
      console.log("skipm", skipm, page, rowsPerPage)

      try {
        let query = `&isBilled=${false}&limit=${rowsPerPage}&skip=${skipm}&paymentStatus=${"Pending"}&status=${"Active"}`;
        // let query = `&isBilled=${false}`;
        console.log("deb q", query)
        let res = await axios.get(GetAllDebitsAPI(userInfo.entity) + query, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        setChallans(res.data.debits);
        setTota(res.data.total);
      } catch (err) {
        // Handle error
        toast.error("Error occured while fetching Transactions.");
      }
    },
    []
  );

  async function _deleteItem(item) {
    try {
      let res = await axios.delete(DeleteDebitAPI(item._id), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      toast.success("Successfully Deleted!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      _getChallans();
      // window.location.reload();
    } catch (e) {
      console.log("err:", e);
      toast.error("Something went wrong, Please Contact Admin", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  async function _getEntity() {
    try {
      let res = await axios.get(GetEntityAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      // console.log(res.data)
      setEntityDetails(res.data);
    } catch (e) { }
  }
  // console.log(entityDetails.name)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpenInvoiceSelectionModal = (item) => {
    setSelectedItem(item);
    setShowInvoiceSelectionDialog(true);
  };

  function closeAccountViewDialog() {
    setOpenAccountViewDialog(null);
  }

  function closeViewDialog() {
    setOpenViewDialog(null);
  }

  function closeEditDialog() {
    setOpenEditDialog(null);
  }

  function closeAddDialog() {
    setOpenAddDialog(false);
    _getChallans()
  }

  function closeDCDialog() {
    setOpenDCDialog(false);
  }
  console.log(authToken, "auth");

  async function _onPreview(invoiceType) {
    if (_.isEmpty(selectedItem)) {
      return;
    }
    let htmlData = {},
      htmlTemplate = {};
    let { party } = selectedItem;
    let challanCode = selectedItem.code;
    let packagingCost = selectedItem.packagingCost;
    console.log(selectedItem, "my item before");
    let items = [],
      totalQuantity = 0;

    let challanCreatedAtDate = selectedItem.createdAt;

    _.map(selectedItem.items, async (item, index) => {
      let {
        codeL,
        codeR,
        nameL,
        nameR,
        rateR,
        rateL,
        quantity,
        amount,
        fitting,
        fittingAmount,
        discountAmount,
        discountPercent,
        tint,
      } = item;
      let productName = "",
        price = 0;

      items.push({
        name: productName,
        rate: amount,
        codeR,
        codeL,
        nameL,
        nameR,
        rateR,
        rateL,
        fitting,
        fittingAmount,
        quantity,
        discountAmount,
        discountPercent,
        tint,
      });
    });

    let res = await axios.get(GetAllPrintAdsAPI(userInfo.entity));
    let printAds = res.data.printAds;

    let ad = "";
    if (printAds && printAds[0]) {
      printAds.map((item) => {
        ad = ad + item.title + " ";
      });
    }

    let accountRes = _.find(accounts, { _id: party.accountId });

    htmlData = {
      ad,
      clientAddress: party?.accountAddress ? party.accountAddress : "",
      clientName: party.accountName,
      clientGSTNo: party.gst,
      challanDate: moment().format("DD-MM-YYYY"),
      createdAt: moment(challanCreatedAtDate).format("DD-MM-YYYY"),
      items: selectedItem.items,
      quantity: totalQuantity,
      challanCode,
      packagingCost: selectedItem.packagingCost,
      entityName: entityDetails.name,
      entityId: entityDetails._id,
      runningChallanBalance: accountRes?.showRunningBalance
        ? accountRes?.runningChallanBalance
        : null,
    };

    console.log(htmlData, "my data");
    let options = null;
    if (invoiceType === "challan1") {
      options = {
        orientation: "p",
        unit: "pt",
        format: [284, 800],
        // format:'letter',
        putOnlyUsedFonts: true,
      };
      htmlTemplate = challan1Template(htmlData);
    } else if (invoiceType === "challan2") {
      options = {
        orientation: "p",
        unit: "pt",
        // format: [297.638, 595.276],
        format: "a4",
        width: 595.276, // A4 width in points
        height: 297.638, // Half of A4 height in points
        // format:'letter',
        putOnlyUsedFonts: true,
      };
      htmlTemplate = challan2Template(htmlData);
    } else if (invoiceType === "challan3") {
      options = {
        orientation: "p",
        unit: "px",
        format: "a6",
        precision: 2,
        // format: [850, 1066],
        // format:'letter',
        putOnlyUsedFonts: true,
      };
      htmlTemplate = challan3Template(htmlData);
    } else if (invoiceType === "dnote") {
      options = {
        orientation: "p",
        unit: "px",
        format: "a5",
        precision: 2,
        // format: [850, 1066],
        // format:'letter',
        putOnlyUsedFonts: true,
      };
      htmlTemplate = dnoteTemp(htmlData);
    }

    await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
      const preBlob = dataURItoBlob(dataString);
      window.open(URL.createObjectURL(preBlob));
    });
  }

  const InvoiceSelectionDialog = () => {
    return (
      <Dialog
        onClose={() => setShowInvoiceSelectionDialog(false)}
        aria-labelledby="simple-dialog-title"
        open={showInvoiceSelectionDialog}
      >
        <DialogTitle id="simple-dialog-title">Select Invoice</DialogTitle>
        <List>
          {invoiceTemplates.map(({ label, value }) => (
            <ListItem
              button
              onClick={() => {
                _onPreview(value);
                setShowInvoiceSelectionDialog(false);
              }}
              key={value}
            >
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  };

  const RenderTableRow = (props) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    let { row } = props;
    if (!row) {
      return null;
    }
    let { orderId, orderNo, challanId, orderDate, party, items } = row;
    return (
      <>
        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
          <TableCell padding="checkbox">
            <Checkbox
              style={{
                color: "#512da8",
              }}
              onChange={() => {
                let copy = _.clone(selectedRows);
                if (_.includes(copy, row._id)) {
                  let index = _.indexOf(copy, row._id);
                  copy.splice(index, 1);
                } else {
                  copy.push(row._id);
                }
                setSelectedRows(copy);
              }}
              checked={_.includes(selectedRows, row._id)}
              inputProps={{ "aria-labelledby": row._id }}
            />
          </TableCell>
          {columns.map((column) => {
            let value = row[column.id];
            if (column.id === "party") {
              value = value ? value.accountName : "";
            }
            if (column.id === "collapse") {
              return (
                <TableCell align={"center"}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setIsCollapsed(!isCollapsed)}
                  >
                    {isCollapsed ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )}
                  </IconButton>
                </TableCell>
              );
            }
            if (column.id === "createdAt") {
              return (
                <TableCell>{moment(value).format("DD-MM-YYYY")}</TableCell>
              );
            }
            if (column.id === "isBilled") {
              return <TableCell>{value ? "Yes" : "no"}</TableCell>;
            }
            if (column.id === "action") {
              return (
                <TableCell align={"center"}>
                  <Box display={"flex"} justifyContent={"center"}>
                    <IconButton
                      onClick={() =>
                        setOpenEditDialog({
                          open: true,
                          item: row,
                        })
                      }
                      aria-label="view"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleOpenInvoiceSelectionModal(row)}
                      aria-label="view"
                    >
                      <Receipt />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setOpenConfirmationDialog(true);
                        setSelectedChallan(row);
                      }}
                      aria-label="view"
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      aria-label="view"
                      onClick={() =>
                        setOpenViewDialog({
                          open: true,
                          item: row,
                        })
                      }
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              );
            }
            return (
              <TableCell
                key={column.id}
                align={column.align}
                onClick={async () => {
                  if (column.id === "party") {
                    try {
                      let oneAccount = _.find(accounts, { _id: row._id });
                      // let res = await axios.get(GetAccountAPI(row._id), {
                      //   headers: {
                      //     Authorization: `Bearer ${authToken}`,
                      //   },
                      // });
                      setOpenAccountViewDialog({
                        open: true,
                        item: oneAccount,
                      });
                    } catch (e) {
                      // console.log("err:", e);
                      toast.error(
                        "Something went wrong, Please Contact Admin",
                        {
                          position: "top-center",
                          autoClose: 1500,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        }
                      );
                    }
                  }
                }}
              >
                {column.format && typeof value === "number"
                  ? column.format(value)
                  : value}
              </TableCell>
            );
          })}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {itemColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={"center"}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((row, index) => {
                    return (
                      <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                        {itemColumns.map((column) => {
                          let value = row[column.id];
                          if (column.id === "nameL") {
                            value = row.nameL + " " + row.codeL;
                          } else if (column.id === "nameR") {
                            value = row.nameR + " " + row.codeR;
                          }
                          return (
                            <TableCell key={column.id} align={"center"}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  async function searchChallan(searchTerm) {
    try {
      console.log("searchin", searchTerm)
      let query = `&isBilled=false&search=${searchTerm}`;
      let challa = await axios.get(GetAllDebitsAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      console.log('search challan', challa);
      setChallans(challa.data.debits);
      // Process the result of the API call
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  }

  let data = challans;
  console.log("finale", data)
  // let data = []
  // if (searchTerm !== "") {
  //   data = _.filter(data, (i) => {
  //     return i.code && i.code.toLowerCase().includes(searchTerm.toLowerCase());
  //   });
  // }
  useEffect(() => {
    if (searchTerm !== "") {
      searchChallan(searchTerm);
    } else {
      _getChallans(page, rowsPerPage);
    }
  }, [searchTerm]);

  if (filter) {
    let { startDate, endDate, party, status } = filter;
    if (startDate && endDate) {
      data = _.filter(data, (i) => {
        return (
          moment(startDate).isSame(moment.utc(i.createdAt), "day") ||
          moment(i.createdAt).isBetween(
            moment.utc(startDate),
            moment.utc(endDate),
            "day"
          ) ||
          moment(endDate).isSame(moment.utc(i.createdAt), "day")
        );
      });
    }
    if (party) {
      data = _.filter(data, (i) => {
        return i.party.accountName === party;
      });
    }
    if (status) {
      data = _.filter(data, (i) => {
        return i.paymentStatus === status;
      });
    }
  }

  return (
    <>
      <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
        <IconButton aria-label="add" onClick={() => setOpenAddDialog(true)}>
          <AddIcon />
        </IconButton>
        {selectedRows.length > 0 && (
          <IconButton aria-label="add" onClick={() => setOpenDCDialog(true)}>
            <PrintIcon />
          </IconButton>
        )}
      </Box>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <Checkbox
              style={{
                color: "#512da8",
              }}
              onChange={(e) => {
                let c = [];
                if (e.target.checked) {
                  data.map((i) => {
                    c.push(i._id);
                  });
                }
                setSelectedRows(c);
              }}
              checked={
                selectedRows.length > 0 && data.length === selectedRows.length
              }
              inputProps={{ "aria-labelledby": -1 }}
            />
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              return <RenderTableRow {...props} row={row} />;
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <InvoiceSelectionDialog />

      {openAccountViewDialog ? (
        <AccountView
          open={openAccountViewDialog.open}
          close={closeAccountViewDialog}
          item={openAccountViewDialog.item}
        />
      ) : null}
      {/*<Fab color="primary" aria-label="add" className={classes.fab}*/}
      {/*     onClick={() => setOpenAddDialog(true)}>*/}
      {/*    <AddIcon/>*/}
      {/*</Fab>*/}
      {openViewDialog ? (
        <ChallanView
          open={openViewDialog.open}
          close={closeViewDialog}
          item={openViewDialog.item}
        />
      ) : null}
      {openAddDialog ? (
        <ChallanAddDialog
          open={openAddDialog}
          close={closeAddDialog}
          readyMasterRange={readyMasterRange}
          users={accounts}
        />
      ) : null}

      {openDCDialog ? (
        <ChallanDCDialog
          open={openDCDialog}
          close={closeDCDialog}
          data={data}
          selectedRows={selectedRows}
        />
      ) : null}
      {openEditDialog ? (
        <ChallanEditDialog
          open={openEditDialog.open}
          close={closeEditDialog}
          item={openEditDialog.item}
          source={"debit"}
        />
      ) : null}
      {openConfirmationDialog ? (
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={() => setOpenConfirmationDialog(false)}
          onConfirm={() => _deleteItem(selectedChallan)}
          title={"Delete Record"}
          msg={"Do you want to delete this record?"}
        />
      ) : null}
    </>
  );
};
