import React, { useEffect, useState } from "react";
import _ from "lodash";
// @material-ui/core components
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Fab,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
} from "@material-ui/core";
import moment from "moment";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
// import Table from "../../components/Table/Table.js";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import OrderView from "../Order/OrderView";
import axios from "axios";
import {
  BulkPrintOrdersAPI,
  DeleteOrderAPI,
  GetAllOrdersAPI,
  UpdateOrderAPI,
} from "../../config/api";
import { ConfirmationDialog } from "../../components/common";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory } from "react-router-dom";
import CardHeader from "../../components/Card/CardHeader";
import People from "@material-ui/icons/People";
import CustomInput from "../../components/CustomInput/CustomInput";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { OrderReturnResponseDialog } from "./OrderReturnResponseDialog.js";
import { useSelector } from "react-redux";
import CompanyOrderReturnView from "./CompanyOrderReturnView.js";
import { toast } from "react-toastify";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const columns = [
  { id: "party", label: "Party" },
  { id: "code", label: "Order Code" },
  { id: "orderType", label: "Order Type" },
  { id: "product", label: "Product" },
  { id: "orderAmount", label: "Order Amount" },
  {
    id: "createdAt",
    label: "Order Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
  { id: "action", label: "Action" },
];

const columnsCompany = [
  { id: "party", label: "Party" },
  // { id: "orderType", label: "Order Type" },
  { id: "totalOrders", label: "Total Orders" },
  { id: "totalAmt", label: "Total Amount" },
  { id: "totalQty", label: "Total Quantity" },

  {
    id: "date",
    label: "Order Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
  { id: "action", label: "Action" },
];

const orderTypes = ["Fitting", "Prescription"];

export default function OrderReturn(props) {
  const classes = useStyles();
  const history = useHistory();
  const userInfo = useSelector((state) => state.userReducer.userInfo);
  const authToken = useSelector((state) => state.userReducer.authToken);
  const [orders, setOrders] = useState([]);
  const [openReadyOrderAddDialog, setOpenReadyOrderAddDialog] = useState(false);
  const [openPrescriptionOrderAddDialog, setOpenPrescriptionOrderAddDialog] =
    useState(false);
  const [openFittingOrderAddDialog, setOpenFittingOrderAddDialog] =
    useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openViewDialog, setOpenViewDialog] = useState(null);
  const [openOrderTypeDialog, setOpenOrderTypeDialog] = useState(false);
  const [selectedType, setSelectedType] = useState(orderTypes[0]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const [openOrderResponseDialog, setOpenOrderResponseDialog] = useState(null);
  const [orderReturnAdminDetails, setOrderReturnAdminDetails] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [tota, setTota] = useState(0);
  const [openViewCompanyOrderDialog, setOpenViewCompanyOrderDialog] =
    useState(null);
  const [openOrderDetailsModal, setOpenOrderDetailsModal] = useState(null);

  useEffect(() => {
    _getOrders();
  }, [openViewCompanyOrderDialog]);

  async function searchData(term) {
    setSearchTerm(term);
    let res = await axios.get(
      GetAllOrdersAPI(userInfo.entity) +
      `&sortBy=createdAt:desc&orderReturnStatus=None&limit=${rowsPerPage}&skip=${page * rowsPerPage}&code=${term}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    setOrders(res.data.orders);
    // setOrdersGrouped(res.data.ordersGrouped);
    setTota(res.data.total);
  }

  async function _getOrders() {
    try {
      let res = await axios.get(
        GetAllOrdersAPI(userInfo.entity) +
        "&sortBy=createdAt:desc&isOrderFromApp=true",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setOrders(res.data.orders);
    } catch (err) {
      // console.log("error:", err);
      // t
    }
  }

  function handleSearch() {
    searchData(search);
  }

  async function _deleteItem(item) {
    try {
      let res = await axios.delete(DeleteOrderAPI(item._id), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      window.location.reload();
    } catch (e) {
      // console.log("err:", e);
      // t
    }
  }

  async function _onPrintAllOrders() {
    try {
      let res = await axios.post(
        BulkPrintOrdersAPI(),
        {
          orders: selectedRows,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      let tmp = [];
      selectedRows.map((i) => {
        let o = _.find(orders, { _id: i });
        tmp.push(o);
      });
      history.push("/admin/order-print", { orders: tmp });
    } catch (e) {
      // console.log("err:", e);
      // t
    }
  }

  async function _confirmOrder(row, data) {
    try {
      let res = await axios.patch(
        UpdateOrderAPI(row.code),
        {
          orderReturnStatus: "Confirmed",
          orderReturnAdminDetails: [...row.orderReturnAdminDetails, data],
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      _getOrders();
    } catch (err) {
      // console.log(err);
      // t
    }
  }

  async function _rejectOrder(row, data) {
    try {
      let res = await axios.patch(
        UpdateOrderAPI(row.code),
        {
          orderReturnStatus: "Rejected",
          orderReturnAdminDetails: [...row.orderReturnAdminDetails, data],
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      _getOrders();
    } catch (err) {
      // console.log(err);
      // t
    }
  }

  const handleOpenConfirmationModal = (object) => {
    setOpenConfirmationModal(object);
  };
  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(null);
  };

  const handleOpenOrderResponseModal = (object) => {
    setOpenOrderResponseDialog(object);
  };

  const handleCloseOrderResponsenModal = () => {
    setOpenOrderResponseDialog(null);
  };

  const handleOpenOrderDetailsModal = (object) => {
    setOpenOrderDetailsModal(object);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function closeReadyOrderAddDialog() {
    setOpenReadyOrderAddDialog(false);
  }

  function closeFittingOrderAddDialog() {
    setOpenFittingOrderAddDialog(false);
  }

  function closePrescriptionOrderAddDialog() {
    setOpenPrescriptionOrderAddDialog(false);
  }

  function closeViewDialog() {
    setOpenViewDialog(null);
  }

  function closeViewCompanyOrderDialog() {
    setOpenViewCompanyOrderDialog(null);
  }

  function _renderTableCompany(orderType, data) {
    // if (searchTerm !== "") {
    //   data = _.filter(data, (i) => {
    //     return (
    //       i.code && i.code.toLowerCase().includes(searchTerm.toLowerCase())
    //     );
    //   });
    // }
    if (orderType === "Prescription") {
      data = _.orderBy(data, ["createdAt"], ["desc"]);
    }
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {/* {orderType === "Prescription" ? (
            <Button
              color="primary"
              onClick={() =>
                handleOpenConfirmationModal({
                  msg: "Do you want to print all records",
                  onPress: () => _onPrintAllOrders(),
                })
              }
            >
              Print All Records
            </Button>
          ) : (
            <div />
          )} */}
          <div>
            <CustomInput
              id="search"
              formControlProps={{
                fullWidth: false,
              }}
              inputProps={{
                style: {
                  color: "primary",
                  marginTop: 0,
                },
                placeholder: "Search",
                value: search,
                // onChange: (e) => searchData(e.target.value),
                onChange: (e) => {
                  setSearch(e.target.value);
                  if (e.target.value === "" || e.target.value === null) {
                    _getOrders(page, rowsPerPage);
                  }

                },
                // onChange: (e) => handleInputChange(e),
                onKeyDown: (e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    handleSearch();
                  }
                },
                // },onChange: (e) => setSearch(e.target.value),
                // onSubmit: (e) => searchData(e.target.value),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton color={"primary"} onClick={(e) => {
                      // setSearchTerm(e.target.value),
                      // handleKeyPress(e)
                      // handleKeyPress()
                      handleSearch()
                    }}>
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Box>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {orderType === "Prescription" ? (
                <Checkbox
                  style={{
                    color: "#512da8",
                  }}
                  onChange={(e) => {
                    let c = [];
                    if (e.target.checked) {
                      orders.map((i) => {
                        c.push(i._id);
                      });
                    }
                    setSelectedRows(c);
                  }}
                  checked={
                    selectedRows.length > 0 &&
                    orders.length === selectedRows.length
                  }
                  inputProps={{ "aria-labelledby": -1 }}
                />
              ) : null}
              {columnsCompany.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {orderType === "Prescription" ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          style={{
                            color: "#512da8",
                          }}
                          onChange={() => {
                            let copy = _.clone(selectedRows);
                            if (_.includes(copy, row._id)) {
                              let index = _.indexOf(copy, row._id);
                              copy.splice(index, 1);
                            } else {
                              copy.push(row._id);
                            }
                            setSelectedRows(copy);
                          }}
                          checked={_.includes(selectedRows, row._id)}
                          inputProps={{ "aria-labelledby": row._id }}
                        />
                      </TableCell>
                    ) : null}
                    {columnsCompany.map((column) => {
                      let value = row[column.id];

                      if (column.id === "action") {
                        return (
                          <TableCell align={"center"}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <IconButton
                                aria-label="view"
                                onClick={() =>
                                  setOpenViewCompanyOrderDialog({
                                    open: true,
                                    item: row,
                                  })
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              {row.orderAdminStatus === "Pending" && (
                                <React.Fragment>
                                  <IconButton
                                    aria-label="confirm"
                                    onClick={() =>
                                      handleOpenOrderDetailsModal({
                                        title: "Do you want to Accept Order",
                                        onPress: (data) =>
                                          _confirmOrder(row, data),
                                      })
                                    }
                                  // onClick={(data) => _confirmOrder(row, data)}
                                  >
                                    <CheckCircleIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="reject"
                                    onClick={() =>
                                      handleOpenOrderDetailsModal({
                                        title: "Do you want to Reject Order",
                                        onPress: (data) =>
                                          _rejectOrder(row, data),
                                      })
                                    }
                                  // onClick={(data) => _rejectOrder(row.code,data)}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </React.Fragment>
                              )}
                              {/* <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleOpenConfirmationModal({
                                    msg: "Do you want to delete this record?",
                                    title: "Delete Record",
                                    onPress: () => _deleteItem(row),
                                  })
                                }
                              >
                                <DeleteIcon />
                              </IconButton> */}
                            </Box>
                          </TableCell>
                        );
                      }

                      if (column.id === "totalOrders") {
                        return (
                          <TableCell key={column.id} align={"center"}>
                            {row.orders.length}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </GridItem>
    );
  }

  function _renderTable(orderType, data) {
    if (searchTerm !== "") {
      data = _.filter(data, (i) => {
        return (
          i.code && i.code.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    }
    // if (orderType==='Prescription'){
    //     data = _.orderBy(data, ['createdAt'], ['desc']);
    // }
    return (
      <GridItem xs={12} sm={12} md={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {/* {orderType === "Prescription" ? (
            <Button
              color="primary"
              onClick={() =>
                handleOpenConfirmationModal({
                  msg: "Do you want to print all records",
                  onPress: () => _onPrintAllOrders(),
                })
              }
            >
              Print All Records
            </Button>
          ) : (
            <div />
          )} */}
          <div>
            <CustomInput
              id="search"
              formControlProps={{
                fullWidth: false,
              }}
              inputProps={{
                style: {
                  color: "primary",
                  marginTop: 0,
                },
                placeholder: "Search",
                value: searchTerm,
                onChange: (e) => setSearchTerm(e.target.value),
                endAdornment: (
                  <InputAdornment position="end">
                    <People />
                  </InputAdornment>
                ),
              }}
            />
            {/* <IconButton
              aria-label="add"
              onClick={() => setOpenOrderTypeDialog(true)}
            >
              <AddIcon />
            </IconButton> */}
          </div>
        </Box>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {orderType === "Prescription" ? (
                <Checkbox
                  style={{
                    color: "#512da8",
                  }}
                  onChange={(e) => {
                    let c = [];
                    if (e.target.checked) {
                      orders.map((i) => {
                        c.push(i._id);
                      });
                    }
                    setSelectedRows(c);
                  }}
                  checked={
                    selectedRows.length > 0 &&
                    orders.length === selectedRows.length
                  }
                  inputProps={{ "aria-labelledby": -1 }}
                />
              ) : null}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {orderType === "Prescription" ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          style={{
                            color: "#512da8",
                          }}
                          onChange={() => {
                            let copy = _.clone(selectedRows);
                            if (_.includes(copy, row._id)) {
                              let index = _.indexOf(copy, row._id);
                              copy.splice(index, 1);
                            } else {
                              copy.push(row._id);
                            }
                            setSelectedRows(copy);
                          }}
                          checked={_.includes(selectedRows, row._id)}
                          inputProps={{ "aria-labelledby": row._id }}
                        />
                      </TableCell>
                    ) : null}
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id === "product") {
                        if (row.orderType === "Fitting") {
                          value =
                            row.orderDetail && row.orderDetail.productName
                              ? row.orderDetail.productName
                              : "";
                        } else if (row.orderType === "Ready") {
                          value =
                            row.orderDetail && row.orderDetail.product
                              ? row.orderDetail.product.name
                              : "";
                        } else {
                          value =
                            row.orderDetail && row.orderDetail.product
                              ? row.orderDetail.product.productName
                              : "";
                        }
                      }
                      if (column.id === "action") {
                        return (
                          <TableCell align={"center"}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <IconButton
                                aria-label="view"
                                onClick={() =>
                                  setOpenViewDialog({
                                    open: true,
                                    item: row,
                                  })
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              {row.orderReturnStatus === "Pending" && (
                                <React.Fragment>
                                  <IconButton
                                    aria-label="confirm"
                                    onClick={() =>
                                      handleOpenOrderResponseModal({
                                        title: "Do you want to Accept Order",
                                        onPress: (orderReturnAdminDetails) =>
                                          _confirmOrder(
                                            row,
                                            orderReturnAdminDetails
                                          ),
                                      })
                                    }
                                  >
                                    <CheckCircleIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="reject"
                                    onClick={() =>
                                      handleOpenOrderResponseModal({
                                        title: "Do you want to Reject Order",
                                        onPress: (orderReturnAdminDetails) =>
                                          _rejectOrder(
                                            row,
                                            orderReturnAdminDetails
                                          ),
                                      })
                                    }
                                  // onClick={() => _rejectOrder(row.code)}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </React.Fragment>
                              )}
                              {/* <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleOpenConfirmationModal({
                                    msg: "Do you want to delete this record?",
                                    title: "Delete Record",
                                    onPress: () => _deleteItem(row),
                                  })
                                }
                              >
                                <DeleteIcon />
                              </IconButton> */}
                            </Box>
                          </TableCell>
                        );
                      }

                      if (column.id === "party") {
                        return (
                          <TableCell key={column.id} align={"center"}>
                            {value.accountName}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </GridItem>
    );
  }

  //   let pendingFittingOrders = _.filter(orders, {
  //     orderType: "Fitting",
  //     orderStatus: "Pending",
  //   });
  //   let completedFittingOrders = _.filter(orders, {
  //     orderType: "Fitting",
  //     orderStatus: "Completed",
  //   });

  // update confirmed to pending
  let companyPrescriptionOrders = _.chain(orders)
    .filter((order) => order.orderReturnStatus === "Pending")
    .groupBy("party.accountCode")
    .map((value, key) => ({ party: key, orders: value, date: value[0].createdAt, totalAmt: _.sumBy(value.map((i) => i.orderAmount)), totalQty: _.sumBy(value.map((i) => i.quantity)) }))
    .value();

  let pendingPrescriptionOrders = _.filter(orders, {
    // orderType: "Prescription",
    orderReturnStatus: "Pending",
  });
  let confirmedPrescriptionOrders = _.filter(orders, {
    // orderType: "Prescription",
    orderReturnStatus: "Confirmed",
  });
  let rejectedPrescriptionOrders = _.filter(orders, {
    // orderType: "Prescription",
    orderReturnStatus: "Rejected",
  });
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          title=""
          headerColor="primary"
          tabs={[
            // {
            //   tabName: "Pending Fitting",
            //   tabIcon: BugReport,
            //   tabContent: _renderTable("Fitting", pendingFittingOrders),
            // },
            // {
            //   tabName: "Completed Fitting",
            //   tabIcon: BugReport,
            //   tabContent: _renderTable("Fitting", completedFittingOrders),
            // },
            {
              tabName: "Pending Company Orders",
              tabIcon: Code,
              tabContent: _renderTableCompany(
                "Prescription",
                companyPrescriptionOrders
              ),
            },
            {
              tabName: "Pending",
              tabIcon: Code,
              tabContent: _renderTable(
                "Prescription",
                pendingPrescriptionOrders
              ),
            },
            {
              tabName: "Confirmed",
              tabIcon: Cloud,
              tabContent: _renderTable(
                "Prescription",
                confirmedPrescriptionOrders
              ),
            },
            {
              tabName: "Rejected",
              tabIcon: Cloud,
              tabContent: _renderTable(
                "Prescription",
                rejectedPrescriptionOrders
              ),
            },
          ]}
        />
      </GridItem>

      {openViewDialog ? (
        <OrderView
          open={openViewDialog.open}
          close={closeViewDialog}
          item={openViewDialog.item}
        />
      ) : null}
      {openConfirmationModal ? (
        <ConfirmationDialog
          open={openConfirmationModal}
          onClose={handleCloseConfirmationModal}
          onConfirm={openConfirmationModal.onPress}
          title={openConfirmationModal.title}
          msg={openConfirmationModal.msg}
        />
      ) : null}
      {openOrderResponseDialog ? (
        <OrderReturnResponseDialog
          open={openOrderResponseDialog}
          onClose={handleCloseOrderResponsenModal}
          onConfirm={openOrderResponseDialog.onPress}
          title={openOrderResponseDialog.title}
          orderReturnAdminDetails={orderReturnAdminDetails}
          setOrderReturnAdminDetails={setOrderReturnAdminDetails}
        />
      ) : null}
      {openViewCompanyOrderDialog ? (
        <CompanyOrderReturnView
          open={openViewCompanyOrderDialog.open}
          close={closeViewCompanyOrderDialog}
          item={openViewCompanyOrderDialog.item}
          orderType={"Prescription"}
        />
      ) : null}
    </GridContainer>
  );
}
