import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@mui/material/TextField";
export function OrderReturnResponseDialog(props) {
  const {
    open,
    onClose,
    onConfirm,
    title,
    orderReturnAdminDetails,
    setOrderReturnAdminDetails,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description"> */}
        <TextField
          id="standard-search"
          label="Order Return Admin Message"
          type="search"
          variant="standard"
          onChange={(event) => setOrderReturnAdminDetails(event.target.value)}
          value={orderReturnAdminDetails}
          fullWidth
        />

        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          No
        </Button>
        <Button
          onClick={() => {
            onConfirm(orderReturnAdminDetails);
            onClose();
          }}
          color="primary"
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
