import jsPDF from "jspdf";
// import autotable from "jspdf-autotable";
import html2canvas from "html2canvas";

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const injectHTML = (htmlCode) => {
  let iframe = document.getElementById("previewIframe");

  let iframedoc = iframe.document;
  if (iframe.contentDocument) {
    iframedoc = iframe.contentDocument;
  } else if (iframe.contentWindow) {
    iframedoc = iframe.contentWindow.document;
  }

  if (iframedoc) {
    iframedoc.open();
    iframedoc.writeln(htmlCode);
    iframedoc.close();
  }
};

export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export async function htmlTemplateToDataString(
  htmlTemplate,
  options,
  callback
) {



  let pdf = new jsPDF(options);
  window.scrollTo(0, 0);
  window.html2canvas = html2canvas;
  pdf.html(htmlTemplate, {
    html2canvas: { scale: 1 },
    // margin: [10, 40, 10, 40],
    callback: async function (doc) {
      // console.log("aagya yaha", doc)
      let dataString = await doc.output("datauristring");
      callback(dataString);
    },
  });
}

let a = [
  "",
  "One ",
  "Two ",
  "Three ",
  "Four ",
  "Five ",
  "Six ",
  "Seven ",
  "Eight ",
  "Nine ",
  "Ten ",
  "Eleven ",
  "Twelve ",
  "Thirteen ",
  "Fourteen ",
  "Fifteen ",
  "Sixteen ",
  "Seventeen ",
  "Eighteen ",
  "Nineteen ",
];
let b = [
  "",
  "",
  "Twenty",
  "Thirty",
  "Forty",
  "Fifty",
  "Sixty",
  "Seventy",
  "Eighty",
  "Ninety",
];

export function inWords(num) {
  if ((num = num.toString()).length > 9) {
    return "overflow";
  }
  let n = ("000000000" + num)
    .substr(-9)
    .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) {
    return;
  }
  let str = "";
  str +=
    n[1] != 0
      ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "Crore "
      : "";
  str +=
    n[2] != 0
      ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "Lakh "
      : "";
  str +=
    n[3] != 0
      ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "Thousand "
      : "";
  str +=
    n[4] != 0
      ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "Hundred "
      : "";
  str +=
    n[5] != 0
      ? (str != "" ? "and " : "") +
      (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
      "Rupees Only "
      : "";
  return str;
}
export function inWords2(num) {
  const units = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const paise = [
    "Zero Paise",
    "One Paise",
    "Two Paise",
    "Three Paise",
    "Four Paise",
    "Five Paise",
    "Six Paise",
    "Seven Paise",
    "Eight Paise",
    "Nine Paise",
  ];

  let rupees = Math.floor(num);
  let paisa = Math.round((num - rupees) * 100);

  let rupeesInWords = "";

  if (rupees === 0) {
    rupeesInWords = "Zero";
  } else {
    if (Math.floor(rupees / 10000000) > 0) {
      rupeesInWords += inWords2(Math.floor(rupees / 10000000)) + " Crore ";
      rupees %= 10000000;
    }

    if (Math.floor(rupees / 100000) > 0) {
      rupeesInWords += inWords2(Math.floor(rupees / 100000)) + " Lakh ";
      rupees %= 100000;
    }

    if (Math.floor(rupees / 1000) > 0) {
      rupeesInWords += inWords2(Math.floor(rupees / 1000)) + " Thousand ";
      rupees %= 1000;
    }

    if (Math.floor(rupees / 100) > 0) {
      rupeesInWords += inWords2(Math.floor(rupees / 100)) + " Hundred ";
      rupees %= 100;
    }

    if (rupees > 0) {
      if (rupeesInWords !== "") rupeesInWords += "and ";
      // if (rupees = 10) rupeesInWords += "ten";
      if (rupees < 10) rupeesInWords += units[rupees];
      else if (rupees < 20) rupeesInWords += teens[rupees - 10];
      else {
        rupeesInWords += tens[Math.floor(rupees / 10)];
        if (rupees % 10 > 0) rupeesInWords += " " + units[rupees % 10];
      }
    }

    rupeesInWords += " ";
  }

  // let paisaInWords = paise[paisa];
  // if (paisa > 0) paisaInWords += " and " + paise[paisa];

  return rupeesInWords;
}

export function formatBytes(a, b) {
  if (0 == a) {
    return "0 Bytes";
  }
  let c = 1024,
    d = b || 2,
    e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
}


export function formatAmount(amount) {
  let [integerPart, decimalPart] = amount.toString().split('.');
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);  
  const formattedIntegerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + (otherDigits ? "," : "") + lastThreeDigits;
  return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
}