import React, { useEffect, useState } from "react";
import {
  Dialog,
  makeStyles,
  useMediaQuery,
  useTheme,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import axios from "axios";
import { GetAllBillsAPI, GetAllChallansAPI } from "../../config/api";
import DateUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import { useSelector } from "react-redux";
import { pointToPointBillTemplate } from "../../assets/html/pointToPointBillTemplate.js";
import { dataURItoBlob, htmlTemplateToDataString } from "../../utils/index.js";
import { pointToPointTemplate } from "../../assets/html/pointToPointTemplate.js";

const useStyles = makeStyles({
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    fontSize: "14px",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    fontWeight: "300",
    marginBottom: "3px",
  },
});

export default function PointToPoint(props) {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const { 
    PTPType, 
    openPTPTypeSelectionDialog, 
    setOpenPTPTypeSelectionDialog, 
    setPTPType, 
    startDate, 
    setStartDate, 
    endDate, 
    setEndDate, 
    item 
  } = props;

  const [bills, setBills] = useState([]);
  const [challans, setChallans] = useState([]);

  const userInfo = useSelector((state) => state.userReducer.userInfo);
  const authToken = useSelector((state) => state.userReducer.authToken);

  const types = [
    { label: "Challan", value: "challan" },
    { label: "Bill", value: "bill" },
  ];

  // Fetch Bills
  const _getBills = async () => {
    try {
      const query = `&userId=${item._id}&startDate=${moment(startDate).format("DD-MM-YYYY")}&endDate=${moment(endDate).format("DD-MM-YYYY")}`;
      const res = await axios.get(GetAllBillsAPI(userInfo.entity) + query, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      console.log("billres", res.data)

      setBills(res.data.bills);
    } catch (err) {
      console.error("Error fetching bills:", err);
    }
  };

  // Fetch Challans
  const _getChallans = async () => {
    try {
      const query = `&userId=${item._id}&isBilled=false&startDate=${moment(startDate).format("DD-MM-YYYY")}&endDate=${moment(endDate).format("DD-MM-YYYY")}`;
      const res = await axios.get(GetAllChallansAPI(userInfo.entity) + query, {
        headers: { Authorization: `Bearer ${authToken}` },
      });
      console.log("chalanres", res.data)
      setChallans(res.data.challans.reverse());
    } catch (err) {
      console.error("Error fetching challans:", err);
    }
  };

  // Generate Bill PDF
  const _generateBill = async () => {
    try {
      const htmlData = { bills, startDate, endDate };
      const htmlTemplate = pointToPointBillTemplate(htmlData);
      const options = { orientation: "p", unit: "pt", format: [850, 1066], putOnlyUsedFonts: true };
      await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
        const preBlob = dataURItoBlob(dataString);
        window.open(URL.createObjectURL(preBlob));
      });
    } catch (e) {
      console.error("Error generating bill:", e);
    }
  };

  // Generate Challan PDF
  const _generateChallan = async () => {
    try {
      const htmlData = { challans, challanDate: moment().format("DD-MM-YYYY"), startDate, endDate };
      const htmlTemplate = pointToPointTemplate(htmlData);
      const options = { orientation: "p", unit: "pt", format: [850, 1066], putOnlyUsedFonts: true };
      await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
        const preBlob = dataURItoBlob(dataString);
        window.open(URL.createObjectURL(preBlob));
      });
    } catch (e) {
      console.error("Error generating challan:", e);
    }
  };

  // Fetch data based on the selected type
  useEffect(() => {
    if (startDate && endDate) {
      PTPType === "challan" ? _getChallans() : _getBills();
    }
  }, [startDate, endDate, PTPType]);

  return (
    <Dialog
      onClose={() => setOpenPTPTypeSelectionDialog(false)}
      open={openPTPTypeSelectionDialog}
      fullScreen={fullScreen}
    >
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Select Type</h4>
            </CardHeader>
            <CardBody>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="select-type-label">Type</InputLabel>
                <Select
                  labelId="select-type-label"
                  value={PTPType}
                  onChange={(e) => setPTPType(e.target.value)}
                >
                  {types.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <MuiPickersUtilsProvider utils={DateUtils}>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <DatePicker
                    label="Start Date"
                    fullWidth
                    value={startDate}
                    onChange={setStartDate}
                  />
                </GridItem>
                <GridItem xs={12} style={{ marginTop: 20 }}>
                  <DatePicker
                    label="End Date"
                    fullWidth
                    value={endDate}
                    onChange={setEndDate}
                  />
                </GridItem>
              </MuiPickersUtilsProvider>
            </CardBody>
            <CardFooter style={{ justifyContent: "center" }}>
              <Button
                color="primary"
                onClick={() => {
                  if (PTPType === "bill") {
                    _generateBill();
                  } else {
                    _generateChallan();
                  }
                  setOpenPTPTypeSelectionDialog(false);
                }}
              >
                Submit
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
