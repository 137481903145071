import React, { useState } from "react";
import _ from "lodash";
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import {
  Box,
  Button,
  Dialog,
  makeStyles,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Card from "../../components/Card/Card";
import axios from "axios";
import { baseUrl } from "../../config/api";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";

const styles = {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function ImportExcel(props) {
  const { open, close, db } = props;
  let authToken = useSelector((state) => state.userReducer.authToken);
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const [alertObject, setAlertObject] = useState(null);
  const [tableData, setTableData] = useState([]);
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertObject(null);
  };

  function _changeHandler(event) {
    let fileObj = event.target.files[0];
    //just pass the fileObj as parameter
    if (fileObj) {
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          console.log(err);
        } else {
          setCols(resp.cols);
          setRows(resp.rows);
          let rowHeaders = resp.rows[0];
          let rows = resp.rows;
          let data = [];
          for (let i = 1; i <= rows.length; i++) {
            if (rows[i]) {
              let object = {};
              rows[i].map((item, index) => {
                _.merge(object, { [rowHeaders[index]]: item });
              });
              data.push(object);
            }
          }
          setTableData(data);
        }
      });
    }
  }

  async function _onUpload() {
    try {
      let url = `${baseUrl}/${db}/bulk-upload`;
      let res = await axios.post(
        url,
        { data: tableData, entity: userInfo.entity },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setAlertObject({
        status: "success",
        message: "Data uploaded successfully",
      });
      window.location.reload();
    } catch (e) {
      setAlertObject({
        status: "error",
        message: "Something went wrong please check the format",
      });
      console.log("err:", e);
    }
    setTimeout(() => close(), 2000);
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={"md"}
      fullWidth
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <GridContainer spacing={2}>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <h4 className={classes.cardTitleWhite}>Upload File</h4>
                <Button color="primary" variant="contained" onClick={_onUpload}>
                  Upload
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <input
                    className="btn"
                    type="file"
                    onChange={_changeHandler}
                    style={{ padding: "10px" }}
                  />
                  <OutTable
                    data={rows}
                    columns={cols}
                    tableClassName="ExcelTable2007"
                    tableHeaderRowClass="heading"
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {alertObject ? (
        <Snackbar
          open={alertObject}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={alertObject.status}>
            {alertObject.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Dialog>
  );
}
