import React from "react";
// @material-ui/core components
import {
  Box,
  Button,
  Dialog,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import _ from "lodash";
import moment from "moment";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  { id: "nameR", label: "Name R" },
  { id: "nameL", label: "Name L" },
  { id: "quantity", label: "Quantity" },
  { id: "amount", label: "Amount" },
];

export default function ChallanView(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { open, close, item } = props;
  const { party, status, amount, items, packagingCost, packagingTaxPercent, challanDate } = item;
  console.log(item, "My item in view")

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Challan Details</h4>
                <Button style={{ color: "white" }} onClick={close}>
                  Close
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    labelText="Party Name"
                    id="partyName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: party?.accountName ? party.accountName : "",
                      disabled: true,
                    }}
                  />
                </GridItem>

                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    labelText="Status"
                    id="status"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: status,
                      disabled: true,
                    }}
                  />
                </GridItem>
                
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    labelText="Challan Date"
                    id="challanDate"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: moment(challanDate).format('DD-MM-yyyy'),
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Amount"
                    id="amount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: amount,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Packaging Cost"
                    id="Packaging Cost"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: packagingCost,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Packaging Tax Percent"
                    id="Packaging Tax Percent"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: packagingTaxPercent,
                      disabled: true,
                    }}
                  />
                </GridItem>
                {_.isEmpty(items) ? null : (
                  <GridItem xs={12}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={"center"}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items.map((row, index) => {
                          return (
                            <TableRow
                              key={index}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                            >
                              {columns.map((column) => {
                                let value = row[column.id];
                                if (column.id === "nameL") {
                                  value = row.nameL + " " + row.codeL;
                                } else if (column.id === "nameR") {
                                  value = row.nameR + " " + row.codeR;
                                }
                                return (
                                  <TableCell key={column.id} align={"center"}>
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
