import React, { useEffect, useState } from "react";
import {
  Box,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import {
  GetAllLedgerRequestsAPI,
  UpdateLedgerRequestAPI,
  DeleteLedgerRequestAPI,
} from "../../config/api";
import FittingMasterAdd from "../Master/FittingMasterAdd";
// import BankAdd from "./BankAdd";
import { CloudDownload, CloudUpload, Search } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { ConfirmationDialog } from "../../components/common";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CustomInput from "../../components/CustomInput/CustomInput";
import People from "@material-ui/icons/People";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify"

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const columns = [
  {
    id: "accountId",
    label: "Account",
    format: (value) => value?.name,
  },
  {
    id: "userId",
    label: "User",
    format: (value) => value?.name,
  },
  {
    id: "startDate",
    label: "Start Date",
    format: (value) => moment(value).format("DD/MM/YYYY"),
  },
  {
    id: "endDate",
    label: "End Date",
    format: (value) => moment(value).format("DD/MM/YYYY"),
  },
  {
    id: "validTill",
    label: "Validity",
    format: (value) => (value ? moment(value).format("DD/MM/YYYY") : ""),
  },
  { id: "adminStatus", label: "Admin Status" },
  { id: "action", label: "Action" },
];

export default function LedgerRequestsList() {
  const classes = useStyles();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);
  const [ledgerRequests, setLedgerRequests] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [tota, setTota] = useState(0);
  const [message, setMessage] = useState("");

  useEffect(() => {
    _getLedgerRequests(page, rowsPerPage);
  }, [page, rowsPerPage]);

  async function searchData(term) {
    setSearchTerm(term);
    let res = await axios.get(
      GetAllLedgerRequestsAPI(userInfo.entity) +
      `&limit=${rowsPerPage}&skip=${page * rowsPerPage}&code=${term}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    setLedgerRequests(res.data.ledgerRequest);
  }

  async function _getLedgerRequests(page, rowsPerPage) {
    let query = `&limit=${rowsPerPage}&skip=${page * rowsPerPage}`
    try {
      let res = await axios.get(GetAllLedgerRequestsAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setLedgerRequests(res.data.ledgerRequest);
    } catch (err) {
      // console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  // console.log(ledgerRequests)

  async function _confirmItem(item) {
    try {
      let res = await axios.patch(
        UpdateLedgerRequestAPI(item._id),
        { adminStatus: "Confirmed" },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success('Successfully accepted!', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      _getLedgerRequests();
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _rejectItem(item) {
    try {
      let res = await axios.patch(
        UpdateLedgerRequestAPI(item._id),
        { adminStatus: "Rejected" },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      toast.success('Successfully Rejected!', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      _getLedgerRequests();
    } catch (e) {
      // console.log("err:", e);
      //  toast.error(("Something went wrong, Please Contact Admin") , {
      // position: "top-center",
      // autoClose: 1500,
      // hideProgressBar: false,
      // closeOnClick: true,
      // pauseOnHover: true,
      // draggable: true,
      // progress: undefined,
      // theme: "light",
      // }); 
    }
  }
  async function _deleteItem(item) {
    try {
      let res = await axios.delete(DeleteLedgerRequestAPI(item._id), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      toast.success('Successfully Deleted!', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      _getLedgerRequests();
    } catch (e) {
      console.log("err:", e);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function closeAddDialog() {
    setOpenAddDialog(false);
  }

  const handleOpenConfirmationModal = (object) => {
    setOpenConfirmationModal(object);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(null);
  };

  let data = ledgerRequests;
  // if (searchTerm !== "") {
  //   data = _.filter(data, (i) => {
  //     return (
  //       (i.accountId &&
  //         i.accountId.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
  //       (i.userId &&
  //         i.userId.name.toLowerCase().includes(searchTerm.toLowerCase()))
  //     );
  //   });
  // }

  function handleSearch() {
    searchData(search);
  }
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <Box
              style={{
                display: "flex",
                flex: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4 className={classes.cardTitleWhite}>Ledger Requests</h4>
              {/* <Box
                style={{
                  display: "flex",
                  flex: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomInput
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    style: {
                      color: "primary",
                      marginTop: 0,
                    },
                    placeholder: "Search",
                    value: search,
                    // onChange: (e) => searchData(e.target.value),
                    onChange: (e) => setSearch(e.target.value),
                    // onSubmit: (e) => searchData(e.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton color={"primary"} onClick={(e) => {
                          // setSearchTerm(e.target.value),
                          // handleKeyPress(e)
                          // handleKeyPress()
                          handleSearch()
                        }}>
                          <Search />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <IconButton
                  aria-label="add"
                  style={{ color: "white" }}
                  onClick={() => setOpenAddDialog(true)}
                >
                  <AddIcon />
                </IconButton> */}
              {/* </Box>  */}
            </Box>
          </CardHeader>
          <CardBody>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={"center"}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          let value = row[column.id];
                          if (column.id === "action") {
                            return (
                              <TableCell align={"center"}>
                                <Box display={"flex"} justifyContent={"center"}>
                                  {row["adminStatus"] === "Pending" && (
                                    <>
                                      <IconButton
                                        aria-label="confirm"
                                        onClick={() => {
                                          setMessage(
                                            "Do you want to provide access"
                                          );
                                          handleOpenConfirmationModal({
                                            onPress: () => _confirmItem(row),
                                          });
                                        }}
                                      >
                                        <CheckCircleIcon />
                                      </IconButton>
                                      <IconButton
                                        aria-label="Reject"
                                        onClick={() => {
                                          setMessage(
                                            "Do you want to reject access"
                                          );
                                          handleOpenConfirmationModal({
                                            onPress: () => _rejectItem(row),
                                          });
                                        }}
                                      >
                                        <CancelIcon />
                                      </IconButton>
                                    </>
                                  )}
                                  <IconButton
                                    aria-label="Delete"
                                    onClick={() => {
                                      setMessage(
                                        "Do you want to delete request"
                                      );
                                      handleOpenConfirmationModal({
                                        onPress: () => _deleteItem(row),
                                      });
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={column.id} align={"center"}>
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      </GridItem>

      {openConfirmationModal ? (
        <ConfirmationDialog
          open={openConfirmationModal}
          onClose={handleCloseConfirmationModal}
          onConfirm={openConfirmationModal.onPress}
          title={"Ledger Request Access"}
          msg={message}
        />
      ) : null}
    </GridContainer>
  );
}
