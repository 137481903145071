import React from "react";
// @material-ui/icons
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import InboxIcon from '@mui/icons-material/Inbox';
// core components
import ReadyMasterList from "./ReadyMasterList";
import ProductMasterList from "./ProductMasterList";
import Tabs from "../../components/CustomTabs/CustomTabs";
import FittingMasterList from "./FittingMasterList";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

let tabs = [
  {
    tabName: "Ready",
    tabIcon: BugReport,
    tabContent: <ReadyMasterList />,
  },
  {
    tabName: "Rx Products",
    tabIcon: InboxIcon,
    tabContent: <ProductMasterList />,
  },
  {
    tabName: "Fitting",
    tabIcon: Code,
    tabContent: <FittingMasterList />,
  },
];

export default function Master(props) {
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Tabs title="Master:" headerColor="rose" tabs={tabs} />
      </GridItem>
    </GridContainer>
  );
}
