import {IS_COMMUNICATING, IS_DEVICE_ONLINE, UPDATE_NETWORK_REQUEST, SHOW_IMAGE} from '../actions/types';

const DEFAULT_STATE = {
    isOnline: true,
    isCommunicating: false,
    networkRequest: null,
    imageVisible: false,
    imageSource: null,
};

export default (state = DEFAULT_STATE, {type, payload} = {}) => {
    switch (type) {
        case IS_DEVICE_ONLINE:
            return {...state, isOnline: payload};
        case IS_COMMUNICATING:
            return {...state, isCommunicating: payload};
        case UPDATE_NETWORK_REQUEST:
            return {...state, networkRequest: payload};
        case SHOW_IMAGE:
            return {...state, imageVisible: payload.imageVisible, imageSource: payload.imageSource};
        default:
            return state;
    }
}
