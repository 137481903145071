import _ from "lodash";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  Box,
  Button,
  Card,
  IconButton,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import BackupIcon from "@material-ui/icons/Backup";
import { baseUrl } from "../../config/api";
import { useSelector } from "react-redux";
import Pagination from "@mui/material/Pagination";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  input: {
    display: "none",
  },
  deleteIcon: {},
});

export const FilePicker = (props) => {
  const authToken = useSelector((state) => state.userReducer.authToken);
  const { buttonLabel, maxFiles, setNewFiles, id, filetype } = props;
  const classes = useStyles();
  const [files, setFiles] = useState(props.files);
  const inputFile = useRef(null);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(null);

  useEffect(() => {
    setFiles(props.files);
  }, [props.files]);

  function handleChangeFile(event) {
    const file = event.target.files;
    console.log("file:", file);
    // if (!file) {
    //   return;
    // }
    const arr = Array.from(file);

    let copy = _.isEmpty(files) ? [] : _.clone(files);
    arr.map((fl) => {
      let f = {
        name: fl.name,
        size: fl.size,
        type: fl.type,
        path: fl,
      };

      copy.push(f);
    });
    console.log(copy);
    // setFiles(copy);
    setNewFiles(copy);
  }

  const handleChange = (event, value) => {
    setPage(value);
  };

  async function _deleteFile(id, index) {
    try {
      //   let res = await axios.delete(deleteFileAPI + id, {
      //     headers: {
      //       Authorization: authToken,
      //     },
      //   });
      let copy = _.clone(files);
      copy.splice(index, 1);
      setFiles(copy);
    } catch (e) {}
  }

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  function _renderImage(file, index) {
    // console.log(file.name.split(".").pop());
    let src = "",
      children = null;
    if (!file.path) {
      children = <BackupIcon />;
    } else if (file.name.split(".").pop() === "pdf") {
      children = (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Document
            file={file.path}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <div style={{ display: "block", margin: "auto" }}>
                <CircularProgress />
              </div>
            }
          >
            {Array.from(new Array(numPages), (el, index) => {
              if (index === page - 1) {
                return (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                );
              }
            })}
          </Document>
          <Pagination
            count={numPages}
            page={page}
            onChange={handleChange}
            color="primary"
            className={classes.pagination}
          />
        </Box>
      );
    } else if (typeof file.path === "object") {
      src = URL.createObjectURL(file.path);
      children = (
        <img
          alt={"image"}
          src={src}
          style={{ aspectRation: 1, width: 350, height: 200 }}
        />
      );
    } else {
      src = baseUrl + "/" + file.path;
      children = (
        <img
          alt={"image"}
          src={src}
          style={{ aspectRation: 1, width: 350, height: 200 }}
        />
      );
    }
    return children;
  }

  console.log(files);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      // alignItems={"center"}
      justifyContent={"center"}
      margin={"30px 0 0 0"}
    >
      <input
        ref={inputFile}
        multiple={maxFiles > 1 ? true : false}
        className={classes.input}
        // accept={".png,.jpg,.jpeg,.pdf"}
        accept={filetype}
        id={id}
        type="file"
        onChange={handleChangeFile}
      />
      <Button
        variant="contained"
        onClick={() => {
          if (files && maxFiles && maxFiles <= files.length) {
            return alert(`Only ${maxFiles} can be uploaded`);
          }
          inputFile.current.click();
        }}
        color="primary"
        className={classes.button}
        startIcon={<CloudUploadIcon />}
      >
        {buttonLabel}
      </Button>
      {files && files.length > 0 && (
        <Box
          display={"flex"}
          flexDirection={"row"}
          margin={2}
          // justifyContent={"center"}
          style={{ overflowX: "scroll" }}
        >
          {files.map((file, index) => {
            console.log("file", file);
            return (
              <div>
                <Card
                  style={{
                    width: "100%",
                    display: "flex",
                    marginRight: 10,
                    alignItems: "center",
                    overflow: "hidden",
                    justifyContent: "center",
                    padding: 10,
                  }}
                >
                  <Box flexDirection={"column"}>
                    <Box
                      alignItems={"center"}
                      justifyContent={"flex-end"}
                      backgroundColor={"green"}
                      flex={1}
                      display={"flex"}
                    >
                      <IconButton
                        aria-label="delete"
                        style={{ alignSelf: "flex-end" }}
                        onClick={() => {
                          if (file._id) {
                            return _deleteFile(file._id, index);
                          }
                          let copy = _.clone(files);
                          copy.splice(index, 1);
                          setFiles(copy);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                    {_renderImage(file, index)}
                  </Box>
                </Card>
              </div>
            );
          })}
        </Box>
      )}
    </Box>
  );
};
