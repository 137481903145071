import _ from "lodash";
import { inWords, inWords2 } from "../../utils";

export const challan1Template = (data) => {
  let { clientAddress, clientName, challanDate, items, quantity, entityId, challanCode, entityName, packagingCost, runningChallanBalance, createdAt } =
    data;

  let code = ""
  if (entityId === "62da46cf7eb6aa02db41297f") {
    code = `VO`;
  } else if (entityId === "62da46c57eb6aa02db41297d") {
    code = `IE`;
  } else {
    code = `VR`;
  }


  console.log(data, "this is data in challan template")

  let totalAmountInWords = "",
    totalQuantity = 0,
    totalAmount = 0;

  let rows = "";
  items.map((o, index) => {
    let {
      nameR,
      nameL,
      lProductCode,
      rProductCode,
      rate,
      rateL,
      rateR,
      quantity,
      amount,
    } = o;
    // let productCode =
    //   nameR +
    //   "<br/>" +
    //   "(" + rProductCode.substring(3, rProductCode.length) + ")" +
    //   "(" +
    //   lProductCode.substring(3, lProductCode.length) + ")";
    let productCode = "";

    if (nameR) {
      productCode += nameR + " " + " (" + rProductCode.substring(3) + ")" + "<br/>";
    }

    if (nameL) {
      productCode += (productCode ? " " : "") + nameL + " " + " (" + lProductCode.substring(3) + ")" + "<br/>";
    }
    let item = { productCode, quantity, rate, amount, index };
    totalAmount += Number(amount);
    totalQuantity += Number(quantity);
    rows = rows + renderTableRow(item) + "\n";
  });

  totalAmountInWords = inWords2(totalAmount + Number(packagingCost));

  let challanCodeUpdate = challanCode.split("-");
  let challanCodeValue = challanCodeUpdate[0] + "-" + challanCodeUpdate[3];

  return `<!DOCTYPE html>
<html lang="en">
<head>
  <title>Challan 1</title>
  <meta charset="UTF-8">
  <style>
  @media print {
      body {
        width: 80mm; /* Set width to 80mm */
        height: auto; /* Allow height to adjust automatically */
        overflow: hidden; /* Prevent overflow */
        margin: 0; /* Remove default margin */
      }
      table tr {
        page-break-inside: avoid;
      }
      .no-break {
        white-space: nowrap;
      }
    }
      table {
  width: 100%; /* Use full width */
  table-layout: fixed; /* Ensure the layout respects the widths set in <th> */
}
    h2 {
      margin: 1px;
      font-size: 12pt;
    }
    h4 {
      margin: 1pt;
      font-size: 8pt;
    }

    h3 {
      margin: 1pt;
      font-size: 8pt;
    }

    h5 {
      margin: 1pt;
      font-size: 8pt;
    }

    p {
      margin: 1pt;
      font-size: 8pt;
    }

    .no-break {
  white-space: nowrap;
 }

    th, td {
      font-weight: bold;
      font-size: 10px;
      border-collapse: collapse;
      border-bottom: 1px solid black;
      padding-bottom : 4px
    }
    @media print {
      tr {
        page-break-inside: avoid;
      }
      .pagebreak {
 display: block;
 clear: both;
 page-break-after: always;
}
    }
  </style>
</head>
<body style="white-space: nowrap; width: 20mm">
<div style="height: 300px; width: 333px;">
<div style="display:flex;flex:1;flex-direction:column;margin: 10px" >
  <div style="display: flex;flex-direction: row;justify-content: space-between">
  <h4>${code}<h4/>
  <p>${challanCodeValue}</p>
 
  </div>
  <div style="display: flex;flex-direction: row;justify-content: space-between">
 
  <h4 style="font-size:10px"> M/S : ${clientName}</h4>
    <p>Challan Date : ${createdAt}</p>
  </div>
  <div style="margin-top: 2px">
    <table style="white-space: nowrap;">
      <tr style="white-space: nowrap;">
        <th style="width: 5%;"></th>
        <th style="width: 65%;text-align: left;">Product Description</th>
        <th style="width: 10%">Qty</th>
        <th style="width: 10%">Net Rate</th>
        <th style="width: 10%">Amt</th>
      </tr>
      ${rows}
      <tr style='white-space: nowrap;'>
      <th style="width: 0%;"></th>
      <th style="width: 70%;text-align: center;">Packaging Cost</th>
      <th style="width: 10%"></th>
      <th style="width: 10%"/>
      <th style="width: 10%">${_.round(Number(packagingCost), 2).toFixed(
    2
  )}</th>
    </tr>
      <tr style='white-space: normal;'>
        <th style="width: 0%;"></th>
        <th style="width: 70%;text-align: center;">Total</th>
        <th style="width: 10%">${totalQuantity}</th>
        <th style="width: 10%"/>
        <th style="width: 10%">${_.round(totalAmount + Number(packagingCost), 2).toFixed(
    2
  )}</th>
      </tr>
    </table>
  </div>
  <div style="display: flex;flex-direction: row;justify-content: space-between; white-space: normal; page-break-inside: avoid;">
    <div>
      <h3>In Words : ${totalAmountInWords} Rupees Only</h3>
      <h3 style='page-break-inside: avoid;'>E & OE</h3>
      <p>Working Hours : 10.30 AM to 7.00 PM</p>
    </div>
    <div>
    ${runningChallanBalance ? `
    <h4>Balance Upto Date : ${runningChallanBalance}</h4>
    ` : ``}
    </div>
  </div>
   
</body>
</html>
`;
};

let renderTableRow = (item) => {
  let { name, productCode, quantity, rate, rateL, rateR, amount, index } = item;
  return `<tr style='white-space: normal;'>
        <th style="width: 5%;">${index + 1}</th>
        <th style="width: 65%;text-align: left; columns:2; max-width: 100px">${productCode}</th>
        <th style="width: 10%">${quantity}</th>
        <th style="width: 10%">${rate}</th>
        <th style="width: 10%">${_.round(amount, 2).toFixed(2)}</th>
      </tr>`;
};
