import React, { useEffect, useState } from "react";
// @material-ui/core components
import {
  Dialog,
  makeStyles,
  Slide,
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import { ConfirmationDialog } from "../../components/common";
import axios from "axios";
import { UpdateAccountAPI } from "../../config/api.js";
import { useSelector } from "react-redux";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};
const columns = [
  { id: "product", label: "Product" },
  { id: "rate", label: "Rate" },
  { id: "discountPercent", label: "Discount Percent" },
  { id: "discountedPrice", label: "Discount Price" },
  { id: "type", label: "Discount Type" },
  { id: "action", label: "Action" },
];
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function DiscountEdit(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { open, close, item } = props;
  const { discounts } = item;
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const authToken = useSelector((state) => state.userReducer.authToken);
  const userInfo = useSelector((state) => state.userReducer.userInfo);
  useEffect(() => { }, [item]);

  async function _deleteItem(doc, index) {
    let copy = _.clone(item.discounts);
    copy.splice(index, 1);
    let res = await axios.patch(
      UpdateAccountAPI(item._id),
      {
        discounts: copy,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    close();
  }

  const handleOpenConfirmationModal = (object) => {
    setOpenConfirmationModal(object);
  };
  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(null);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Discount Detail</h4>
                <Button style={{ color: "white" }} onClick={close}>
                  Close
                </Button>
              </Box>
              {/*<p className={classes.cardCategoryWhite}>Complete your profile</p>*/}
            </CardHeader>
            <CardBody>
              <GridContainer>
                {_.isEmpty(discounts) ? null : (
                  <GridItem xs={12}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {discounts.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                let value = row[column.id];
                                if (column.id === "product") {
                                  value = value.productName;
                                }
                                if (column.id === "action") {
                                  return (
                                    <TableCell align={"center"}>
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                      >
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() =>
                                            handleOpenConfirmationModal({
                                              onPress: () =>
                                                _deleteItem(row, index),
                                            })
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </TableCell>
                                  );
                                }
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {openConfirmationModal ? (
        <ConfirmationDialog
          open={openConfirmationModal}
          onClose={handleCloseConfirmationModal}
          onConfirm={openConfirmationModal.onPress}
          title={"Delete Record"}
          msg={"Do you want to delete this record?"}
        />
      ) : null}
    </Dialog>
  );
}
