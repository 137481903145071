//App
export const FCM_TOKEN_REFRESH = "FCM_TOKEN_REFRESH";
export const IS_DEVICE_ONLINE = "IS_DEVICE_ONLINE";
export const IS_COMMUNICATING = "IS_COMMUNICATING";
export const SHOW_IMAGE = "SHOW_IMAGE";
export const UPDATE_NETWORK_REQUEST = "UPDATE_NETWORK_REQUEST";
export const READY_MASTER = "READY_MASTER";

//User
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const UPDATE_USER_INFO = "UPDATE_INFO";
export const UPDATE_AUTH_PIN = "UPDATE_AUTH_PIN";
export const LOGOUT = "LOGOUT";
