import React, { useState } from "react";
// @material-ui/core components
import { Box, Dialog, Modal, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import axios from "axios";
import { CreateBankAPI, GetAllChallansAPI, GetAllLedgersAPI, RoundOffAPI } from "../../config/api";
import DateUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import CustomInput from "../../components/CustomInput/CustomInput";
import { toast } from "react-toastify";
import moment from "moment";
import { useSelector } from "react-redux";
import _ from "lodash";

const styles = {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

const sides = ["B", "L", "R"];

export default function RoundOff(props) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    open,
    close,
    item,
    fetchData,
    openRoundOffChallanDialog,
    setOpenRoundOffChallanDialog,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  } = props;

  console.log(">>>>", item)

  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  //   const [startDate, setStartDate] = useState(null);
  //   const [endDate, setEndDate] = useState(null);
  const [openingBalance, setOpeningBalance] = useState("");
  const [challans, setChallans] = useState([]);
  const [initialAmount, setInitialChalanAmount] = useState(0);
  // setInitialChalanAmount(item.runningChallanBalance)
  const authToken = useSelector((state) => state.userReducer.authToken);

  let userInfo = useSelector((state) => state.userReducer.userInfo);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDateError, setStartDateError] = useState('');
  const [endDateError, setEndDateError] = useState('');


  console.log("challans>>86", challans)

  const validateDates = () => {
    let valid = true;
    if (!startDate) {
      setStartDateError('Start date is required');
      valid = false;
    } else {
      setStartDateError('');
    }
    if (!endDate) {
      setEndDateError('End date is required');
      valid = false;
    } else {
      setEndDateError('');
    }
    return valid;
  };


  // async function _getChallans(startDate, endDate) {
  //   try {
  //     console.log("the run")

  //     let query = `&isBilled=${false}&skip=undefined&limit=undefined&activityType=sale&transactionType=${"challan"}&transactionType=${"credit"}&activityType=${"purchase"}`;
  //     if (startDate && endDate) {
  //       let sd = moment(startDate).format("DD-MM-YYYY");
  //       let ed = moment(endDate).format("DD-MM-YYYY");
  //       query += `&startDate=${sd}&endDate=${ed}`;
  //     }
  //     let res = await axios.get(GetAllLedgersAPI(userInfo.entity) + query, {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });

  //     console.log("space", res.data.ledgers.length)
  //     setChallans(res.data.ledgers)


  //     let fd = _.sortBy(res.data, obj => obj.date);


  //     setChallans(fd);
  //     // setCheckedState(new Array(res.data.challans.length).fill(false));
  //     let amount = 0;
  //     // res.data.ledgers.map((item) => {
  //     //   if (item.amount) {
  //     //     amount += parseInt(item.amount);
  //     //     if (item.transactionType == "credit") {
  //     //       amount -= parseInt(item.amount)
  //     //     }
  //     //   }
  //     // });
  //     const totalAmount = res.data.ledgers.reduce((acc, item) => {
  //       // console.log(">>>>140", item)
  //       if (item.amount) {
  //         if (item.transactionType === "challan") {
  //           acc += parseInt(Number(item.amount));
  //           console.log("accafterchallan", acc)
  //         }
  //         else if (item.transactionType === "credit") {
  //           acc -= parseInt(Number(item.amount));
  //           console.log("accaftercredit", acc)

  //         } else if (item.transactionType === "debit") {
  //           acc += parseInt(Number(item.amount));
  //           console.log("accafterdebit", acc)
  //         }
  //       }
  //       return acc;
  //     }, 0);
  //     setInitialChalanAmount(totalAmount);
  //   } catch (err) {
  //     // console.log("error:", err);
  //     // toast.error(("Something went wrong, Please Contact Admin") , {
  //     //   position: "top-center",
  //     //   autoClose: 1500,
  //     //   hideProgressBar: false,
  //     //   closeOnClick: true,
  //     //   pauseOnHover: true,
  //     //   draggable: true,
  //     //   progress: undefined,
  //     //   theme: "light",
  //     //   }); 
  //   }
  // }

  async function _getChallans(startDate, endDate) {
    try {
      let query;
      // if (ledgerType === "PurchaseBill") {
      //   query = `&accountId=${item._id}&activityType=${"purchase"}&transactionType=${"bill"}`;
      // }
      // else if (ledgerType === "PurchaseChallan") {
      //   query = `&accountId=${item._id}&activityType=${"purchase"}&transactionType=${"challan"}`;
      // }
      // else if (ledgerType === "Challan") {
      //   query = `&accountId=${item._id}&activityType=sale&transactionType=${"challan"}&transactionType=${"credit"}&transactionType=${"debit"}&activityType=${"purchase"}`;
      // }
      // else if (ledgerType === "Bill") {
      //   query = `&accountId=${item._id}&activityType=sale&transactionType=${"bill"}`;
      // }
      // else if (ledgerType === "CreditNote") {
      //   query = `&accountId=${item._id}&activityType=sale&transactionType=${"credit"}`;
      // } else if (ledgerType === "DebitNote") {
      //   query = `&accountId=${item._id}&activityType=sale&transactionType=${"debit"}`;
      // }
      if (startDate && endDate) {
        let sd = moment(startDate).format("DD-MM-YYYY");
        let ed = moment(endDate).format("DD-MM-YYYY");
        query = `&startDate=${sd}&endDate=${ed}&accountId=${item._id}&activityType=sale&transactionType=${"challan"}&transactionType=${"credit"}&transactionType=${"debit"}&activityType=${"purchase"}`;

        // query = `&accountId=${item._id}&activityType=sale&transactionType=${"challan"}&transactionType=${"credit"}&transactionType=${"debit"}&activityType=${"purchase"}`
      }
      let res = await axios.get(GetAllLedgersAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log("space", res.data.ledgers)



      let fd = _.sortBy(res.data.ledgers, obj => obj.date);
      console.log('tempBalance before', fd)
      setChallans(fd);
      let totalAmount = fd.reduce((acc, item) => {
        if (item.amount) {
          if (item.type === "challan") {
            console.log("add", acc, item.amount, item.transactionType)
            acc += parseInt(Number(item.amount));
          }
          else if (item.type === "credit") {
            console.log("subs", acc, item.amount, item.transactionType)
            acc -= parseInt(Number(item.amount));
          }
          else if (item.type === "debit") {
            console.log("add", acc, item.amount, item.transactionType)
            acc += parseInt(Number(item.amount));
          }
        }
        console.log("add hogya", acc)
        return acc;
      }, 0);
      
      totalAmount = totalAmount + item.openingChallanBalance;
      setInitialChalanAmount(totalAmount);

    } catch (err) {
      // toast.error(('Cannot fetch data'), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  async function _onSearch() {
    try {
      // let data = { startDate, endDate, openingBalance };
      //   let res = await axios.post(RoundOffAPI(item._id), data);
      //   close();
      //   window.location.reload();
      //   fetchData();
      // close();
      // setOpenRoundOffChallanDialog(true);
      if (validateDates()) {
        _getChallans(startDate, endDate)
      }

    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _onSave() {
    try {
      setIsModalOpen(true);
      // let data = { startDate, endDate, openingBalance };
      //   let res = await axios.post(RoundOffAPI(item._id), data);
      //   close();
      //   window.location.reload();
      //   fetchData();
      // close();
      // setOpenRoundOffChallanDialog(true);

      // _getChallans(startDate, endDate)
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = async () => {
    if (validateDates()) {
      try {
        // Extract the IDs from the challans array
        const ids = challans.map(challan => challan._id);

        let challanTotal = initialAmount
        // Create a data object containing the IDs
        const data = { challanIds: ids, openingBalance: openingBalance, startDate: startDate, endDate: endDate, challanTotal: challanTotal };

        // Send the data object to the API
        const response = await axios.post(RoundOffAPI(item._id), data, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        // Handle the response as needed
        console.log('API response:', response);
        if (response.status === 200 || 201) {
          // Close the modal
          window.location.reload();
          setIsModalOpen(false);
          close();
          
          toast.success("Round Off Success")
          
        } else {
          toast.error(`Error ${response.status}`)
        }

        window.location.reload();

      } catch (error) {
        console.error('Error sending data to API:', error);

        // Optionally, you can show an error message to the user
        // toast.error("Something went wrong, Please Contact Admin", {
        //   position: "top-center",
        //   autoClose: 1500,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        // });
      }
    } else {
      console.log("VALIDATE DATES")
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <GridContainer style={{ margin: "0px!important" }}>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Round Off Challan</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
              <h5>* This will not round off Bill Data</h5>
                <MuiPickersUtilsProvider utils={DateUtils}>
                  <GridItem xs={12}>
                    <DatePicker
                      label="Start Date"
                      fullWidth
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                      animateYearScrolling
                    />
                  </GridItem>
                  <GridItem xs={12} style={{ marginTop: 20 }}>
                    <DatePicker
                      label="End Date"
                      fullWidth
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                      animateYearScrolling
                    />
                  </GridItem>
                </MuiPickersUtilsProvider>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ justifyContent: "center" }}>
              <Button color="primary" onClick={() => _onSearch()}>
                Search Data
              </Button>
            </CardFooter>
            <Box paddingLeft={4}>
              <div>Total Amount: {initialAmount}</div>
              <div>Set New Challan Opening Balance</div>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Challan Opening Balance"
                  id="openBal"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: openingBalance,
                    onChange: (e) => setOpeningBalance(e.target.value),
                  }}
                />
              </GridItem>
            </Box>
            <CardFooter style={{ justifyContent: "center" }}>
              <Button color="primary" disabled={initialAmount == 0 ? true : false} onClick={() => _onSave()}>
                ROUND OFF CHALLAN
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <Modal open={isModalOpen} onClose={handleClose}>
        <div style={{ padding: '20px', backgroundColor: 'white', margin: 'auto', marginTop: '15%', width: '30%' }}>
          <h2>Warning</h2>
          <p>
            All the challans, orders, ledgers, CN & DN, and payment list data that lies between the specified dates of
            this account will be DELETED. Are you sure you want to proceed?
          </p>
          <Button variant="contained" color="secondary" onClick={handleConfirm}>
            Confirm
          </Button>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Modal>
    </Dialog>
  );
}
