import React, { useState, useEffect } from "react";
import axios from "axios";
// @material-ui/icons
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
// core components
import { ChallanList } from "../Challan/ChallanList";
import { BillList } from "../Bill/BillList";
import classNames from "classnames";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import { Box, Dialog, makeStyles, Slide, Tab, Tabs } from "@material-ui/core";
import CustomInput from "../../components/CustomInput/CustomInput";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import CardBody from "../../components/Card/CardBody";
import styles from "../../assets/jss/material-dashboard-react/components/customTabsStyle";
import DateUtils from "@date-io/moment"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { FilterDialog } from "../../components/common/FilterDialog";
import { GetAllAccountsAPI, GetBulkReadyMasterRangeAPI } from "../../config/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ChallanListDebit } from "../Challan/ChallanListDebit";
import { Search } from "@material-ui/icons";
import { BillListDebit } from "../Bill/BillLIstDebit";
let tabs = [
    {
        tabName: "Challans",
        tabIcon: BugReport,
        tabContent: <ChallanListDebit />,
    },
    {
        tabName: "Bills",
        tabIcon: Code,
        tabContent: <BillListDebit />,
    },
];
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default function DebitNote(props) {
    const [value, setValue] = useState(0);
    let authToken = useSelector((state) => state.userReducer.authToken);
    let userInfo = useSelector((state) => state.userReducer.userInfo);

    const [searchTerm, setSearchTerm] = useState("");
    const [search, setSearch] = useState("");

    const [filter, setFilter] = useState(null);
    const [openFilter, setOpenFilter] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [party, setParty] = useState([]);
    const classes = useStyles();
    const { rtlActive } = props;
    const cardTitle = classNames({
        [classes.cardTitle]: true,
        [classes.cardTitleRTL]: rtlActive,
    });
    const [readyMasterRange, setReadyMasterRange] = useState([]);
    console.log("rmr", readyMasterRange)



    useEffect(() => {
        async function _getAllAccounts() {
            try {
                let res = await axios.get(GetAllAccountsAPI(userInfo.entity), {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                let accounts = res.data.accounts.filter(account => account.partyType === "purchase");

                setAccounts(accounts);
                let party = accounts.map((i) => i.name);
                setParty(party);
            } catch (e) {
                setAccounts([]);
                toast.error(("Something went wrong, Please Contact Admin"), {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }
        async function _getBulkReadyMasterRangeAPI() {
            try {
                let res = await axios.get(GetBulkReadyMasterRangeAPI(userInfo.entity), {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });

                setReadyMasterRange(res.data.readyMasterRange);
            } catch (err) {
                // console.log(err);
                toast.error(("Something went wrong, Please Contact Admin"), {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }

        _getAllAccounts();
        _getBulkReadyMasterRangeAPI()
    }, []);

    const handleChange = (event, value) => {
        setValue(value);
    };

    const handleCloseFilterModal = () => {
        setOpenFilter(false);
    };

    function handleSearch() {
        setSearchTerm(search);
    }

    return (
        <Card plain={false}>
            <CardHeader color={"rose"} plain={false}>
                <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <div>
                        <div className={cardTitle}>{"Transaction:"}</div>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            classes={{
                                root: classes.tabsRoot,
                                indicator: classes.displayNone,
                                scrollButtons: classes.displayNone,
                            }}
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {tabs.map((prop, key) => {
                                let icon = {};
                                if (prop.tabIcon) {
                                    icon = {
                                        icon: <prop.tabIcon />,
                                    };
                                }
                                return (
                                    <Tab
                                        classes={{
                                            root: classes.tabRootButton,
                                            selected: classes.tabSelected,
                                            wrapper: classes.tabWrapper,
                                        }}
                                        key={key}
                                        label={prop.tabName}
                                        {...icon}
                                    />
                                );
                            })}
                        </Tabs>
                    </div>
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                        <CustomInput
                            id="search"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                style: {
                                    marginTop: 0,
                                    paddingTop: 0,
                                    marginRight: 10,
                                    color: "white",
                                },
                                placeholder: "Search",
                                value: search,
                                // onChange: (e) => setSearch(e.target.value),
                                onChange: (e) => {
                                    setSearch(e.target.value);
                                    // if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
                                    //   _getOrders(page, rowsPerPage);
                                    // }

                                },
                                onKeyDown: (e) => {
                                    if (e.keyCode === 13) {
                                        e.preventDefault();
                                        handleSearch();
                                    }
                                },
                            }}
                        />
                        <IconButton color={"secondary"} onClick={(e) => {
                            // setSearchTerm(e.target.value),
                            // handleKeyPress(e)
                            // handleKeyPress()
                            handleSearch()


                        }}>
                            <Search />
                        </IconButton>
                        <IconButton color={"secondary"} onClick={() => setOpenFilter(true)}>
                            <FilterListIcon />
                        </IconButton>
                    </Box>
                </Box>
            </CardHeader>
            <CardBody>
                {value === 0 ? (
                <ChallanListDebit searchTerm={searchTerm} filter={filter} readyMasterRange={readyMasterRange} accounts={accounts} />
                ) : (
                    <BillListDebit searchTerm={searchTerm} filter={filter} readyMasterRange={readyMasterRange} accounts={accounts} />
                )}
            </CardBody>
            <FilterDialog
                party={party}
                filter={filter}
                setFilter={setFilter}
                showFilterDialog={openFilter}
                handleCloseFilterDialog={handleCloseFilterModal}
            />
        </Card>
    );
}
