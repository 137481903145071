import React, { useEffect, useMemo, useState } from "react";
// @material-ui/core components
import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
// import Table from "../../components/Table/Table.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CustomInput from "../../components/CustomInput/CustomInput";
import People from "@material-ui/icons/People";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import PrintIcon from "@mui/icons-material/Print";
import AddIcon from "@material-ui/icons/Add";
import AccountAdd from "./AccountAdd";
import AccountView from "./AccountView";
import _ from "lodash";
import { ConfirmationDialog } from "../../components/common";
import AccountEdit from "./AccountEdit";
import axios from "axios";
import {
  DeleteAccountAPI,
  GetAllAccountsAPI,
  GetEntityAPI,
} from "../../config/api";
import { Close, CloudDownload, CloudUpload,Search } from "@material-ui/icons";
import ReactExport from "react-data-export";
import { ImportExcel } from "../Master/ImportExcel";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));
const columns = [
  { id: "name", label: "Name" },
  { id: "accountCode", label: "Account Code" },
  { id: "whatsappNumber", label: "whatsapp Number" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];

export default function TableList() {
  const classes = useStyles();
  const history = useHistory();

  const [accounts, setAccounts] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [entity, setEntity] = useState("");
  const [tota, setTota] = useState(0)


  const authToken = useSelector((state) => state.userReducer.authToken);
  const userInfo = useSelector((state) => state.userReducer.userInfo);


  // useEffect(() => {
  //   _getAccounts();
  //   _getEntity();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      await _getAccounts(page, rowsPerPage);
      await _getEntity();
    };

    fetchData();
  }, [openAddDialog, openEditDialog, page, rowsPerPage]);



  const _getAccounts = useMemo(() => async (page, rowsPerPage) => {
    // let skipm = page + 1
    let skipm = page * rowsPerPage

    try {
      let query = `&search=${searchTerm}&limit=${rowsPerPage}&skip=${skipm}`;

      let res = await axios.get(
        GetAllAccountsAPI(userInfo.entity) + query,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(userInfo.entity, "This is user entity")
      setAccounts(res.data.accounts);
      setTota(res.data.total)

    } catch (err) {
      console.log("Error:  ", (err))
      // toast.error(("Something went wrong, Please Contact Admin"), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }

  }, [])


  async function _getEntity() {
    try {
      let res = await axios.get(GetEntityAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(res.data)
      setEntity(res.data);
      console.log("This is setted entity", entity)
    } catch (err) {
      // toast.error({err} , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
      setEntity("");
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleOpenConfirmationModal = (object) => {
    setOpenConfirmationModal(object);
  };
  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(null);
  };

  function closeAddDialog() {
    setOpenAddDialog(false);
  }

  function closeViewDialog() {
    setOpenViewDialog(null);
  }

  async function _deleteItem(item) {
    try {
      let res = await axios.delete(DeleteAccountAPI(item._id), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      window.location.reload();
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin"), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  async function _printItem(item) {
    history.push("/admin/account-address", { account: item, sender: entity });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };


  let data = accounts;
  if (searchTerm !== "") {
    data = _.filter(data, (i) => {
      return (
        (i.name && i.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (i.accountCode &&
          i.accountCode.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    });
  }

  async function handleSearch() {
    console.log("search", search)
    setSearchTerm(search);
    await _getAccounts();
  }

  let excelData = [];
  data.map((i) => {
    let d = [];
    d.push({ value: i.accountCode });
    d.push({ value: i.partyType });
    d.push({ value: i.name });
    d.push({ value: i.contact });
    d.push({ value: i.address });
    d.push({ value: i.city });
    d.push({ value: i.state });
    d.push({ value: i.pin });
    d.push({ value: i.landline });
    d.push({ value: i.whatsappNumber });
    d.push({ value: i.mobile });
    d.push({ value: i.email });
    d.push({ value: i.shippingAddress });
    d.push({ value: i.openBalance });
    d.push({ value: i.currentBalance });
    d.push({ value: i.lastYearBalance });
    d.push({ value: i.thresholdBalance });
    d.push({ value: i.challanType });
    d.push({ value: i.cashPreference });
    d.push({ value: i.showRunningBalance });
    d.push({ value: i.runningChallanBalance });
    d.push({ value: i.runningBillBalance });
    d.push({ value: i.runningPurchaseBalance });
    excelData.push(d);
  });
  const multiDataSet = [
    {
      columns: [
        { title: "accountCode" },
        { title: "partyType" },
        { title: "name" },
        { title: "contact" },
        { title: "address" },
        { title: "city" },
        { title: "state" },
        { title: "pin" },
        { title: "landline" },
        { title: "whatsappNumber" },
        { title: "mobile" },
        { title: "email" },
        { title: "shippingAddress" },
        { title: "openBalance" },
        { title: "currentBalance" },
        { title: "lastYearBalance" },
        { title: "thresholdBalance" },
        { title: "challanType" },
        { title: "cashPreference" },
        { title: "showRunningBalance" },
        { title: "runningChallanBalance" },
        { title: "runningBillBalance" },
        { title: "runningPurchaseBalance" },
      ],
      data: excelData,
    },
  ];

  let emptyData = [];
  let d = [];
  for (let i = 0; i < multiDataSet[0].columns.length; i++) {
    d.push({ value: "" });
  }
  emptyData.push(d);
  const importTemplateDataSet = [
    {
      columns: [
        { title: "accountCode" },
        { title: "schedNo" },
        { title: "name" },
        { title: "contact" },
        { title: "address" },
        { title: "city" },
        { title: "state" },
        { title: "pin" },
        { title: "postAccount" },
        { title: "officeTelephone" },
        { title: "residentTelephone" },
        { title: "fax" },
        { title: "mobile" },
        { title: "email" },
        { title: "shippingAddress" },
        { title: "openBalance" },
        { title: "currentBalance" },
        { title: "lastYearBalance" },
      ],
      data: emptyData,
    },
  ];

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4 className={classes.cardTitleWhite}>Accounts</h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <CustomInput
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    style: {
                      color: "white",
                    },
                    placeholder: "Search",
                    value: search,
                    // onChange: (e) => setSearch(e.target.value),
                    onChange: (e) => {
                      setSearch(e.target.value);
                      if (e.target.value === "" || e.target.value === null) {
                        _getAccounts(page, rowsPerPage);
                      }

                    },
                    // onKeyDown: (e) => {
                    //   if (e.keyCode === 13) {
                    //     e.preventDefault();
                    //     handleSearch();
                    //   }
                    // },
                    endAdornment: (
                      <InputAdornment position="end">
                        {
                          searchTerm === ""
                            ?
                            <IconButton color={"secondary"} onClick={(e) => {
                              handleSearch()
                            }}>
                              <Search />
                            </IconButton>
                            :
                            <IconButton color={"secondary"} onClick={async (e) => {
                              setSearchTerm("")
                              await _getAccounts();
                            }}>
                              <Close />
                            </IconButton>
                        }


                      </InputAdornment>
                    ),
                  }}
                />
                <IconButton
                  aria-label="add"
                  style={{ color: "white" }}
                  onClick={() => setOpenAddDialog(true)}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </div>
          </CardHeader>

          <CardBody>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={"center"}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        {column.label}
                        {column.id === "action" ? (
                          <Box justifyContent={"center"} alignItems={"center"}>
                            <ExcelFile
                              element={
                                <Tooltip title={"Export to excel"}>
                                  <IconButton color={"primary"}>
                                    <CloudDownload />
                                  </IconButton>
                                </Tooltip>
                              }
                            >
                              <ExcelSheet
                                dataSet={multiDataSet}
                                name="Booking"
                              />
                            </ExcelFile>
                            <ExcelFile
                              element={
                                <Tooltip title={"Import Template"}>
                                  <IconButton color={"primary"}>
                                    <CloudDownload />
                                  </IconButton>
                                </Tooltip>
                              }
                            >
                              <ExcelSheet
                                dataSet={importTemplateDataSet}
                                name="Import Template"
                              />
                            </ExcelFile>
                            <Tooltip title={"Import Excel to database"}>
                              <IconButton
                                color={"primary"}
                                onClick={() => setOpenImportDialog(true)}
                              >
                                <CloudUpload />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        ) : null}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          if (column.id === "action") {
                            return (
                              <TableCell align={"center"}>
                                <Box display={"flex"} justifyContent={"center"}>
                                  <IconButton
                                    onClick={() =>
                                      setOpenViewDialog({
                                        open: true,
                                        item: row,
                                      })
                                    }
                                    aria-label="view"
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                      setSelectedItem(row);
                                      setOpenEditDialog(true);
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      handleOpenConfirmationModal({
                                        onPress: () => _deleteItem(row),
                                      })
                                    }
                                    aria-label="delete"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      // handleOpenConfirmationModal({
                                      //   onPress: () => _printItem(row),
                                      // })
                                      _printItem(row)
                                    }
                                    aria-label="Print"
                                  >
                                    <PrintIcon />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={column.id} align={"center"}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={tota}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      </GridItem>
      {/*<Fab color="primary" aria-label="add" className={classes.fab}*/}
      {/*     onClick={() => setOpenAddDialog(true)}>*/}
      {/*    <AddIcon/>*/}
      {/*</Fab>*/}

      {openAddDialog && (
        <AccountAdd
          fetchData={_getAccounts}
          open={openAddDialog}
          close={closeAddDialog}
        />
      )}
      {selectedItem && openEditDialog ? (
        <AccountEdit
          fetchData={_getAccounts}
          open={openEditDialog}
          item={selectedItem}
          close={() => setOpenEditDialog(false)}
        />
      ) : null}
      {openViewDialog ? (
        <AccountView
          open={openViewDialog.open}
          close={closeViewDialog}
          item={openViewDialog.item}
        />
      ) : null}
      {openConfirmationModal ? (
        <ConfirmationDialog
          open={openConfirmationModal}
          onClose={handleCloseConfirmationModal}
          onConfirm={openConfirmationModal.onPress}
          title={"Delete Record"}
          msg={"Do you want to delete this record?"}
        />
      ) : null}
      <ImportExcel
        db={"accounts"}
        open={openImportDialog}
        close={() => setOpenImportDialog(false)}
      />
    </GridContainer>
  );
}
