import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardAvatar from "../../components/Card/CardAvatar.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";

import avatar from "../../assets/img/faces/marc.jpg";
import { useSelector } from "react-redux";
import axios from "axios";
import { UpdateLoggedInUserAPI } from "../../config/api";
import { toast } from "react-toastify";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function UserProfile(props) {
  const classes = useStyles();
  const userInfo = useSelector((state) => state.userReducer.userInfo);

  const [company, setCompany] = useState(userInfo.company);
  const [name, setName] = useState(userInfo.name);
  const [mobile, setMobile] = useState(userInfo.mobile);
  const [email, setEmail] = useState(userInfo.email);
  const [address, setAddress] = useState(userInfo.address);
  const [password, setPassword] = useState(userInfo.password);
  const authToken = useSelector((state) => state.userReducer.authToken);

  async function _onSave() {
    try {
      let user = {
        name,
        mobile,
        email,
        address,
        password,
      };
      let res = await axios.patch(
        UpdateLoggedInUserAPI(),

        user,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
    } catch (e) {
      // console.log("err:", e);

    }
  }

  return (
    <Card>
      <CardHeader color="primary">
        <h4 className={classes.cardTitleWhite}>Edit Profile</h4>
        <p className={classes.cardCategoryWhite}>Complete your profile</p>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="Company"
              id="company"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: company,
                onChange: (e) => setCompany(e.target.value),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="Name"
              id="name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: name,
                onChange: (e) => setName(e.target.value),
              }}
            />
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <CustomInput
              labelText="Email address"
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: email,
                onChange: (e) => setEmail(e.target.value),
              }}
            />
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <CustomInput
              labelText="Mobile"
              id="mobile"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: mobile,
                onChange: (e) => setMobile(e.target.value),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="Address"
              id="address"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: address,
                onChange: (e) => setAddress(e.target.value),
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="Change Password"
              id="password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: password,
                onChange: (e) => setPassword(e.target.value),
              }}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
      <CardFooter>
        <Button color="primary" onClick={() => _onSave()}>
          Update Profile
        </Button>
      </CardFooter>
    </Card>
  );
}
