import React, { useState } from "react";
// @material-ui/core components
import { Dialog, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import axios from "axios";
import { CreatePrintAdAPI } from "../../config/api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const styles = {
    formControl: {
        paddingBottom: "10px",
        margin: "27px 0 0 0",
        position: "relative",
        verticalAlign: "unset"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);

export default function PrintAdAdd(props) {
    let userInfo = useSelector((state) => state.userReducer.userInfo);

    const classes = useStyles();
    const theme = useTheme();
    const { open, close, fetchData } = props;
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [title, setTitle] = useState('');

    async function _onSave() {
        try {
            let data = {
                title,
                entity: userInfo.entity
            };
            let res = await axios.post(CreatePrintAdAPI(), { data });
            close();
            window.location.reload()
            fetchData()
        } catch (e) {
            // console.log('err:', e);
            toast.error(("Something went wrong, Please Contact Admin"), {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={close}
            aria-labelledby="responsive-dialog-title">
            <GridContainer>
                <GridItem xs={12} sm={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Add Print Ad Master</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="Title"
                                        id="title"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: title,
                                            onChange: (event) => setTitle(event.target.value)
                                        }}
                                    />
                                </GridItem>

                            </GridContainer>
                        </CardBody>
                        <CardFooter style={{ justifyContent: 'center' }}>
                            <Button color="primary" onClick={() => _onSave()}>Add Print Ad Master</Button>
                        </CardFooter>
                    </Card>
                </GridItem>

            </GridContainer>
        </Dialog>
    );
}
