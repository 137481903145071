import React, { useEffect, useState } from "react";
import PouchDB from "pouchdb";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Backdrop,
  Snackbar,
  CircularProgress,
  makeStyles,
  TextField,
  Toolbar,
  FormControl,
  Select,
  MenuItem,
  Typography,
  InputLabel,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Account,
  AccountPlus,
  Email,
  // Account,
  Phone,
  Lock,
  ViewDashboard,
} from "mdi-material-ui";
import PouchdbFind from "pouchdb-find";
import { loginSuccess, readyMasters } from "../../actions";
import { UserRegisterAdminAPI } from "../../config/api";

PouchDB.plugin(PouchdbFind);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  gradient: {
    background: "linear-gradient(87deg,#11cdef,#1171ef)",
    width: "100%",
    display: "flex",
    flex: 0.6,
    justifyContent: "center",
    // height: 400
  },
  cardContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    // position: 'absolute',
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    left: "50%",
    // transform: 'translate(-50%,-50%)'
  },
  card: {
    width: "20%",
    flexDirection: "column",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

let entityTypes = [
  {
    label: "Vijaya Optician",
    value: "Vijaya Optician",
  },
  {
    label: "Infinity Enterprises",
    value: "Infinity Enterprises",
  },
  {
    label: "V.R. Enterprises",
    value: "V.R. Enterprises",
  },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Register(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [alertObject, setAlertObject] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [entityType, setEntityType] = useState(null);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const _onRegister = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let res = await axios.post(UserRegisterAdminAPI(), {
        email,
        password,
        entity: entityType,
        name,
        mobile,
        isSystemUser: true,
      });
      setOpen(true);
      setAlertObject({ status: "success", message: "Register successful" });

      dispatch(
        loginSuccess({
          userInfo: res.data.user,
          authToken: res.data.token,
        })
      );

      dispatch(readyMasters({ readyMasters: res.data.readyMasters }));
      // setOpen(false);
      setTimeout(() => props.history.replace("/admin/dashboard"), 2000);
    } catch (err) {
      setLoading(false);
      setOpen(true);
      setAlertObject({ status: "error", message: err.response.data });
    }
  };

  return (
    <div className={classes.root}>
      <div
        style={{
          position: "absolute",
          zIndex: -1,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          width: "100%",
          height: "100%",
        }}
      >
        <div className={classes.gradient}></div>
        <div
          style={{
            backgroundColor: "#172b4d",
            width: "100%",
            display: "flex",
            flex: 1,
            // height: 400
          }}
        />
      </div>

      <div className={classes.cardContainer}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>V.R ENTERPRISES</title>
        </Helmet>
        <Typography variant="h4" style={{ color: "white", margin: 40 }}>
          Welcome to Astra Lens !
        </Typography>
        <Card className={classes.card}>
          <form onSubmit={_onRegister}>
            <p style={{ textAlign: "center", color: "#adb5bd" }}>Sign in</p>
            <Box
              display={"flex"}
              backgroundColor={"red"}
              flexDirection={"column"}
              marginTop={4}
            >
              <TextField
                autoFocus
                className={classes.margin}
                placeholder={"Name"}
                value={name}
                onChange={(event) => setName(event.target.value)}
                id="input-with-icon-textfield"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Account style={{ color: "#adb5bd" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                className={classes.margin}
                placeholder={"Mobile"}
                value={mobile}
                onChange={(event) => setMobile(event.target.value)}
                id="input-with-icon-textfield"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Phone style={{ color: "#adb5bd" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                className={classes.margin}
                placeholder={"Email"}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                id="input-with-icon-textfield"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email style={{ color: "#adb5bd" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                className={classes.margin}
                id="input-with-icon-textfield"
                placeholder={"Password"}
                type={"password"}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock style={{ color: "#adb5bd" }} />
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Entity Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={entityType}
                  label="Type"
                  onChange={(e) => {
                    e.preventDefault();
                    setEntityType(e.target.value);
                  }}
                >
                  {entityTypes.map(({ label, value }) => {
                    return <MenuItem value={value}>{label}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Box>
            <Button
              type="submit"
              style={{ marginTop: 40 }}
              variant="contained"
              color="primary"
              // onClick={() => _onLogin()}
            >
              Sign Up
            </Button>
          </form>
        </Card>
      </div>
      {alertObject ? (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alertObject.status}>
            {alertObject.message}
          </Alert>
        </Snackbar>
      ) : null}
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
    </div>
  );
}
