import React, { useEffect } from "react";
// @material-ui/core components
import {
  Box,
  Button,
  Dialog,
  makeStyles,
  Slide,
  useTheme,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import { baseUrl } from "../../config/api";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  imageSize: {
    height: 300,
    width: 300,
    margin: 20,
  },
};
const columns = [
  { id: "product", label: "Product" },
  { id: "discountPercent", label: "Discount Percent" },
  { id: "discountAmount", label: "Discount Amount" },
];
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ReadyMasterView(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { open, close, item } = props;
  const {
    productCode,
    productName,
    rate,
    side,
    type,
    color,
    quantity,
    sph,
    cyl,
    axis,
    addition,
    isOfferValid,
    offerAmount,
    offerPercent,
    isTrendValid,
    trendAmount,
    trendPercent,
    readyMasterImages,
    brand,
    category,
    hsnCode,
    taxPercent
  } = item;

  useEffect(() => {}, [item]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Ready Master Detail</h4>
                <Button style={{ color: "white" }} onClick={close}>
                  Close
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="MAINGRP (Product Code)"
                    id="mainGrp"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: productCode,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Product Name"
                    id="name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: productName,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="rate"
                    id="rate"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rate,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="SIDE"
                    id="side"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: side,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="TYPE"
                    id="type"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: type,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="COLOR"
                    id="color"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: color,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="SPH"
                    id="sph"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: sph,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="CYL"
                    id="cyl"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: cyl,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="AXI"
                    id="axi"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: axis,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="ADDN"
                    id="addn"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: addition,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Total Quantity"
                    id="quantity"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: quantity,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Offer IsValid"
                    id="isOfferValid"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: isOfferValid,
                      disabled: true,
                    }}
                  />
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Offer Percent"
                    id="offerPercent"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: offerPercent,
                      disabled: true,
                    }}
                  />
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Offer Amount"
                    id="offerAmount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: offerAmount,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Trend IsValid"
                    id="isTrendValid"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: isTrendValid,
                      disabled: true,
                    }}
                  />
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Trend Percent"
                    id="trendPercent"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: trendPercent,
                      disabled: true,
                    }}
                  />
                </GridItem>{" "}
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Trend Amount"
                    id="trendAmount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: trendAmount,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Brand Name"
                    id="brandName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: brand ? brand.name : "",
                      disabled: true,
                    }}
                  />
                </GridItem><GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Hsn code"
                    id="hsnCode"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: hsnCode ? hsnCode : "",
                      disabled: true,
                    }}
                  />
                </GridItem><GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Tax Percent"
                    id="taxPercent"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: taxPercent ? taxPercent : "",
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Category Name"
                    id="trendAmount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: category ? category.name : "",
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    style={{ overflowX: "scroll" }}
                  >
                    {readyMasterImages &&
                      readyMasterImages.length > 0 &&
                      readyMasterImages.map((i) => {
                        return (
                          <img
                            src={`${baseUrl}/uploads/${i}`}
                            className={classes.imageSize}
                          />
                        );
                      })}
                  </Box>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
