import React, { useEffect, useState } from "react";
// @material-ui/core components
import {
  Box,
  Button,
  Dialog,
  Fab,
  IconButton,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import {
  baseUrl,
  CheckQuantity,
  CreateChallanAPI,
  CreateCreditNoteAPI,
  CreateDebitNoteAPI,
  GetAllAccountsAPI,
  GetAllFittingMastersAPI,
  GetAllOrdersAPI,
  GetAllReadyMastersAPI,
  GetAllReadyMastersCodesAPI,
  UpdateOrderAPI,
} from "../../config/api";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { ChallanOrdersDialog } from "./ChallanOrdersDialog.js";
import { ColorsDialog } from "./ColorsDialog.js";
import { toast } from "react-toastify";
import { Filter9Plus } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateUtils from "@date-io/moment";
import moment from "moment";
import debounce from "lodash/debounce";
import { useLocation } from "react-router-dom";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  limit: 500,
});

const useStyles = makeStyles((theme) => ({
  fab: {
    marginTop: 26,
  },
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

let columns = [
  { id: "codeR", label: "Code R" },
  { id: "codeL", label: "Code L" },
  { id: "nameR", label: "Name R" },
  { id: "nameL", label: "Name L" },
  { id: "quantity", label: "Quantity" },
  { id: "rateR", label: "Rate R" },
  { id: "rateL", label: "Rate L" },
  { id: "rate", label: "Rate" },
  { id: "discountAmount", label: "Discount Amount" },
  { id: "discountPercent", label: "Discount Percent" },
  { id: "fitting", label: "Fitting" },
  { id: "fittingAmount", label: "Fitting Amount" },
  { id: "tint", label: "Tint" },
  { id: "amount", label: "Amount" },
  { id: "orderRef", label: "Order Ref" },
  { id: "action", label: "Action" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ChallanAddDialog(props) {
  const location = useLocation();

  const classes = useStyles();
  const theme = useTheme();
  const { open, close, readyMasterRange, users } = props;
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);

  const [readyMasters, setReadyMasters] = useState([]);
  const [party, setParty] = useState(null);
  const [codeL, setCodeL] = useState(null);
  const [codeR, setCodeR] = useState(null);
  const [nameL, setNameL] = useState("");
  const [nameR, setNameR] = useState("");
  const [brandR, setBrandR] = useState("");
  const [brandL, setBrandL] = useState("");
  const [lProductCode, setLProductCode] = useState("");
  const [rProductCode, setRProductCode] = useState("");
  const [quantity, setQuantity] = useState("");
  const [rateR, setRateR] = useState("");
  const [rateL, setRateL] = useState("");
  const [rate, setRate] = useState("");
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountAmount, setDiscountAmount] = useState("");
  const [fitting, setFitting] = useState("");
  const [fittingAmount, setFittingAmount] = useState("");
  const [tint, setTint] = useState("");
  const [amount, setAmount] = useState("");
  const [orderRef, setOrderRef] = useState("");
  const [items, setItems] = useState([]);
  const [coating, setCoating] = useState("");
  const [orders, setOrders] = useState([]);
  const [codes, setCodes] = useState([]);
  const [codesL, setCodesL] = useState([]);
  const [codesR, setCodesR] = useState([]);
  const [lSph, setLsph] = useState("");
  const [rSph, setRsph] = useState("");
  const [lCyl, setLcyl] = useState("");
  const [rCyl, setRcyl] = useState("");
  const [rAxis, setRaxis] = useState("");
  const [lAxis, setLaxis] = useState("");
  const [lAdd, setLadd] = useState("");
  const [rAdd, setRadd] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [taxPercent, setTaxPercent] = useState("");
  const [packagingCost, setPackagingCost] = useState("");
  const [packagingTaxPercent, setPackagingTaxPercent] = useState("");
  const [fittingMasters, setFittingMasters] = useState([]);
  const [fittingMastersCodes, setFittingMastersCodes] = useState([]);
  const [openOrdersDialog, setOpenOrdersDialog] = useState(false);
  const [openColorsDialog, setOpenColorsDialog] = useState(false);
  const [challanDate, setChallanDate] = useState(null);
  const [selectR, setSelectR] = useState({});
  const [selectL, setSelectL] = useState({});

  console.log(selectR, selectL, "<<<<<<<RL");

  useEffect(() => {
    console.log("afterR");
    debouncedEffect(selectR.code);
    setCodeR(selectR.code);
  }, [selectR]);
  useEffect(() => {
    console.log("afterL");
    debouncedEffectL(selectL.code);
    setCodeL(selectL.code);
  }, [selectL]);

  // Callback function that will be passed to child
  const setR = (data) => {
    setSelectR(data); // Update parent state
  };
  const setL = (data) => {
    setSelectL(data); // Update parent state
  };

  const [filteredOptions, setFilteredOptions] = useState([]);

  const sumOfQuantities = items.reduce((accumulator, currentValue) => {
    return Number(accumulator + Number(currentValue.quantity));
  }, 0);

  const sumOfAmounts = items.reduce((accumulator, currentValue) => {
    return Number(accumulator + Number(currentValue.amount));
  }, 0);

  useEffect(() => {
    _getFittingMasters();
  }, []);

  useEffect(() => {
    _getOrders();
  }, [party]);

  const debouncedEffectL = _.debounce((codeL) => {
    console.log("debounce EL");
    let obj;
    let codes = [];
    let codesL = [];
    if (codeL) {
      obj = _.find(orders, { code: codeL });
      _.map(orders, (i) => {
        codes.push(i.code);
        codesL.push(i.code);
      });
      if (!obj) {
        let readyMasters2 = [];

        if (codeL.length < 5) {
          return;
        }

        axios
          .get(GetAllReadyMastersCodesAPI(userInfo.entity, codeL, "L"), {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          })
          .then((res) => {
            setReadyMasters(res.data.readyMasters);
            _.map(res.data.readyMasters, (i) => {
              codes.push(i.productCode);
              if (i.side === "B" || i.side === "L") {
                codesL.push(i.productCode);
              }
            });
            // Reorder codesL array
            codesL = codesL.sort((a, b) => {
              // Move codes starting with 'o' to the end
              if (a.startsWith("o") && !b.startsWith("o")) {
                return 1;
              } else if (!a.startsWith("o") && b.startsWith("o")) {
                return -1;
              }
              return 0;
            });
            setCodesL(codesL);
          })
          .catch((e) => {
            //console.log(e, "<<err");
          });
      }
      setCodes(codes);
      if (obj) {
        if (obj.productName) {
          setNameL(obj.productName);
          setRateL(obj.rate);
          setLProductCode(obj.productCode);
        } else {
          if (obj.orderDetail) {
            console.log("232", obj);
            setQuantity(obj.quantity);
            setRateL(obj.price);
            setHsnCode(obj.orderDetail.product.hsnCode);
            let productCode = "";
            if (obj.orderType === "Fitting") {
              if (obj.orderDetail.fitting) {
                setFitting(obj.orderDetail.fitting);
                let fitting = _.find(fittingMasters, {
                  fittingType: obj.orderDetail.fitting,
                });
                setFittingAmount(fitting.amount);
              }
              setRateL(obj.orderDetail.lRate);
              productCode =
                obj.orderDetail.lSph +
                "/" +
                obj.orderDetail.lCyl +
                "/" +
                obj.orderDetail.lAxis +
                "/" +
                obj.orderDetail.lAddition;
              setNameL(obj.orderDetail.productName + "-" + productCode);
            } else {
              setRateL(obj.price);
              productCode =
                obj.orderDetail.lSph +
                "/" +
                obj.orderDetail.lCyl +
                "/" +
                obj.orderDetail.lAxis +
                "/" +
                obj.orderDetail.lAddition;
              if (obj.orderDetail.product) {
                setNameL(
                  obj.orderDetail.product.productName + "-" + productCode
                );
              }
            }
            setOrderRef(obj.code);
            setLProductCode(productCode);
          }
        }
      }
    }
    if (!codeL) {
      if (!codeR) {
        setQuantity("");
        setFittingAmount("");
        setFitting("");
        setOrderRef("");
        setRate("");
        setHsnCode("");
        setDiscountPercent("");
        setTaxPercent("");
      }
      setNameL("");
      setRateL("");
      setLProductCode("");
    }
  }, 500);

  const afterSelectR = (codeR) => {
    console.log("after select >> >> ", codeR, readyMasters);
    let obj = _.find(readyMasters, { productCode: codeR });
    console.log("after select >> >> ", obj);
    if (obj) {
      console.log(obj, "this my product");
      if (obj) {
        console.log("hiiii", obj);
        setNameR(obj.index + "-" + obj?.productName);
        setBrandR(obj.brand);
        console.log(obj.productName, "byeee");
        console.log("814setnamecomplete", party);
        setRateR(obj?.rate);
        setRProductCode(obj?.productCode);
        setHsnCode(obj?.hsnCode);
        setTaxPercent(obj?.taxPercent);
        setPackagingTaxPercent(obj?.taxPercent);
        setRsph(obj?.sph)
        setRcyl(obj?.cyl)
        setRaxis(obj?.axis)
        setRadd(obj?.addition)

        let dissArray = party?.discounts;
        if (dissArray.length > 0 && obj) {
          console.log(dissArray, "diss");
          console.log(obj, "dissObj");
          let selectedDis = dissArray
            .reverse()
            .find((item) => item.type === "Range");
          console.log("this happening", selectedDis);
          if (selectedDis) {
            let fR = selectedDis?.product?.codeRanges?.find(
              (item) => item == codeR
            );
            console.log(fR, "fR");
            if (fR != undefined) {
              console.log(
                "comp316",
                selectedDis.product.productName,
                selectedDis.discountedPrice,
                selectedDis.discountPercent
              );
              setNameR(selectedDis.product.productName);
              setRateR(selectedDis.discountedPrice);
              setDiscountPercent(selectedDis.discountPercent);
              console.log("321");
            }
          } else {
            console.log("no range here");
            let selectedDis2 = dissArray
              .reverse()
              .find((item) => item.product.color === obj.color);
            console.log("forReal>>>>editse", selectedDis2, codeR, dissArray);
            console.log("comp330", selectedDis2);
            if (selectedDis2) {
              setNameR(selectedDis2?.product?.productName);
              setRateR(selectedDis2?.discountedPrice);
              setDiscountPercent(selectedDis2.discountPercent);
              // setDiscountPercent(selectedDis2?.discountPercent);
              // setDiscountAmount(selectedDis2.discountedPrice);
              if (discountPercent > 0) {
                let newD =
                  Number(discountPercent) +
                  Number(selectedDis2?.discountPercent);
                //console.log("987", newD)
                let finD = newD / 2;
                //console.log("setfromdebounceR988", finD)
                setDiscountPercent(finD);
              } else {
                setDiscountPercent(selectedDis2?.discountPercent);
              }
            } else {
            }
            // //console.log("that happening")

            // let selectedDis2 = dissArray
            //   .reverse()
            //   .find((item) => item.product.color === obj.color);
            // setNameR(selectedDis2.product.productName)
            // setDiscountPercent(selectedDis2.discountPercent);

            // setDiscountAmount(selectedDis2.discountedPrice);
          }
          //// //console.log(selectedDis, "dis");
        }
      } else {
        if (obj.orderDetail) {
          console.log(obj, "373");

          setQuantity(obj.quantity);
          let productCode = "";
          if (obj.orderType === "Fitting") {
            if (obj.orderDetail.fitting) {
              setFitting(obj.orderDetail.fitting);
              let fitting = _.find(fittingMasters, {
                fittingType: obj.orderDetail.fitting,
              });
              setFittingAmount(fitting.amount);
            }
            setRateR(obj.orderDetail.rRate);
            productCode =
              obj.orderDetail.rSph +
              "/" +
              obj.orderDetail.rCyl +
              "/" +
              obj.orderDetail.rAxis +
              "/" +
              obj.orderDetail.rAddition;
            console.log("864orderDetailcomp");

            setNameR(obj.orderDetail.productName + "-" + productCode);
          } else {
            setRateR(obj.rate);
            productCode =
              obj.orderDetail.rSph +
              "/" +
              obj.orderDetail.rCyl +
              "/" +
              obj.orderDetail.rAxis +
              "/" +
              obj.orderDetail.rAddition;
            if (obj.orderDetail.product) {
              console.log("396comp");
              setNameR(obj.orderDetail.product.productName + "-" + productCode);
            }
          }
          setOrderRef(obj.code);
          setRProductCode(productCode);
        }
      }
    }
  };

  // const afterSelectL = (codeL) => {
  //   console.log("after EL", codeL)
  //   let obj = _.find(readyMasters, { productCode: codeL });
  //   if (obj) {
  //     if (obj?.productName) {
  //       setNameL(obj?.productName);
  //       setRateL(obj?.rate);
  //       setLProductCode(obj?.productCode);

  //       setHsnCode(obj?.hsnCode);
  //       setTaxPercent(obj?.taxPercent);

  //       let dissArray = party?.discounts;
  //       console.log("it is", obj, dissArray)

  //       if (dissArray && obj) {

  //         let selectedDis = dissArray
  //           .reverse()
  //           .find((item) => item.type === "Range");
  //         if (selectedDis) {

  //           let fR = selectedDis?.product?.codeRanges?.find((item) => (item === codeL))

  //           if (fR != undefined) {
  //             setNameL(selectedDis?.product?.productName)
  //             let newD = Number(discountPercent) + Number(selectedDis.discountPercent)
  //             let finD = newD / 2
  //             setDiscountPercent(finD);
  //           } else {

  //           }
  //         } else {
  //           let selectedDis2 = dissArray
  //             .reverse()
  //             .find((item) => item.product.color === obj.color);

  //           if (selectedDis2) {

  //             setNameL(selectedDis2?.product?.productName)
  //             if (discountPercent == 0) {
  //               setDiscountPercent(selectedDis2?.discountPercent)
  //             } else {

  //               let newD =
  //                 Number(discountPercent) +
  //                 Number(selectedDis2?.discountPercent);
  //               let finD = newD / 2;
  //               setDiscountPercent(finD);
  //             }
  //           } else {
  //           }
  //         }
  //       }
  //     } else {
  //       if (obj.orderDetail) {
  //         setQuantity(obj.quantity);
  //         setRateL(obj.price);
  //         let productCode = "";
  //         if (obj.orderType === "Fitting") {
  //           if (obj.orderDetail.fitting) {
  //             setFitting(obj.orderDetail.fitting);
  //             let fitting = _.find(fittingMasters, {
  //               fittingType: obj.orderDetail.fitting,
  //             });
  //             setFittingAmount(fitting.amount);
  //           }
  //           setRateL(obj.orderDetail.lRate);
  //           productCode =
  //             obj.orderDetail.lSph +
  //             "/" +
  //             obj.orderDetail.lCyl +
  //             "/" +
  //             obj.orderDetail.lAxis +
  //             "/" +
  //             obj.orderDetail.lAddition;
  //           setNameL(obj.orderDetail.productName + "-" + productCode);
  //         } else {
  //           setRateL(obj.rate);
  //           productCode =
  //             obj.orderDetail.lSph +
  //             "/" +
  //             obj.orderDetail.lCyl +
  //             "/" +
  //             obj.orderDetail.lAxis +
  //             "/" +
  //             obj.orderDetail.lAddition;
  //           if (obj.orderDetail.product) {
  //             setNameL(
  //               obj.orderDetail.product.productName + "-" + productCode
  //             );
  //           }
  //         }
  //         setOrderRef(obj.code);
  //         setLProductCode(productCode);
  //       }
  //     }
  //   }
  // }

  const afterSelectL = (codeL) => {
    console.log("after select L>> >> ", codeL, readyMasters);
    let obj = _.find(readyMasters, { productCode: codeL });
    if (obj) {
      console.log(obj, "this my product");
      if (obj) {
        console.log("hiiii", obj);
        setNameL(obj.index + "-" + obj?.productName);
        console.log(obj.productName, "byeee");
        console.log("814setnamecomplete", party);
        setRateL(obj?.rate);
        setBrandL(obj.brand);
        setLProductCode(obj?.productCode);
        setHsnCode(obj?.hsnCode);
        setTaxPercent(obj?.taxPercent);
        setLsph(obj?.sph)
        setLcyl(obj?.cyl)
        setLaxis(obj?.axis)
        setLadd(obj?.addition)
        let dissArray = party?.discounts;
        if (dissArray.length > 0 && obj) {
          console.log(dissArray, "diss");
          console.log(obj, "dissObj");
          let selectedDis = dissArray
            .reverse()
            .find((item) => item.type === "Range");
          console.log("this happening", selectedDis);
          if (selectedDis) {
            let fR = selectedDis?.product?.codeRanges?.find(
              (item) => item == codeL
            );
            if (fR != undefined) {
              console.log("comp316");
              setNameL(selectedDis.product.productName);
              setBrandL(obj.brand);
              setRateL(selectedDis.discountedPrice);
              setDiscountPercent(selectedDis.discountPercent);
            }
          } else {
            console.log("no range here");
            let selectedDis2 = dissArray
              .reverse()
              .find((item) => item.product.color === obj.color);
            console.log("forReal>>>>editse", selectedDis2, codeL, dissArray);
            console.log("comp330", selectedDis2);
            if (selectedDis2) {
              setNameL(selectedDis2?.product?.productName);
              setBrandL(obj.brand);
              setDiscountPercent(selectedDis2.discountPercent);
              setRateL(selectedDis2?.discountedPrice);
              // setDiscountPercent(selectedDis2?.discountPercent);
              // setDiscountAmount(selectedDis2.discountedPrice);
              if (discountPercent > 0) {
                let newD =
                  Number(discountPercent) +
                  Number(selectedDis2?.discountPercent);
                //console.log("987", newD)
                let finD = newD / 2;
                //console.log("setfromdebounceR988", finD)
                setDiscountPercent(finD);
              } else {
                setDiscountPercent(selectedDis2?.discountPercent);
              }
            } else {
            }
            // //console.log("that happening")

            // let selectedDis2 = dissArray
            //   .reverse()
            //   .find((item) => item.product.color === obj.color);
            // setNameR(selectedDis2.product.productName)
            // setDiscountPercent(selectedDis2.discountPercent);

            // setDiscountAmount(selectedDis2.discountedPrice);
          }
          //// //console.log(selectedDis, "dis");
        }
      } else {
        if (obj.orderDetail) {
          console.log(obj, "594");
          //console.log("856");

          setQuantity(obj.quantity);
          let productCode = "";
          if (obj.orderType === "Fitting") {
            if (obj.orderDetail.fitting) {
              setFitting(obj.orderDetail.fitting);
              let fitting = _.find(fittingMasters, {
                fittingType: obj.orderDetail.fitting,
              });
              setFittingAmount(fitting.amount);
            }
            setRateL(obj.orderDetail.lRate);
            productCode =
              obj.orderDetail.lSph +
              "/" +
              obj.orderDetail.lCyl +
              "/" +
              obj.orderDetail.lAxis +
              "/" +
              obj.orderDetail.lAddition;
            console.log("864orderDetailcomp");

            setNameL(obj.orderDetail.productName + "-" + productCode);
          } else {
            setRateL(obj.rate);
            productCode =
              obj.orderDetail.lSph +
              "/" +
              obj.orderDetail.lCyl +
              "/" +
              obj.orderDetail.lAxis +
              "/" +
              obj.orderDetail.lAddition;
            if (obj.orderDetail.product) {
              console.log("396comp");
              setNameL(obj.orderDetail.product.productName + "-" + productCode);
            }
          }
          setOrderRef(obj.code);
          setLProductCode(productCode);
        }
      }
    }
  };

  const debouncedEffect = _.debounce((codeR) => {
    //console.log("useeffect debounced code R running2")
    let obj;
    let codes = [];
    let codesR = [];
    if (codeR) {
      obj = _.find(orders, { code: codeR });
      _.map(orders, (i) => {
        codes.push(i.code);
        codesR.push(i.code);
        //console.log("pushingthistoR", i.code)
      });
      if (!obj) {
        // codes = [];
        // codesR = [];

        let readyMasters2 = [];

        if (codeR.length < 5) {
          return;
        }
        //console.log("useEffect codeR readyMasters2", readyMasters2);
        axios
          .get(GetAllReadyMastersCodesAPI(userInfo.entity, codeR, "R"), {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          })
          .then((res) => {
            //console.log("res", res);
            //console.log(
            //   "useEffect codeR readyMasters2 res",
            //   res.data.readyMasters
            // );
            setReadyMasters(res.data.readyMasters);
            _.map(res.data.readyMasters, (i) => {
              codes.push(i.productCode);
              if (i.side === "B" || i.side === "R") {
                codesR.push(i.productCode);
              }
            });
            // Reorder codesR array
            codesR = codesR.sort((a, b) => {
              // Move codes starting with 'o' to the end
              if (a.startsWith("o") && !b.startsWith("o")) {
                return 1;
              } else if (!a.startsWith("o") && b.startsWith("o")) {
                return -1;
              }
              return 0;
            });
            setCodesR(codesR);
          })
          .catch((e) => {
            //console.log(e, "<<err");
          });
      }
      setCodes(codes);
      if (obj) {
        if (obj.productName) {
          console.log("571comp", obj);
          setNameR(obj.productName);
          //console.log("sethere1");
          setRateR(obj.rate);
          setRProductCode(obj.productCode);
        } else {
          if (obj.orderDetail) {
            setQuantity(obj.quantity);
            setRateR(obj.price);
            let productCode = "";
            if (obj.orderType === "Fitting") {
              if (obj.orderDetail.fitting) {
                setFitting(obj.orderDetail.fitting);
                let fitting = _.find(fittingMasters, {
                  fittingType: obj.orderDetail.fitting,
                });
                setFittingAmount(fitting.amount);
              }
              setRateR(obj.orderDetail.rRate);
              productCode =
                obj.orderDetail.rSph +
                "/" +
                obj.orderDetail.rCyl +
                "/" +
                obj.orderDetail.rAxis +
                "/" +
                obj.orderDetail.rAddition;
              console.log("600comp", obj);
              setNameR(
                obj.orderDetail.product.index +
                  "-" +
                  obj.orderDetail.productName +
                  "-" +
                  obj.orderDetail.product.coating +
                  "-" +
                  productCode
              );
            } else {
              setRateR(obj.rate);
              productCode =
                obj.orderDetail.rSph +
                "/" +
                obj.orderDetail.rCyl +
                "/" +
                obj.orderDetail.rAxis +
                "/" +
                obj.orderDetail.rAddition;
              if (obj.orderDetail.product) {
                console.log("769comp", obj);
                setNameR(
                  obj.orderDetail.product.index +
                    "-" +
                    obj.orderDetail.productName +
                    "-" +
                    obj.orderDetail.product.coating +
                    "-" +
                    productCode
                );
                setRateR(obj?.orderDetail?.product?.price);
                setHsnCode(obj?.orderDetail?.product?.hsnCode);
                //console.log("sethere3");
              }
            }
            setOrderRef(obj.code);
            setRProductCode(productCode);
          }
        }
      }
    }
    if (!codeR) {
      if (!codeL) {
        setQuantity("");
        setFittingAmount("");
        setFitting("");
        setOrderRef("");
        setRate("");
        setDiscountPercent("");
        setHsnCode("");
        setTaxPercent("");
      }
      setNameR("");
      setRateR("");
      setRProductCode("");
    }
    //console.log("hojaye shuru");
  }, 500);

  // useEffect(() => {
  //   //console.log("useeffect code R running2")
  //   debouncedEffect(codeR);
  // }, [codeR]);

  useEffect(() => {
    if (rateR) {
      setRate(rateR.toString());
    }

    if (rateL) {
      setRate(rateL.toString());
    }

    if (rateR && rateL) {
      //console.log("dono");
      let rate = (Number(rateR) + Number(rateL)) / 2;
      setRate(rate.toString());
    }
  }, [rateR, rateL]);

  useEffect(() => {
    let amount = 0;
    if (rate && quantity) {
      amount = Number(rate) * Number(quantity);
    }
    if (discountAmount) {
      amount = amount - Number(discountAmount);
    }
    if (discountPercent) {
      amount = amount - (amount * Number(discountPercent)) / 100;
    }
    if (fittingAmount) {
      amount += Number(fittingAmount) * Number(quantity);
    }
    if (tint && quantity) {
      amount += Number(quantity) * Number(tint);
    }
    setAmount(amount.toFixed(2));
  }, [rate, fittingAmount, discountAmount, discountPercent, tint, quantity]);

  useEffect(() => {
    let obj = _.find(fittingMasters, { fittingType: fitting });
    if (obj) {
      setFittingAmount(obj.amount);
    }
  }, [fitting]);

  async function _getFittingMasters() {
    try {
      let res = await axios.get(GetAllFittingMastersAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setFittingMasters(res.data.fittingMasters);
      let fittingMastersCodes = [];
      _.map(res.data.fittingMasters, (i) => {
        fittingMastersCodes.push(i.fittingType);
      });
      setFittingMastersCodes(fittingMastersCodes);
    } catch (err) {
      // //// //console.log(err);
      // toast.error(('Cannot fetch data'), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  async function _getOrders() {
    try {
      if (party) {
        let res = await axios.get(
          GetAllOrdersAPI(userInfo.entity) +
            `&userId=${party._id}&isChallanCreated=false&orderAdminStatus=Confirmed`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        console.log(res.data, "these are my orders");
        setOrders(res.data.orders);
      }
    } catch (err) {
      // //// //console.log("error:", err);
      // toast.error(('Cannot fetch data'), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  async function _onSave() {
    try {
      // Check the pathname to determine which API to use
      let apiUrl;
      if (location.pathname.includes("creditNote")) {
        apiUrl = CreateCreditNoteAPI();
      } else if (location.pathname.includes("debitNote")) {
        apiUrl = CreateDebitNoteAPI();
      } else {
        apiUrl = CreateChallanAPI();
      }
      if (items.length === 0) {
        if (location.pathname.includes("creditNote")) {
          toast.error("Please add a Credit Note", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        } else if (location.pathname.includes("debitNote")) {
          toast.error("Please add a Debit Note", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        } else {
          toast.error("Please add a Challan", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }
        return;
      }
      let totalAmount = 0;
      _.map(items, async (i) => {
        totalAmount =
          Number(totalAmount) + Number(i?.amount) + Number(i?.packagingCost);
      });
      //console.log("amtFinale>>>>", totalAmount);
      // return

      let res = await axios.post(
        apiUrl,
        {
          items,
          amount: totalAmount,
          entity: userInfo.entity,
          packagingCost: packagingCost,
          packagingTaxPercent: packagingTaxPercent,
          challanDate:
            challanDate == null
              ? moment().toISOString()
              : moment(challanDate).toISOString(),
          createdAt:
            challanDate == null
              ? moment().toISOString()
              : moment(challanDate).toISOString(),
          party: {
            accountCode: party.accountCode,
            accountId: party._id,
            accountName: party.name,
            accountAddress: party.address,
            accountGst: party.gst,
            runningChallanBalance: party.runningChallanBalance + totalAmount,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      //console.log("resp>>>>", res);

      toast.success("Successfully Added!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      close();
    } catch (e) {
      if (e.response && e.response.status === 404) {
        console.log("Resource not found", e);
        // Customize your toast message for 404 error
        toast.error(`${e.response.data}`, {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.log("saverr", e.response ? e.response.data : e.message);
        toast.error("Something went wrong, Please Contact Admin", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  function _onAddRecord() {
    if (
      party === null ||
      (codeR === null && codeL === null) ||
      quantity === ""
    ) {
      toast.error("Please fill all the Fields", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    let data = {
      codeR,
      codeL,
      nameR,
      brandR,
      brandL,
      nameL,
      quantity,
      rateR,
      rSph,
      lSph, 
      rCyl, 
      lCyl,
      rAxis,
      lAxis,
      rAdd,
      lAdd,
      rateL,
      rate,
      discountAmount,
      discountPercent,
      fitting,
      fittingAmount,
      tint,
      amount,
      packagingCost,
      orderRef,
      lProductCode,
      rProductCode,
      hsnCode,
      taxPercent,
      coating,
      // orderRef
    };
    //// //console.log(data, "this is my data");
    let copy = _.clone(items);
    copy.push(data);
    setItems(copy);
    setCodeR(null);
    setCodeL(null);
    setNameR("");
    setNameL("");
    setQuantity("");
    setRateL("");
    setRateR("");
    setRate("");
    setDiscountAmount("");
    setDiscountPercent("");
    setFittingAmount("");
    setFitting("");
    setTint("");
    setAmount("");
    setOrderRef("");
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>
                  {location.pathname.includes("creditNote")
                    ? "Credit Note Add"
                    : location.pathname.includes("debitNote")
                    ? "Debit Note Add"
                    : "Challan Add"}
                </h4>
                <Box display={"flex"} alignItems={"center"}>
                  {orders.length > 0 && (
                    <Button
                      style={{ color: "white" }}
                      onClick={() => setOpenOrdersDialog(true)}
                    >
                      View Orders
                    </Button>
                  )}
                  {/* <Box>
                    <CustomInput
                      labelText="Packaging Cost"
                      id="packag"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: nameR,
                        disabled: true,
                      }}
                    />
                  </Box> */}

                  <Button
                    style={{ color: "white" }}
                    onClick={() => setOpenColorsDialog(true)}
                  >
                    GET CODES
                  </Button>
                  <Button style={{ color: "white" }} onClick={close}>
                    Close
                  </Button>
                  <Button style={{ color: "white" }} onClick={() => _onSave()}>
                    Save
                  </Button>
                </Box>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={3} sm={3} md={2} style={{ marginTop: 25 }}>
                  <MuiPickersUtilsProvider utils={DateUtils}>
                    <DatePicker
                      label="Challan Date"
                      fullWidth
                      format="DD/MM/YYYY"
                      value={challanDate}
                      // defaultValue={moment.utc().format("DD/MM/YYYY")}
                      onChange={(date) => {
                        setChallanDate(date);
                      }}
                      animateYearScrolling
                    />
                  </MuiPickersUtilsProvider>
                </GridItem>

                <GridItem xs={3} sm={3} md={2}>
                  <Autocomplete
                    className={classes.formControl}
                    options={users}
                    fullWidth
                    value={party}
                    onChange={(event, value) => setParty(value)}
                    getOptionLabel={(option) => option.name}
                    id="party"
                    style={{ marginTop: 26 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Party" />
                    )}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  {codeR && Object.keys(selectR).length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "8px 0",
                      }}
                    >
                      {/* Column for displaying the selected code */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginRight: 10,
                        }}
                      >
                        <CustomInput
                          labelText="Order Code R"
                          id="ordercodeR"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: codeR,
                            disabled: true,
                          }}
                        />
                      </div>

                      {/* Button for deselecting */}
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                          setCodeR(null); // Reset codeR to null or an empty value
                          setSelectR({}); // Optionally clear selectR or trigger other effects
                        }}
                        style={{
                          marginLeft: 10, // Add space between the button and the input
                          marginTop: 30, // Add space between the button and the input
                          height: 36,
                          // Match the height with the CustomInput field
                        }}
                      >
                        X
                      </Button>
                    </div>
                  ) : (
                    <Autocomplete
                      loading={codesR.length === 0}
                      className={classes.formControl}
                      options={codesR}
                      fullWidth
                      filterOptions={filterOptions}
                      value={codeR}
                      onChange={(event, value) => {
                        setCodeR(value);
                        afterSelectR(value);
                      }}
                      onInputChange={(event, value) => {
                        debouncedEffect(value);
                      }}
                      getOptionLabel={(option) => option}
                      style={{ marginTop: 26 }}
                      id="codeR"
                      disabled={Object.keys(selectR).length > 0}
                      renderInput={(params) => (
                        <TextField {...params} label="Code R" />
                      )}
                    />
                  )}
                </GridItem>

                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    labelText="Name R"
                    id="nameR"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: nameR,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  {codeL && Object.keys(selectL).length > 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "8px 0",
                      }}
                    >
                      {/* Column for displaying the selected code */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          marginRight: 10,
                        }}
                      >
                        <CustomInput
                          labelText="Order Code L"
                          id="ordercodeL"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            value: codeL,
                            disabled: true,
                          }}
                        />
                      </div>

                      {/* Button for deselecting */}
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => {
                          setCodeL(null); // Reset codeR to null or an empty value
                          setSelectL({}); // Optionally clear selectR or trigger other effects
                        }}
                        style={{
                          marginLeft: 10, // Add space between the button and the input
                          marginTop: 30, // Add space between the button and the input
                          height: 36,
                          // Match the height with the CustomInput field
                        }}
                      >
                        X
                      </Button>
                    </div>
                  ) : (
                    <Autocomplete
                      loading={codesL.length === 0 ? true : false}
                      className={classes.formControl}
                      options={codesL}
                      fullWidth
                      filterOptions={filterOptions}
                      value={codeL}
                      onChange={(event, value) => {
                        setCodeL(value);
                        afterSelectL(value);
                      }}
                      onInputChange={(event, value) => {
                        //console.log("val", value)
                        // setCodeL(value)
                        debouncedEffectL(value);
                      }}
                      getOptionLabel={(option) => option}
                      style={{ marginTop: 26 }}
                      id="codeL"
                      renderInput={(params) => (
                        <TextField {...params} label="Code L" />
                      )}
                    />
                  )}
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    labelText="Name L"
                    id="nameL"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: nameL,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Quantity"
                    id="quantity"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: quantity,
                      onChange: (e) => setQuantity(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Rate R"
                    id="rateR"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rateR,
                      onChange: (e) => setRateR(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Rate L"
                    id="rateL"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rateL,
                      onChange: (e) => setRateL(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Rate"
                    id="rate"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    disabled
                    inputProps={{
                      value: rate,
                      disabled: true,
                      onChange: (e) => setRate(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Dis %"
                    id="discountPercent"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: discountPercent,
                      onChange: (e) => setDiscountPercent(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Dis Amt"
                    id="discountAmount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      value: discountAmount,
                      onChange: (e) => setDiscountAmount(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <Autocomplete
                    className={classes.formControl}
                    options={fittingMastersCodes}
                    fullWidth
                    value={fitting}
                    onChange={(event, value) => setFitting(value)}
                    getOptionLabel={(option) => option}
                    style={{ marginTop: 26 }}
                    id="fitting"
                    renderInput={(params) => (
                      <TextField {...params} label="Fitting" />
                    )}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Fit Amt"
                    id="fittingAmount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      value: fittingAmount,
                      onChange: (e) => setFittingAmount(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Hsn Code"
                    id="hsnCode"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: hsnCode,
                      disabled: true,
                      onChange: (e) => setHsnCode(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Tax Percent"
                    id="taxPercent"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: taxPercent,
                      onChange: (e) => setTaxPercent(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Tint"
                    id="tint"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: tint,
                      onChange: (e) => setTint(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Amount"
                    id="amount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: amount,
                      onChange: (e) => setAmount(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Order Ref"
                    id="orderRef"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: orderRef,
                      onChange: (e) => setOrderRef(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={1} sm={1} md={1}>
                  <Fab
                    color="primary"
                    aria-label="add"
                    className={classes.fab}
                    onClick={_onAddRecord}
                  >
                    <AddIcon />
                  </Fab>
                </GridItem>

                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Pkg tax %"
                    id="packagingTaxPercent"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: packagingTaxPercent,
                      onChange: (e) => setPackagingTaxPercent(e.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Pkg Cost"
                    id="packagingCost"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: packagingCost,
                      onChange: (e) => {
                        if (items.length > 0) {
                          // Show a toast notification
                          toast.info(
                            "Pkg Cost is disabled because items are present."
                          );
                        } else {
                          setPackagingCost(e.target.value);
                        }
                      },
                      disabled: items.length > 0, // Disable if items array has any element
                    }}
                  />
                </GridItem>

                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Total Qty"
                    id="totQuant"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    disabled
                    // value={sumOfQuantities}
                    inputProps={{
                      value: sumOfQuantities,
                    }}
                  />
                </GridItem>

                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Total Amt"
                    id="totQuant"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    disabled
                    // value={sumOfAmounts}
                    inputProps={{
                      value: Number(sumOfAmounts) + Number(packagingCost),
                    }}
                  />
                </GridItem>

                {_.isEmpty(items) ? null : (
                  <GridItem xs={12}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={"center"}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                if (column.id === "action") {
                                  return (
                                    <TableCell align={"center"}>
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                      >
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() => {
                                            let copy = _.clone(items);
                                            copy.splice(index, 1);
                                            setItems(copy);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </TableCell>
                                  );
                                }
                                return (
                                  <TableCell key={column.id} align={"center"}>
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {openOrdersDialog && (
        <ChallanOrdersDialog
          open={openOrdersDialog}
          onClose={() => setOpenOrdersDialog(false)}
          data={orders}
          OnR={setR}
          OnL={setL}
        />
      )}

      {openColorsDialog && (
        <ColorsDialog
          open={openColorsDialog}
          onClose={() => setOpenColorsDialog(false)}
          data={readyMasterRange}
        />
      )}
    </Dialog>
  );
}
