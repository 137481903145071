import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
import { NavLink } from "react-router-dom";

// core components
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import axios from "axios";
import styles from "../../assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationDialog } from "../common";
import { logout } from "../../actions";
import { LogoutAPI } from "../../config/api";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const userInfo = useSelector((state) => state.userReducer.userInfo);

  const authToken = useSelector((state) => state.userReducer.authToken);
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const handleOpenConfirmationModal = () => {
    setOpenConfirmationModal(true);
  };
  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  async function _onLogout() {
    try {
      let res = await axios.post(LogoutAPI(), null, {
        headers: {
          Authorization: authToken,
        },
      });
      history.push("/login");
      dispatch(logout());
    } catch (e) {
      console.log("err:", e);
    }
  }

  return (
    <div>
      <div className={classes.manager}>
        <Typography variant="body1">{userInfo.name} {userInfo.entity}</Typography>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
        >
          <Person className={classes.icons} />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem className={classes.dropdownItem}>
                      <NavLink
                        to={"/admin/user"}
                        style={{
                          textDecoration: "none",
                          "&:hover,&:focus,&:visited,&": {
                            color: "#fff",
                          },
                        }}
                      >
                        User Profile
                      </NavLink>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleCloseProfile();
                        handleOpenConfirmationModal();
                      }}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
      <ConfirmationDialog
        open={openConfirmationModal}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => _onLogout()}
        title={"Logout"}
        msg={"Do you want to logout from this app?"}
      />
    </div>
  );
}
