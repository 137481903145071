import React, { useEffect, useState } from "react";
// @material-ui/core components
import {
  Dialog,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import axios from "axios";
import {
  CreateReadyMasterAPI,
  GetAllFittingMastersAPI,
  GetAllReadyMastersAPI,
  GetAllBrandsAPI,
  GetAllCategorysAPI,
} from "../../config/api";
import { Autocomplete } from "@material-ui/lab";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";
import _ from "lodash";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { FilePicker } from "../../components/common/FilePicker.js";
import { toast } from "react-toastify"

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 500,
});

const styles = {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function ReadyMasterAdd(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { open, close, fetchData } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [fittings, setFittings] = useState([]);
  const [brand, setBrand] = useState([]);
  const [category, setCategory] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [productName, setProductName] = useState("");
  const [productCode, setProductCode] = useState("00/0/0");
  const [index, setIndex] = useState("");
  const [side, setSide] = useState("");
  const [type, setType] = useState("");
  const [color, setColor] = useState("");
  const [rate, setRate] = useState("");
  const [sph, setSph] = useState("0");
  const [cyl, setCyl] = useState("0");
  const [axis, setAxis] = useState("0");
  const [addition, setAddition] = useState("0");
  const [readyMasterImage, setReadyMasterImage] = useState([]);
  const [hsnCode, setHsnCode] = useState("")
  const [taxPercent, setTaxPercent] = useState("")

  let userInfo = useSelector((state) => state.userReducer.userInfo);

  let authToken = useSelector((state) => state.userReducer.authToken);
  // const readyMasters = useSelector((state) => state.userReducer.readyMasters);

  useEffect(() => {
    _getFittings();
    _getBrands();
    _getCategory();
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      setProductName(selectedProduct.productName);
      setIndex(selectedProduct.index);
      setSide(selectedProduct.side);
      setType(selectedProduct.type);
      setColor(selectedProduct.color);
      setRate(selectedProduct.rate);
    } else {
      setProductName("");
      setIndex("");
      setSide("");
      setType("");
      setColor("");
      setRate("");
    }


  }, [selectedProduct]);

  async function _getBrands() {
    try {
      let res = await axios.get(GetAllBrandsAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setBrand(res.data.brandMasters);
    } catch (err) {
      // console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _getCategory() {
    try {
      let res = await axios.get(GetAllCategorysAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setCategory(res.data.categoryMasters);
    } catch (err) {
      // console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _getFittings() {
    try {
      let res = await axios.get(
        GetAllReadyMastersAPI(userInfo.entity),
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFittings(res.data.readyMasters);
    } catch (err) {
      // console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _onSave() {
    try {
      let formData = new FormData();
      formData.append("productCode", productCode);
      formData.append("productName", productName);
      formData.append("index", index);
      formData.append("side", side);
      formData.append("type", type);
      formData.append("color", color);
      formData.append("rate", rate);
      formData.append("sph", sph);
      formData.append("cyl", cyl);
      formData.append("axis", axis);
      formData.append("addition", addition);
      formData.append("brand", selectedBrand._id);
      formData.append("category", selectedCategory._id);
      formData.append("hsnCode", hsnCode);
      formData.append("taxPercent", taxPercent)


      formData.append("entity", userInfo.entity);

      for (let i = 0; i < readyMasterImage.length; i++) {
        formData.append("readyMasterImages", readyMasterImage[i].path);
      }
      // let ready = {
      //   productCode,
      //   productName,
      //   index,
      //   side,
      //   type,
      //   color,
      //   rate,
      //   entity: userInfo.entity,
      // };

      if (
        readyMasterImage.length === 0) {
        toast.error("Please fill all the fields", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      let res = await axios.post(CreateReadyMasterAPI(), formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success('Successfully Added!', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      close();
      fetchData();
    } catch (e) {
      console.log("err:", e);
      toast.error("Please fill all the fields", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
  }

  function _setReadyMasterImage(files) {
    setReadyMasterImage(files);
  }

  useEffect(() => {
    let c = color + sph + cyl + "/" + axis + "/" + addition;
    setProductCode(c);
  }, [color, sph, cyl, axis, addition]);

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Add Ready Master</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <GridItem xs={12}>
                    <FilePicker
                      files={readyMasterImage}
                      buttonLabel={"Upload Ready Master Image"}
                      id={"Ready Master Photo"}
                      maxFiles={10}
                      setNewFiles={_setReadyMasterImage}
                      filetype={".png,.jpg,.jpeg"}
                    />
                  </GridItem>
                  <Autocomplete
                    // filterOptions={filterOptions}
                    loading={fittings.length === 0 ? true : false}
                    className={classes.formControl}
                    options={fittings}
                    value={selectedProduct}
                    onChange={(event, newValue) => {
                      setSelectedProduct(newValue);
                    }}
                    getOptionLabel={(option) =>
                      option.productName
                        ? option.productName + " " + option.productCode
                        : ""
                    }
                    id="selectedProduct"
                    renderInput={(params) => (
                      <TextField {...params} label="Select Product" />
                    )}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="Product Name"
                    id="productName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: productName,
                      onChange: (event) => setProductName(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="Product Code"
                    id="productCode"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: productCode,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} md={12} sm={12}>
                  <Barcode value={productCode} />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Index"
                    id="index"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: index,
                      onChange: (event) => setIndex(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <Autocomplete
                    className={classes.formControl}
                    options={["B", "R", "L"]}
                    value={side}
                    onChange={(event, newValue) => {
                      setSide(newValue);
                    }}
                    getOptionLabel={(option) => option}
                    id="selectedSide"
                    renderInput={(params) => (
                      <TextField {...params} label="Select Side" />
                    )}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Type"
                    id="type"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: type,
                      onChange: (event) => setType(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Color/ABR"
                    id="color"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: color,
                      onChange: (event) => {
                        setColor(event.target.value);
                      },
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Sph"
                    id="sph"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: sph,
                      onChange: (event) => {
                        let sph = event.target.value;
                        setSph(sph);
                      },
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Cyl"
                    id="cyl"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: cyl,
                      onChange: (event) => setCyl(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Axis"
                    id="axis"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: axis,
                      onChange: (event) => setAxis(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Addition"
                    id="addition"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: addition,
                      onChange: (event) => setAddition(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="rate"
                    id="rate"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rate,
                      onChange: (event) => setRate(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Autocomplete
                    filterOptions={filterOptions}
                    loading={brand.length === 0 ? true : false}
                    className={classes.formControl}
                    options={brand}
                    value={selectedBrand}
                    onChange={(event, newValue) => {
                      setSelectedBrand(newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    id="selectedBrand"
                    renderInput={(params) => (
                      <TextField {...params} label="Select Brand" />
                    )}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <Autocomplete
                    filterOptions={filterOptions}
                    loading={category.length === 0 ? true : false}
                    className={classes.formControl}
                    options={category}
                    value={selectedCategory}
                    onChange={(event, newValue) => {
                      setSelectedCategory(newValue);
                    }}
                    getOptionLabel={(option) => option.name}
                    id="selectedCategory"
                    renderInput={(params) => (
                      <TextField {...params} label="Select Category" />
                    )}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="Hsn Code"
                    id="hsnCode"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: hsnCode,
                      onChange: (event) => setHsnCode(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="Tax percent"
                    id="taxPercent"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: taxPercent,
                      onChange: (event) => setTaxPercent(event.target.value),
                    }}
                  />
                </GridItem>

              </GridContainer>
            </CardBody>
            <CardFooter style={{ justifyContent: "center" }}>
              <Button color="primary" onClick={() => _onSave()}>
                Add Ready Master
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
