import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
// @material-ui/core components
import {
  Box,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { useSelector } from "react-redux";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
// import Table from "../../components/Table/Table.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import AddIcon from "@material-ui/icons/Add";
import DiscountAdd from "./DiscountAdd";
import { CloudDownload, Search } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DiscountView from "./DiscountView";
import DiscountEdit from "./DiscountEdit";
import CustomInput from "../../components/CustomInput/CustomInput";
import People from "@material-ui/icons/People";
import ReactExport from "react-data-export";
import axios from "axios";
import { GetAllAccountsAPI } from "../../config/api";
import { ConfirmationDialog } from "../../components/common";
import { toast } from "react-toastify";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const columns = [
  { id: "accountCode", label: "Party Code" },
  { id: "name", label: "Party Name" },
  { id: "discountedProducts", label: "Discounted Products" },
  // { id: "createdByName", label: "Discount Provided By" },
  { id: "action", label: "Action" },
];

export default function DiscountList() {
  const classes = useStyles();
  let authToken = useSelector((state) => state.userReducer.authToken);
  let userInfo = useSelector((state) => state.userReducer.userInfo);

  const [accounts, setAccounts] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openViewDialog, setOpenViewDialog] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(null);
  const [tota, setTota] = useState(0)


  // useEffect(() => {
  //   _getDiscounts();
  // }, [openEditDialog]);
  useEffect(() => {
    const fetchData = async () => {
      await _getDiscounts(page, rowsPerPage);
    };

    fetchData();
  }, [openEditDialog, page, rowsPerPage]);

  const _getDiscounts = useMemo(() => async (page, rowsPerPage) => {
    let skipm = page * rowsPerPage

    try {
      let query = `&limit=${rowsPerPage}&skip=${skipm}`;

      let res = await axios.get(
        GetAllAccountsAPI(userInfo.entity) + query,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(userInfo.entity, "This is user entity")
      setAccounts(res.data.accounts);
      setTota(res.data.total)

    } catch (err) {
      console.log("Error:  ", (err))
      // toast.error(("Something went wrong, Please Contact Admin"), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }

  }, [])

  // async function _getDiscounts() {
  //   try {
  //     let res = await axios.get(GetAllAccountsAPI(userInfo.entity), {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });
  //     setAccounts(res.data.accounts);
  //   } catch (err) {
  //     // console.log(err);
  //     // toast.error(("Something went wrong, Please Contact Admin") , {
  //     //   position: "top-center",
  //     //   autoClose: 1500,
  //     //   hideProgressBar: false,
  //     //   closeOnClick: true,
  //     //   pauseOnHover: true,
  //     //   draggable: true,
  //     //   progress: undefined,
  //     //   theme: "light",
  //     //   }); 
  //   }
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function closeAddDialog() {
    setOpenAddDialog(false);
  }

  function closeViewDialog() {
    setOpenViewDialog(null);
  }

  function closeEditDialog() {
    setOpenEditDialog(null);
  }

  async function searchChallan(searchTerm) {
    try {
      console.log("searchin", searchTerm)
      let query = `&search=${searchTerm}`;
      let res = await axios.get(
        GetAllAccountsAPI(userInfo.entity) + query,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      // console.log(userInfo.entity, "This is user entity")
      setAccounts(res.data.accounts);
      setTota(res.data.total)
      // Process the result of the API call
    } catch (error) {
      console.error("Error fetching data from API:", error);
    }
  }

  let data = accounts;

  useEffect(() => {
    if (searchTerm !== "") {
      searchChallan(searchTerm);
    } else {
      _getDiscounts(page, rowsPerPage)
    }
  }, [searchTerm]);

  // if (searchTerm !== "") {
  //   data = _.filter(data, (i) => {
  //     return i.name && i.name.toLowerCase().includes(searchTerm.toLowerCase());
  //   });
  // }

  let excelData = [];
  data.map((o) => {
    o.discounts &&
      o.discounts.map((i) => {
        let { product, discountPercent, discountPrice } = i;
        let d = [];
        d.push({ value: o.accountName });
        d.push({ value: o.accountCode });
        d.push({ value: product.productName });
        d.push({ value: product.productCode });
        d.push({ value: product.lab });
        d.push({ value: product.brand });
        d.push({ value: product.price });
        d.push({ value: product.index });
        d.push({ value: product.dia });
        d.push({ value: product.fittingHeight });
        d.push({ value: product.powerRange });
        d.push({ value: product.coating });
        d.push({ value: product.quantity });
        d.push({ value: discountPercent });
        d.push({ value: discountPrice });
        excelData.push(d);
      });
  });

  const multiDataSet = [
    {
      columns: [
        { title: "Account Name" },
        { title: "Account Code" },
        { title: "Product Name" },
        { title: "Product Code" },
        { title: "Lab" },
        { title: "Brand" },
        { title: "Price" },
        { title: "Index" },
        { title: "Dia" },
        { title: "Fitting Height" },
        { title: "Power Range" },
        { title: "Coating" },
        { title: "Quantity" },
        { title: "Discount Percent" },
        { title: "Discount Price" },
      ],
      data: excelData,
    },
  ];

  function handleSearch() {
    setSearchTerm(search);
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4 className={classes.cardTitleWhite}>Discounts</h4>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <CustomInput
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    style: {
                      marginTop: 0,
                      marginRight: 10,
                      color: "white",
                    },
                    placeholder: "Search",
                    value: search,
                    onChange: (e) => setSearch(e.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <People />
                      </InputAdornment>
                    ),
                  }}
                />
                <IconButton color={"secondary"} onClick={(e) => {
                  // setSearchTerm(e.target.value),
                  // handleKeyPress(e)
                  // handleKeyPress()
                  handleSearch()


                }}>
                  <Search />
                </IconButton>
                <IconButton
                  aria-label="add"
                  style={{ color: "white" }}
                  onClick={() => setOpenAddDialog(true)}
                >
                  <AddIcon />
                </IconButton>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={"center"}
                      style={{ minWidth: column.minWidth }}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        {column.label}
                        {column.id === "action" ? (
                          <Box justifyContent={"center"} alignItems={"center"}>
                            <ExcelFile
                              element={
                                <Tooltip title={"Export to excel"}>
                                  <IconButton color={"primary"}>
                                    <CloudDownload />
                                  </IconButton>
                                </Tooltip>
                              }
                            >
                              <ExcelSheet
                                dataSet={multiDataSet}
                                name="Discount"
                              />
                            </ExcelFile>
                          </Box>
                        ) : null}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          let value = row[column.id];
                          if (column.id === "discountedProducts") {
                            value = !_.isEmpty(row.discounts)
                              ? row.discounts.length
                              : 0;
                          }
                          if (column.id === "action") {
                            return (
                              <TableCell align={"center"}>
                                <Box display={"flex"} justifyContent={"center"}>
                                  <IconButton
                                    aria-label="view"
                                    onClick={() =>
                                      setOpenViewDialog({
                                        open: true,
                                        item: row,
                                      })
                                    }
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() =>
                                      setOpenEditDialog({
                                        open: true,
                                        item: row,
                                      })
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={column.id} align={"center"}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={tota}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      </GridItem>
      {/*<Fab color="primary" aria-label="add" className={classes.fab}*/}
      {/*     onClick={() => setOpenAddDialog(true)}>*/}
      {/*    <AddIcon/>*/}
      {/*</Fab>*/}

      {openAddDialog && (
        <DiscountAdd
          fetchData={_getDiscounts}
          open={openAddDialog}
          close={closeAddDialog}
        />
      )}
      {openViewDialog ? (
        <DiscountView
          open={openViewDialog.open}
          close={closeViewDialog}
          item={openViewDialog.item}
        />
      ) : null}
      {openEditDialog ? (
        <DiscountEdit
          open={openEditDialog.open}
          close={closeEditDialog}
          item={openEditDialog.item}
        />
      ) : null}
    </GridContainer>
  );
}
