import React, { useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import {
  makeStyles,
  IconButton,
  InputAdornment,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  Checkbox,
  Box,
  Dialog,
  Slide,
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import People from "@material-ui/icons/People";
import { ConfirmationDialog } from "../../components/common";
import { UpdateHomePageAPI } from "../../config/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const columns = [
  { id: "productCode", label: "Product Code" },
  { id: "productName", label: "Product Name" },
  { id: "index", label: "Index" },
  { id: "side", label: "Side" },
  { id: "type", label: "Type" },
  { id: "color", label: "Color/ABR" },
  { id: "rate", label: "Rate" },
  { id: "action", label: "Action" },
];

export default function HomePageMastersView(props) {
  const { open, close, item } = props;

  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openViewDialog, setOpenViewDialog] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);
  const handleOpenConfirmationModal = (object) => {
    setOpenConfirmationModal(object);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function closeViewDialog() {
    setOpenViewDialog(null);
  }

  async function _deleteItem(product) {
    try {
      var index = item.productId.indexOf(product);
      if (index !== -1) {
        item.productId.splice(index, 1);
      }
      let res = await axios.patch(UpdateHomePageAPI(item._id), item, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });

      // window.location.reload();
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  let data = item.productId;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h4 className={classes.cardTitleWhite}>{item.name}</h4>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <CustomInput
                    id="search"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      style: {
                        marginTop: 0,
                        marginRight: 10,
                        color: "white",
                      },
                      placeholder: "Search",
                      value: searchTerm,
                      onChange: (e) => setSearchTerm(e.target.value),
                      endAdornment: (
                        <InputAdornment position="end">
                          <People />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </CardHeader>

            <CardBody>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <Checkbox
                      style={{
                        color: "#512da8",
                      }}
                      onChange={(e) => {
                        let c = [];
                        if (e.target.checked) {
                          data.map((i) => {
                            c.push(i._id);
                          });
                        }
                        setSelectedRows(c);
                      }}
                      checked={
                        selectedRows.length > 0 &&
                        data.length === selectedRows.length
                      }
                      inputProps={{ "aria-labelledby": -1 }}
                    />
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={"center"}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              style={{
                                color: "#512da8",
                              }}
                              onChange={() => {
                                let copy = _.clone(selectedRows);
                                if (_.includes(copy, row._id)) {
                                  let index = _.indexOf(copy, row._id);
                                  copy.splice(index, 1);
                                } else {
                                  copy.push(row._id);
                                }
                                setSelectedRows(copy);
                              }}
                              checked={_.includes(selectedRows, row._id)}
                              inputProps={{ "aria-labelledby": row._id }}
                            />
                          </TableCell>
                          {columns.map((column) => {
                            const value = row[column.id];

                            if (column.id === "action") {
                              return (
                                <TableCell align={"center"}>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"center"}
                                  >
                                    <IconButton
                                      onClick={() =>
                                        handleOpenConfirmationModal({
                                          title: "Delete Record",
                                          msg: "Do you want to delete this record?",
                                          onPress: () => _deleteItem(row),
                                        })
                                      }
                                      aria-label="delete"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Box>
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell key={column.id} align={"center"}>
                                {column.format ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </CardBody>
          </Card>
        </GridItem>
        {openConfirmationModal ? (
          <ConfirmationDialog
            open={openConfirmationModal}
            onClose={handleCloseConfirmationModal}
            onConfirm={openConfirmationModal.onPress}
            title={"Delete Record"}
            msg={"Do you want to delete this record?"}
          />
        ) : null}
      </GridContainer>
    </Dialog>
  );
}
