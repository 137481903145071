import axios from "axios";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import {
  Dialog,
  Box,
  Slide,
  makeStyles,
  Paper,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button.js";
import { baseUrl, CreatePricingListAPI } from "../../config/api";
import { useSelector } from "react-redux";
import { FilePicker } from "../../components/common/FilePicker";
import DeleteIcon from "@material-ui/icons/Delete";
import Pagination from "@mui/material/Pagination";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  imageSize: {
    maxWidth: 350,
    maxHeight: 200,
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PricingListMastersView(props) {
  const { open, close, item } = props;
  const classes = useStyles();
  let userInfo = useSelector((state) => state.userReducer.userInfo);

  const [pricingListName, setPricingListName] = useState(item.name);
  const [priceListImage, setpriceListImage] = useState([]);
  const [priceListFile, setpriceListFile] = useState([]);
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(null);

  function _setpriceListImage(files) {
    setpriceListImage(files);
  }

  function _setpriceListFile(files) {
    setpriceListFile(files);
  }

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>View PricingList</h4>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="PricingList Name"
                    id="PricingListName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    disabled
                    inputProps={{
                      value: pricingListName,
                      onChange: (event) =>
                        setPricingListName(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <Paper
                    stlye={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {item.priceListImage !== "null" && (
                      <Box className={classes.imageWrapper}>
                        <img
                          src={`${baseUrl}/uploads/${item.priceListImage}`}
                          className={classes.imageSize}
                        />
                      </Box>
                    )}
                  </Paper>
                </GridItem>

                <GridItem xs={12} sm={6} md={8}>
                  <Paper>
                    {item.priceListFile !== "null" && (
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Document
                          file={`${baseUrl}/uploads/${item.priceListFile}`}
                          onLoadSuccess={onDocumentLoadSuccess}
                          loading={
                            <div style={{ display: "block", margin: "auto" }}>
                              <CircularProgress />
                            </div>
                          }
                        >
                          {Array.from(new Array(numPages), (el, index) => {
                            if (index === page - 1) {
                              return (
                                <Page
                                  key={`page_${index + 1}`}
                                  pageNumber={index + 1}
                                />
                              );
                            }
                          })}
                        </Document>
                        <Pagination
                          count={numPages}
                          page={page}
                          onChange={handleChange}
                          color="primary"
                          className={classes.pagination}
                        />
                      </Box>
                    )}
                  </Paper>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
