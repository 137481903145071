import React, { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useSelector } from "react-redux";
// @material-ui/core components
import {
  Box,
  Checkbox,
  Dialog,
  DialogTitle,
  Fab,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import { invoiceTemplate } from "../../assets/html/invoiceTemplate";
import { challan1Template } from "../../assets/html/challan1Template";
import { challan2Template } from "../../assets/html/challan2Template";
import { challan3Template } from "../../assets/html/challan3Template";
import { dataURItoBlob, htmlTemplateToDataString } from "../../utils";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import {
  CreateBulkOrderAPI,
  GetAllAccountsAPI,
  GetAllProductMastersAPI,
} from "../../config/api";
import { toast } from "react-toastify";
import { FilePicker } from "../../components/common/FilePicker.js";

const styles = {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    flex: 1,
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

const glassTypes = ["Cr", "Gi", "P"];
// const coatOptions = ['Crizal UV', 'Forte UV', 'Nature Kote', 'Prevenda', 'Titus', 'Torus'];
// const indexOptions = [
//   "1.49",
//   "1.50",
//   "1.53",
//   "1.56",
//   "1.59",
//   "1.60",
//   "1.67",
//   "1.74",
//   "1.80",
// ];
const frameSections = ["3pc", "Supra", "Full Frame"];
const fittingHeightOptions = ["Regular", "Short"];

let invoiceTemplates = [
  {
    label: "Invoice",
    value: "invoice",
  },
  {
    label: "Challan 1",
    value: "challan1",
  },
  {
    label: "Challan 2",
    value: "challan2",
  },
  {
    label: "Challan 3",
    value: "challan3",
  },
];

const columns = [
  { id: "party", label: "Party" },
  { id: "product", label: "Product" },
  { id: "tint", label: "Tint", format: (value) => (value ? "Yes" : "No") },
  { id: "rSph", label: "Right Sph" },
  { id: "rCyl", label: "Right Cyl" },
  { id: "rAxis", label: "Right Axis" },
  { id: "rAddition", label: "Right Add" },
  { id: "lSph", label: "Left Sph" },
  { id: "lCyl", label: "Left Cyl" },
  { id: "lAxis", label: "Left Axis" },
  { id: "lAddition", label: "Left Add" },
  { id: "action", label: "Action" },
];
export default function PrescriptionOrderAdd(props) {
  const classes = useStyles();
  const theme = useTheme();

  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);

  const { open, close, fetchData } = props;
  const [selectedItem, setSelectedItem] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [quantity, setQuantity] = useState("");
  const [lab, setLab] = useState(null);
  const [productType, setProductType] = useState(null);
  const [product, setProduct] = useState(null);
  const [party, setParty] = useState(null);
  const [partyRef, setPartyRef] = useState("");
  const [partyMobileNo, setPartyMobileNo] = useState("");
  const [clientName, setClientName] = useState("");
  const [glassType, setGlassType] = useState("");
  const [dia, setDia] = useState("");
  const [index, setIndex] = useState("");
  const [fittingHeight, setFittingHeight] = useState("");
  const [coat, setCoat] = useState("");
  const [tint, setTint] = useState(false);
  const [sphRight, setSphRight] = useState("");
  const [sphLeft, setSphLeft] = useState("");
  const [cylLeft, setCylLeft] = useState("");
  const [cylRight, setCylRight] = useState("");
  const [axisRight, setAxisRight] = useState("");
  const [axisLeft, setAxisLeft] = useState("");
  const [addRight, setAddRight] = useState("");
  const [addLeft, setAddLeft] = useState("");
  const [prism, setPrism] = useState("");
  const [pd, setPd] = useState("");
  const [ed, setEd] = useState("");
  const [frameSection, setFrameSection] = useState("");

  const [note, setNote] = useState("");
  const [users, setUsers] = useState([]);
  const [labs, setLabs] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productsMaster, setProductsMaster] = useState([]);
  const [products, setProducts] = useState([]);
  const [coatings, setCoatings] = useState([]);
  const [items, setItems] = useState([]);
  const [indexOptions, setIndexOptions] = useState([])
  const [showInvoiceSelectionDialog, setShowInvoiceSelectionDialog] =
    useState(false);
  const [categoryImage, setCategoryImage] = useState([]);

  console.log("categ",categoryImage)


  useEffect(() => {
    _getUsers();
  }, []);

  useEffect(() => {
    _getProductMaster();
  }, []);

  useEffect(() => {
    if (lab) {
      let productsTypes = _.filter(productsMaster, { lab: lab.lab });
      productsTypes = _.uniqBy(productsTypes, "productType");
      setProductTypes(productsTypes);
    }
  }, [lab]);

  useEffect(() => {
    if (productType) {
      let products = _.filter(productsMaster, {
        lab: lab.lab,
        productType: productType.productType,
      });
      products = _.uniqBy(products, "productName");
      setProducts(products);
    }
  }, [productType]);



  useEffect(() => {
    if (product) {
      let coatTypes = _.filter(productsMaster, {
        productName: product.productName,
      });
      coatTypes = _.uniqBy(coatTypes, "coating");
      setCoatings(coatTypes);
    }
  }, [product]);


  useEffect(() => {
    if (product) {
      let irs = _.filter(productsMaster, {
        productName: product.productName,
        lab: lab.lab,
        productType: productType.productType,
      });
      irs = _.uniqBy(irs, "index");
      const indexArray = irs.map((i) => {
        return i.index
      })
      console.log(indexArray)
      setIndexOptions(indexArray);
    }
  }, [product]);

  // useEffect(() => {
  //   if (index) {
  //     let coatTypes = _.filter(productsMaster, {
  //       productName: product.productName,
  //       lab: lab.lab,
  //       productType: productType.productType,
  //       index: index.index
  //     });
  //     coatTypes = _.uniqBy(coatTypes, "coating");
  //     console.log('coatTYPES FOR product check', coatTypes)
  //     setCoatings(coatTypes);
  //   }
  // }, [index]);



  async function _getUsers() {
    try {
      let res = await axios.get(GetAllAccountsAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      let saleUsers = _.filter(res.data.accounts, { partyType: "sale" });
      setUsers(saleUsers);
    } catch (e) {
      // console.log("er:", e);
      // toast.error(("Something went wrong, Please Contact Admin"), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  async function _getProductMaster() {
    try {
      let res = await axios.get(GetAllProductMastersAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      let productMaster = res.data.productMasters;
      let labs = _.uniqBy(productMaster, "lab");
      setProductsMaster(productMaster);
      setLabs(labs);
    } catch (e) {
      // console.log("er:", e);
      // toast.error(("Something went wrong, Please Contact Admin"), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  async function _onSave() {
    try {
      if (items.length === 0) {
        toast.error("Please add a Presciption order", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      // let formData = new FormData();
      // if (!_.isEmpty(categoryImage)) {
      //   formData.append("image", categoryImage[0].path);
      // }
      // formData.append("data", items)
      // formData.append("entity", userInfo.entity)

      let formData = new FormData();
// if (!_.isEmpty(categoryImage)) {
//   formData.append("image", categoryImage[0].path);
// }
if(categoryImage.length > 0){
  console.log("doin it", typeof items)
  formData.append("image", categoryImage[0].path);
  formData.append("imageName", categoryImage[0].name);
}
// items.push("image" , categoryImage[0].path)
// console.log(">>>>items",items)
// Serialize items array to a JSON string
formData.append("data", JSON.stringify(items));
formData.append("entity", userInfo.entity);
      for (const value of formData.values()) {
        console.log(value);
      }
      let res = await axios.post(
        CreateBulkOrderAPI(),
        // {
        
        //   // data: items,
        //   // entity: userInfo.entity,
        //   // ...items, entity: userInfo.entity,
        // },
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
          },
        }
      );
      setItems([]);
      toast.success("Successfully Added!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      close();
      fetchData();
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin"), {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  }

  const handleOpenInvoiceSelectionModal = (item) => {
    setSelectedItem(item);
    setShowInvoiceSelectionDialog(true);
  };

  async function _onPreview(invoiceType) {
    if (_.isEmpty(selectedItem)) {
      return;
    }
    let htmlData = {},
      htmlTemplate = {};

    let { party, challanDate, quantity, product } = selectedItem;
    let items = [
      {
        name: product.productName,
        rate: product.price,
      },
    ];
    htmlData = {
      clientAddress: party.shippingAddress,
      clientName: party.name,
      clientGSTNo: party.gst,
      challanDate: moment().format("DD-MM-YYYY"),
      items,
      quantity,
    };
    let options = null;
    if (invoiceType === "invoice") {
      options = {
        orientation: "p",
        unit: "pt",
        format: [850, 1066],
        // format:'letter',
        putOnlyUsedFonts: true,
      };
      htmlTemplate = invoiceTemplate(htmlData);
    } else if (invoiceType === "challan1") {
      options = {
        orientation: "p",
        unit: "pt",
        format: [850, 1066],
        // format:'letter',
        putOnlyUsedFonts: true,
      };
      htmlTemplate = challan1Template(htmlData);
    } else if (invoiceType === "challan2") {
      options = {
        orientation: "p",
        unit: "pt",
        format: [850, 1066],
        // format:'letter',
        putOnlyUsedFonts: true,
      };

      htmlTemplate = challan2Template(htmlData);
    } else if (invoiceType === "challan3") {
      options = {
        orientation: "p",
        unit: "px",
        format: "a6",
        // format: [850, 1066],
        // format:'letter',
        putOnlyUsedFonts: true,
      };

      htmlTemplate = challan3Template(htmlData);
    }

    await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
      const preBlob = dataURItoBlob(dataString);
      window.open(URL.createObjectURL(preBlob));
    });
  }

  const InvoiceSelectionDialog = () => {
    return (
      <Dialog
        onClose={() => {
          setShowInvoiceSelectionDialog(false);
          close();
        }}
        aria-labelledby="simple-dialog-title"
        open={showInvoiceSelectionDialog}
      >
        <DialogTitle id="simple-dialog-title">Select Invoice</DialogTitle>
        <List>
          {invoiceTemplates.map(({ label, value }) => (
            <ListItem
              button
              onClick={() => {
                _onPreview(value);
                setShowInvoiceSelectionDialog(false);
                close();
              }}
              key={value}
            >
              <ListItemText primary={label} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  };

  function _setCategoryImage(files) {
    setCategoryImage(files);
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
              // justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>
                  Add Prescription Order
                </h4>
                <Button color={"transparent"} onClick={() => close()}>
                  Close
                </Button>
                <Button color={"transparent"} onClick={() => _onSave()}>
                  Save
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
            
              <GridContainer>
                <GridItem xs={4} sm={4} md={4}>
                  <Autocomplete
                    className={classes.formControl}
                    options={labs}
                    value={lab}
                    onChange={(event, newValue) => setLab(newValue)}
                    getOptionLabel={(option) => (option.lab ? option.lab : "")}
                    id="lab"
                    renderInput={(params) => (
                      <TextField {...params} label="Lab*" />
                    )}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <Autocomplete
                    className={classes.formControl}
                    options={productTypes}
                    value={productType}
                    onChange={(event, newValue) => setProductType(newValue)}
                    getOptionLabel={(option) =>
                      option.productType ? option.productType : ""
                    }
                    id="productType"
                    renderInput={(params) => (
                      <TextField {...params} label="Product Type*" />
                    )}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <Autocomplete
                    className={classes.formControl}
                    options={products}
                    value={product}
                    onChange={(event, newValue) => setProduct(newValue)}
                    getOptionLabel={(option) => option.productName}
                    id="product"
                    renderInput={(params) => (
                      <TextField {...params} label="Product*" />
                    )}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <Autocomplete
                    className={classes.formControl}
                    options={glassTypes}
                    value={glassType}
                    onChange={(event, value) => setGlassType(value)}
                    getOptionLabel={(option) => option}
                    id="glassType"
                    renderInput={(params) => (
                      <TextField {...params} label="Glass Type*" />
                    )}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Dia"
                    id="dia"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: dia,
                      onChange: (event) => setDia(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <Autocomplete
                    className={classes.formControl}
                    options={indexOptions}
                    value={index}
                    onChange={(event, value) => setIndex(value)}
                    getOptionLabel={(option) => option}
                    id="index"
                    renderInput={(params) => (
                      <TextField {...params} label="Index*" />
                    )}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <Autocomplete
                    className={classes.formControl}
                    options={coatings}
                    value={coat}
                    onChange={(event, value) => setCoat(value)}
                    getOptionLabel={(option) => option.coating}
                    id="coat"
                    renderInput={(params) => (
                      <TextField {...params} label="Coat Type*" />
                    )}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <Autocomplete
                    className={classes.formControl}
                    options={fittingHeightOptions}
                    value={fittingHeight}
                    onChange={(event, value) => setFittingHeight(value)}
                    getOptionLabel={(option) => option}
                    id="fittingHeight"
                    renderInput={(params) => (
                      <TextField {...params} label="Fitting Height" />
                    )}
                  />
                </GridItem>

                <GridItem xs={4} sm={4} md={3}>
                  <Autocomplete
                    className={classes.formControl}
                    options={users}
                    value={party}
                    onChange={(event, newValue) => setParty(newValue)}
                    getOptionLabel={(option) => option.name}
                    id="party"
                    renderInput={(params) => (
                      <TextField {...params} label="Party*" />
                    )}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={3}>
                  <CustomInput
                    labelText="Party Reference"
                    id="partyRef"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: partyRef,
                      onChange: (event) => setPartyRef(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={3}>
                  <CustomInput
                    labelText="Party Mobile No."
                    id="partyMobileNo"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: partyMobileNo,
                      onChange: (event) => setPartyMobileNo(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={3}>
                  <CustomInput
                    labelText="Client Name"
                    id="clientName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: clientName,
                      onChange: (event) => setClientName(event.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Prism"
                    id="prism"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: prism,
                      onChange: (event) => setPrism(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Ed"
                    id="ed"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: ed,
                      onChange: (event) => setEd(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Pd"
                    id="pd"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: pd,
                      onChange: (event) => setPd(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <Autocomplete
                    className={classes.formControl}
                    options={frameSections}
                    value={frameSection}
                    onChange={(event, value) => setFrameSection(value)}
                    getOptionLabel={(option) => option}
                    id="frameSection"
                    renderInput={(params) => (
                      <TextField {...params} label="Frame Section" />
                    )}
                  />
                </GridItem>

                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    labelText="Pair / Quantity*"
                    id="quantity"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      value: quantity,
                      onChange: (event) => setQuantity(event.target.value),
                    }}
                  />
                </GridItem>
                <Box
                  component={Grid}
                  container
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderRadius={5}
                  boxShadow={3}
                >
                  <GridItem xs={12} sm={12} md={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tint}
                          onChange={(e) => setTint(e.target.checked)}
                          name="tint"
                          color={"primary"}
                        />
                      }
                      label="Tint"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      id="sphRight"
                      labelText="Right sph*"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: sphRight,
                        onChange: (event) => setSphRight(event.target.value),
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      id="cylRight"
                      labelText="Right cyl*"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: cylRight,
                        onChange: (event) => setCylRight(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      id="axisRight"
                      labelText="Right axis*"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: axisRight,
                        onChange: (event) => setAxisRight(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      id="addRight"
                      labelText="Right add*"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: addRight,
                        onChange: (event) => setAddRight(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      id="sphLeft"
                      labelText="Left sph*"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: sphLeft,
                        onChange: (event) => setSphLeft(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      id="cylLeft"
                      labelText="Left cyl*"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: cylLeft,
                        onChange: (event) => setCylLeft(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      id="axisLeft"
                      labelText="Left axis*"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: axisLeft,
                        onChange: (event) => setAxisLeft(event.target.value),
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <CustomInput
                      id="addLeft"
                      labelText="Left add*"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: addLeft,
                        onChange: (event) => setAddLeft(event.target.value),
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={1}>
                    <Box justifyContent={"center"} marginTop={4}>
                      <Fab
                        size={"small"}
                        color="primary"
                        aria-label="add"
                        onClick={() => {
                          if (
                            lab === null ||
                            productType === null ||
                            product === null ||
                            party === null ||
                            glassType === "" ||
                            index === "" ||
                            quantity === "" ||
                            sphRight === "" ||
                            sphLeft === "" ||
                            cylRight === "" ||
                            cylLeft === "" ||
                            axisRight === "" ||
                            axisLeft === "" ||
                            addRight === "" ||
                            addLeft === "" ||
                            coat === ""
                          ) {
                            toast.error("Please fill all the required fields", {
                              position: "top-center",
                              autoClose: 1500,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            });
                            return;
                          }

                          let productMain = _.filter(productsMaster, {
                            productName: product.productName,
                            coating: coat.coating,
                            lab: lab.lab,
                            index: index
                          });
                          console.log(product.productName, "this is productname")
                          console.log(coat.coating, "this is coat")
                          console.log(lab.lab, "this is lab")
                          console.log(index, "this is index")
                          console.log(productMain, "this is productmain")
                          // console.log(product, "this is product")
                          let orderAmount =
                            Number(productMain[0].price) * Number(quantity);
                          console.log(orderAmount, "this is orderamount")
                          let object = {
                            orderType: "Prescription",
                            party: {
                              accountCode: party.accountCode,
                              accountId: party._id,
                              accountName: party.name,
                              accountAddress: party.address,
                              accountGst: party.gst,
                            },
                            orderDetail: {
                              lab: lab ? lab.lab : "",
                              partyRef,
                              partyMobileNo,
                              clientName,
                              product: product,
                              productName: product.productName,
                              productType: productType
                                ? productType.productType
                                : "",
                              glassType,
                              dia,
                              index,
                              coat: coat ? coat.coating : "",
                              fittingHeight,
                              prism,
                              ed,
                              pd,
                              frameSection,
                              quantity,
                              note : note,
                              tint,
                              rSph: sphRight,
                              rCyl: cylRight,
                              rAxis: axisRight,
                              rAddition: addRight,
                              lSph: sphLeft,
                              lCyl: cylLeft,
                              lAxis: axisLeft,
                              lAddition: addLeft,
                            },
                            orderAmount,
                            price: product.price,
                            quantity,
                            createdBy: userInfo._id,
                            createdByName: userInfo.name,
                            orderAdminStatus: "Confirmed",
                          };

                          let copy = _.clone(items);
                          copy.push(object);
                          setItems(copy);

                          setLab(null);
                          setParty(null);
                          setPartyRef("");
                          setPartyMobileNo("");
                          setClientName("");
                          setProduct(null);
                          setProductType("");
                          setGlassType("");
                          setDia("");
                          setIndex("");
                          setCoat("");
                          setFittingHeight("");
                          setPrism("");
                          setEd("");
                          setPd("");
                          setFrameSection("");
                          setQuantity("");
                          setTint(false);
                          setSphRight("");
                          setCylRight("");
                          setAxisRight("");
                          setAddRight("");
                          setSphLeft("");
                          setCylLeft("");
                          setAxisLeft("");
                          setAddLeft("");
                        }}
                      >
                        <AddIcon />
                      </Fab>
                    </Box>
                  </GridItem>
                  {_.isEmpty(items) ? null : (
                    <GridItem xs={12}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {columns.map((column) => (
                              <TableCell
                                key={column.id}
                                align={"center"}
                                style={{ minWidth: column.minWidth }}
                              >
                                {column.label}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {items.map((row, index) => {
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.code}
                              >
                                {columns.map((column) => {
                                  let value = row.orderDetail[column.id];
                                  if (column.id === "product") {
                                    value = row.orderDetail?.product
                                      ?.productName
                                      ? row.orderDetail.product.productName
                                      : "";
                                  }
                                  if (column.id === "party") {
                                    value = row.party.accountName;
                                  }
                                  if (column.id === "action") {
                                    return (
                                      <TableCell align={"center"}>
                                        <Box
                                          display={"flex"}
                                          justifyContent={"center"}
                                        >
                                          <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                              let copy = _.clone(items);
                                              copy.splice(index, 1);
                                              setItems(copy);
                                            }}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Box>
                                      </TableCell>
                                    );
                                  }
                                  return (
                                    <TableCell key={column.id} align={"center"}>
                                      {value}
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </GridItem>
                  )}
                </Box>

                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText={"Notes"}
                    id="note"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      rows: 3,
                      multiline: true,
                      value: note,
                      onChange: (event) => setNote(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    <FilePicker
      files={categoryImage}
      buttonLabel={"Upload Prescription Order Image*"}
      id={"Prescription Photo"}
      maxFiles={1}
      setNewFiles={_setCategoryImage}
      filetype={".png,.jpg,.jpeg"}
    />
  </div>
</GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <InvoiceSelectionDialog />
    </Dialog>
  );
}
