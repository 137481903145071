import axios from "axios";
import _ from "lodash";

import React, { useState, useEffect } from "react";
import {
  Dialog,
  Box,
  Slide,
  makeStyles,
  Paper,
  IconButton,
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button.js";
import { UpdateBrandsAPI, baseUrl } from "../../config/api";
import { FilePicker } from "../../components/common/FilePicker";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  imageSize: {
    maxWidth: 350,
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-30px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BrandMastersEdit(props) {
  const { open, close, item } = props;
  const classes = useStyles();

  const [brandName, setBrandName] = useState(item.name);
  const [brandImage, setBrandImage] = useState([]);
  // const [image ,setImage] = useState([])

  const [deleteImage, setDeleteImage] = useState(
    item.brandImage === "null" ? true : false
  );
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);

  async function _onSave() {
    try {
      let formData = new FormData();
      formData.append("name", brandName);
      formData.append("entity", userInfo.entity);
      console.log(brandImage, "My brand imgagfe")
      if (!_.isEmpty(brandImage)) {
        formData.append("brandImage", brandImage[0].path);
      } else if (deleteImage) {
        formData.append("brandImage", null);
      }
      if (brandName === "" || deleteImage === true && brandImage.length === 0) {
        toast.error("Please fill all the fields", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      console.log(formData, "formdata in brand lists")
      let res = await axios.patch(UpdateBrandsAPI(item._id), formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success('Successfully Edited!', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      close();
      console.log("Brand image", brandImage)
    } catch (e) {
      // console.log(e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  function _setBrandImage(files) {
    setBrandImage(files);
  }

  return (
    <Dialog
      // fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Edit Brand</h4>
                <Button color={"transparent"} onClick={() => _onSave()}>
                  Save
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <CustomInput
                    labelText="Brand Name*"
                    id="brandName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: brandName,
                      onChange: (event) => setBrandName(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  {!deleteImage && (
                    <Box
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                      padding={"10px"}
                    >
                      <IconButton
                        onClick={() => {
                          setDeleteImage(true);
                          setBrandImage([]);
                        }}
                        style={{
                          padding: 0,
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                  {!deleteImage && (
                    <Box className={classes.imageWrapper}>
                      <img
                        src={`${baseUrl}/uploads/${item.brandImage}`}
                        className={classes.imageSize}
                      />
                    </Box>
                  )}
                  {deleteImage && (
                    <FilePicker
                      files={brandImage}
                      buttonLabel={"Upload brand Photo*"}
                      id={"brand Photo"}
                      maxFiles={1}
                      setNewFiles={_setBrandImage}
                      filetype={".png,.jpg,.jpeg"}
                    />
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
