import React, { useState } from "react";
// @material-ui/core components
import { Dialog, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import axios from "axios";
import { CreateBankAPI } from "../../config/api";
import { toast } from "react-toastify"
import { useSelector } from "react-redux";

const styles = {
    formControl: {
        paddingBottom: "10px",
        margin: "27px 0 0 0",
        position: "relative",
        verticalAlign: "unset"
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);

const sides = ['B', 'L', 'R'];

export default function BankAdd(props) {
    let userInfo = useSelector((state) => state.userReducer.userInfo);

    const classes = useStyles();
    const theme = useTheme();
    const { open, close, fetchData } = props;
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [bankName, setBankName] = useState('');
    const [acNo, setAcNo] = useState('');
    const [ifscCode, setIfscCode] = useState('');
    const [branch, setBranch] = useState('');

    async function _onSave() {
        try {
            let bank = {
                bankName,
                acNo,
                ifscCode,
                branch,
                entity: userInfo.entity
            };
            if (bankName === "" || acNo === "" || ifscCode === "" || branch === "") {
                toast.error("Please fill all the fields", {
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return;
            }
            let res = await axios.post(CreateBankAPI(), bank);
            toast.success('Successfully Added!', {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            close();
            // window.location.reload()
            fetchData()
            setBankName("")
            setIfscCode("")
            setAcNo("")
            setBranch("")

        } catch (e) {
            // console.log('err:', e);
            // toast.error(("Something went wrong, Please Contact Admin") , {
            //     position: "top-center",
            //     autoClose: 1500,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            //     }); 
        }
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={close}
            aria-labelledby="responsive-dialog-title">
            <GridContainer>
                <GridItem xs={12} sm={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>Add Bank Master</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={6} sm={6} md={6}>
                                    <CustomInput
                                        labelText="Bank Name*"
                                        id="bankName"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: bankName,
                                            onChange: (event) => setBankName(event.target.value)
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <CustomInput
                                        labelText="Ac No*"
                                        id="acNo"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: acNo,
                                            onChange: (event) => setAcNo(event.target.value)
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <CustomInput
                                        labelText="Ifsc Code*"
                                        id="ifscCode"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: ifscCode,
                                            onChange: (event) => setIfscCode(event.target.value)
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <CustomInput
                                        labelText="Branch*"
                                        id="branch"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: branch,
                                            onChange: (event) => setBranch(event.target.value)
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter style={{ justifyContent: 'center' }}>
                            <Button color="primary" onClick={() => _onSave()}>Add Bank Master</Button>
                        </CardFooter>
                    </Card>
                </GridItem>

            </GridContainer>
        </Dialog>
    );
}
