import {
  LOGIN_SUCCESS,
  LOGOUT,
  UPDATE_USER_INFO,
  UPDATE_AUTH_PIN,
  FCM_TOKEN_REFRESH,
  READY_MASTER,
} from "./types";

export const loginSuccess = (object) => {
  return {
    type: LOGIN_SUCCESS,
    payload: object,
  };
};

export const    readyMasters = (object) => {
  return {
    type: READY_MASTER,
    payload: object,
  };
};

export const fcmTokenRefresh = (token) => {
  return {
    type: FCM_TOKEN_REFRESH,
    payload: token,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const updateUserInfo = (object) => {
  return {
    type: UPDATE_USER_INFO,
    payload: object,
  };
};
export const updateAuthPin = (pin) => {
  return {
    type: UPDATE_AUTH_PIN,
    payload: pin,
  };
};
