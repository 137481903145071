import axios from "axios";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import {
  Dialog,
  Box,
  Slide,
  makeStyles,
  Typography,
  TextField,
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button.js";
import {
  CreateHomePageAPI,
  GetBulkReadyMasterRangeAPI,
  GetAllCategorysAPI,
  GetAllBrandsAPI,
} from "../../config/api";
import { useSelector } from "react-redux";
import { FilePicker } from "../../components/common/FilePicker";
import Switch from "@mui/material/Switch";
import { Autocomplete } from "@material-ui/lab";
import { toast } from "react-toastify"

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  imageSize: {
    maxWidth: 350,
  },
  imageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HomePageMastersAdd(props) {
  const { open, close } = props;
  const classes = useStyles();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);

  const [selectedType, setSelectedType] = useState("");
  const [selectedItem, setSelectedItem] = useState("");

  const [selectedItemOptions, setSelectedItemOptions] = useState([]);
  const [selectedBrandOptions, setSelectedBrandOptions] = useState([]);
  const [selectedCatOptions, setSelectedCatOptions] = useState([]);

  console.log("selectedItemOptions", selectedItemOptions)

  const [status, setStatus] = useState(true);
  const [imagePath, setImagePath] = useState([]);

  async function _onSave() {
    try {
      let formData = new FormData();
      formData.append("redirectionType", selectedType);
      formData.append("redirectionPath", JSON.stringify(selectedItem));
      formData.append("entity", userInfo.entity);
      formData.append("status", status ? "Active" : "Inactive");
      if (!_.isEmpty(imagePath)) {
        formData.append("imagePath", imagePath[0].path);
      }
      if (selectedType === "" || imagePath.length === 0 || selectedItem === "") {
        toast.error("Please fill all the required fields", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      let res = await axios.post(CreateHomePageAPI(), formData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
      });
      toast.success('Successfully Edited!', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      close();
    } catch (e) {
      // console.log(e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _getBulkReadyMasterRangeAPI() {
    try {
      let res = await axios.get(GetBulkReadyMasterRangeAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });


      setSelectedItemOptions(res.data.readyMasterRange);
    } catch (err) {
      // console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _getBrands() {
    try {
      let res = await axios.get(GetAllBrandsAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setSelectedBrandOptions(res.data.brandMasters);
    } catch (err) {
      // console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _getCategory() {
    try {
      let res = await axios.get(GetAllCategorysAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setSelectedCatOptions(res.data.categoryMasters);
    } catch (err) {
      // console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  useEffect(() => {
    if (selectedType === "product") {
      _getBulkReadyMasterRangeAPI();
    } else if (selectedType === "category") {
      _getCategory();
    } else {
      _getBrands();
    }
  }, [selectedType]);

  function _setHomePageImage(files) {
    setImagePath(files);
  }

  return (
    <Dialog
      // fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Add Home Page Master</h4>
                <Button color={"transparent"} onClick={() => _onSave()}>
                  Save
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} style={{ marginTop: 26 }}>
                  <Autocomplete
                    id="type"
                    className={classes.formControl}
                    options={["product", "category", "brand"]}
                    value={selectedType}
                    onChange={(event, value) => {
                      setSelectedType(value);
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Type*" />
                    )}
                  />
                </GridItem>
                {selectedType === "product" && (
                  <GridItem xs={12} style={{ marginTop: 26 }}>
                    <Autocomplete
                      id="item"
                      className={classes.formControl}
                      options={selectedItemOptions}
                      value={selectedItem}
                      onChange={(event, value) => {
                        setSelectedItem(value);
                      }}
                      getOptionLabel={(option) => option.productName}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Item*" />
                      )}
                    />
                  </GridItem>
                )}

                {(selectedType === "brand") && (
                  <GridItem xs={12} style={{ marginTop: 26 }}>
                    <Autocomplete
                      id="item"
                      className={classes.formControl}
                      options={selectedBrandOptions}
                      value={selectedItem}
                      onChange={(event, value) => {
                        setSelectedItem(value);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Item*" />
                      )}
                    />
                  </GridItem>
                )}

                {(selectedType === "category") && (
                  <GridItem xs={12} style={{ marginTop: 26 }}>
                    <Autocomplete
                      id="item"
                      className={classes.formControl}
                      options={selectedCatOptions}
                      value={selectedItem}
                      onChange={(event, value) => {
                        setSelectedItem(value);
                      }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Item*" />
                      )}
                    />
                  </GridItem>
                )}
                <GridItem
                  xs={12}
                  style={{
                    marginTop: 20,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>Status</Typography>
                  <Switch
                    checked={status}
                    onChange={() => setStatus(!status)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <FilePicker
                    files={imagePath}
                    buttonLabel={"Upload Home Page Image*"}
                    id={"homePage Photo"}
                    maxFiles={1}
                    setNewFiles={_setHomePageImage}
                    filetype={".png,.jpg,.jpeg"}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
