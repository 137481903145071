import axios from "axios";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import React, { useState, useEffect } from "react";
import {
  Dialog,
  Box,
  Slide,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Fab,
  TextField,
} from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button.js";
import {
  CreateTrendingAPI,
  GetBulkReadyMasterRangeAPI,
} from "../../config/api";
import { useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const columns = [
  { id: "color", label: "Color" },
  { id: "trendPercent", label: "Trend Percent" },
  { id: "trendAmount", label: "Trend Amount" },
  { id: "action", label: "Action" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TrendingMastersEdit(props) {
  const { open, close, item } = props;
  const classes = useStyles();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);

  const [trendName, setTrendName] = useState(item.trendName);
  const [readyMasterProduct, setReadyMasterProduct] = useState([]);
  const [selectedReadyMasterProduct, setSelectedReadyMasterProduct] = useState(
    []
  );
  const [product, setProduct] = useState(item.readyMasterRangeId);
  const [deletedProduct, setDeletedProduct] = useState([]);

  useEffect(() => {
    _getBulkReadyMasterRangeAPI();
  }, []);

  async function _getBulkReadyMasterRangeAPI() {
    try {
      let res = await axios.get(GetBulkReadyMasterRangeAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setReadyMasterProduct(res.data.readyMasterRange);
    } catch (e) {
      // console.log(e);

    }
  }

  async function _onSave() {
    try {
      let res = await axios.post(
        CreateTrendingAPI(),
        {
          product,
          entity: userInfo.entity,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      close();
    } catch (e) {
      // console.log(e);

    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Add Trending</h4>
                <Button color={"transparent"} onClick={() => _onSave()}>
                  Save
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="trend Name"
                    id="trendName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: trendName,
                      onChange: (event) => setTrendName(event.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={6} sm={3} md={3} style={{ marginTop: 26 }}>
                  <Autocomplete
                    multiple
                    id="party"
                    className={classes.formControl}
                    options={readyMasterProduct}
                    value={selectedReadyMasterProduct}
                    onChange={(event, value) => {
                      setSelectedReadyMasterProduct(value);
                    }}
                    getOptionLabel={(option) => option.productName}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Product" />
                    )}
                  />
                </GridItem>

                <GridItem xs={2} sm={2} md={2}>
                  <Box justifyContent={"center"} marginTop={4}>
                    <Fab
                      size={"small"}
                      color="primary"
                      aria-label="add"
                      onClick={() => {
                        if (selectedReadyMasterProduct.length === 0) {
                          return alert("Please Select Product");
                        }

                        let data = {
                          trendName,
                          selectedReadyMasterProduct,
                        };
                        let copy = _.clone(product);
                        copy.push(data);
                        setProduct(copy);
                        setTrendName("");
                        setSelectedReadyMasterProduct([]);
                      }}
                    >
                      <AddIcon />
                    </Fab>
                  </Box>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  {_.isEmpty(product) ? null : (
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {product.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                let value = row[column.id];

                                if (column.id === "action") {
                                  return (
                                    <TableCell align={"center"}>
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                      >
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() => {
                                            let deleteCopy =
                                              _.clone(deletedProduct);

                                            let copy = _.clone(product);
                                            deleteCopy.push(copy[index]);
                                            setDeletedProduct(deleteCopy);
                                            copy.splice(index, 1);
                                            setProduct(copy);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </TableCell>
                                  );
                                }
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
