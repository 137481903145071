import React, { useEffect, useState } from "react";
// @material-ui/core components
import {
  Box,
  Button,
  Dialog,
  Fab,
  IconButton,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import {
  CreateChallanAPI,
  GetAllAccountsAPI,
  GetAllFittingMastersAPI,
  GetAllOrdersAPI,
  GetAllReadyMastersAPI,
  UpdateChallanAPI,
  UpdateCreditAPI,
  UpdateDebitAPI,
  GetAllReadyMastersCodesAPI,
  GetChallanAPI,
  CheckQuantity,
} from "../../config/api";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";
import _ from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSelector } from "react-redux";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { toast } from "react-toastify";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 500,
});

const useStyles = makeStyles((theme) => ({
  fab: {
    marginTop: 26,
  },
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
}));

let columns = [
  { id: "codeR", label: "Code R" },
  { id: "codeL", label: "Code L" },
  { id: "nameR", label: "Name R" },
  { id: "nameL", label: "Name L" },
  { id: "quantity", label: "Quantity" },
  { id: "rateR", label: "Rate R" },
  { id: "rateL", label: "Rate L" },
  { id: "rate", label: "Rate" },
  { id: "discountAmount", label: "Discount Amount" },
  { id: "discountPercent", label: "Discount Percent" },
  { id: "fitting", label: "Fitting" },
  { id: "fittingAmount", label: "Fitting Amount" },
  { id: "tint", label: "Tint" },
  { id: "arc", label: "Arc" },
  { id: "amount", label: "Amount" },
  { id: "orderRef", label: "Order Ref" },
  { id: "action", label: "Action" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function ChallanEditDialog(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { open, close, item, source } = props;

  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);

  const [users, setUsers] = useState([]);
  const [readyMasters, setReadyMasters] = useState([]);
  const [party, setParty] = useState(item.party);
  const [codeL, setCodeL] = useState("");
  const [codeR, setCodeR] = useState("");
  const [nameL, setNameL] = useState("");
  const [nameR, setNameR] = useState("");
  const [lProductCode, setLProductCode] = useState("");
  const [rProductCode, setRProductCode] = useState("");
  const [quantity, setQuantity] = useState("");
  const [rateR, setRateR] = useState("");
  const [rateL, setRateL] = useState("");
  const [rate, setRate] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");
  const [discountPercent, setDiscountPercent] = useState("");
  const [fitting, setFitting] = useState("");
  const [fittingAmount, setFittingAmount] = useState("");
  const [tint, setTint] = useState("");
  const [arc, setArc] = useState("");
  const [amount, setAmount] = useState("");
  const [orderRef, setOrderRef] = useState("");
  const [packagingCost, setPackagingCost] = useState("");
  const [packagingTaxPercent, setPackagingTaxPercent] = useState("");
  const [items, setItems] = useState(item.items);
  const [orders, setOrders] = useState([]);
  const [codes, setCodes] = useState([]);
  const [codesL, setCodesL] = useState([]);
  const [codesR, setCodesR] = useState([]);
  const [fittingMasters, setFittingMasters] = useState([]);
  const [fittingMastersCodes, setFittingMastersCodes] = useState([]);
  const [coating, setCoating] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [taxPercent, setTaxPercent] = useState("");

  const readyMastersProducts = useSelector(
    (state) => state.userReducer.readyMasters
  );

  useEffect(() => {
    _getUsers();
    _getReady();
    _getFittingMasters();
  }, []);

  useEffect(() => {
    _getOrders();
  }, [party]);

  useEffect(() => {
    _getCodes();
  }, [orders, readyMasters]);

  // const afterSelectR = (codeR) => {
  //   console.log("after select >> >> ", codeR, readyMasters)
  //   let obj = _.find(readyMasters, { productCode: codeR });
  //   if (obj) {
  //     //console.log(obj, "this my product");
  //     if (obj?.productName) {
  //       //console.log("814");

  //       setNameR(obj?.productName);
  //       setRateR(obj?.rate);
  //       setRProductCode(obj?.productCode);

  //       setHsnCode(obj?.hsnCode);
  //       setTaxPercent(obj?.taxPercent);

  //       let dissArray = party?.discounts;
  //       console.log(dissArray, "diss");
  //       console.log(obj, "dissObj");
  //       // if (dissArray && obj) {
  //       //   let selectedDis = dissArray
  //       //     .reverse()
  //       //     .find((item) => item.type === "Range");
  //       //   if (selectedDis) {
  //       //     let fR = selectedDis?.product?.codeRanges?.find(
  //       //       (item) => item === codeR
  //       //     );
  //       //     if (fR != undefined) {
  //       //       //console.log("831", discountPercent, selectedDis);
  //       //       setNameR(selectedDis?.product?.productName);
  //       //       let newD =
  //       //         Number(discountPercent) +
  //       //         Number(selectedDis.discountPercent);
  //       //       let finD = newD / 2;
  //       //       //console.log("setfrom974debounceR", finD)
  //       //       setDiscountPercent(finD);
  //       //     }
  //       //   } else {
  //       //     let selectedDis2 = dissArray
  //       //       .reverse()
  //       //       .find((item) => item.product.color === obj.color);
  //       //     if (selectedDis2) {
  //       //       //console.log("982", discountPercent, selectedDis2);
  //       //       setNameR(selectedDis2?.product?.productName);
  //       //       if (discountPercent == 0) {
  //       //         setDiscountPercent(selectedDis2?.discountPercent);
  //       //       } else {
  //       //         let newD =
  //       //           Number(discountPercent) +
  //       //           Number(selectedDis2?.discountPercent);
  //       //         //console.log("987", newD)
  //       //         let finD = newD / 2;
  //       //         //console.log("setfromdebounceR988", finD)
  //       //         setDiscountPercent(finD);
  //       //       }
  //       //     }
  //       //   }
  //       // }
  //       if (dissArray.length > 0 && obj) {
  //         // //console.log(dissArray, "diss");
  //         // //console.log(obj, "diss");
  //         // let selectedDis = dissArray
  //         //   .reverse()
  //         //   .find((item) => item.product.color === obj.color);
  //         //// //console.log(obj, "coderrr");
  //         let selectedDis = dissArray
  //           .reverse()
  //           .find((item) => item.type === "Range");
  //         console.log("this happening", selectedDis)
  //         if (selectedDis) {

  //           let fR = selectedDis?.product?.codeRanges?.find((item) => (item == codeR))
  //           //console.log("forReal>>>>editse", fR, selectedDis, codeR)
  //           if (fR != undefined) {
  //             setNameR(selectedDis.product.productName)
  //             // //console.log("right name r")
  //             setDiscountPercent(selectedDis.discountPercent);
  //             // setDiscountAmount(selectedDis.discountedPrice);
  //           } else {


  //           }
  //         } else {

  //           // //console.log("no range here")
  //           let selectedDis2 = dissArray
  //             .reverse()
  //             .find((item) => item.product.color === obj.color);
  //           //console.log("forReal>>>>editse", selectedDis2, codeR)
  //           console.log("dis2", selectedDis2)
  //           if (selectedDis2) {

  //             setNameR(selectedDis2?.product?.productName)
  //             // setDiscountPercent(selectedDis2?.discountPercent);
  //             // setDiscountAmount(selectedDis2.discountedPrice);

  //             if (discountPercent > 0) {
  //               let newD =
  //                 Number(discountPercent) +
  //                 Number(selectedDis2?.discountPercent);
  //               //console.log("987", newD)
  //               let finD = newD / 2;
  //               //console.log("setfromdebounceR988", finD)
  //               setDiscountPercent(finD);
  //             } else {
  //               setDiscountPercent(selectedDis2?.discountPercent);
  //             }
  //             // //console.log("that happening")
  //           }

  //           // let selectedDis2 = dissArray
  //           //   .reverse()
  //           //   .find((item) => item.product.color === obj.color);
  //           // setNameR(selectedDis2.product.productName)
  //           // setDiscountPercent(selectedDis2.discountPercent);

  //           // setDiscountAmount(selectedDis2.discountedPrice);
  //         }
  //         //// //console.log(selectedDis, "dis");
  //       }
  //     } else {
  //       if (obj.orderDetail) {
  //         //console.log(obj, "this my product");
  //         //console.log("856");

  //         setQuantity(obj.quantity);
  //         let productCode = "";
  //         if (obj.orderType === "Fitting") {
  //           if (obj.orderDetail.fitting) {
  //             setFitting(obj.orderDetail.fitting);
  //             let fitting = _.find(fittingMasters, {
  //               fittingType: obj.orderDetail.fitting,
  //             });
  //             setFittingAmount(fitting.amount);
  //           }
  //           setRateR(obj.orderDetail.rRate);
  //           productCode =
  //             obj.orderDetail.rSph +
  //             "/" +
  //             obj.orderDetail.rCyl +
  //             "/" +
  //             obj.orderDetail.rAxis +
  //             "/" +
  //             obj.orderDetail.rAddition;
  //           //console.log("864");

  //           setNameR(obj.orderDetail.productName + "-" + productCode);
  //         } else {
  //           setRateR(obj.rate);
  //           productCode =
  //             obj.orderDetail.rSph +
  //             "/" +
  //             obj.orderDetail.rCyl +
  //             "/" +
  //             obj.orderDetail.rAxis +
  //             "/" +
  //             obj.orderDetail.rAddition;
  //           if (obj.orderDetail.product) {
  //             //console.log("871");

  //             setNameR(
  //               obj.orderDetail.product.productName + "-" + productCode
  //             );
  //           }
  //         }
  //         setOrderRef(obj.code);
  //         setRProductCode(productCode);
  //       }
  //     }
  //   }
  // }

  const afterSelectR = (codeR) => {
    console.log("after select >> >> ", codeR, readyMasters)
    let obj = _.find(readyMasters, { productCode: codeR });
    if (obj) {
      console.log(obj, "this my product");
      if (obj) {
        console.log('hiiii');
        setNameR(obj?.productName)
        console.log(obj.productName, 'byeee');
        console.log("814setnamecomplete", party);
        setRateR(obj?.rate);
        setRProductCode(obj?.productCode);
        setHsnCode(obj?.hsnCode);
        setTaxPercent(obj?.taxPercent);

        let dissArray = party?.discounts;
        if (dissArray.length > 0 && obj) {
          console.log(dissArray, "diss");
          console.log(obj, "dissObj");
          let selectedDis = dissArray
            .reverse()
            .find((item) => item.type === "Range");
          console.log("this happening", selectedDis)
          if (selectedDis) {

            let fR = selectedDis?.product?.codeRanges?.find((item) => (item == codeR))
            console.log(fR,'fR')
            if (fR != undefined) {
              console.log("comp316", selectedDis.product.productName, selectedDis.discountedPrice,
                selectedDis.discountPercent
              )
              setNameR(selectedDis.product.productName)
              setRateR(selectedDis.discountedPrice)
              setDiscountPercent(selectedDis.discountPercent);
              console.log("321")

            }
          } else {
            console.log("no range here")
            let selectedDis2 = dissArray
              .reverse()
              .find((item) => item.product.color === obj.color);
            console.log("forReal>>>>editse", selectedDis2, codeR, dissArray)
            console.log("comp330", selectedDis2)
            if (selectedDis2) {
              setNameR(selectedDis2?.product?.productName)
              setRateR(selectedDis2?.discountedPrice)
              setDiscountPercent(selectedDis2.discountPercent)
              // setDiscountPercent(selectedDis2?.discountPercent);
              // setDiscountAmount(selectedDis2.discountedPrice);
              if (discountPercent > 0) {
                let newD =
                  Number(discountPercent) +
                  Number(selectedDis2?.discountPercent);
                //console.log("987", newD)
                let finD = newD / 2;
                //console.log("setfromdebounceR988", finD)
                setDiscountPercent(finD);
              } else {
                setDiscountPercent(selectedDis2?.discountPercent);
              }
            }
            else { }
            // //console.log("that happening")

            // let selectedDis2 = dissArray
            //   .reverse()
            //   .find((item) => item.product.color === obj.color);
            // setNameR(selectedDis2.product.productName)
            // setDiscountPercent(selectedDis2.discountPercent);

            // setDiscountAmount(selectedDis2.discountedPrice);
          }
          //// //console.log(selectedDis, "dis");
        }
      } else {
        if (obj.orderDetail) {
          //console.log(obj, "this my product");
          //console.log("856");

          setQuantity(obj.quantity);
          let productCode = "";
          if (obj.orderType === "Fitting") {
            if (obj.orderDetail.fitting) {
              setFitting(obj.orderDetail.fitting);
              let fitting = _.find(fittingMasters, {
                fittingType: obj.orderDetail.fitting,
              });
              setFittingAmount(fitting.amount);
            }
            setRateR(obj.orderDetail.rRate);
            productCode =
              obj.orderDetail.rSph +
              "/" +
              obj.orderDetail.rCyl +
              "/" +
              obj.orderDetail.rAxis +
              "/" +
              obj.orderDetail.rAddition;
            console.log("864orderDetailcomp");

            setNameR(obj.orderDetail.productName + "-" + productCode);
          } else {
            setRateR(obj.rate);
            productCode =
              obj.orderDetail.rSph +
              "/" +
              obj.orderDetail.rCyl +
              "/" +
              obj.orderDetail.rAxis +
              "/" +
              obj.orderDetail.rAddition;
            if (obj.orderDetail.product) {
              console.log("396comp");
              setNameR(
                obj.orderDetail.product.productName + "-" + productCode
              );
            }
          }
          setOrderRef(obj.code);
          setRProductCode(productCode);
        }
      }
    }
  }

  // const afterSelectL = (codeL) => {
  //   console.log("after EL", codeL)
  //   let obj = _.find(readyMasters, { productCode: codeL });
  //   if (obj) {
  //     if (obj?.productName) {
  //       setNameL(obj?.productName);
  //       setRateL(obj?.rate);
  //       setLProductCode(obj?.productCode);

  //       setHsnCode(obj?.hsnCode);
  //       setTaxPercent(obj?.taxPercent);

  //       let dissArray = party?.discounts;
  //       console.log("it is", obj, dissArray)

  //       if (dissArray && obj) {

  //         let selectedDis = dissArray
  //           .reverse()
  //           .find((item) => item.type === "Range");
  //         if (selectedDis) {


  //           let fR = selectedDis?.product?.codeRanges?.find((item) => (item === codeL))

  //           if (fR != undefined) {
  //             setNameL(selectedDis?.product?.productName)
  //             let newD = Number(discountPercent) + Number(selectedDis.discountPercent)
  //             let finD = newD / 2
  //             setDiscountPercent(finD);
  //           } else {

  //           }
  //         } else {
  //           let selectedDis2 = dissArray
  //             .reverse()
  //             .find((item) => item.product.color === obj.color);

  //           if (selectedDis2) {

  //             setNameL(selectedDis2?.product?.productName)
  //             if (discountPercent == 0) {
  //               setDiscountPercent(selectedDis2?.discountPercent)
  //             } else {

  //               let newD =
  //                 Number(discountPercent) +
  //                 Number(selectedDis2?.discountPercent);
  //               let finD = newD / 2;
  //               setDiscountPercent(finD);
  //             }
  //           } else {
  //           }
  //         }
  //       }
  //     } else {
  //       if (obj.orderDetail) {
  //         setQuantity(obj.quantity);
  //         setRateL(obj.price);
  //         let productCode = "";
  //         if (obj.orderType === "Fitting") {
  //           if (obj.orderDetail.fitting) {
  //             setFitting(obj.orderDetail.fitting);
  //             let fitting = _.find(fittingMasters, {
  //               fittingType: obj.orderDetail.fitting,
  //             });
  //             setFittingAmount(fitting.amount);
  //           }
  //           setRateL(obj.orderDetail.lRate);
  //           productCode =
  //             obj.orderDetail.lSph +
  //             "/" +
  //             obj.orderDetail.lCyl +
  //             "/" +
  //             obj.orderDetail.lAxis +
  //             "/" +
  //             obj.orderDetail.lAddition;
  //           setNameL(obj.orderDetail.productName + "-" + productCode);
  //         } else {
  //           setRateL(obj.rate);
  //           productCode =
  //             obj.orderDetail.lSph +
  //             "/" +
  //             obj.orderDetail.lCyl +
  //             "/" +
  //             obj.orderDetail.lAxis +
  //             "/" +
  //             obj.orderDetail.lAddition;
  //           if (obj.orderDetail.product) {
  //             setNameL(
  //               obj.orderDetail.product.productName + "-" + productCode
  //             );
  //           }
  //         }
  //         setOrderRef(obj.code);
  //         setLProductCode(productCode);
  //       }
  //     }
  //   }
  // }
  const afterSelectL = (codeL) => {
    console.log("after select L>> >> ", codeL, readyMasters)
    let obj = _.find(readyMasters, { productCode: codeL });
    if (obj) {
      console.log(obj, "this my product");
      if (obj) {
        console.log('hiiii');
        setNameL(obj?.productName)
        console.log(obj.productName, 'byeee');
        console.log("814setnamecomplete", party);
        setRateL(obj?.rate);
        setLProductCode(obj?.productCode);
        setHsnCode(obj?.hsnCode);
        setTaxPercent(obj?.taxPercent);

        let dissArray = party?.discounts;
        if (dissArray.length > 0 && obj) {
          console.log(dissArray, "diss");
          console.log(obj, "dissObj");
          let selectedDis = dissArray
            .reverse()
            .find((item) => item.type === "Range");
          console.log("this happening", selectedDis)
          if (selectedDis) {

            let fR = selectedDis?.product?.codeRanges?.find((item) => (item == codeL))
            if (fR != undefined) {
              console.log("comp316")
              setNameL(selectedDis.product.productName)
              setRateL(selectedDis.discountedPrice)
              setDiscountPercent(selectedDis.discountPercent);
            }
          } else {
            console.log("no range here")
            let selectedDis2 = dissArray
              .reverse()
              .find((item) => item.product.color === obj.color);
            console.log("forReal>>>>editse", selectedDis2, codeL, dissArray)
            console.log("comp330", selectedDis2)
            if (selectedDis2) {
              setNameL(selectedDis2?.product?.productName)
              setDiscountPercent(selectedDis2.discountPercent)
              setRateL(selectedDis2?.discountedPrice)
              // setDiscountPercent(selectedDis2?.discountPercent);
              // setDiscountAmount(selectedDis2.discountedPrice);
              if (discountPercent > 0) {
                let newD =
                  Number(discountPercent) +
                  Number(selectedDis2?.discountPercent);
                //console.log("987", newD)
                let finD = newD / 2;
                //console.log("setfromdebounceR988", finD)
                setDiscountPercent(finD);
              } else {
                setDiscountPercent(selectedDis2?.discountPercent);
              }
            }
            else { }
            // //console.log("that happening")

            // let selectedDis2 = dissArray
            //   .reverse()
            //   .find((item) => item.product.color === obj.color);
            // setNameR(selectedDis2.product.productName)
            // setDiscountPercent(selectedDis2.discountPercent);

            // setDiscountAmount(selectedDis2.discountedPrice);
          }
          //// //console.log(selectedDis, "dis");
        }
      } else {
        if (obj.orderDetail) {
          //console.log(obj, "this my product");
          //console.log("856");

          setQuantity(obj.quantity);
          let productCode = "";
          if (obj.orderType === "Fitting") {
            if (obj.orderDetail.fitting) {
              setFitting(obj.orderDetail.fitting);
              let fitting = _.find(fittingMasters, {
                fittingType: obj.orderDetail.fitting,
              });
              setFittingAmount(fitting.amount);
            }
            setRateL(obj.orderDetail.lRate);
            productCode =
              obj.orderDetail.lSph +
              "/" +
              obj.orderDetail.lCyl +
              "/" +
              obj.orderDetail.lAxis +
              "/" +
              obj.orderDetail.lAddition;
            console.log("864orderDetailcomp");

            setNameL(obj.orderDetail.productName + "-" + productCode);
          } else {
            setRateL(obj.rate);
            productCode =
              obj.orderDetail.lSph +
              "/" +
              obj.orderDetail.lCyl +
              "/" +
              obj.orderDetail.lAxis +
              "/" +
              obj.orderDetail.lAddition;
            if (obj.orderDetail.product) {
              console.log("396comp");
              setNameL(
                obj.orderDetail.product.productName + "-" + productCode
              );
            }
          }
          setOrderRef(obj.code);
          setLProductCode(productCode);
        }
      }
    }
  }

  const debouncedEffect = _.debounce((codeR) => {
    //console.log("useeffect debounced code R running2")
    let obj;
    let codes = [];
    let codesR = [];
    if (codeR) {
      obj = _.find(orders, { code: codeR });
      _.map(orders, (i) => {
        codes.push(i.code);
        codesR.push(i.code);
        //console.log("pushingthistoR", i.code)
      });
      if (!obj) {
        // codes = [];
        // codesR = [];

        let readyMasters2 = [];

        if (codeR.length < 5) {
          return;
        }
        //console.log("useEffect codeR readyMasters2", readyMasters2);
        axios
          .get(GetAllReadyMastersCodesAPI(userInfo.entity, codeR, "R"), {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          })
          .then((res) => {
            //console.log("res", res);
            //console.log(
            //   "useEffect codeR readyMasters2 res",
            //   res.data.readyMasters
            // );
            setReadyMasters(res.data.readyMasters)
            _.map(res.data.readyMasters, (i) => {
              codes.push(i.productCode);
              if (i.side === "B" || i.side === "R") {
                codesR.push(i.productCode);
              }
            });
            // Reorder codesR array
            codesR = codesR.sort((a, b) => {
              // Move codes starting with 'o' to the end
              if (a.startsWith("o") && !b.startsWith("o")) {
                return 1;
              } else if (!a.startsWith("o") && b.startsWith("o")) {
                return -1;
              }
              return 0;
            });
            setCodesR(codesR);

          })
          .catch((e) => {
            //console.log(e, "<<err");
          });
      }
      setCodes(codes);
      if (obj) {
        if (obj.productName) {
          console.log("571comp");
          setNameR(obj.productName);
          //console.log("sethere1");
          setRateR(obj.rate);
          setRProductCode(obj.productCode);
        } else {
          if (obj.orderDetail) {
            //console.log("900", obj);

            setQuantity(obj.quantity);
            setRateR(obj.price);
            let productCode = "";
            if (obj.orderType === "Fitting") {
              if (obj.orderDetail.fitting) {
                setFitting(obj.orderDetail.fitting);
                let fitting = _.find(fittingMasters, {
                  fittingType: obj.orderDetail.fitting,
                });
                setFittingAmount(fitting.amount);
              }
              setRateR(obj.orderDetail.rRate);
              productCode =
                obj.orderDetail.rSph +
                "/" +
                obj.orderDetail.rCyl +
                "/" +
                obj.orderDetail.rAxis +
                "/" +
                obj.orderDetail.rAddition;
              console.log("600comp");

              setNameR(obj.orderDetail.productName + "-" + productCode);
              //console.log("sethere2");
            } else {
              setRateR(obj.rate);
              productCode =
                obj.orderDetail.rSph +
                "/" +
                obj.orderDetail.rCyl +
                "/" +
                obj.orderDetail.rAxis +
                "/" +
                obj.orderDetail.rAddition;
              if (obj.orderDetail.product) {
                console.log("615comp", obj.orderDetail.product);
                setNameR(
                  obj.orderDetail.product.productName + "-" + productCode
                );
                //console.log("sethere3");
              }
            }
            setOrderRef(obj.code);
            setRProductCode(productCode);
          }
        }
      }
    }
    if (!codeR) {
      if (!codeL) {
        setQuantity("");
        setFittingAmount("");
        setFitting("");
        setOrderRef("");
        setRate("")
        setDiscountPercent("")
        setHsnCode("")
        setTaxPercent("")
      }
      setNameR("");
      setRateR("");
      setRProductCode("");
    }
    //console.log("hojaye shuru");
  }, 500);

  // const debouncedEffect = _.debounce((codeR) => {
  //   console.log("useeffect debounced code R running2")
  //   let obj;
  //   let codes = [];
  //   let codesR = [];
  //   if (codeR) {
  //     obj = _.find(orders, { code: codeR });
  //     _.map(orders, (i) => {
  //       codes.push(i.code);
  //       codesR.push(i.code);
  //     });
  //     if (!obj) {

  //       let readyMasters2 = [];

  //       if (codeR.length < 5) {
  //         return;
  //       }

  //       console.log("useEffect codeR readyMasters2", readyMasters2);
  //       axios
  //         .get(GetAllReadyMastersCodesAPI(userInfo.entity, codeR, "R"), {
  //           headers: {
  //             Authorization: `Bearer ${authToken}`,
  //           },
  //         })
  //         .then((res) => {
  //           console.log("res", res);
  //           console.log(
  //             "useEffect codeR readyMasters2 res",
  //             res.data.readyMasters
  //           );
  //           setReadyMasters(res.data.readyMasters)
  //           _.map(res.data.readyMasters, (i) => {
  //             codes.push(i.productCode);
  //             if (i.side === "B" || i.side === "R") {
  //               codesR.push(i.productCode);
  //             }
  //           });
  //           // Reorder codesR array
  //           codesR = codesR.sort((a, b) => {
  //             // Move codes starting with 'o' to the end
  //             if (a.startsWith("o") && !b.startsWith("o")) {
  //               return 1;
  //             } else if (!a.startsWith("o") && b.startsWith("o")) {
  //               return -1;
  //             }
  //             return 0;
  //           });
  //           setCodesR(codesR);
  //         })
  //         .catch((e) => {
  //           console.log(e, "<<err");
  //         });
  //     }
  //     setCodes(codes);
  //     if (obj) {
  //       if (obj.productName) {
  //         console.log("888");
  //         setNameR(obj.productName);
  //         console.log("sethere1");
  //         setRateR(obj.rate);
  //         setRProductCode(obj.productCode);
  //       } else {
  //         if (obj.orderDetail) {
  //           console.log("900", obj);

  //           setQuantity(obj.quantity);
  //           setRateR(obj.price);
  //           let productCode = "";
  //           if (obj.orderType === "Fitting") {
  //             if (obj.orderDetail.fitting) {
  //               setFitting(obj.orderDetail.fitting);
  //               let fitting = _.find(fittingMasters, {
  //                 fittingType: obj.orderDetail.fitting,
  //               });
  //               setFittingAmount(fitting.amount);
  //             }
  //             setRateR(obj.orderDetail.rRate);
  //             productCode =
  //               obj.orderDetail.rSph +
  //               "/" +
  //               obj.orderDetail.rCyl +
  //               "/" +
  //               obj.orderDetail.rAxis +
  //               "/" +
  //               obj.orderDetail.rAddition;
  //             console.log("905");

  //             setNameR(obj.orderDetail.productName + "-" + productCode);
  //             console.log("sethere2");
  //           } else {
  //             setRateR(obj.rate);
  //             productCode =
  //               obj.orderDetail.rSph +
  //               "/" +
  //               obj.orderDetail.rCyl +
  //               "/" +
  //               obj.orderDetail.rAxis +
  //               "/" +
  //               obj.orderDetail.rAddition;
  //             if (obj.orderDetail.product) {
  //               console.log("914", obj.orderDetail.product);
  //               setNameR(
  //                 obj.orderDetail.product.productName + "-" + productCode
  //               );
  //               console.log("sethere3");
  //             }
  //           }
  //           setOrderRef(obj.code);
  //           setRProductCode(productCode);
  //         }
  //       }
  //     }
  //   }
  //   if (!codeR) {
  //     if (!codeL) {
  //       setQuantity("");
  //       setFittingAmount("");
  //       setFitting("");
  //       setOrderRef("");
  //       setRate("")
  //       setDiscountPercent("")
  //       setHsnCode("")
  //       setTaxPercent("")
  //     }
  //     setNameR("");
  //     setRateR("");
  //     setRProductCode("");
  //   }
  //   console.log("hojaye shuru");
  // }, 500);

  const debouncedEffectL = _.debounce((codeL) => {
    let obj;
    let codes = [];
    let codesL = [];
    if (codeL) {
      obj = _.find(orders, { code: codeL });
      _.map(orders, (i) => {
        codes.push(i.code);
        codesL.push(i.code);
      });
      if (!obj) {
        // codes = [];
        // codesL = [];

        let readyMasters2 = [];

        if (codeL.length < 5) {
          return;
        }

        console.log("useEffect codeL readyMasters2", readyMasters2);
        axios
          .get(GetAllReadyMastersCodesAPI(userInfo.entity, codeL, "L"), {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          })
          .then((res) => {
            setReadyMasters(res.data.readyMasters)
            _.map(res.data.readyMasters, (i) => {
              codes.push(i.productCode);
              if (i.side === "B" || i.side === "L") {
                codesL.push(i.productCode);
              }
            });
            // Reorder codesL array
            codesL = codesL.sort((a, b) => {
              // Move codes starting with 'o' to the end
              if (a.startsWith("o") && !b.startsWith("o")) {
                return 1;
              } else if (!a.startsWith("o") && b.startsWith("o")) {
                return -1;
              }
              return 0;
            });
            setCodesL(codesL);
          })
          .catch((e) => {
            console.log(e, "<<err");
          });
      }
      setCodes(codes);
      if (obj) {
        if (obj.productName) {
          setNameL(obj.productName);
          console.log("sethere1");
          setRateL(obj.rate);
          setLProductCode(obj.productCode);
        } else {
          if (obj.orderDetail) {
            setQuantity(obj.quantity);
            setRateL(obj.price);
            let productCode = "";
            if (obj.orderType === "Fitting") {
              if (obj.orderDetail.fitting) {
                setFitting(obj.orderDetail.fitting);
                let fitting = _.find(fittingMasters, {
                  fittingType: obj.orderDetail.fitting,
                });
                setFittingAmount(fitting.amount);
              }
              setRateL(obj.orderDetail.lRate);
              productCode =
                obj.orderDetail.lSph +
                "/" +
                obj.orderDetail.lCyl +
                "/" +
                obj.orderDetail.lAxis +
                "/" +
                obj.orderDetail.lAddition;
              setNameL(obj.orderDetail.productName + "-" + productCode);
            } else {
              setRateL(obj.price);
              productCode =
                obj.orderDetail.lSph +
                "/" +
                obj.orderDetail.lCyl +
                "/" +
                obj.orderDetail.lAxis +
                "/" +
                obj.orderDetail.lAddition;
              if (obj.orderDetail.product) {
                setNameL(
                  obj.orderDetail.product.productName + "-" + productCode
                );
                console.log("sethere3");
              }
            }
            setOrderRef(obj.code);
            setLProductCode(productCode);
          }
        }
      }
    }
    if (!codeL) {
      if (!codeR) {
        setQuantity("");
        setFittingAmount("");
        setFitting("");
        setOrderRef("");
        setRate("")
        setHsnCode("")
        setDiscountPercent("")
        setTaxPercent("")
      }
      setNameL("");
      setRateL("");
      setLProductCode("");
    }
    console.log(" hojaye shuru");
  }, 500);

  useEffect(() => {
    if (discountAmount) {
      setDiscountPercent("");
    } else {
      setDiscountAmount("");
    }
  }, [discountAmount]);

  useEffect(() => {
    if (discountPercent) {
      setDiscountAmount("");
    } else {
      setDiscountPercent("");
    }
  }, [discountPercent]);

  useEffect(() => {
    if (rateR) {
      setRate(rateR.toString());
    }
    if (rateL) {
      setRate(rateL.toString());
    }
    if (rateR && rateL) {
      let rate = (Number(rateR) + Number(rateL)) / 2;
      setRate(rate.toString());
    }
  }, [rateR, rateL]);

  useEffect(() => {
    let amount = 0;
    if (rate && quantity) {
      amount = Number(rate) * Number(quantity);
    }
    if (fittingAmount) {
      amount += Number(fittingAmount);
    }
    if (discountAmount) {
      amount = amount - Number(discountAmount);
    }
    if (discountPercent) {
      amount = amount - (amount * Number(discountPercent)) / 100;
    }
    if (tint) {
      amount += Number(tint);
    }
    setAmount(amount.toString());
  }, [rate, fittingAmount, discountAmount, discountPercent, tint, quantity]);

  useEffect(() => {
    let obj = _.find(fittingMasters, { fittingType: fitting });
    if (obj) {
      setFittingAmount(obj.amount);
    }
  }, [fitting]);

  function _getCodes() {
    if (!codeL && !codeR) {
      let codes = [];
      let codesR = [];
      let codesL = [];
      _.map(orders, (i) => {
        codes.push(i.code);
        codesR.push(i.code);
        codesL.push(i.code);
      });
      _.map(readyMasters, (i) => {
        codes.push(i.productCode);
        if (i.side === "B" || i.side === "R") {
          codesR.push(i.productCode);
        }
        if (i.side === "B" || i.side === "L") {
          codesL.push(i.productCode);
        }
      });
      setCodes(codes);
      setCodesL(codesL);
      setCodesR(codesR);
    }
  }

  async function _getReady() {
    try {
      let res = await axios.get(GetAllReadyMastersCodesAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setReadyMasters(res.data.readyMasters);
    } catch (err) {
      // console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _getFittingMasters() {
    try {
      let res = await axios.get(GetAllFittingMastersAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setFittingMasters(res.data.fittingMasters);
      let fittingMastersCodes = [];
      _.map(res.data.fittingMasters, (i) => {
        fittingMastersCodes.push(i.fittingType);
      });
      setFittingMastersCodes(fittingMastersCodes);
    } catch (err) {
      // console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _getUsers() {
    try {
      let res = await axios.get(GetAllAccountsAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setUsers(res.data.accounts);
      let sp = _.find(res.data.accounts, { _id: item.party.accountId });
      setParty(sp);
    } catch (e) {
      // console.log("er:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _getOrders() {
    try {
      if (party) {
        let res = await axios.get(
          GetAllOrdersAPI(userInfo.entity) +
          `&userId=${party._id}&isChallanCreated=false`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setOrders(res.data.orders);
      }
    } catch (err) {
      // console.log("error:", err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _onSave() {
    try {
      let totalAmount = 0;
      _.map(items, async (i) => {
        totalAmount += Number(i.amount);
      });



      let EditApi;
      console.log(source, "source");
      if (source === "credit") {
        EditApi = UpdateCreditAPI
      }
      else if (source === 'debit') {
        EditApi = UpdateDebitAPI
      }
      else {
        EditApi = UpdateChallanAPI
      }
      // let oldChallanRes = await axios.get(GetChallanAPI(item._id), {
      //   headers: {
      //     Authorization: `Bearer ${authToken}`,
      //   },
      // });
      let res = await axios.patch(
        EditApi(item._id),
        {
          items,
          amount: totalAmount,
          // oldAmount: oldChallanRes?.data.amount,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      close();
    }
    catch (e) {
      if (e.response && e.response.status === 404) {
        console.log("Resource not found", e.response.data.error);
        // Customize your toast message for 404 error
        toast.error(`${e.response.data.error}`, {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.log("saverr", e.response ? e.response.data : e.message);
        toast.error("Something went wrong, Please Contact Admin", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }

  function _onAddRecord() {
    let data = {
      codeR,
      codeL,
      nameR,
      nameL,
      quantity,
      rateR,
      rateL,
      rate,
      discountAmount,
      discountPercent,
      fitting,
      fittingAmount,
      tint,
      arc,
      amount,
      orderRef,
      lProductCode,
      rProductCode,
    };
    let copy = _.clone(items);
    copy.push(data);
    setItems(copy);
    setCodeR(null);
    setCodeL(null);
    setNameR("");
    setNameL("");
    setQuantity("");
    setRateL("");
    setRateR("");
    setRate("");
    setDiscountAmount("");
    setDiscountPercent("");
    setFittingAmount("");
    setFitting("");
    setTint("");
    setArc("");
    setAmount("");
    setOrderRef("");
  }


  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Challan Edit</h4>
                <Box display={"flex"} alignItems={"center"}>
                  <Button style={{ color: "white" }} onClick={close}>
                    Close
                  </Button>
                  <Button style={{ color: "white" }} onClick={() => _onSave()}>
                    Save
                  </Button>
                </Box>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={3} sm={3} md={3}>
                  <Autocomplete
                    className={classes.formControl}
                    options={users}
                    fullWidth
                    value={party}
                    onChange={(event, value) => setParty(value)}
                    getOptionLabel={(option) => option.name}
                    id="party"
                    style={{ marginTop: 26 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Party" />
                    )}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <Autocomplete
                    loading={codesR.length === 0 ? true : false}
                    className={classes.formControl}
                    options={codesR}
                    fullWidth
                    value={codeR}
                    onChange={(event, value) => {
                      setCodeR(value)
                      afterSelectR(value)
                    }}
                    onInputChange={(event, value) => {
                      // console.log(codeR, "ONCHANGEINPUT")

                      // setCodeR(value)
                      debouncedEffect(value)

                    }}
                    getOptionLabel={(option) => option}
                    style={{ marginTop: 26 }}
                    id="codeR"
                    renderInput={(params) => (
                      <TextField {...params} label="Code R" />
                    )}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Name R"
                    id="nameR"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: nameR,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <Autocomplete
                    loading={codesL.length === 0 ? true : false}
                    className={classes.formControl}
                    options={codesL}
                    fullWidth
                    value={codeL}
                    onChange={(event, value) => {
                      setCodeL(value)
                      afterSelectL(value)
                    }}
                    onInputChange={(event, value) => {
                      console.log("val", value)
                      // setCodeL(value)
                      debouncedEffectL(value)

                    }}
                    getOptionLabel={(option) => option}
                    style={{ marginTop: 26 }}
                    id="codeL"
                    renderInput={(params) => (
                      <TextField {...params} label="Code L" />
                    )}
                  />
                </GridItem>
                <GridItem xs={2} sm={2} md={2}>
                  <CustomInput
                    labelText="Name L"
                    id="nameL"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: nameL,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Quantity"
                    id="quantity"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: quantity,
                      onChange: (e) => setQuantity(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Rate R"
                    id="rateR"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rateR,
                      onChange: (e) => setRateR(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Rate L"
                    id="rateL"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rateL,
                      onChange: (e) => setRateL(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Rate"
                    id="rate"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: rate,
                      onChange: (e) => setRate(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Dis %"
                    id="discountPercent"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: discountPercent,
                      onChange: (e) => setDiscountPercent(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Dis Amt"
                    id="discountAmount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: discountAmount,
                      onChange: (e) => setDiscountAmount(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <Autocomplete
                    className={classes.formControl}
                    options={fittingMastersCodes}
                    fullWidth
                    value={fitting}
                    onChange={(event, value) => setFitting(value)}
                    getOptionLabel={(option) => option}
                    style={{ marginTop: 26 }}
                    id="fitting"
                    renderInput={(params) => (
                      <TextField {...params} label="Fitting" />
                    )}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Fit Amt"
                    id="fittingAmount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: fittingAmount,
                      onChange: (e) => setFittingAmount(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Tint"
                    id="tint"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: tint,
                      onChange: (e) => setTint(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="ARC"
                    id="arc"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: arc,
                      onChange: (e) => setArc(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Amount"
                    id="amount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: amount,
                      onChange: (e) => setAmount(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <CustomInput
                    labelText="Order Ref"
                    id="orderRef"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: orderRef,
                      onChange: (e) => setOrderRef(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={2}>
                  <CustomInput
                    labelText="Packaging tax Percent"
                    id="packagingTaxPercent"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: packagingTaxPercent,
                      onChange: (e) => setPackagingTaxPercent(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={2}>
                  <CustomInput
                    labelText="Packaging Cost"
                    id="packagingCost"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: packagingCost,
                      onChange: (e) => setPackagingCost(e.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={1} sm={1} md={1}>
                  <Fab
                    color="primary"
                    aria-label="add"
                    className={classes.fab}
                    onClick={_onAddRecord}
                  >
                    <AddIcon />
                  </Fab>
                </GridItem>
                {_.isEmpty(items) ? null : (
                  <GridItem xs={12}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={"center"}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                if (column.id === "action") {
                                  return (
                                    <TableCell align={"center"}>
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                      >
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() => {
                                            let copy = _.clone(items);
                                            copy.splice(index, 1);
                                            setItems(copy);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </TableCell>
                                  );
                                }
                                return (
                                  <TableCell key={column.id} align={"center"}>
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
