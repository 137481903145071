import React, { useEffect, useState } from "react";
// @material-ui/core components
import {
  Box,
  Button,
  Dialog,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  Checkbox,
  Snackbar,
  FormControlLabel,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";

import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import moment from "moment";
import axios from "axios";
import {
  CreateLedgerAPI,
  GetAllChallansAPI,
  UpdateChallanAPI,
  RoundOffChallansApi,
} from "../../config/api";
import _ from "lodash";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CheckSharp } from "@material-ui/icons";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const challanColumns = [
  { id: "select", label: "Select" },
  { id: "code", label: "Challan Id" },
  // {id: 'party.name', label: 'Party',},
  //   { id: "orderNo", label: "Order Id" },
  { id: "amount", label: "Challan Amount" },
  {
    id: "createdAt",
    label: "Order Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
];

export default function RoundOffChallan(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { open, close, item, startDate, endDate } = props;
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertObject, setAlertObject] = useState(null);
  const { _id } = item;
  const [selectAll, setSelectAll] = useState(false)
  console.log("sab lelo", startDate, endDate)
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  const authToken = useSelector((state) => state.userReducer.authToken);

  useEffect(() => {
    _getChallans(startDate, endDate);
  }, [item]);

  const [challans, setChallans] = useState([]);

  const [checkedState, setCheckedState] = useState([]);
  console.log("for the lelo", checkedState)

  const [total, setTotal] = useState(0);

  const [openingBalance, setOpeningBalance] = useState(0);

  const [initialChalanAmount, setInitialChalanAmount] = useState(0);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  // const handleSelectAll = () => {
  //   const updatedCheckedState = Array(challans.length).fill(!selectAll);
  //   setCheckedState(updatedCheckedState);
  //   setSelectAll(!selectAll);

  //   const totalPrice = updatedCheckedState.reduce(
  //     (sum, currentState, index) => {
  //       if (currentState === true) {
  //         return sum + parseInt(challans[index].amount);
  //       }
  //       return sum;
  //     },
  //     0
  //   );
  //   setTotal(totalPrice);
  // };

  const handleSelectAll = () => {
    const toggleState = !selectAll;
    console.log("lelo1", toggleState)
    const updatedCheckedState = challans.map(() => toggleState);
    setCheckedState(updatedCheckedState);
    setSelectAll(toggleState);

    const totalPrice = updatedCheckedState.reduce(
      (sum, currentState, index) => {
        if (currentState === true) {
          return sum + parseInt(challans[index].amount);
        }
        return sum;
      },
      0
    );
    setTotal(totalPrice);
  };


  const handleCheckboxChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);

    const totalPrice = updatedCheckedState.reduce(
      (sum, currentState, index) => {
        if (currentState === true) {
          return sum + parseInt(challans[index].amount);
        }
        return sum;
      },
      0
    );

    setTotal(totalPrice);

    // Check if all checkboxes are now checked after toggling the current checkbox
    const allChecked = updatedCheckedState.every((isChecked) => isChecked);
    setSelectAll(allChecked);
  };

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);

    const totalPrice = updatedCheckedState.reduce(
      (sum, currentState, index) => {
        if (currentState === true) {
          return sum + parseInt(challans[index].amount);
        }
        return sum;
      },
      0
    );

    setTotal(totalPrice);
  };

  async function _getChallans(startDate, endDate) {
    try {
      let query = `&userId=${item._id}&isBilled=${false}&skip=undefined&limit=undefined`;
      if (startDate && endDate) {
        let sd = moment(startDate).format("DD-MM-YYYY");
        let ed = moment(endDate).format("DD-MM-YYYY");
        query += `&startDate=${sd}&endDate=${ed}`;
      }
      let res = await axios.get(GetAllChallansAPI(userInfo.entity) + query, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });


      let fd = _.sortBy(res.data.challans, obj => obj.challanDate);


      setChallans(fd);
      setCheckedState(new Array(res.data.challans.length).fill(false));
      let amount = 0;
      res.data.challans.map((item) => {
        if (item.amount) {
          amount += parseInt(item.amount);
        }
      });
      setInitialChalanAmount(amount);
    } catch (err) {
      // console.log("error:", err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function _generate() {
    try {
      await Promise.all(
        challans.map(async (i) => {
          let res = await axios.patch(UpdateChallanAPI(i._id), {
            paymentStatus: "Paid",
          });
        })
      );
      let orderIds = [];
      _.map(challans, (i) => {
        orderIds = _.concat(orderIds, i.orderIds);
      });
      let data = {
        ids: orderIds,
        type: "Bill",
        date: moment(),
        amount: 0,
      };
      let res = await axios.patch(CreateLedgerAPI(), data);

      close();
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  async function roundOff() {
    try {
      let deleteChallanId = [];
      for (let i = 0; i < checkedState.length; i++) {
        if (checkedState[i] == true) {
          deleteChallanId.push(challans[i]._id);
        }
      }

      let res = await axios.post(RoundOffChallansApi(), {
        challan_ids: deleteChallanId,
        account_id: item._id,
        total: total,

      }, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      });

      if (res.status == 200) {
        setOpenSnackbar(true);
        setAlertObject({ status: "success", message: "RoundOff successful" });
        // close();
        setTimeout(() => close(), 2000);
      }
    } catch (e) {
      console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
      setOpenSnackbar(true);
      setAlertObject({ status: "error", message: "Round Failed" });
    }
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <Card>
        <CardHeader color="primary">
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <h4 className={classes.cardTitleWhite}>Round Off</h4>
            <Button style={{ color: "white" }} onClick={close}>
              Close
            </Button>
          </Box>
          {/*<p className={classes.cardCategoryWhite}>Complete your profile</p>*/}
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {challanColumns.map((column) => (
                      <>
                        {column.id === "select" ? (
                          <TableCell
                            key={column.id}
                            align={column.align}
                          // style={{ padding: 0 }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  color="secondary"
                                  style={{ color: "black" }}
                                  iconStyle={{ fill: "black" }}
                                  inputStyle={{ color: "black" }}
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              }
                              label="Select All"
                            />
                          </TableCell>
                        ) : (

                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ width: "25%", padding: "8px 0px" }}
                          >
                            {column.label}
                          </TableCell>
                        )}
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {challans.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {challanColumns.map((column) => {
                          if (column.id == "select") {
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                              // style={{ padding: 0 }}
                              >
                                <Checkbox
                                  color="secondary"
                                  labelStyle={{ color: "black" }}
                                  iconStyle={{ fill: "black" }}
                                  inputStyle={{ color: "black" }}
                                  style={{ color: "black" }}
                                  checked={selectAll ? selectAll : checkedState[index]}
                                  // onChange={() => handleOnChange(index)}
                                  onChange={() => {
                                    handleCheckboxChange(index)
                                    // console.log("lelo", checkedState[index])
                                  }}
                                />
                              </TableCell>
                            );
                          } else {
                            const value = row[column.id];
                            if (column.id == "createdAt") {
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                //   style={{ padding: 0 }}
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : moment(value).format("DD-MM-YYYY")}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                //   style={{ padding: 0 }}
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            }
                          }
                        })}
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>

                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>

                    <TableCell
                      style={{
                        padding: "8px 0px 8px 0px",
                        borderBottom: "none",
                      }}
                    >
                      Opening Balance: ₹ {initialChalanAmount}{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>

                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell
                      style={{
                        padding: "8px 0px 8px 0px",
                        borderBottom: "none",
                      }}
                    >
                      Total Amount: ₹ {total}{" "}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>

                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell
                      style={{
                        padding: "8px 0px 8px 0px",
                        borderBottom: "none",
                      }}
                    >
                      Remaining Balance: ₹ {initialChalanAmount - total}{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    {/* <TableCell
                      style={{
                        padding: "8px 0px 8px 0px",
                        borderBottom: "none",
                      }}
                    >
                      <CustomInput
                        labelText="Set Opening Balance"
                        id="name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: openingBalance,
                          onChange: (event) =>
                            setOpeningBalance(event.target.value),
                        }}
                      />
                    </TableCell> */}
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell style={{ borderBottom: "none" }}></TableCell>
                    <TableCell
                      style={{
                        padding: "8px 0px 8px 0px",
                        borderBottom: "none",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => roundOff()}
                      >
                        Round Off
                      </Button>{" "}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      {alertObject ? (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={alertObject.status}>
            {alertObject.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Dialog>
  );
}
