export const challan4Template = (data) => {
    console.log("challan4", data)
    return `<!DOCTYPE html>
<html lang="en">
<head>
    <style>
    src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.4.0/jspdf.umd.min.js"
        @media print {
            .pagebreak {
                page-break-before: always;
                /* You can also use page-break-after if needed */
            }
            table tr {
    page-break-inside: avoid;
  }
  .no-break {
    white-space: nowrap;
  }
        }
        body {
                background-color: #f5f5f5; /* Set your desired background color */
                white-space: nowrap;
            }
            .no-break {
    white-space: nowrap;
    page-break-before: always;
  }
    </style>
</head>
<body>
    <h1 style="width: 100%; margin: 0; padding: 0; color: red; white-space: nowrap;">My Page with Page Breaks</h1>
    <p class='no-break'>This is some content on the first page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak">THE BRAKE</div> 
    <p class='no-break'>This content will appear on the second printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>

    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p><div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page. asdasd nsd
    fg sdgknsdgfsdg sdfgjdsgkjsdfg sdg sdgjnsdkg sdgkd 
    sfgsd gds fgsd g fsmh erhrh l wel elmgnlskglsdkfnglksdjglksdj
    fgkjsdgflksdgf gfkdnsfgjdhsgjsgjsdlfgdfjhdsfhkjjksjksjd sks
    dkfhjhdsfjkh sdksdjsjhdjhfkjeiuunjd sksksjksjkjshhsjs sjsjsjjsjsjsj
    sjsjjjdhdhdhdhdh dh  eewwefwefhfff
    ffd d dshdhdhd dhdhdh dddkusdjf sdfjhsdfkjhsdf </p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>
    <div style='page-break-before: always;'></div>
    <div class="pagebreak"></div> <!-- Another page break -->
    <p class='no-break'>And this content will be on the third printed page.</p>



</body>
</html>
`
};

