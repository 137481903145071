import React, { useEffect, useState } from "react";
import {
  Box,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import axios from "axios";
import { DeletePrintAdAPI, GetAllPrintAdsAPI } from "../../config/api";
import PrintAdAdd from "./PrintAdAdd";
import DeleteIcon from "@material-ui/icons/Delete";
import { ConfirmationDialog } from "../../components/common";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import People from "@material-ui/icons/People";
import CustomInput from "../../components/CustomInput/CustomInput";
import _ from "lodash";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const columns = [
  { id: "title", label: "Title" },
  { id: "action", label: "Action" },
];

export default function PrintAdList() {
  let userInfo = useSelector((state) => state.userReducer.userInfo);

  const classes = useStyles();

  const [printAds, setPrintAds] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    _getPrintAds();
  }, []);

  async function _getPrintAds() {
    try {
      let res = await axios.get(GetAllPrintAdsAPI(userInfo.entity));
      setPrintAds(res.data.printAds);
    } catch (err) {
      // console.log(err);

    }
  }

  async function _deleteItem(item) {
    try {
      let res = await axios.delete(DeletePrintAdAPI(item._id));
      window.location.reload();
    } catch (e) {
      // console.log("err:", e);

    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function closeAddDialog() {
    setOpenAddDialog(false);
  }

  const handleOpenConfirmationModal = (object) => {
    setOpenConfirmationModal(object);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(null);
  };

  let data = printAds;
  if (searchTerm !== "") {
    data = _.filter(data, (i) => {
      return (
        i.title && i.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <Box
              style={{
                display: "flex",
                flex: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4 className={classes.cardTitleWhite}>Print Ads</h4>
              <Box
                style={{
                  display: "flex",
                  flex: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomInput
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    style: {
                      color: "white",
                      marginTop: 0,
                    },
                    placeholder: "Search",
                    value: searchTerm,
                    onChange: (e) => setSearchTerm(e.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <People />
                      </InputAdornment>
                    ),
                  }}
                />
                <IconButton
                  aria-label="add"
                  style={{ color: "white" }}
                  onClick={() => setOpenAddDialog(true)}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
          </CardHeader>
          <CardBody>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={"center"}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        {columns.map((column) => {
                          let value = row[column.id];
                          if (column.id === "action") {
                            return (
                              <TableCell align={"center"}>
                                <Box display={"flex"} justifyContent={"center"}>
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() =>
                                      handleOpenConfirmationModal({
                                        onPress: () => _deleteItem(row),
                                      })
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell key={column.id} align={"center"}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </CardBody>
        </Card>
      </GridItem>
      {/*<Fab color="primary" aria-label="add" className={classes.fab}*/}
      {/*     onClick={() => setOpenAddDialog(true)}>*/}
      {/*    <AddIcon/>*/}
      {/*</Fab>*/}
      <PrintAdAdd
        fetchData={_getPrintAds}
        open={openAddDialog}
        close={closeAddDialog}
      />
      {openConfirmationModal ? (
        <ConfirmationDialog
          open={openConfirmationModal}
          onClose={handleCloseConfirmationModal}
          onConfirm={openConfirmationModal.onPress}
          title={"Delete Record"}
          msg={"Do you want to delete this record?"}
        />
      ) : null}
    </GridContainer>
  );
}
