import React, { useEffect, useState } from "react";
// @material-ui/core components
import {
  Box,
  Checkbox,
  Dialog,
  Fab,
  FormControlLabel,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import { Autocomplete } from "@material-ui/lab";
import Check from "@material-ui/icons/Check";
import styles from "../../../src/assets/jss/material-dashboard-react/checkboxAdnRadioStyle.js";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import {
  GetAllAccountsAPI,
  UpdateAccountAPI,
  GetAllProductMastersAPI,
  GetAccountAPI,
  GetAllReadyMastersAPI,
  GetBulkReadyMasterRangeAPI,
} from "../../config/api";
import { useSelector } from "react-redux";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { toast } from "react-toastify";

let s = _.merge(styles, {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
});

const useStyles = makeStyles(s);

const columns = [
  { id: "product", label: "Product" },
  { id: "discountPercent", label: "Discount Percent" },
  { id: "discountedPrice", label: "Discounted Price" },
  { id: "action", label: "Action" },
];

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 500,
});

// const products = ['Product 1', 'Product 2', 'Product 3', 'Product 4', 'Product 5'];

export default function DiscountAdd(props) {
  const classes = useStyles();
  const theme = useTheme();
  const authToken = useSelector((state) => state.userReducer.authToken);
  const userInfo = useSelector((state) => state.userReducer.userInfo);

  const { open, close, fetchData } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [party, setParty] = useState(null);
  const [copyDiscount, setCopyDiscount] = useState(false);
  const [disButton, setDisButton] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const types = ["Color", "Range"];
  const [type, setType] = useState("");
  console.log("type:", type);
  const [openPartySelectionDialog, setOpenPartySelectionDialog] =
    useState(false);
  const [product, setProduct] = useState(null);
  const [discountPercent, setDiscountPercent] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [discounts, setDiscounts] = useState([]);
  console.log("lele>>", discounts)
  const [readyMasterRange, setReadyMasterRange] = useState([]);

  useEffect(() => {
    _getAccounts();
    _getBulkReadyMasterRangeAPI();
  }, []);

  useEffect(() => {
    if (party) {
      _getAccount();
    }
  }, [party]);

  async function _getAccounts() {
    try {
      let res = await axios.get(
        GetAllAccountsAPI(userInfo.entity),

        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setAccounts(res.data.accounts);
    } catch (err) {
      // console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  async function _getAccount() {
    let res = await axios.get(GetAccountAPI(party._id), {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    setDiscounts(res.data.discounts);
  }

  async function _getBulkReadyMasterRangeAPI() {
    try {
      let res = await axios.get(GetBulkReadyMasterRangeAPI(userInfo.entity), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      setReadyMasterRange(res.data.readyMasterRange);
    } catch (err) {
      // console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  console.log(readyMasterRange, "range in discount");
  async function _onSave() {
    try {
      if (!party) {
        return;
      }

      party.discounts = discounts;
      // party.discounts.map((m, i) => {
      //   console.log("m>>", m);
      //   m.type = type;
      // });

      console.log("<>>>>", discounts);
      // return;
      let res = await axios.patch(
        UpdateAccountAPI(party._id),
        { discounts: party.discounts },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setParty(null);
      setDiscounts([]);
      setProduct(null);
      close();
      fetchData();
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  const PartySelectionDialog = (props) => {
    const [selectedParty, setSelectedParty] = useState(null);

    function _onSave() {
      if (!selectedParty) {
        return;
      }
      let d = _.concat(discounts, selectedParty.discounts);
      setDiscounts(d);
      setSelectedParty(null);
      setOpenPartySelectionDialog(false);
    }

    return (
      <Dialog
        fullScreen={fullScreen}
        maxWidth={"sm"}
        fullWidth
        open={openPartySelectionDialog}
        onClose={() => setOpenPartySelectionDialog(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <GridContainer>
          <GridItem xs={12} sm={12}>
            <Card>
              <CardHeader color="primary">
                <h4 className={classes.cardTitleWhite}>
                  Copy Discount from Party
                </h4>
              </CardHeader>
              <CardBody>
                <GridItem xs={12} sm={12} md={12}>
                  <Autocomplete
                    id="party"
                    className={classes.formControl}
                    options={accounts}
                    value={selectedParty}
                    onChange={(event, value) => {
                      setSelectedParty(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Party" />
                    )}
                  />
                </GridItem>
              </CardBody>
              <CardFooter style={{ justifyContent: "center" }}>
                <Button color="primary" onClick={() => _onSave()}>
                  Save
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </Dialog>
    );
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Add Discount</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                {/* <GridItem xs={12} sm={12} md={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        tabIndex={-1}
                        onChange={(e) => {
                          setCopyDiscount(e.target.checked);
                          if (e.target.checked) {
                            setOpenPartySelectionDialog(true);
                          }
                        }}
                        value={copyDiscount}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                        }}
                      />
                    }
                    label="Copy From Company"
                  />
                </GridItem> */}
                <GridItem xs={6} sm={6} md={6}>
                  <Autocomplete
                    id="party"
                    className={classes.formControl}
                    options={accounts}
                    value={party}
                    onChange={(event, value) => {
                      setParty(value);
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Party" />
                    )}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <Autocomplete
                    id="type"
                    className={classes.formControl}
                    options={types}
                    value={type}
                    onChange={(event, value) => {
                      setType(value);
                    }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Type" />
                    )}
                  />
                </GridItem>

                <GridItem xs={6}>
                  <Autocomplete
                    id="product"
                    filterOptions={filterOptions}
                    loading={readyMasterRange.length === 0 ? true : false}
                    className={classes.formControl}
                    options={readyMasterRange}
                    value={product}
                    onChange={(event, value) => {
                      // Check if any product already has a discount
                      const hasDiscount = discounts.some(d => d?.product.productName === value?.productName);
                      if (hasDiscount) {
                        toast.error("Product already has discount");
                        setDisButton(true); // Disable the button
                      } else {
                        setDisButton(false); // Enable the button
                      }

                      setProduct(value);
                    }}
                    getOptionLabel={(option) => `${option.productName}`}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Product" />
                    )}
                  />

                </GridItem>

                <GridItem xs={4}>
                  <CustomInput
                    labelText="Price"
                    id="price"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      disabled: true,
                      value: product ? (product?.rate ? product.rate : 0) : "",
                    }}
                  />
                </GridItem>
                <GridItem xs={4}>
                  <CustomInput
                    labelText="Discount Percent"
                    id="discountPercent"
                    type={"number"}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      value: discountPercent,
                      onChange: (event) => {
                        let discountPercent = event.target.value;
                        setDiscountPercent(discountPercent);
                        let price = product ? product.rate : 0;
                        let discountPrice = price - (discountPercent / 100) * price;
                        setDiscountedPrice(discountPrice);
                      },
                    }}
                  />
                </GridItem>
                <GridItem xs={4}>
                  <CustomInput
                    labelText="Discounted Price"
                    id="DiscountedPrice"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: discountedPrice,
                      onChange: (event) => {
                        let finalValue = event.target.value;
                        setDiscountedPrice(finalValue);
                        let price = product ? product.rate : 0;
                        let discountPercent = (((price - finalValue) * 100) / price).toFixed();
                        setDiscountPercent(discountPercent);
                      }
                    }
                    }
                  />
                </GridItem>


                <GridItem xs={1} sm={1} md={1}>
                  <Box justifyContent={"center"} marginTop={4}>
                    <Fab
                      size={"small"}
                      color="primary"
                      aria-label="add"
                      onClick={() => {
                        if (product === "") {
                          return alert("Please select product");
                        }
                        if (discountPercent === "") {
                          return alert("Please enter discount percent");
                        }
                        if (discountedPrice === "") {
                          return alert("Please discount amount");
                        }



                        let data = {
                          rate: product?.rate,
                          product,
                          discountPercent,
                          discountedPrice,
                          type: type,
                        };
                        discounts.push(data);
                        setProduct(null);
                        setDiscountedPrice("");
                        setDiscountPercent("");
                      }}
                      disabled={disButton}
                    >
                      <AddIcon />
                    </Fab>
                  </Box>
                </GridItem>
                {_.isEmpty(discounts) ? null : (
                  <GridItem xs={12}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {discounts.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                let value = row[column.id];
                                if (column.id === "product") {
                                  value = value.productName;
                                }
                                if (column.id === "action") {
                                  return (
                                    <TableCell align={"center"}>
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                      >
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() => {
                                            let copy = _.clone(discounts);
                                            copy.splice(index, 1);
                                            setDiscounts(copy);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </TableCell>
                                  );
                                }
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format && typeof value === "number"
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
            <CardFooter style={{ justifyContent: "center" }}>
              <Button color="primary" onClick={() => _onSave()}>
                Add Discount
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <PartySelectionDialog />
    </Dialog>
  );
}
