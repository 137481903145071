import React, { useEffect, useMemo, useState } from "react";
import _, { set } from "lodash";
// @material-ui/core components
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Fab,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
} from "@material-ui/core";
import moment from "moment";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
// import Table from "../../components/Table/Table.js";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import OrderView from "../Order/OrderView.js";
import PrescriptionOrderAdd from "../Order/PrescriptionOrderAdd.js";
import ReadyOrderAdd from "../Order/ReadyOrderAdd.js";
import FittingOrderAdd from "../Order/FittingOrderAdd.js";
import axios from "axios";
import {
  BulkPrintOrdersAPI,
  DeleteOrderAPI,
  GetAllOrdersAPI,
  UpdateMultipleOrders,
  UpdateOrderAPI,
} from "../../config/api";
import { ConfirmationDialog } from "../../components/common";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory } from "react-router-dom";
import CardHeader from "../../components/Card/CardHeader";
import People from "@material-ui/icons/People";
import CustomInput from "../../components/CustomInput/CustomInput";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import SendEmail from "../Order/SendEmail.js";
import { OrderDetailsDialog } from "../OrderApp/OrderDetailsDialog.js";
import { fxOrderTemplate } from "../../assets/html/readyOrderTemplate.js";
import { dataURItoBlob, htmlTemplateToDataString } from "../../utils/index.js";
import PrintIcon from "@mui/icons-material/Print";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

const columns = [
  { id: "party", label: "Party" },
  { id: "code", label: "Order Code" },
  { id: "orderType", label: "Order Type" },
  { id: "product", label: "Product" },
  { id: "productType", label: "Product Type" },
  {
    id: "orderAmount",
    label: "Order Amount",
    format: (value) =>
      Number(value).toLocaleString("en-IN", {
        maximumFractionDigits: 2,
        style: "currency",
        currency: "INR",
      }),
  },
  {
    id: "createdAt",
    label: "Order Date",
    format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  },
  { id: "action", label: "Action" },
];

const orderTypes = ["Fitting", "Prescription"];

export default function PrescriptionList(props) {
  const classes = useStyles();
  const history = useHistory();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);
  const [orders, setOrders] = useState([]);
  const [ordersGrouped, setOrdersGrouped] = useState([]);
  const [openReadyOrderAddDialog, setOpenReadyOrderAddDialog] = useState(false);
  const [openPrescriptionOrderAddDialog, setOpenPrescriptionOrderAddDialog] =
    useState(false);
  const [openFittingOrderAddDialog, setOpenFittingOrderAddDialog] =
    useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRows1, setSelectedRows1] = useState([]);
  console.log(selectedRows1, "selectedRows");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openViewDialog, setOpenViewDialog] = useState(null);
  const [openOrderTypeDialog, setOpenOrderTypeDialog] = useState(false);
  const [selectedType, setSelectedType] = useState(orderTypes[0]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState("");
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [openOrderDetailsModal, setOpenOrderDetailsModal] = useState(null);
  const [orderAdminDetails, setOrderAdminDetails] = useState(null);
  // const [columns , setColumns] = useState("");
  const [tota, setTota] = useState(0);

  useEffect(() => {
    _getOrders(page, rowsPerPage);
  }, [page, rowsPerPage]);

  // async function _getOrders() {
  //   try {
  //     let res = await axios.get(
  //       GetAllOrdersAPI(userInfo.entity) +
  //       "&sortBy=createdAt:desc",
  //       {
  //         headers: {
  //           Authorization: `Bearer ${authToken}`,
  //         },
  //       }
  //     );
  //     setOrders(res.data.orders);
  //     console.log("Reading orders", res.data.orders);
  //     setOrdersGrouped(res.data.ordersGrouped);
  //     console.log("Checking orders", orders);
  //   } catch (err) {
  //     console.log("error:", err);
  //     // toast.error(("Something went wrong, Please Contact Admin") , {
  //     //   position: "top-center",
  //     //   autoClose: 1500,
  //     //   hideProgressBar: false,
  //     //   closeOnClick: true,
  //     //   pauseOnHover: true,
  //     //   draggable: true,
  //     //   progress: undefined,
  //     //   theme: "light",
  //     //   });
  //   }
  // }

  async function searchData(term) {
    console.log(term, "<<<<term")
    setSearchTerm(term);
    let query = `&sortBy=createdAt:desc&isOrderFromApp=true&orderReturnStatus=None&limit=${rowsPerPage}&skip=${
      page * rowsPerPage
    }&code=${term}`;
    let res = await axios.get(
      GetAllOrdersAPI(userInfo.entity) + query,
      // `&limit=${rowsPerPage}&skip=${page * rowsPerPage}&productName=${term}`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    setOrders(res.data.readyMasterRange);
    setOrdersGrouped(res.data.ordersGrouped);
    setTota(res.data.total);
  }

  const _getOrders = useMemo(
    () => async (page, rowsPerPage) => {
      let skipm = page;
      console.log("limit & skip", rowsPerPage, page, skipm);

      if (
        rowsPerPage === undefined ||
        rowsPerPage === null ||
        rowsPerPage === NaN
      ) {
        rowsPerPage = 10;
      }

      if (page === undefined || page === null || page === NaN) {
        page = 0;
      }

      try {
        let query = `&sortBy=createdAt:desc&limit=${rowsPerPage}&skip=${
          page * rowsPerPage
        }`;
        let res = await axios.get(GetAllOrdersAPI(userInfo.entity) + query, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        setOrders(res.data.orders);
        setOrdersGrouped(res.data.ordersGrouped);
        setTota(res.data.total);
      } catch (err) {
        // console.log("error:", err);
      }
    },
    []
  );

  async function _deleteItem(item) {
    try {
      let res = await axios.delete(DeleteOrderAPI(item._id), {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      toast.success("Successfully Deleted!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      _getOrders();
      // window.location.reload();
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  async function _onPrintAllOrders() {
    try {
      let res = await axios.post(
        BulkPrintOrdersAPI(),
        {
          orders: selectedRows,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      let tmp = [];
      selectedRows.map((i) => {
        let o = _.find(orders, { _id: i });
        o["printCustomerName"] = false;
        tmp.push(o);
      });

      history.push("/admin/order-print", { orders: tmp });
    } catch (e) {
      console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  async function _rejectOrder(row, data) {
    try {
      let res = await axios.patch(
        UpdateOrderAPI(row.code),
        {
          orderAdminStatus: "Rejected",
          orderStatus: "Rejected",
          orderAdminDetails: [...row.orderAdminDetails, data],
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      _getOrders();
    } catch (err) {
      // console.log(err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  async function _onPrintAllOrdersWithCustomerName() {
    try {
      let res = await axios.post(
        BulkPrintOrdersAPI(),
        {
          orders: selectedRows,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      let tmp = [];
      selectedRows.map((i) => {
        let o = _.find(orders, { _id: i });
        o["printCustomerName"] = true;
        tmp.push(o);
      });
      history.push("/admin/order-print", { orders: tmp });
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  async function _confirmMultipleOrders(selectedRows1, data) {
    try {
      // Construct the API URL
      const apiURL = UpdateMultipleOrders(); // Get the API URL
      console.log("API URL:", apiURL, selectedRows1); // Log it to verify if it's correct

      // Create an array of updates for each selected order
      const updates = selectedRows1.map((row) =>
        //   {
        //   console.log("first", row);
        // });
        ({
          code: row.code, // Assuming row contains a unique 'code' for each order
          orderCompleted: true,
          orderAdminStatus: "Confirmed",
          orderStatus: "Completed",
          // orderAdminDetails: [...row.orderAdminDetails, data], // Append new details
        })
      );

      // Make the API request to update multiple orders
      let res = await axios.patch(
        apiURL, // API endpoint for bulk updating orders
        { selectedRows: updates }, // Send the selectedRows in req.body
        {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include authorization header
          },
        }
      );

      console.log(res);
      _getOrders(); // Refresh the orders list after the update
      setSelectedRows1([])
      if (res.status === 200) {
        toast.success("Records Updated."); // Success message
      }
    } catch (err) {
      console.error("Error occurred:", err);
      // Optionally show a notification or toast
      toast.error("Error updating records."); // Error message
    }
  }

  async function _confirmOrder(row, data) {
    // console.log("first", data);
    // return;
    try {
      let res = await axios.patch(
        UpdateOrderAPI(row.code),
        {
          orderCompleted: true,
          orderAdminStatus: "Confirmed",
          orderStatus: "Completed",
          orderAdminDetails: [...row.orderAdminDetails, data],
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(res);
      _getOrders();
    } catch (err) {
      // console.log("Error aara bhai",err);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  }

  const OrderTypeDialog = () => {
    return (
      <Dialog
        onClose={() => setOpenOrderTypeDialog(false)}
        aria-labelledby="simple-dialog-title"
        open={openOrderTypeDialog}
      >
        <DialogTitle id="simple-dialog-title">Select Order Type</DialogTitle>
        <List>
          {orderTypes.map((item) => (
            <ListItem
              button
              onClick={() => {
                setSelectedType(item);
                setOpenOrderTypeDialog(false);
                if (item === "Ready") {
                  setOpenReadyOrderAddDialog(true);
                } else if (item === "Fitting") {
                  setOpenFittingOrderAddDialog(true);
                } else {
                  setOpenPrescriptionOrderAddDialog(true);
                }
              }}
              key={item}
            >
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Dialog>
    );
  };
  const handleOpenConfirmationModal = (object) => {
    setOpenConfirmationModal(object);
  };
  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function closeReadyOrderAddDialog() {
    setOpenReadyOrderAddDialog(false);
  }
  function closeEmailDialog() {
    setOpenEmailDialog(false);
  }

  function closeFittingOrderAddDialog() {
    setOpenFittingOrderAddDialog(false);
  }

  function closePrescriptionOrderAddDialog() {
    setOpenPrescriptionOrderAddDialog(false);
  }

  function closeViewDialog() {
    setOpenViewDialog(null);
  }
  const handleOpenOrderDetailsModal = (object) => {
    setOpenOrderDetailsModal(object);
  };
  const handleCloseOrderDetailsModal = () => {
    setOpenOrderDetailsModal(null);
  };

  function _renderTable(orderType, data) {
    if (searchTerm !== "") {
      data = _.filter(data, (i) => {
        return (
          i.code && i.code.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    }
    // if (orderType==='Prescription'){
    //     data = _.orderBy(data, ['createdAt'], ['desc']);
    // }

    const handlePrint = async (row) => {
      let options = {
        orientation: "p",
        unit: "pt",
        format: [284, 600],
        // format: 'letter',
        putOnlyUsedFonts: true,
      };
      let htmlTemplate = fxOrderTemplate(row);
      await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
        const preBlob = dataURItoBlob(dataString);
        window.open(URL.createObjectURL(preBlob, "_blank"));
      });
    };

    const handlePrints = async (rows) => {
      let options = {
        orientation: "p",
        unit: "pt",
        format: [284, 433],
        // format: 'letter',
        putOnlyUsedFonts: true,
      };
      let htmlTemplate = fxOrderTemplate(rows);
      await htmlTemplateToDataString(htmlTemplate, options, (dataString) => {
        const preBlob = dataURItoBlob(dataString);
        window.open(URL.createObjectURL(preBlob, "_blank"));
      });
    };

    function handleSearch() {
      searchData(search);
    }

    return (
      <GridItem xs={12} sm={12} md={12}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {orderType === "Prescription" ? (
            <>
              <Button
                color="primary"
                onClick={() =>
                  handleOpenConfirmationModal({
                    msg: "Do you want to print all records",
                    onPress: () => _onPrintAllOrders(),
                  })
                }
              >
                Print All Records
              </Button>
              <Button
                color="primary"
                onClick={() =>
                  handleOpenConfirmationModal({
                    msg: "Do you want to print all records",
                    onPress: () => _onPrintAllOrdersWithCustomerName(),
                  })
                }
              >
                Print All Records With Customer Name
              </Button>
              <Button color="Primary" onClick={() => setOpenEmailDialog(true)}>
                Send Emails
              </Button>
            </>
          ) : (
            <div />
          )}
          <div>
            <CustomInput
              id="search"
              formControlProps={{
                fullWidth: false,
              }}
              inputProps={{
                style: {
                  color: "primary",
                  marginTop: 0,
                },
                placeholder: "Search",
                value: search,
                onChange: (e) => {
                  setSearch(e.target.value);
                  if (
                    e.target.value === "" ||
                    e.target.value === null ||
                    e.target.value === undefined
                  ) {
                    _getOrders(page, rowsPerPage);
                  }
                },
                onKeyDown: (e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    handleSearch();
                  }
                },
                // onChange: (e) => searchData(e.target.value),
                // onChange: (e) => setSearch(e.target.value),
                // onSubmit: (e) => searchData(e.target.value),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      color={"primary"}
                      onClick={(e) => {
                        // setSearchTerm(e.target.value),
                        // handleKeyPress(e)
                        // handleKeyPress()
                        handleSearch();
                      }}
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <IconButton
              aria-label="add"
              onClick={() => setOpenPrescriptionOrderAddDialog(true)}
            >
              <AddIcon />
            </IconButton>
          </div>
        </Box>
        <Box>
  <div>
    {/* Conditionally render the Confirm button when selectedRows1 is not empty */}
    {selectedRows1.length > 0 && (
      <>
        <Button
          color="Primary"
          onClick={() =>
            handleOpenOrderDetailsModal({
              title: "Do you want to Accept Multiple Orders?",
              onPress: (data) =>
                _confirmMultipleOrders(selectedRows1, data),
            })
          }
        >
          Confirm Selected Orders
        </Button>

        {/* Add the new Print Selected Orders button */}
        <Button
          color="primary"
          onClick={() =>
            handleOpenOrderDetailsModal({
              title: "Do you want to Print Multiple Orders?",
              onPress: (data) =>
                handlePrints(selectedRows1,data),
            })
          }
        >
          Print Selected Orders
        </Button>
      </>
    )}

    {/* Display selected rows for debugging */}
    <div>
      <h4>Selected Rows: {selectedRows1.length}</h4>
    </div>
  </div>
</Box>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {orderType === "Prescription" ? (
                <Checkbox
                  style={{
                    color: "#512da8",
                  }}
                  onChange={(e) => {
                    let c = [];
                    let d = [];
                    if (e.target.checked) {
                        orders.map((i) => {
                          c.push(i._id);
                          // d.push(i)
                        });
                        data.map((i) => {
                          // c.push(i._id);
                          d.push(i)
                        });
                    }
                    setSelectedRows(c);
                    setSelectedRows1(d);
                  }}
                  checked={
                    selectedRows.length > 0 &&
                    orders.length === selectedRows.length
                  }
                  inputProps={{ "aria-labelledby": -1 }}
                />
              ) : null}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={"center"}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {orderType === "Prescription" ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          style={{
                            color: "#512da8",
                          }}
                          onChange={() => {
                            let copy = _.clone(selectedRows);
                            if (_.includes(copy, row._id)) {
                              let index = _.indexOf(copy, row._id);
                              copy.splice(index, 1);
                            } else {
                              copy.push(row._id);
                            }
                            setSelectedRows(copy);
                            // let rows = _.clone(selectedRows1);
                            // console.log("itincludes", rows, "removing")
                            // if (_.includes(rows, row._id)) {
                            //   console.log("itincludes", rows, "removing")
                            //   let index = _.indexOf(rows, row._id);
                            //   rows.splice(index, 1);
                            // } else {
                            //   console.log("pushing")
                              
                            //   rows.push(row);
                            // }
                            // setSelectedRows1(rows);
                            let rows = _.clone(selectedRows1);
console.log("itincludes", rows, "removing");

// Use _.findIndex to find the correct row by _id
let index = _.findIndex(rows, r => r._id === row._id);

if (index !== -1) {
  console.log("itincludes", rows, "removing");
  rows.splice(index, 1);  // Remove the row by index
} else {
  console.log("pushing");
  rows.push(row);  // Push the entire row object
}

setSelectedRows1(rows);
                          }}
                          checked={_.includes(selectedRows, row._id)}
                          inputProps={{ "aria-labelledby": row._id }}
                        />
                      </TableCell>
                    ) : null}
                    {columns.map((column) => {
                      let value = row[column.id];
                      if (column.id === "product") {
                        if (row.orderType === "Fitting") {
                          value =
                            row.orderDetail && row.orderDetail.productName
                              ? row.orderDetail.productName
                              : "";
                        } else if (row.orderType === "Ready") {
                          value =
                            row.orderDetail && row.orderDetail.product
                              ? row.orderDetail.product.name
                              : "";
                        } else {
                          value =
                            row.orderDetail && row.orderDetail.product
                              ? row.orderDetail.product.productName
                              : "";
                        }
                      }
                      if (column.id === "productType") {
                        value = row.orderDetail.product.productType;
                      }
                      if (column.id === "action") {
                        return (
                          <TableCell align={"center"}>
                            <Box display={"flex"} justifyContent={"center"}>
                              <IconButton
                                aria-label="view"
                                onClick={() =>
                                  setOpenViewDialog({
                                    open: true,
                                    item: row,
                                  })
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton
                                aria-label="view"
                                onClick={() => handlePrint(row)}
                              >
                                <PrintIcon />
                              </IconButton>
                              {row.orderType === "Prescription" &&
                                row.orderAdminStatus === "Pending" &&
                                row.isOrderFromApp === true && (
                                  <React.Fragment>
                                    <IconButton
                                      aria-label="confirm"
                                      onClick={() =>
                                        handleOpenOrderDetailsModal({
                                          title: "Do you want to Accept Order",
                                          onPress: (data) =>
                                            _confirmOrder(row, data),
                                        })
                                      }
                                      // onClick={(data) => _confirmOrder(row, data)}
                                    >
                                      <CheckCircleIcon />
                                    </IconButton>
                                    <IconButton
                                      aria-label="reject"
                                      onClick={() =>
                                        handleOpenOrderDetailsModal({
                                          title: "Do you want to Reject Order",
                                          onPress: (data) =>
                                            _rejectOrder(row, data),
                                        })
                                      }
                                      // onClick={(data) => _rejectOrder(row.code,data)}
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  </React.Fragment>
                                )}
                              {row.orderType === "Prescription" &&
                                row.orderStatus === "Pending" &&
                                row.isOrderFromApp === false && (
                                  <React.Fragment>
                                    <IconButton
                                      aria-label="confirm"
                                      onClick={() =>
                                        handleOpenOrderDetailsModal({
                                          title: "Do you want to Accept Order",
                                          onPress: (data) =>
                                            _confirmOrder(row, data),
                                        })
                                      }
                                      // onClick={(data) => _confirmOrder(row, data)}
                                    >
                                      <CheckCircleIcon />
                                    </IconButton>
                                    <IconButton
                                      aria-label="reject"
                                      onClick={() =>
                                        handleOpenOrderDetailsModal({
                                          title: "Do you want to Reject Order",
                                          onPress: (data) =>
                                            _rejectOrder(row, data),
                                        })
                                      }
                                      // onClick={(data) => _rejectOrder(row.code,data)}
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  </React.Fragment>
                                )}
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleOpenConfirmationModal({
                                    msg: "Do you want to delete this record?",
                                    title: "Delete Record",
                                    onPress: () => _deleteItem(row),
                                  })
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                        );
                      }

                      if (column.id === "party") {
                        return (
                          <TableCell key={column.id} align={"center"}>
                            {value.accountName}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell key={column.id} align={"center"}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 25, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </GridItem>
    );
  }

  let pendingPrescriptionOrders = _.filter(orders, {
    orderType: "Prescription",
    orderStatus: "Pending",
    isOrderFromApp: false,
  });
  let completedPrescriptionOrders = _.filter(orders, {
    orderType: "Prescription",
    orderStatus: "Completed",
    isOrderFromApp: false,
  });
  let completedPrescriptionOrdersApp = _.filter(orders, {
    orderType: "Prescription",
    orderAdminStatus: "Confirmed",
    isOrderFromApp: true,
  });
  let pendingPrescriptionOrdersApp = _.filter(orders, {
    orderType: "Prescription",
    orderAdminStatus: "Pending",
    isOrderFromApp: true,
  });

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomTabs
          title=""
          headerColor="primary"
          tabs={[
            {
              tabName: "Pending Web",
              tabIcon: Code,
              tabContent: _renderTable(
                "Prescription",
                pendingPrescriptionOrders
              ),
            },
            {
              tabName: "Completed Web",
              tabIcon: Cloud,
              tabContent: _renderTable(
                "Prescription",
                completedPrescriptionOrders
              ),
            },
            {
              tabName: "Pending App",
              tabIcon: Code,
              tabContent: _renderTable(
                "Prescription",
                pendingPrescriptionOrdersApp
              ),
            },
            {
              tabName: "Completed App",
              tabIcon: Code,
              tabContent: _renderTable(
                "Prescription",
                completedPrescriptionOrdersApp
              ),
            },
          ]}
        />
      </GridItem>

      <PrescriptionOrderAdd
        fetchData={_getOrders}
        open={openPrescriptionOrderAddDialog}
        close={closePrescriptionOrderAddDialog}
      />
      <ReadyOrderAdd
        fetchData={_getOrders}
        open={openReadyOrderAddDialog}
        close={closeReadyOrderAddDialog}
      />
      <FittingOrderAdd
        fetchData={_getOrders}
        open={openFittingOrderAddDialog}
        close={closeFittingOrderAddDialog}
      />
      <SendEmail
        fetchData={_getOrders}
        open={openEmailDialog}
        close={closeEmailDialog}
      />
      <OrderTypeDialog />
      {openViewDialog ? (
        <OrderView
          open={openViewDialog.open}
          close={closeViewDialog}
          item={openViewDialog.item}
        />
      ) : null}
      {openConfirmationModal ? (
        <ConfirmationDialog
          open={openConfirmationModal}
          onClose={handleCloseConfirmationModal}
          onConfirm={openConfirmationModal.onPress}
          title={openConfirmationModal.title}
          msg={openConfirmationModal.msg}
        />
      ) : null}
      {openOrderDetailsModal ? (
        <OrderDetailsDialog
          open={openOrderDetailsModal}
          onClose={handleCloseOrderDetailsModal}
          onConfirm={openOrderDetailsModal.onPress}
          title={openOrderDetailsModal.title}
          msg={openOrderDetailsModal.msg}
          setOrderAdminDetails={setOrderAdminDetails}
          orderAdminDetails={orderAdminDetails}
        />
      ) : null}
    </GridContainer>
  );
}
