import React, { useState } from "react";
import _ from "lodash";
import moment from "moment";
import axios from "axios";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";

import {
  Button,
  Dialog,
  DialogTitle,
  Fab,
  IconButton,
  InputAdornment,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

import People from "@material-ui/icons/People";
import CustomInput from "../../components/CustomInput/CustomInput";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { GetAllOrdersAPI, UpdateOrderAPI } from "../../config/api";
import { OrderDetailsDialog } from "./OrderDetailsDialog.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import OrderView from "../Order/OrderView";
import { useSelector } from "react-redux";
import OrderEdit from "./OrderEdit.js";
import { toast } from "react-toastify";
const columns = [
  // { id: "party", label: "Party" },
  { id: "code", label: "Order Code" },
  { id: "productName", label: "Product" },
  { id: "orderType", label: "Order Type" },
  { id: "rSph", label: "R Sph" },
  { id: "rCyl", label: "R Cyl" },
  { id: "rAxis", label: "R Axis" },
  { id: "rAddition", label: "R Addition" },
  { id: "lSph", label: "L Sph" },
  { id: "lCyl", label: "L Cyl" },
  { id: "lAxis", label: "L Axis" },
  { id: "lAddition", label: "L Addition" },

  // { id: "orderAmount", label: "Order Amount" },
  // {
  //   id: "createdAt",
  //   label: "Order Date",
  //   format: (value) => (value ? moment(value).format("DD-MM-YYYY") : ""),
  // },
  { id: "action", label: "Action" },
];

export default function CompanyOrderView(props) {
  let { item, open, close, orderType } = props;
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let authToken = useSelector((state) => state.userReducer.authToken);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openViewDialog, setOpenViewDialog] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(null);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const [openOrderDetailsModal, setOpenOrderDetailsModal] = useState(null);
  const [orders, setOrders] = useState(item.orders);
  const [orderAdminDetails, setOrderAdminDetails] = useState(null);
  const handleOpenOrderDetailsModal = (object) => {
    setOpenOrderDetailsModal(object);
  };
  const handleCloseOrderDetailsModal = () => {
    setOpenOrderDetailsModal(null);
  };



  async function _getOrders() {
    try {
      let res = await axios.get(
        GetAllOrdersAPI(userInfo.entity) +
        "&sortBy=createdAt:desc&isOrderFromApp=true&orderReturnStatus=None&orderAdminStatus=Pending",
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setOrders(res.data.orders);
    } catch (err) {
      // console.log("error:", err);
    }
  }

  async function _confirmOrder(row, data) {
    try {
      let res = await axios.patch(
        UpdateOrderAPI(row.code),
        {
          orderAdminStatus: "Confirmed",
          orderCompleted: true,
          orderCompletedBy: userInfo.name,
          orderAdminDetails: [...row.orderAdminDetails, data],
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      let tempOrders = _.filter(orders, (o) => o.code !== row.code);
      setOrders(tempOrders);
    } catch (err) {
      // console.log(err);

    }
  }

  async function _rejectOrder(row, data) {
    try {
      let res = await axios.patch(
        UpdateOrderAPI(row.code),
        {
          orderAdminStatus: "Rejected",
          orderCompleted: true,
          orderCompletedBy: userInfo.name,
          orderAdminDetails: [...row.orderAdminDetails, data],
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      // _getOrders();
      let tempOrders = _.filter(orders, (o) => o.code !== row.code);
      setOrders(tempOrders);
    } catch (err) {
      // console.log(err);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function closeViewDialog() {
    setOpenViewDialog(null);
  }

  function closeEditDialog() {
    setOpenEditDialog(null);
  }

  let data = orders;
  if (searchTerm !== "") {
    data = _.filter(orders, (i) => {
      return i.code && i.code.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }
  if (orderType === "Prescription") {
    data = _.orderBy(data, ["createdAt"], ["desc"]);
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      fullScreen
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Card>
        <CardHeader color="primary">
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <h4
              style={{
                color: "#FFFFFF",
                marginTop: "0px",
                minHeight: "auto",
                fontWeight: "300",
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                marginBottom: "3px",
                textDecoration: "none",
              }}
            >
              {typeof item.party === "string"
                ? item.party
                : item.party.accountName}
            </h4>
            <Box display={"flex"} alignItems={"center"}>
              <Button style={{ color: "white" }} onClick={close}>
                Close
              </Button>
            </Box>
          </Box>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
              >
                <div>
                  <CustomInput
                    id="search"
                    formControlProps={{
                      fullWidth: false,
                    }}
                    inputProps={{
                      style: {
                        color: "primary",
                        marginTop: 0,
                      },
                      placeholder: "Search",
                      value: searchTerm,
                      onChange: (e) => setSearchTerm(e.target.value),
                      endAdornment: (
                        <InputAdornment position="end">
                          <People />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Box>

              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {orderType === "Prescription" ? (
                      <Checkbox
                        style={{
                          color: "#512da8",
                        }}
                        onChange={(e) => {
                          let c = [];
                          if (e.target.checked) {
                            orders.map((i) => {
                              c.push(i._id);
                            });
                          }
                          setSelectedRows(c);
                        }}
                        checked={
                          selectedRows.length > 0 &&
                          orders.length === selectedRows.length
                        }
                        inputProps={{ "aria-labelledby": -1 }}
                      />
                    ) : null}
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={"center"}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {orderType === "Prescription" ? (
                            <TableCell padding="checkbox">
                              <Checkbox
                                style={{
                                  color: "#512da8",
                                }}
                                onChange={() => {
                                  let copy = _.clone(selectedRows);
                                  if (_.includes(copy, row._id)) {
                                    let index = _.indexOf(copy, row._id);
                                    copy.splice(index, 1);
                                  } else {
                                    copy.push(row._id);
                                  }
                                  setSelectedRows(copy);
                                }}
                                checked={_.includes(selectedRows, row._id)}
                                inputProps={{ "aria-labelledby": row._id }}
                              />
                            </TableCell>
                          ) : null}
                          {columns.map((column) => {
                            let value = row[column.id];
                            if (column.id === "product") {
                              if (row.orderType === "Fitting") {
                                value =
                                  row.orderDetail && row.orderDetail.productName
                                    ? row.orderDetail.productName
                                    : "";
                              } else if (row.orderType === "Ready") {
                                value =
                                  row.orderDetail && row.orderDetail.product
                                    ? row.orderDetail.product.name
                                    : "";
                              } else {
                                value =
                                  row.orderDetail && row.orderDetail.product
                                    ? row.orderDetail.product.productName
                                    : "";
                              }
                            }
                            if (column.id === "action") {
                              return (
                                <TableCell align={"center"}>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"center"}
                                  >
                                    <IconButton
                                      aria-label="view"
                                      onClick={() =>
                                        setOpenViewDialog({
                                          open: true,
                                          item: row,
                                        })
                                      }
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                    <IconButton
                                      aria-label="Edit"
                                      onClick={() =>
                                        setOpenEditDialog({
                                          open: true,
                                          item: row,
                                        })
                                      }
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    {row.orderAdminStatus === "Pending" && (
                                      <React.Fragment>
                                        <IconButton
                                          aria-label="confirm"
                                          onClick={() =>
                                            handleOpenOrderDetailsModal({
                                              title:
                                                "Do you want to Accept Order",
                                              onPress: (data) =>
                                                _confirmOrder(row, data),
                                            })
                                          }
                                        // onClick={(data) => _confirmOrder(row, data)}
                                        >
                                          <CheckCircleIcon />
                                        </IconButton>
                                        <IconButton
                                          aria-label="reject"
                                          onClick={() =>
                                            handleOpenOrderDetailsModal({
                                              title:
                                                "Do you want to Reject Order",
                                              onPress: (data) =>
                                                _rejectOrder(row, data),
                                            })
                                          }
                                        // onClick={(data) => _rejectOrder(row.code,data)}
                                        >
                                          <CancelIcon />
                                        </IconButton>
                                      </React.Fragment>
                                    )}
                                    {/* <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  handleOpenConfirmationModal({
                                    msg: "Do you want to delete this record?",
                                    title: "Delete Record",
                                    onPress: () => _deleteItem(row),
                                  })
                                }
                              >
                                <DeleteIcon />
                              </IconButton> */}
                                  </Box>
                                </TableCell>
                              );
                            }

                            if (column.id === "lAxis") {
                              return (
                                <TableCell key={column.id} align={"center"}>
                                  {row.orderDetail.lAxis}
                                </TableCell>
                              );
                            }

                            if (column.id === "rAxis") {
                              return (
                                <TableCell key={column.id} align={"center"}>
                                  {row.orderDetail.rAxis}
                                </TableCell>
                              );
                            }
                            if (column.id === "productName") {
                              return (
                                <TableCell key={column.id} align={"center"}>
                                  {row.orderDetail.productName}
                                </TableCell>
                              );
                            }

                            if (column.id === "rSph") {
                              return (
                                <TableCell key={column.id} align={"center"}>
                                  {row.orderDetail.rSph}
                                </TableCell>
                              );
                            }

                            if (column.id === "lSph") {
                              return (
                                <TableCell key={column.id} align={"center"}>
                                  {row.orderDetail.lSph}
                                </TableCell>
                              );
                            }

                            if (column.id === "lCyl") {
                              return (
                                <TableCell key={column.id} align={"center"}>
                                  {row.orderDetail.lCyl}
                                </TableCell>
                              );
                            }

                            if (column.id === "rCyl") {
                              return (
                                <TableCell key={column.id} align={"center"}>
                                  {row.orderDetail.rCyl}
                                </TableCell>
                              );
                            }

                            if (column.id === "lAddition") {
                              return (
                                <TableCell key={column.id} align={"center"}>
                                  {row.orderDetail.lAddition}
                                </TableCell>
                              );
                            }

                            if (column.id === "rAddition") {
                              return (
                                <TableCell key={column.id} align={"center"}>
                                  {row.orderDetail.rAddition}
                                </TableCell>
                              );
                            }

                            if (column.id === "party") {
                              return (
                                <TableCell key={column.id} align={"center"}>
                                  {value.accountName}
                                </TableCell>
                              );
                            }
                            return (
                              <TableCell key={column.id} align={"center"}>
                                {column.format ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </GridItem>
            {openOrderDetailsModal ? (
              <OrderDetailsDialog
                open={openOrderDetailsModal}
                onClose={handleCloseOrderDetailsModal}
                onConfirm={openOrderDetailsModal.onPress}
                title={openOrderDetailsModal.title}
                msg={openOrderDetailsModal.msg}
                setOrderAdminDetails={setOrderAdminDetails}
                orderAdminDetails={orderAdminDetails}
              />
            ) : null}
            {openViewDialog ? (
              <OrderView
                open={openViewDialog.open}
                close={closeViewDialog}
                item={openViewDialog.item}
              />
            ) : null}
            {openEditDialog ? (
              <OrderEdit
                open={openEditDialog.open}
                close={closeEditDialog}
                item={openEditDialog.item}
                closeCompanyOrderView={close}
              />
            ) : null}
          </GridContainer>
        </CardBody>
      </Card>
    </Dialog>
  );
}
