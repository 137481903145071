import React, { useState } from "react";
// @material-ui/core components
import { Dialog, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import axios from "axios";
import {
  CreateProductMasterAPI,
  UpdateProductMasterAPI,
} from "../../config/api";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const styles = {
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

const labs = ["Product 1", "Product 2", "Product 3", "Product 4", "Product 5"];
const brands = [
  "Product 1",
  "Product 2",
  "Product 3",
  "Product 4",
  "Product 5",
];

export default function ProductMasterEdit(props) {
  const { item } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { open, close, fetchData } = props;
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  let authToken = useSelector((state) => state.userReducer.authToken);
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  const [lab, setLab] = useState(item?.lab ? item.lab : null);
  const [productType, setProductType] = useState(
    item?.productType ? item.productType : ""
  );
  const [productName, setProductName] = useState(
    item?.productName ? item.productName : ""
  );
  const [price, setPrice] = useState(item?.price ? item.price : "");
  const [index, setIndex] = useState(item?.index ? item.index : "");
  const [dia, setDia] = useState(item?.dia ? item.dia : "");
  const [fittingHeight, setFittingHeight] = useState(
    item?.fittingHeight ? item.fittingHeight : ""
  );
  const [powerRange, setPowerRange] = useState(
    item?.powerRange ? item.powerRange : ""
  );
  const [coating, setCoating] = useState(item?.coating ? item.coating : "");
  const [threshold, setThreshold] = useState(
    item?.threshold ? item.threshold : ""
  );
  const [hsnCode, setHsnCode] = useState(item.hsnCode? item.hsnCode : "")
  const [taxPercent, setTaxPercent] = useState(item.taxPercent?item.taxPercent:"")

  async function _onSave() {
    try {
      let product = {
        productName,
        productType,
        lab,
        price,
        index,
        dia,
        fittingHeight,
        powerRange,
        coating,
        threshold,
        hsnCode,
        taxPercent,
      };
      if (
        productName === "" ||
        productType === "" ||
        lab === "" ||
        dia === "" ||
        index === "" ||
        fittingHeight === "" ||
        powerRange === "" ||
        coating === "" ||
        price === "" ||
        threshold === "") {
        toast.error("Please fill all the fields", {
          position: "top-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      let res = await axios.patch(UpdateProductMasterAPI(item._id), product, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      toast.success('Successfully Edited!', {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      close();
      fetchData();
    } catch (e) {
      // console.log("err:", e);
      // toast.error(("Something went wrong, Please Contact Admin") , {
      //   position: "top-center",
      //   autoClose: 1500,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   }); 
    }
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={close}
      aria-labelledby="responsive-dialog-title"
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Update Product Master</h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Product Name"
                    id="productName"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: productName,
                      onChange: (event) => setProductName(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={6} md={6} sm={6}>
                  <Barcode value={productName} />,
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Lab"
                    id="lab"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: lab,
                      onChange: (event) => setLab(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Product Type"
                    id="productType"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: productType,
                      onChange: (event) => setProductType(event.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Index"
                    id="index"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: index,
                      onChange: (event) => setIndex(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Dia"
                    id="dia"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: dia,
                      onChange: (event) => setDia(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="FittingHeight"
                    id="fittingHeight"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: fittingHeight,
                      onChange: (event) => setFittingHeight(event.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    labelText="Power Range"
                    id="powerRange"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: powerRange,
                      onChange: (event) => setPowerRange(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    labelText="Coating"
                    id="coating"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: coating,
                      onChange: (event) => setCoating(event.target.value),
                    }}
                  />
                </GridItem>

                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    labelText="Price"
                    id="price"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: price,
                      onChange: (event) => setPrice(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={3} sm={3} md={3}>
                  <CustomInput
                    labelText="Threshold"
                    id="threshold"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: threshold,
                      onChange: (event) => setThreshold(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="HSN Code"
                    id="hsnCode"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: hsnCode,
                      onChange: (event) => setHsnCode(event.target.value),
                    }}
                  />
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <CustomInput
                    labelText="Tax Percent"
                    id="taxPercent"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: taxPercent,
                      onChange: (event) => setTaxPercent(event.target.value),
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ justifyContent: "center" }}>
              <Button color="primary" onClick={() => _onSave()}>
                Update Product Master
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
