import React from "react";
// @material-ui/core components
import {
  Box,
  Button,
  Dialog,
  makeStyles,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
} from "@material-ui/core";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import _ from "lodash";
import moment from "moment";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  { id: "productCode", label: "Product Code" },
  { id: "productName", label: "Product Name" },
  { id: "rate", label: "Rate" },
  { id: "addedQuantity", label: "Quantity" },
  { id: "total", label: "Total" },
];
export default function PurchaseView(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { open, close, item } = props;

  const { challanNo, billNo, products, gst, packagingAmount, netAmount, totalAmount, party, purchaseDate } = item;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
    >
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <Card>
            <CardHeader color="primary">
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h4 className={classes.cardTitleWhite}>Purchase Detail</h4>
                <Button style={{ color: "white" }} onClick={close}>
                  Close
                </Button>
              </Box>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={8} sm={8} md={8}>
                  <CustomInput
                    labelText="Party Name"
                    id="partyname"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: party.name,
                      disabled: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                  <CustomInput
                    labelText="Purchase Date"
                    id="purchaseDate"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: moment(purchaseDate).format("DD MMMM YYYY"),
                      disabled: true,
                    }}
                  />
                </GridItem>
                {challanNo ? (
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Challan No"
                      id="challanNo"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: challanNo,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                ) : null}
                {billNo ? (
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="Bill No"
                      id="billNo"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: billNo,
                        disabled: true,
                      }}
                    />
                  </GridItem>
                ) : null}
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Net Amount"
                    id="netAmount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: netAmount,
                      disabled: true,
                    }}
                  />
                </GridItem>
                {/* {billNo ? ( */}
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Packaging Cost"
                    id="packagingCost"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: packagingAmount,
                      disabled: true,
                    }}
                  />
                </GridItem>
                {/* ) : null} */}
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Total Amount"
                    id="totalAmount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: totalAmount,
                      disabled: true,
                    }}
                  />
                </GridItem>
                {_.isEmpty(products) ? null : (
                  <GridItem xs={12}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={"center"}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {products.map((row, index) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell key={column.id} align={"center"}>
                                    {value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </GridItem>
                )}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Dialog>
  );
}
